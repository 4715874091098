import { useState, useEffect } from 'react'
import { FaArrowLeft } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa";
import { MdOutlineUnfoldMore } from "react-icons/md";

const CombinedResult = (props) => {
    const [pageSize, setPageSize] = useState(25);
    const [numberBox, setNumberBox] = useState(0);
    const [boxList, setBoxList] = useState([])
    const [data, setData] = useState([])
    const [order, setOrder] = useState(1)

    const sorted = (index) => {
        const sortedData = [...props.combineResult].sort((a, b) => {
            const itemA = a[index];
            const itemB = b[index];
            if (order == 0) {
                if (typeof itemA === 'string' && typeof itemB === 'string') {
                    setOrder(1 - order);
                    return itemA.localeCompare(itemB);
                } else if (typeof itemA === 'number' && typeof itemB === 'number') {
                    setOrder(1 - order);
                    return itemA - itemB;
                } else {
                    setOrder(1 - order);
                    return 0; // No comparison possible, keep the order unchanged
                }
            } else {
                if (typeof itemA === 'string' && typeof itemB === 'string') {
                    setOrder(1 - order);
                    return itemB.localeCompare(itemA);
                } else if (typeof itemA === 'number' && typeof itemB === 'number') {
                    setOrder(1 - order);
                    return itemB - itemA;
                } else {
                    setOrder(1 - order);
                    return 0; // No comparison possible, keep the order unchanged
                }
            }
        });
        setData(sortedData);
        // console.log(sortedData);
    };
    useEffect(() => {
        sorted(0);
    }, [props.combineResult]);


    // useEffect(() => {
    //     let len = final_result.length;
    //     setBoxList(Array(parseInt(len / parseInt(pageSize)) + 1).fill(0))
    //     setData(final_result.slice(numberBox * pageSize, (numberBox + 1) * pageSize))
    //     setTemp(Array(props.finalProfitArray.length).fill(0));
    // }, [pageSize, numberBox,props.fullResult])

    return (
        <div className='p-4 bg-gray-50'>
            <div className="rounded-lg mt-4 mb-2 text-center">
                <h3 className="text-[#333B69] font-serif font-semibold text-xl">List Of Trades</h3>
            </div>
            <div className='overflow-auto'>
                <div className="flex flex-row justify-center">
                    <div className="w-full overflow-auto">
                        <div className="grid border-b-2 bg-gray-200 text-gray-800 font-semibold text-center" style={{ gridTemplateColumns: `repeat(${props.combineResult.length > 0 && props?.combineResult[0].length + 1}, 1fr)` }}>
                            <div className='p-3'>S.No</div>
                            {props.combineResult.length > 0 && props.combineResult[0].map((item, key) => (
                                <div className='p-3 flex items-center justify-center' key={`combined-${key}`}>
                                    {key === 0 && 'Date'}
                                    {key === 1 && 'Day'}
                                    {key === 2 && 'Compare'}
                                    {key > 2 && `Strats-${key - 2}`}
                                    <MdOutlineUnfoldMore className="ml-2 cursor-pointer text-blue-500" onClick={() => { sorted(key); }} />
                                </div>
                            ))}
                        </div>
                        {data.map((item, index) => (
                            <div className="grid border-b text-center bg-white hover:bg-gray-100" style={{ gridTemplateColumns: `repeat(${item.length + 1}, 1fr)` }} key={`data-${index}`}>
                                <div className='p-3 text-gray-700'>{index + 1}</div>
                                {item.map((value, key) => (
                                    <div className='p-3 text-gray-700' key={`item-${key}`}>{value}</div>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className="grid grid-cols-2 gap-4 mt-6">
                <div className="flex items-center">
                    <div className="text-gray-700 font-medium">Page Size</div>
                    <select className="ml-3 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500" value={pageSize} onChange={(e) => { setPageSize(e.target.value); }}>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="75">75</option>
                        <option value="100">100</option>
                    </select>
                </div>
                <div className="flex justify-end">
                    <div className="flex items-center space-x-2">
                        <div className='flex items-center justify-center rounded-md border-2 h-8 w-8 bg-blue-500 text-white cursor-pointer'>
                            <FaArrowLeft />
                        </div>
                        {boxList.map((item, key) => (
                            <div
                                className={`flex items-center justify-center rounded h-8 w-8 font-semibold text-center text-blue-600 cursor-pointer border-2 border-blue-200 ${key === numberBox ? 'bg-blue-100' : 'bg-white'}`}
                                key={key}
                                onClick={() => { setNumberBox(key); }}
                            >
                                {key + 1}
                            </div>
                        ))}
                        <div className='flex items-center justify-center rounded-md border-2 h-8 w-8 bg-blue-500 text-white cursor-pointer'>
                            <FaArrowRight />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CombinedResult