import React, { useState, useEffect } from 'react'
import { Dialog } from '@mui/material';
import axios from 'axios';
import FooterVersion2 from '../Layout/FooterVersion2';
import secureLocalStorage from 'react-secure-storage';
import Navbar from '../Layout/Navbar'
import ProfileUpload from './ProfileUpload'
import { PROFILE_DETAIL, UPDATE_PROFILE_DETAIL, REFERRAL_URL, PAYMENT_HISTORY_API } from '../../constant/constant';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from "react-toastify";
import { MdClose } from "react-icons/md";
import { Audio } from 'react-loader-spinner'
import useBacktestStore from '../../store/backtestStore';
import { FaEnvelope } from 'react-icons/fa';


const Profile = () => {
  const [name, setName] = useState('')
  const [address, setAddress] = useState('')
  const [broker, setBroker] = useState('')
  const [gender, setGender] = useState('')
  const [contact, setContact] = useState('')
  const [profile, setProfile] = useState(null);
  const [profilePic, setProfilePic] = useState(null)
  const [activeAccount, setActiveAccount] = useState(false);
  const [activeComponent, setActiveComponent] = useState("profile");
  const [editProfile, setEditProfile] = useState(false);
  const navigate = useNavigate();
  // refer
  const [referName, setReferName] = useState('')
  const [referEmail, setReferEmail] = useState('')
  const [wait, setWait] = useState(false);

  const updateProfile = () => {
    // Get the token from secureLocalStorage
    let token = secureLocalStorage.getItem('backtest_token');

    // Remove the 'backtest_' prefix if it exists
    if (token && token.startsWith('backtest_')) {
      token = token.replace('backtest_', '');
    }else{
      navigate('/backtest/login');
    }
    const data = { "name": name, "address": address, "broker": broker, "gender": gender, "contact": contact, "email": secureLocalStorage.getItem('backtest_email') }
    let config = {
      method: 'patch',
      maxBodyLength: Infinity,
      url: UPDATE_PROFILE_DETAIL,
      headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}`, },
      data: data
    };

    axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        alert("Profile Update Successfully")
        profileDetail();
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          navigate('/backtest/login');
        } else if (error?.response?.data?.message) {
          toast.error(error.response.data.message)
        } else {
          toast.error('Network Issue');
        }
        console.log(error, 'error at on handle update profile')
      });
  }


  const profileDetail = () => {
    const id = secureLocalStorage.getItem('backtest_id');
    // Get the token from secureLocalStorage
    let token = secureLocalStorage.getItem('backtest_token');

    // Remove the 'backtest_' prefix if it exists
    if (token && token.startsWith('backtest_')) {
      token = token.replace('backtest_', '');
    }else{
      navigate('/backtest/login');
    }
    const data = { "id": id }
    // console.log(data);
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: PROFILE_DETAIL,
      headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}`, },
      data: data
    };
    axios.request(config)
      .then((response) => {
        setProfile(response.data.data);
        console.log(response.data.data)
        setName(response?.data?.data?.name);
        setGender(response?.data?.data?.gender);
        setBroker(response?.data?.data?.broker);
        setAddress(response?.data?.data?.address);
        setContact(response?.data?.data?.contact);
        secureLocalStorage.setItem('phone', response?.data?.data?.contact)

      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          navigate('/backtest/login');
        } else if (error?.response?.data?.message) {
          toast.error(error.response.data.message)
        } else {
          toast.error('Network Issue');
        }
        console.log(error, 'error at on get profile detail')
      });
  }

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const referral = () => {
    const id = secureLocalStorage.getItem('backtest_id');
    // Get the token from secureLocalStorage
    let token = secureLocalStorage.getItem('backtest_token');

    // Remove the 'backtest_' prefix if it exists
    if (token && token.startsWith('backtest_')) {
      token = token.replace('backtest_', '');
    }else{
      navigate('/backtest/login');
    }
    const email = secureLocalStorage.getItem('backtest_email')
    const username = secureLocalStorage.getItem('backtest_name')
    const data = { "email": referEmail, "name": referName, "referEmail": email, "referName": username }

    if (!validateEmail(email)) {
      setWait(false);
      toast("Please enter valid referral email!!");
      return;
    }
    console.log(data, 'referral');
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: REFERRAL_URL,
      headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}`, },
      data: data
    };
    axios.request(config)
      .then(() => {
        toast('Successfully sent the referral link.')
        setWait(false);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          navigate('/backtest/login');
        } else if (error?.response?.data?.message) {
          toast.error(error.response.data.message)
        } else {
          toast.error('Network Issue');
        }
        console.log(error, 'error at on handle referral')
        setWait(false);
      });
  }
  useEffect(() => {
    profileDetail();
    const active = secureLocalStorage.getItem('active');
    setActiveAccount(active);
  }, [])
  return (
    <>
      <ToastContainer />
      <div className='bg-[#DAECF2]'>
        <div className="border-b-4 border-blue-500 fixed w-full z-10">
          <Navbar profilePic={profilePic} />
        </div>
        {!activeAccount && <div className="text-red-600 pt-16 z-0 text-center">**Your account is not active.It will be active within 24hrs.You have to re-login for access backtest tool.</div>}
        {activeAccount && <div className='pt-16'></div>}

        <div className='rounded mt-[40px] flex text-center justify-center'>
          <div className='flex flex-col bg-white rounded-3xl w-full m-2 md:w-10/12 lg:w-8/12 mb-10'>
            <div className='grid grid-cols-3 text-center '>
              <div className="cursor-pointer font-[600] text-[22px] p-2 text-black border-b-2" style={{ borderColor: activeComponent == "profile" ? "blue" : "white" }} onClick={() => { setActiveComponent("profile") }}>Profile</div>
              <div className='cursor-pointer font-[600] text-[22px] p-2 text-black border-b-2' style={{ borderColor: activeComponent == "plan" ? "blue" : "white" }} onClick={() => { setActiveComponent("plan"); }}>Plan Detail</div>
              <div className='cursor-pointer font-[600] text-[22px] p-2 text-black border-b-2' style={{ borderColor: activeComponent == "transaction" ? "blue" : "white" }} onClick={() => { setActiveComponent("transaction"); }}>Transaction</div>
            </div>
            <div className="grid grid-cols-1 h-full">
              {activeComponent == "profile" && <div className="flex flex-col sm:flex-row bg-white rounded-lg  shadow" >
                <div className="flex text-center justify-center p-4">
                  <ProfileUpload setProfilePic={setProfilePic} />
                </div>
                <div className="p-4 text-left flex-grow">
                  <div className="grid grid-cols-1 mt-3">
                    <label className="text-[16px] font-serif" >Your Name  </label>
                    <input className="flex-grow justify-end  border-[1px] border-[#DFEAF2] rounded text-[18px] text-[#718EBF] leading-4 pl-1" value={profile?.name} disabled={true}></input>
                  </div>
                  <div className="grid grid-cols-1 mt-3">
                    <label className="text-[16px] font-serif" >Email</label>
                    <input className="flex-grow justify-end  border-[1px] border-[#DFEAF2] rounded text-[18px] text-[#718EBF] leading-4 pl-1 " value={secureLocalStorage.getItem('backtest_email')} disabled={true}></input>
                  </div>
                  <div className="grid grid-cols-1 mt-3">
                    <label className="text-[16px] font-serif">Contact Number  </label>
                    <input className="flex-grow justify-end  border-[1px] border-[#DFEAF2] rounded text-[18px] text-[#718EBF] leading-4 pl-1" value={profile?.contact} disabled={true}></input>
                  </div>
                  <div className="grid grid-cols-1 mt-3">
                    <label className="text-[16px] font-serif">Current Home Address  </label>
                    <input className="flex-grow justify-end  border-[1px] border-[#DFEAF2] rounded text-[18px] text-[#718EBF] leading-4 pl-1" value={profile?.address} disabled={true}></input>
                  </div>
                  <div className="grid grid-cols-1 mt-3">
                    <label className="text-[16px] font-serif">Gender  </label>
                    <input className="flex-grow justify-end  border-[1px] border-[#DFEAF2] rounded text-[18px] text-[#718EBF] leading-4 pl-1" value={profile?.gender} disabled={true}></input>
                  </div>
                  <div className="grid grid-cols-1 mt-3">
                    <label className="text-[16px] font-serif">Broker  </label>
                    <input className="flex-grow justify-end  border-[1px] border-[#DFEAF2] rounded text-[18px] text-[#718EBF] leading-4 pl-1" value={profile?.broker} disabled={true}></input>
                  </div>
                  <div className="grid grid-cols-2 rounded mt-3">
                    <button style={{ backgroundColor: "#1814F3", color: "white", height: "40px", width: "190px", borderRadius: "15px", fontSize: "18px", lineHeight: "21.78px", fontWeight: "500" }} onClick={() => { setEditProfile(true); }}>Edit Profile</button>
                  </div>
                </div>
              </div>}
              {activeComponent == "plan" && <PlanDetail />}
              {activeComponent == "transaction" && <Transaction />}
            </div>
          </div>
        </div>

        {/* Referral form */}
        <div className='flex text-center justify-center'>
          <div className='m-5 rounded-xl flex flex-col gap-2 shadow-md p-2 bg-white  w-full md:w-10/12 lg:w-8/12'>
            <div className='text-2xl font-semibold text-left'>REFER &  EARN COIN</div>
            <div className='text-xl font-medium flex flex-row'><FaEnvelope className='text-gray-500 mr-2' />Get 25 coins per successful referral signup.Use these coins for backtest strategy analysis result.</div>
            <div className='text-left font-semibold text-xl'>Refer by email</div>
            <input type="text" placeholder='Enter Email' value={referEmail} onChange={(e) => { setReferEmail(e.target.value); }} className='border-[1px] border-gray-500 p-1' />
            <input type="text" placeholder='Enter Name' value={referName} onChange={(e) => { setReferName(e.target.value); }} className='border-[1px] border-gray-500 p-1' />
            {!wait && <div className='bg-blue-600 text-white p-1 w-20 cursor-pointer' onClick={() => { setWait(true); referral(); }}>Refer</div>}
            {wait && <Audio />}
          </div>
        </div>

      </div>




      <FooterVersion2 />
      {/* Confirmation to Delete saved Strategy */}
      <Dialog className="border-2 border-t-8 border-blue-500 rounded-xl w-full" maxWidth="xl" minWidth="l" onClose={(event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') { setEditProfile(false); }
      }} open={editProfile}>
        <div className="flex flex-row border-[1px] w-[680px]">
          <div className="text-[#232323] text-[22px] font-[600] p-2">Edit profile</div>
          <div className="text-[22px] font-[600] text-[#232323] flex-grow flex justify-end p-2 cursor-pointer"><MdClose onClick={() => { setEditProfile(false); }} /></div>
        </div>
        <div className="flex flex-col text-left m-2">
          <label className="text-[16px] font-serif" >Your Name*  </label>
          <input className="flex-grow justify-end  border-[1px] border-[#DFEAF2] rounded text-[18px] outline-0  text-[#718EBF] leading-4 pl-1" value={name} onChange={(e) => { setName(e.target.value); }}></input>
          <label className="text-[16px] font-serif" >Email*</label>
          <input className="flex-grow justify-end  border-[1px] border-[#DFEAF2] rounded text-[18px] outline-0 text-[#718EBF] leading-4 pl-1 " disabled={true} value={secureLocalStorage.getItem('backtest_email')} ></input>
          <label className="text-[16px] font-serif">Contact Number*  </label>
          <input className="flex-grow justify-end  border-[1px] border-[#DFEAF2] rounded text-[18px] outline-0 text-[#718EBF] leading-4 pl-1" value={contact} onChange={(e) => { setContact(e.target.value); }} ></input>
          <label className="text-[16px] font-serif">Current Home Address  </label>
          <input className="flex-grow justify-end  border-[1px] border-[#DFEAF2] rounded text-[18px] outline-0 text-[#718EBF] leading-4 pl-1" value={address} onChange={(e) => { setAddress(e.target.value); }} ></input>
          <label className="text-[16px] font-serif">Gender  </label>
          <select className="flex-grow justify-end  border-[1px] border-[#DFEAF2] rounded text-[18px] outline-0 text-[#718EBF] leading-4 pl-1" value={gender} onChange={(e) => { setGender(e.target.value); }} >
            <option value=''>Select Gender</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Others">Other</option>
          </select>
          <label className="text-[16px] font-serif">Broker  </label>
          <input className="flex-grow justify-end  border-[1px] border-[#DFEAF2] rounded text-[18px] outline-0 text-[#718EBF] leading-4 pl-1" value={broker} onChange={(e) => { setBroker(e.target.value); }} ></input>
          <hr className="mt-2 bg-blue-400"></hr>
          <div className="flex justify-end gap-2 p-1">
            <button className="border-2 p-1 w-24 bg-[#2D5BFF] rounded-lg justify-center text-center text-white cursor-pointer" onClick={() => { updateProfile(); setEditProfile(false); }}>Save</button>
          </div>
        </div>
      </Dialog>
    </>
  )
}

export default Profile

const PlanDetail = () => {
  const activePlan = secureLocalStorage.getItem('activePlan');
  const [expiryDate, setExpiryDate] = useState(secureLocalStorage.getItem('expire') || null);
  const { coin } = useBacktestStore();

  function formatDate(timestamp) {
    if (timestamp === null || timestamp === 'undefined') {
      return "NA";
    }
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  useEffect(() => {
    setExpiryDate(formatDate(expiryDate))
  }, [])

  return (
    <>
      <div className="bg-white rounded-lg shadow grid grid-cols-1 h-[450px]">
        <div className="shadow rounded m-10">
          <h1 className="p-2  font-serif bg-blue-300 rounded font-[600] text-[#333B69]">Running Plan Details</h1>
          <div className="grid grid-cols-1 text-left justify-center md:grid-cols-1 lg:grid-cols-1">
            <div className="grid grid-cols-1 sm:grid-cols-2 p-2 border-b-2">
              <h2 className="text-[16px] font-serif">Active Plan</h2>
              <h2 className="  text-blue-700 text-l">{activePlan?.name || "NA"}</h2>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 p-2 border-b-2">
              <h2 className="text-[16px] font-serif">Remaining Credit  </h2>
              <h2 className="  text-blue-700 text-l">{coin}</h2>
            </div>
            <div className=" grid grid-cols-1 sm:grid-cols-2 p-2 bg-blue-200">
              <h2 className="text-[16px] font-serif ">Expiry Date Of Plan  </h2>
              <h2 className="text-blue-700 text-l">{expiryDate || "NA"}</h2>

            </div>
            {/* <div className="grid grid-cols-1 sm:grid-cols-2 p-2">
              <h2 className="text-[16px] font-serif">Total Coin Collected  </h2>
              <h2 className="text-blue-700 text-l">-------------------</h2>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 p-2 bg-blue-200">
              <h2 className="text-[16px] font-serif">Broker  </h2>
              <h2 className="text-blue-700 text-l">-------------------</h2>
            </div> */}
          </div>
        </div>
      </div>
    </>
  )
}

const Transaction = () => {
  const [paymentHistory, setPaymentHistory] = useState([]);

  function formatDate(timestamp) {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  // Function to format the time as HH:mm
  function formatTime(timestamp) {
    console.log(timestamp)
    const date = new Date(timestamp.replace('Z', ''));
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  }

  const fetchPaymentHistory = async () => {
    let config = {
      method: 'get',
      url: PAYMENT_HISTORY_API + secureLocalStorage.getItem('backtest_email'),
      headers: { "Content-Type": "application/json", "Authorization": `Bearer ${secureLocalStorage.getItem('backtest_token')}`, },
    };

    axios.request(config)
      .then((response) => {
        console.log(response);
        setPaymentHistory(response.data);
      })
      .catch((error) => {
        console.log(error)
        console.log(error, 'error at on fetch payment history')
      });

  }
  useEffect(() => {
    fetchPaymentHistory();
  }, [])

  return (
    <>
      {/* Added transaction history table */}
      <div className="bg-white  border-2 p-3 rounded-lg overflow-x-none overflow-y-auto justify-center h-[450px]">
        <h3 className="-mt-3 -ml-3 -mr-3 p-3 text-[#333B69] font-[600]">TRANSACTION HISTORY</h3>
        <hr></hr>
        <table className=" overflow-y-none overflow-x-auto w-full">
          <thead className="text-black text-center justify-center">
            <tr className='bg-blue-400'>
              <td className="font-medium font-serif text-[18px]">Transaction Id</td>
              <td className="font-medium font-serif text-[18px]">Package</td>
              <td className="font-medium font-serif text-[18px]">Date</td>
              <td className="font-medium font-serif text-[18px]">Time</td>
              <td className="font-medium font-serif text-[18px]">Validation</td>
              <td className="font-medium font-serif text-[18px]">Status</td>
            </tr>
          </thead>
          {paymentHistory.length > 0 && paymentHistory.map((item, index) => {
            var activeClass = index % 2 === 0 ? '' : 'bg-gray-200';
            return (
              <>
                <tbody className={activeClass} key={`transaxtion-${index}`}>
                  <tr className='text-[12px]'>
                    <td className="p-2 text-[14px]">{item.txId}</td>
                    <td className="p-2 text-[14px]">{item.plan.name}</td>
                    <td className="p-2 text-[14px]">{formatDate(item.createdAt['$date'])}</td>
                    <td className="p-2 text-[14px]">{formatTime(item.createdAt['$date'])}</td>
                    <td className="p-2 text-[14px]">{`${item.plan.validity} days`}</td>
                    <td className="p-2 text-[14px]">{item.status}</td>
                  </tr>
                </tbody>
              </>
            )
          })}

        </table>
        {
          paymentHistory.length == 0 &&
          <div className='flex text-center justify-center text-2xl font-semibold mt-5'>No transaction or payment has been made.</div>
        }
      </div>
    </>
  )
}
