export const statisticalResultPresentation = (worksheet5, inputValue) => {
    // Define color styles for a more impressive design
    const borderStyle = { style: 'thin', color: { argb: '4F81BD' } }; // Border color
    const headerFillColor = '0070C0'; // Dark blue header background color
    const headerFontColor = 'FFFFFF'; // White header text color
    const defaultFontColor = '333333'; // Dark gray default text color
    const noteFontColor = 'A6A6A6'; // Lighter gray for notes
    const cellFillColor = 'E6F7FF'; // Light blue fill for data cells
    const profitFontColor = '28A745'; // Green for profit
    const lossFontColor = 'DC3545'; // Red for loss

    // Helper function to set cell properties
    const setCellProperties = (cell, value, mergeRange, alignment = 'center', fill = null, fontColor = null) => {
        cell.value = value;
        if (mergeRange) {
            try {
                worksheet5.mergeCells(mergeRange); // Attempt to merge cells
            } catch (error) {
                // console.error(`Failed to merge cells for range ${mergeRange}: ${error.message}`);
            }
        }
        cell.alignment = { horizontal: alignment, vertical: 'middle' };
        cell.border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
        if (fill) cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: fill } };
        if (fontColor) cell.font = { color: { argb: fontColor }, bold: true,size:13 }; // Bold text for headers
    };

    // Section Header
    setCellProperties(
        worksheet5.getCell('B36'), 
        'STATISTICAL RESULT', 
        'B36:K36', 
        'center', 
        headerFillColor, 
        headerFontColor
    );

    // Statistical Result Labels and Values
    const statRows = [
        { label: 'Total Margin', value: inputValue.margin, row: 38 },
        { label: 'Total Profit', value: inputValue.profit, row: 39 },
        { label: 'Maximum Drawdown', value: inputValue.maximumDrawdown, row: 40 },
        { label: 'Max Profit', value: inputValue.maxProfit, row: 41 },
        { label: 'Max Loss', value: inputValue.maxLoss, row: 42 },
        { label: 'Win Days', value: inputValue.winTrade, row: 43 },
        { label: 'Loss Days', value: inputValue.lossTrade, row: 44 },
        { label: 'Average Profit on Win Days', value: inputValue.avgProfit, row: 45 },
        { label: 'Average Loss on Loss Days', value: inputValue.avgLoss, row: 46 },
        { label: 'Investment Days', value: inputValue.investmentDay, row: 47 },
        { label: 'Expectancy', value: '', row: 48 }, // Placeholder for expectancy
        { label: 'Max Continuous Profit Streak', value: inputValue.maxStreak, row: 49 },
        { label: 'Max Continuous Loss Streak', value: inputValue.lossStreak, row: 50 },
        { label: 'Leg in Strategies', value: inputValue.leg || 'N/A', row: 51 },
        { label: 'MDD (Recovery period)', value: `${inputValue.mdd.recovery} days`, row: 52 },
        { label: 'Return to MDD Ratio', value: '', row: 53 } // Placeholder for Return to MDD Ratio
    ];

    statRows.forEach(({ label, value, row }) => {
        setCellProperties(worksheet5.getCell(`B${row}`), label, `B${row}:D${row}`, 'left', null, defaultFontColor);
        
        // Set different colors based on profit or loss
        const isProfit = label.includes('Profit') || label.includes('Win Days') || label.includes('Streak');
        const isLoss = label.includes('Loss') || label.includes('Drawdown') || label.includes('Loss Days');

        // Use green for profits and red for losses
        const valueColor = isProfit ? profitFontColor : isLoss ? lossFontColor : defaultFontColor;

        setCellProperties(worksheet5.getCell(`E${row}`), value, `E${row}:F${row}`, 'center', null, valueColor);
    });

    // Row placeholders with dashed values for MDD
    // ['E52', '53'].forEach(cell => {
    //     setCellProperties(
    //         worksheet5.getCell(cell), 
    //         '----------', 
    //         `${cell}:F${cell}`, 
    //         'center', 
    //         null, 
    //         noteFontColor // Note color for placeholders
    //     );
    // });

    // Slippages section
    setCellProperties(worksheet5.getCell('B56'), 'Slippages', 'B50:D50', 'left', headerFillColor, headerFontColor);
    worksheet5.mergeCells('B56:D56')
    setCellProperties(worksheet5.getCell('B57'), 'Vix', 'B51:D51', 'left', headerFillColor, headerFontColor);
    worksheet5.mergeCells('B57:D57')
    setCellProperties(worksheet5.getCell('E56'), 0, 'E50:F50', 'center');
    setCellProperties(worksheet5.getCell('E57'), '-----', 'E51:F51', 'center', null, noteFontColor);

    // Slippages Note
    setCellProperties(
        worksheet5.getCell('G56'), 
        '(0.5% Slippages are already included for options strategies)', 
        'G56:M56', 
        'left', 
        null, 
        noteFontColor
    );

    // Adjust column widths for better readability
    worksheet5.getColumn('B').width = 25; // Wider for labels
    worksheet5.getColumn('E').width = 20; // Wider for values
    worksheet5.getColumn('G').width = 25; // Wider for notes
};
