import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware'

const bucketResultStore = (set) => ({
  resultFilter: [],
  addNewResultFilter: (newFilter) => {
    set((state) => ({
      resultFilter: [...state.resultFilter, newFilter]
    }));
  },
  updateResultFilterStrategy: (index, newFilter) => {
    set((state) => {
      let res = [...state.resultFilter]; // Create a copy of the current resultFilter array
      res[index] = newFilter; // Update the specific index with newFilter
      return {
        resultFilter: res // Return the updated state
      };
    });
  },
  updateResultFilterBucket: (newFilter) => {
    set({
      resultFilter:newFilter
    })
  }

})

// Now,We will make a above code as a store and perform action in another component to perform it.
const useBucketResultStore = create(
  devtools(
    persist(bucketResultStore, {
      name: "bucketResult",
    })
  )
)


export default useBucketResultStore;