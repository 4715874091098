import React, { useEffect, useState } from 'react';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import useBucketStore from '../../store/BucketStore';
import { ADD_STRATEGY_BUCKET } from '../../constant/constant';
import { extractErrorMessage } from '../../utility/utils';
import { Dialog } from '@mui/material';
// icons
import { MdClose,MdAppRegistration,MdAddBox } from 'react-icons/md';


const StrategyDefault = (props) => {
    // Advanced Setting
    const [lotSize, setLotSize] = useState(1)
    const [target, setTarget] = useState(10)
    const [stoploss, setStoploss] = useState(10);
    const [profitMoves, setProfitMoves] = useState(10);
    const [stoplossMoves, setStoplossMoves] = useState(10);
    const [waitTrade, setWaitTrade] = useState(10);
    const [selectedStrategy, setSelectedStrategy] = useState([true, true, true, false, false, false, false])
    const [targetChoice, setTargetChoice] = useState('TP%')
    const [stoplossChoice, setStoplossChoice] = useState('SL%')
    const [trailLossChoice, setTrailLossChoice] = useState('point')
    const [waitTradeChoice, setWaitTradeChoice] = useState('% --')
    const [reEntryChoice, setReEntryChoice] = useState('SL')
    const [reEntryTimes, setReEntryTimes] = useState(2)
    const [reExecuteChoice, setReExecuteChoice] = useState('SL')
    const [reExecuteTimes, setReExecuteTimes] = useState(4);
    const [strategy,setStrategy]=useState([]);

    const [defaultStrategyVisible, setAddDefaultStrategyVisible] = useState(false);

    const {activeBasketName,updateStatsInclude,updateStatsMultiplier,include,updateSlippage,slippage,strategyMultiplier}=useBucketStore();
    const {preDefinedStrategy,strategyChecked,updateStrategyCheckedAll,addStrategiesOnActiveBasket}=useBucketStore();
    const {dayOption,updateDayOption}=useBucketStore();
    const navigate = useNavigate();


    const updateSelectedStrategy = (index) => {
        const input = [...selectedStrategy];
        input[index] = !input[index];
        setSelectedStrategy(input);
    }

    //  method to add strategy into basket
    const add_strategy_bucket = (strategy) => {
        const id = secureLocalStorage.getItem("backtest_id");
        const inputdata = {
            'name': strategy?.name,
            'array': strategy.array,
            'dayoption': [1, 1, 1, 1, 1],
            'variant': ['contained', 'contained', 'contained', 'contained', 'contained']
        };
        if (activeBasketName === '') {
            toast.warn('Please choose any basket from list');
            return;
        }
        // Get the token from secureLocalStorage
        let token = secureLocalStorage.getItem('backtest_token');

        // Remove the 'backtest_' prefix if it exists
        if (token && token.startsWith('backtest_')) {
            token = token.replace('backtest_', '');
        } else {
            navigate('/backtest/login');
        }
        let config = { method: "post", url: `${ADD_STRATEGY_BUCKET}/${id}/${activeBasketName}`, headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}`, }, data: inputdata };
        axios
            .request(config)
            .then((response) => {
                // update some state when we are adding strategy of active Basket
                addStrategiesOnActiveBasket(inputdata);
                updateStrategyCheckedAll([...strategyChecked, true]);
                props.setExecutedStrategy([...props.executedStrategy, 0]);
                props.setResult([...props.result, {}]);
                updateDayOption([...dayOption, [1, 1, 1, 1, 1]]);
                updateStatsMultiplier([...strategyMultiplier, 1]);
                updateStatsInclude([...include, true]);
                updateSlippage([...slippage, 0]);
                toast.success('Strategy Added Successfully!!!');
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    navigate('/backtest/login');
                } else {
                    toast.error(extractErrorMessage(error));
                }
                console.log(error, 'error at add strategy')
            });
    }

    // advanced strategy method to add
    const handleAddCombineStrategy = () => {
        let temp_strategy = strategy; // copy of strategy
        let length = temp_strategy?.array?.leg;
        for (let key = 0; key < 7; key++) {
            // for reEntry
            if (selectedStrategy[key] === true) {
                if (key === 0) {
                    temp_strategy.array.lotarray = Array(length).fill(lotSize);
                    temp_strategy.array.lotarray1 = temp_strategy.array.tradesymbol === 'NIFTY' ? Array(length).fill(lotSize * 50) : Array(length).fill(lotSize * 50);
                } else if (key === 1) {
                    // for target
                    temp_strategy.array.legsTargetProfit = new Array(length).fill(target);
                    temp_strategy.array.legsTargetProfitType = new Array(length).fill(targetChoice);
                } else if (key === 2) {
                    // for stoploss
                    temp_strategy.array.legsStoploss = new Array(length).fill(stoploss);
                    temp_strategy.array.legsStoplossType = new Array(length).fill(stoplossChoice);
                } else if (key === 3) {
                    // for trailLoss
                    temp_strategy.array.trailStoploss = new Array(length).fill(true);
                    temp_strategy.array.trailStoplossLoss = new Array(length).fill(stoplossMoves);
                    temp_strategy.array.trailStoplossProfit = new Array(length).fill(profitMoves);
                    temp_strategy.array.trailStoplossType = new Array(length).fill(trailLossChoice);
                } else if (key === 4) {
                    temp_strategy.array.reEntry = true;
                    temp_strategy.array.reEntryValue = new Array(length).fill(reEntryTimes);
                    temp_strategy.array.reEntryType = new Array(length).fill(reEntryChoice)
                    temp_strategy.array.reEntryChoice = new Array(length).fill("Re-Entry");
                    temp_strategy.array.legsReEntry = new Array(length).fill(true);
                } else if (key === 5) {
                    // for reExecution
                    // console.log(reExecuteChoice,reEntryTimes);
                    temp_strategy.array.reEntry = true;
                    temp_strategy.array.reEntryType = new Array(length).fill(reExecuteChoice)
                    temp_strategy.array.reEntryValue = new Array(length).fill(reExecuteTimes);
                    temp_strategy.array.reEntryChoice = new Array(length).fill("ReExecute");
                    temp_strategy.array.legsReEntry = new Array(length).fill(true);
                } else if (key === 6) {
                    // for waitTrade
                    // console.log(waitTrade,waitTradeChoice,'wait trade')
                    temp_strategy.array.waitTrade = true;
                    temp_strategy.array.waitTradeType = new Array(length).fill(waitTradeChoice);
                    temp_strategy.array.waitTradeValue = new Array(length).fill(waitTrade);
                }
            }
        }
        temp_strategy.array.atmprice = 'future';
        add_strategy_bucket(temp_strategy);

    }
    useEffect(()=>{
    preDefinedStrategy.length>0 && setStrategy(preDefinedStrategy[0]);
    },[])

  

    return (
        <>
        <button
          className="p-2 bg-gradient-to-r from-blue-500 to-blue-700 text-white rounded-md shadow-md hover:shadow-lg transition-transform duration-300 flex items-center justify-center gap-2"
          onClick={() => setAddDefaultStrategyVisible(true)}
        >
          <MdAddBox className="text-2xl font-semibold" aria-hidden="false" />
          Add Default Strategy
        </button>
        
            {/* Dialog Box for Default Strategy */}
            <Dialog className="border-2 border-t-8 border-blue-500 rounded-xl" maxWidth="xl" onClose={(event, reason) => {
                if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') { setAddDefaultStrategyVisible(false); }
            }} open={defaultStrategyVisible}>
                <div className="flex flex-row border-b-2 justify-between items-center bg-blue-400 text-white">
                    <div className="text-xl p-2 w-96">Add Default Strategy</div>
                    <div className="text-xl flex justify-end font-semibold p-2 cursor-pointer">
                        <MdClose className="text-2xl" onClick={() => { setAddDefaultStrategyVisible(false); }} />
                    </div>
                </div>

                <div className="m-2">
                    <div className="flex flex-col gap-2 overflow-auto p-2">
                        <div className="grid grid-cols-8 gap-2 mr-4 min-w-[1400px]">
                            <div className="flex flex-row justify-start">
                                <div className="flex flex-row"><MdAppRegistration className='text-2xl'/> Saved&nbsp;Strategy</div>
                            </div>
                            <div className="flex flex-row justify-start">
                                <input type="checkbox" className="w-6 mt-1 h-4 outline-none" checked={selectedStrategy[0]} onChange={() => { updateSelectedStrategy(0); }} />
                                <div className="">Lot</div>
                            </div>
                            <div className="flex flex-row justify-start">
                                <input type="checkbox" className="w-6 mt-1 h-4 outline-none" checked={selectedStrategy[1]} onChange={() => { const inputdata = [...selectedStrategy]; inputdata[1] = !selectedStrategy[1]; setSelectedStrategy(inputdata); }} />
                                <div className="">Target</div>
                            </div>
                            <div className="flex flex-row justify-start">
                                <input type="checkbox" className="w-6 mt-1 h-4 outline-none" checked={selectedStrategy[2]} onChange={() => { const inputdata = [...selectedStrategy]; inputdata[2] = !selectedStrategy[2]; setSelectedStrategy(inputdata); }} />
                                <div className="">Stoploss</div>
                            </div>
                            <div className="flex flex-row justify-start">
                                <input type="checkbox" className="w-6 mt-1 h-4 outline-none" checked={selectedStrategy[3]} onChange={() => { selectedStrategy[2] && updateSelectedStrategy(3); }} />
                                <div className="">TrailStoploss</div>
                            </div>
                            <div className="flex flex-row justify-start">
                                <input type="checkbox" className="w-6 mt-1 h-4 outline-none" checked={selectedStrategy[4]} onChange={() => { !selectedStrategy[4] ? selectedStrategy[2] && updateSelectedStrategy(4) : updateSelectedStrategy(4); }} />
                                <div className="">ReEntry</div>
                            </div>
                            <div className="flex flex-row justify-start">
                                <input type="checkbox" className="w-6 mt-1 h-4 outline-none" checked={selectedStrategy[5]} onChange={() => { !selectedStrategy[5] ? (selectedStrategy[2] || selectedStrategy[1]) && updateSelectedStrategy(5) : updateSelectedStrategy(5); }} />
                                <div className="">ReExecute</div>
                            </div>
                            <div className="flex flex-row justify-start">
                                <input type="checkbox" className="w-6 mt-1 h-4 outline-none" checked={selectedStrategy[6]} onChange={() => { updateSelectedStrategy(6); }} />
                                <div className="">Wait&nbsp;Trade</div>
                            </div>
                        </div>
                        <div className="grid grid-cols-8 gap-2  min-w-[1400px]">
                            <select type="number" className="outline-none border-2 rounded p-1" onChange={(e) => { setStrategy(JSON.parse(e.target.value)); }}>
                                {preDefinedStrategy.map((item, key) => {
                                    return (
                                        <option value={JSON.stringify(item)} key={`pre-defined-${key}`}>{item.name}</option>
                                    )
                                })}
                            </select>
                            <input type="number" className="outline-none border-2 rounded p-1 text-center" disabled={!selectedStrategy[0]} value={lotSize} onChange={(e) => { setLotSize(e.target.value); }}></input>
                            <div className="grid grid-cols-2">
                                <select value={targetChoice} className="outline-none border-2 rounded p-1" disabled={!selectedStrategy[1]} onChange={(e) => { setTargetChoice(e.target.value); }}>
                                    <option value="TP%">TP%</option>
                                    <option value="TP Point">TP Point</option>
                                </select>
                                <input type="number" className="outline-none border-2 rounded p-1 text-center" disabled={!selectedStrategy[1]} value={target} onChange={(e) => { setTarget(e.target.value); }}></input>
                            </div>
                            <div className="grid grid-cols-2">
                                <select value={stoplossChoice} className="outline-none border-2 rounded p-1" disabled={!selectedStrategy[2]} onChange={(e) => { setStoplossChoice(e.target.value); }}>
                                    <option value="SL%">SL%</option>
                                    <option value="SL Point">SL Point</option>
                                </select>
                                <input type="number" className="outline-none border-2 rounded p-1  text-center" disabled={!selectedStrategy[2]} value={stoploss} onChange={(e) => { setStoploss(e.target.value); }}></input>
                            </div>
                            <div className="grid grid-cols-3">
                                <input type="number" className="outline-none border-2 rounded p-1 text-center" disabled={!selectedStrategy[3]} value={profitMoves} onChange={(e) => { setProfitMoves(e.target.value); }}></input>
                                <input type="number" className="outline-none border-2 rounded p-1 text-center" disabled={!selectedStrategy[3]} value={stoplossMoves} onChange={(e) => { setStoplossMoves(e.target.value); }}></input>
                                <select value={trailLossChoice} className="outline-none border-2 rounded p-1" disabled={!selectedStrategy[3]} onChange={(e) => { setTrailLossChoice(e.target.value); }}>
                                    <option value="point">point</option>
                                    <option value="percent">percent</option>
                                </select>
                            </div>
                            <div className="grid grid-cols-2">
                                <select value={reEntryChoice} className="outline-none border-2 rounded p-1" disabled={!selectedStrategy[4]} onChange={(e) => { setReEntryChoice(e.target.value); }}>
                                    <option value="SL">SL</option>
                                </select>
                                <select value={reEntryTimes} className="outline-none border-2 rounded p-1" disabled={!selectedStrategy[4]} onChange={(e) => { setReEntryTimes(e.target.value); }}>
                                    <option value={1}>1</option>
                                    <option value={2}>2</option>
                                    <option value={3}>3</option>
                                    <option value={4}>4</option>
                                    <option value={5}>5</option>
                                </select>
                            </div>
                            <div className="grid grid-cols-2">
                                <select value={reExecuteChoice} className="outline-none border-2 rounded p-1" disabled={!selectedStrategy[5]} onChange={(e) => { setReExecuteChoice(e.target.value); }}>
                                    <option value="SL">SL</option>
                                    <option value="TP">TP</option>
                                </select>
                                <select value={reExecuteTimes} className="outline-none border-2 rounded p-1" disabled={!selectedStrategy[5]} onChange={(e) => { setReExecuteTimes(e.target.value); }}>
                                    <option value={1}>1</option>
                                    <option value={2}>2</option>
                                    <option value={3}>3</option>
                                    <option value={4}>4</option>
                                    <option value={5}>5</option>
                                </select>
                            </div>
                            <div className="grid grid-cols-2">
                                <select value={waitTradeChoice} className="outline-none border-2 rounded p-1" disabled={!selectedStrategy[6]} onChange={(e) => { setWaitTradeChoice(e.target.value); }}>
                                    <option className="text-black" value="% ++" >  % ++ </option>
                                    <option className="text-black" value="% --">   % --</option>
                                    <option className="text-black" value="Point ++">  Point ++ </option>
                                    <option className="text-black" value="Point --">  Point -- </option>
                                </select>
                                <input type="number" className="outline-none border-2 rounded p-1 text-center" disabled={!selectedStrategy[6]} value={waitTrade} onChange={(e) => { setWaitTrade(e.target.value); }}></input>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-5 flex flex-row gap-4 bg-gray-300 p-2">
                    <div className="bg-blue-600 text-white rounded p-1 w-32 text-center cursor-pointer" onClick={() => { setAddDefaultStrategyVisible(false); handleAddCombineStrategy(); }}>Add</div>
                </div>
            </Dialog>
        </>
    )
}

export default StrategyDefault