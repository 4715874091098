export const dayAndMonthProfitability = (worksheet3, week, month) => {
    // Define styles
    const borderStyle = { style: 'thin', color: { argb: '000000' } };
    const headerFillColor = 'FFFF00'; // Yellow background
    const headerFontColor = '000000'; // Black font color for headers
    const cellFontColor = '000000';   // Black font color for data cells

    // Helper function to set cell styling
    const setCellStyle = (cell, fontSize = 11, bold = false, color = cellFontColor, fillColor = null, alignment = 'center') => {
        cell.font = { size: fontSize, name: 'Calibri', bold: bold, color: { argb: color } };
        if (fillColor) cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: fillColor } };
        cell.alignment = { horizontal: alignment, vertical: 'middle' };
    };

    // Center-align entire worksheet
    worksheet3.properties.defaultRowHeight = 20;

    // Day Wise Data Header
    // worksheet3.mergeCells('A1:F1');
    // worksheet3.getCell('A1').value = 'Day Wise Breakup';
    // setCellStyle(worksheet3.getCell('A1'), 12, true, headerFontColor, 'ADD8E6');

    // Weekly Column Headers
    worksheet3.addRow(['Serial', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri']).eachCell((cell) => {
        setCellStyle(cell, 11, true, headerFontColor, headerFillColor);
        cell.border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
    });

    // Adding Weekly Data Rows
    let rowIndex = 3; // Starting row for data
    for (const key in week) {
        const row = worksheet3.addRow([key, ...week[key]]);
        row.eachCell((cell) => {
            setCellStyle(cell);
            cell.border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
        });
        rowIndex++;
    }

    // Spacer Row
    worksheet3.addRow([]);
    rowIndex++;

    // Month Wise Data Header
    // worksheet3.mergeCells(`A${rowIndex}:P${rowIndex}`);
    // const monthHeaderCell = worksheet3.getCell(`A${rowIndex}`);
    // monthHeaderCell.value = 'Monthly Breakup';
    // setCellStyle(monthHeaderCell, 12, true, headerFontColor, 'ADD8E6');

    // Monthly Column Headers
    worksheet3.addRow(['No.', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Total', 'MDD', 'ROI'])
        .eachCell((cell) => {
            setCellStyle(cell, 11, true, headerFontColor, headerFillColor);
            cell.border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
        });

    // Adding Monthly Data Rows
    for (const key in month) {
        const row = worksheet3.addRow([key, ...month[key]]);
        row.eachCell((cell) => {
            setCellStyle(cell);
            cell.border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
        });
    }

    return worksheet3;
};
