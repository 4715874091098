export const combinedResult = (worksheet, combineResult) => {

  if (combineResult.length === 0) return worksheet;

  // Add top spacer row for better layout
  worksheet.addRow([]);

  // Generate headers dynamically based on the first row
  const headers = ["Date", "Day", ...combineResult[0].slice(2).map((_, index) => `Strategy-${index + 1}`)];

  // Add headers to the worksheet
  const headerRow = worksheet.addRow(headers);
  headerRow.height = 25; // Increase header row height for spacing
  headerRow.eachCell(cell => {
    cell.font = { bold: true, color: { argb: 'FFFFFF' }, size: 12 }; // Bold white font
    cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '1E90FF' } }; // Blue background for headers
    cell.alignment = { horizontal: 'center', vertical: 'middle' }; // Center-align text
  });

  // Add data rows to the worksheet with alternating row colors for readability
  combineResult.forEach((row, rowIndex) => {
    const dataRow = worksheet.addRow(row);
    dataRow.height = 20; // Increase data row height for spacing
    const fillColor = rowIndex % 2 === 0 ? 'F0F8FF' : 'E6E6FA'; // Light colors for alternating rows

    dataRow.eachCell(cell => {
      cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: fillColor } };
      cell.alignment = { horizontal: 'center', vertical: 'middle' }; // Center-align text
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }; // Border for readability
    });
  });

  // Auto-fit columns based on content
  worksheet.columns.forEach(column => {
    let maxLength = 0;
    column.eachCell({ includeEmpty: true }, cell => {
      maxLength = Math.max(maxLength, cell.value ? cell.value.toString().length : 0);
    });
    column.width = maxLength + 4; // Adding extra padding for readability
  });

  return worksheet;
};
