import React, { useState } from 'react';
import { bullish } from "./../Strategy/Bullish.js";
import { bearish } from "./../Strategy/Bearish.js";
import { nonDirection } from "./../Strategy/NonDirection.js";

import Navbar from './../Page/Layout/Navbar.jsx';
import FooterVersion2 from './../Page/Layout/FooterVersion2.jsx';

import BearCallSpread from './assets/BearCallSpread.svg'
import BearishButterfly from './assets/BearishButterfly.svg'
import BearishCondor from './assets/BearishCondor.svg'
import BearPutSpread from './assets/BearPutSpread.svg'
import BullishButterfly from './assets/BullishButterfly.svg'
import BullCallSpread from './assets/BullCallSpread.svg'
import BullishCondor from './assets/BullishCondor.svg'
import BullPutSpread from './assets/BullPutSpread.svg';
import ShortStraddle from './assets/ShortStraddle.svg';
import ShortStrangle from './assets/ShortStrangle.svg';
import ShortSyntheticFuture from './assets/ShortSyntheticFuture.svg';
import ShortPut from './assets/ShortPut.svg';
import ShortCall from './assets/ShortCall.svg';
import RiskReversal from './assets/RiskReversal.svg';
import RangeForward from './assets/RangeForward.svg';
import PutRatioBackSpread from './assets/PutRatioBackSpread.svg';
import LongCall from './assets/LongCall.svg';
import LongPut from './assets/LongPut.svg';
import LongStraddle from './assets/LongStraddle.svg';
import LongStrangle from './assets/LongStrangle.svg';
import LongSyntheticFuture from './assets/LongSyntheticFuture.svg';
import LongIronCondor from './assets/LongIronCondor.svg'
import ShortIronCondor from './assets/ShortIronCondor.svg'
import ShortIronFly from './assets/ShortIronFly.svg'
import Batman from './assets/Batman.svg'
import CallRatioBackSpread from './assets/CallRatioBackSpread.svg'

const Template = () => {
  const [selectedStrategy, setSelectedStrategy] = useState('bullish'); // Default to Bullish

  const images = {
     BearCallSpread,
 BearishButterfly,
 BearishCondor,
 BearPutSpread ,
 BullishButterfly ,
 BullCallSpread,
 BullishCondor ,
 BullPutSpread ,
 ShortStraddle ,
 ShortStrangle,
 ShortSyntheticFuture,
 ShortPut ,
 ShortCall ,
 RiskReversal ,
 RangeForward ,
 PutRatioBackSpread ,
 LongCall ,
 LongPut ,
 LongStraddle ,
 LongStrangle ,
 LongSyntheticFuture,
  LongIronCondor,
 ShortIronCondor,
 ShortIronFly ,
 Batman,
 CallRatioBackSpread
    // Add more mappings if needed
  };

  const point = [
    -1000, -950, -900, -850, -800, -750, -700, -650, -600, -550,
    -500, -450, -400, -350, -300, -250, -200, -150, -100, -50,
    0, 50, 100, 150, 200, 250, 300, 350, 400, 450,
    500, 550, 600, 650, 700, 750, 800, 850, 900, 950,
    1000
  ]


  // Handle selecting strategy
  const handleSelectionChange = (event) => {
    setSelectedStrategy(event.target.value);
  };

  // Get the strategies based on selection
  const getStrategies = () => {
    switch (selectedStrategy) {
      case 'bullish':
        return bullish;
      case 'bearish':
        return bearish;
      case 'nonDirectional':
        return nonDirection;
      default:
        return [];
    }
  };

  const strategies = getStrategies(); // Get selected strategies

  return (
    <>
      <Navbar />
      <div className="p-6 mx-auto">
        {/* Dropdown for selecting strategy */}
        <div className="mb-6">
          <label className="block text-xl font-semibold mb-2 text-gray-800">
            Select Strategy Type:
          </label>
          <select
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={selectedStrategy}
            onChange={handleSelectionChange}
          >
            <option value="bullish">Bullish Strategy</option>
            <option value="bearish">Bearish Strategy</option>
            <option value="nonDirectional">Non-Directional Strategy</option>
          </select>
        </div>

        {/* Display selected strategies */}
        <div>
          <h2 className="text-2xl font-bold mb-4 text-blue-600">
            {selectedStrategy.charAt(0).toUpperCase() + selectedStrategy.slice(1)} Strategies
          </h2>
          <ul className="flex flex-wrap gap-6">
            {strategies.length > 0 ? (
              strategies.map((strategy, index) => (
                <li
                  key={index}
                  className="flex-1 min-w-[320px] max-w-[350px] border border-gray-300 rounded-lg p-4 shadow-md hover:shadow-lg transition-shadow duration-300"
                >
                  <div className="flex flex-col items-center">
                    <div className="text-lg font-medium text-gray-800 mb-2 text-center">
                      {strategy.name}
                    </div>
                    {/* Example image display */}
                    <img
                      src={images[strategy?.url]}
                      alt={strategy?.name}
                      className="w-40 h-auto rounded-md border border-gray-200 mb-2"
                    />
                  </div>
                  <div className="flex flex-col">
                    {strategy.array.legarray.map((item, key) => (
                      <div className="flex flex-row mb-2 items-center" key={key}>
                        <div className="flex flex-row gap-1 items-center">
                          <input
                            type="text"
                            className="border text-center border-gray-300 bg-gray-100 rounded-lg px-2 py-1 outline-none focus:border-blue-500 transition-all w-[60px]"
                            value={strategy.array.lotarray1[key]}
                          />
                        
                          <div
                            className="border border-gray-300 bg-gray-100 rounded-lg px-2 py-1 outline-none focus:border-blue-500 transition-all w-[100px]"
                          >
                           {strategy.array.atm[key]}
                          </div>
                        </div>
                        <div
                          className="w-16 border border-gray-300 m-1 bg-gray-100 rounded-lg px-2 py-1 cursor-pointer text-center"
                          style={{
                            backgroundColor: strategy.array.optionBuySell[key] === 'Buy' ? '#12b564' : '#f25d52',
                            color: 'white',
                          }}
                        >
                          {strategy.array.optionBuySell[key]}
                        </div>
                        <div
                          className="w-16 border border-gray-300 m-1 bg-gray-100 rounded-lg px-2 py-1 cursor-pointer text-center"
                          style={{
                            backgroundColor: strategy.array.optionCallPut[key] === 'Call' ? '#3ca10d' : '#db230b',
                            color: 'white',
                          }}
                        >
                          {strategy.array.optionCallPut[key]}
                        </div>
                      </div>
                    ))}
                  </div>
                </li>
              ))
            ) : (
              <li className="text-lg text-gray-500">No strategies available</li>
            )}
          </ul>
        </div>
      </div>

      <FooterVersion2 />
    </>
  );
};

export default Template;
