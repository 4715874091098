import React, { useEffect, useState } from 'react'
import AcUnitIcon from "@mui/icons-material/AcUnit";
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { special_days } from '../../../constant/constant';
import EventIcon from '@mui/icons-material/Event';

import { IoIosArrowUp } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import { MdOutlineCompareArrows } from "react-icons/md";
import { MdOutlineAcUnit } from "react-icons/md";
import { FaDirections } from "react-icons/fa";

import { bullish } from './../../../Strategy/Bullish.js';
import { bearish } from './../../../Strategy/Bearish.js';
import { nonDirection } from './../../../Strategy/NonDirection.js';
// store
import DateRangeIcon from '@mui/icons-material/DateRange';
import { PRE_DEFINED_STRATEGY } from '../../../constant/constant';
import { extractErrorMessage } from '../../../utility/utils';

const Top = (props) => {

  const navigate = useNavigate();
  const [visibleDefault, setVisibleDefault] = useState(false);
  const [visibleSaved, setVisibleSaved] = useState(false);
  const [visibleRange, setVisibleRange] = useState(false);
  const [activeRange, setActiveRange] = useState('');
  const [preDefinedStrategy, setPreDefinedStrategy] = useState([])
  const [visibleSpecialDay, setVisibleSpecialDay] = useState(false)
  const [activeSpecialDay, setActiveSpecialDay] = useState('')

  const [visibleBullish, setVisibleBullish] = useState(false);
  const [visibleBearish, setVisibleBearish] = useState(false);
  const [visibleNonDirection, setVisibleNonDirection] = useState(false);

  function updateDate(numDay) {
    // Get current date
    let currentDate = new Date();
    props.setEndDate(formatDate(currentDate));
    // Subtract months from current date
    currentDate.setDate(currentDate.getDate() - numDay)
    // Format current date
    let formattedCurrentDate = formatDate(currentDate);
    props.setStartDate(formattedCurrentDate);
  }

  function formatDate(date) {
    // Get year, month, and day from date
    let year = date.getFullYear();
    let month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    let day = String(date.getDate()).padStart(2, '0');
    // Return formatted date
    return `${year}-${month}-${day}`;
  }

  // const updateDate = (date, numMonthsToAdd) => {
  //   var inputDate = new Date();
  //   // Calculate the new date
  //   inputDate.setMonth(inputDate.getMonth() - numMonthsToAdd);
  //   // Format the new date as 'YYYY-MM-DD'
  //   var year = inputDate.getFullYear();
  //   var month = String(inputDate.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
  //   var day = String(inputDate.getDate()).padStart(2, '0');

  //   var newDate = `${year}-${month}-${day}`;
  //   console.log(newDate,'start Date');
  //   props.setStartDate(newDate);
  //   return `${inputDate.getFullYear()}-${inputDate.getMonth()}-${inputDate.getDate()}`;
  // }
  // find all saved strategies from an array
  const onGettingPreDefinedStrategy = () => {
    let data = JSON.stringify({ id: secureLocalStorage.getItem("backtest_id") });
    // Get the token from secureLocalStorage
    let token = secureLocalStorage.getItem('backtest_token');

    // Remove the 'backtest_' prefix if it exists
    if (token && token.startsWith('backtest_')) {
      token = token.replace('backtest_', '');
    }
    let config = { method: "get", maxBodyLength: Infinity, url: PRE_DEFINED_STRATEGY, headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}`, }, data: data, };
    axios
      .request(config)
      .then((response) => {
        // console.log('response',response.data)
        response?.data?.data && setPreDefinedStrategy(response?.data?.data);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          navigate('/backtest/login');
        } else {
          const errorMessage = extractErrorMessage(error);
          if (errorMessage) {
            toast.error(errorMessage);
          } else {
            toast.error('Network Issue');
          }
        }
        console.log(error, 'error at getting strategy')
      });
  };

  useEffect(()=>{
    updateDate(30);
  },[])
  return (
    <>
      <div className='w-full bg-white border-t-2 text-black flex flex-row flex-wrap justify-center text-center gap-1 md:gap-4 min-h-[35px]'>
        {/* Bearsih */}
        <div className='flex flex-row text-l   relative' onMouseLeave={() => { setVisibleBullish(false); }} onMouseEnter={() => { setVisibleBullish(true); }}>
          <div className="text-lg  pt-1 pl-2 font-[400] hover:font-[600]"> Bearish&nbsp;Strategy </div>
          {visibleBullish && <IoIosArrowDown style={{ marginTop: "8px", fontSize: "20px" }} />}
          {!visibleBullish && <IoIosArrowUp style={{ marginTop: "8px", fontSize: "20px" }} />}
          {visibleBullish && <div className='absolute text-black border-2 w-[220px] rounded-lg top-7 bg-[white] flex flex-col max-h-[400px] overflow-auto'>{
            bearish?.length > 0 && bearish.map((item, key) => {
              return (
                <div className="p-2 flex items-center cursor-pointer rounded-md text-[16px] border-2 border-gray-100 border-transparent hover:border-blue-500" key={key}
                  onClick={() => { props.setActiveStrategy(item.name); props.setStrategies(item.array); props.setactiveindex(''); }}
                  style={{ backgroundColor: props.activeStrategy === item.name ? "#89CFF0" : "white" }}> <MdOutlineCompareArrows className='text-lg mr-2 ' />{item.name}</div>
              )
            })
          }
          </div>
          }
        </div>

        {/* Bullish Strategy */}
        <div className="flex flex-row pl-1 pr-1 relative" onMouseLeave={() => { setVisibleBearish(false); }} onMouseEnter={() => { setVisibleBearish(true); }}>
          <div className="text-lg  pl-1 pr-1 pt-1 font-[400] hover:font-[600]">Bullish&nbsp;Strategy </div>
          {visibleBearish && <IoIosArrowDown style={{ marginTop: "8px", fontSize: "20px" }} />}
          {!visibleBearish && <IoIosArrowUp style={{ marginTop: "8px", fontSize: "20px" }} />}
          {visibleBearish && <div className='absolute text-black border-2 w-[220px] rounded-lg top-7 bg-[white] flex flex-col max-h-[400px] overflow-auto'>
            {bullish && bullish.length > 0 && visibleBearish && bullish.map((item, key) => {
              return (
                <div className="p-2 flex items-center cursor-pointer rounded-md text-[16px] border-2 border-gray-100 border-transparent hover:border-blue-500" key={key} onClick={() => {
                  props.setActiveStrategy(item.name); props.setStrategies(item.array); props.setactiveindex('');
                }}
                  style={{ backgroundColor: props.activeStrategy === item.name ? "#89CFF0" : "white" }}> <MdOutlineAcUnit />&nbsp;&nbsp;{item.name}</div>
              );
            })}
          </div>}
        </div>

        {/* Non Direction */}
        <div className="flex flex-row pl-1 pr-1 relative" onMouseLeave={() => { setVisibleNonDirection(false); }} onMouseEnter={() => { setVisibleNonDirection(true); }}>
          <div className="text-lg  pl-1 pr-1 pt-1 font-[400] hover:font-[600]">NonDirectional&nbsp;Strategy </div>
          {visibleNonDirection && <IoIosArrowDown style={{ marginTop: "8px", fontSize: "20px" }} />}
          {!visibleNonDirection && <IoIosArrowUp style={{ marginTop: "8px", fontSize: "20px" }} />}
          {visibleNonDirection && <div className='absolute text-black border-2 w-[220px] rounded-lg top-7 bg-[white] flex flex-col max-h-[400px] overflow-auto'>
            {nonDirection && nonDirection.length > 0 && visibleNonDirection && nonDirection.map((item, key) => {
              return (
                <div className="p-2 flex items-center cursor-pointer rounded-md text-[16px] border-2 border-gray-100 border-transparent hover:border-blue-500" key={key} onClick={() => {
                  props.setActiveStrategy(item.name); props.setStrategies(item.array); props.setactiveindex('');
                }}
                  style={{ backgroundColor: props.activeStrategy === item.name ? "#89CFF0" : "white" }}> <FaDirections />&nbsp;&nbsp;{item.name}</div>
              );
            })}
          </div>}
        </div>

        {/* Saved Strategy */}
        <div className="feature-2 flex flex-row pl-1 pr-1 relative" onMouseLeave={() => { setVisibleSaved(false); }} onMouseEnter={() => { setVisibleSaved(true); }}>
          <div className="text-lg  pl-1 pr-1 pt-1 font-[400] hover:font-[600]">Saved&nbsp;Strategy </div>
          {visibleSaved && <IoIosArrowDown style={{ marginTop: "8px", fontSize: "20px" }} />}
          {!visibleSaved && <IoIosArrowUp style={{ marginTop: "8px", fontSize: "20px" }} />}
          {visibleSaved && <div className='absolute overflow-auto text-black min-h-[280px] max-h-[400px] md:w-[420px] rounded-lg top-7 bg-[white] flex flex-col'>
            {props.savedstrategies && props.savedstrategies.length > 0 && visibleSaved && props.savedstrategies.map((item, key) => (
              <div key={key}
                className={`p-2 flex items-center cursor-pointer rounded-md text-[16px] border-2 border-transparent hover:border-blue-500
              ${props.activeStrategy === item.name ? 'bg-blue-500 text-white ' : 'bg-white text-black'}`}
                onClick={() => {
                  props.setactiveindex(key);
                  props.setStrategies(item.array);
                  props.setActiveStrategy(item.name);
                }}
              >
                <AcUnitIcon className="mr-2" />
                <span className={`text-sm text-left`}>
                  {item.name}
                </span>
              </div>
            ))}


            {
              props.savedstrategies?.length == 0 && <div className='text-[18px] rounded-lg border-[1px] h-[360px] flex justify-center text-center font-[400] pt-10'>No Saved Strategy</div>
            }
          </div>}
        </div>
        <div className='feature-3 cursor-pointer flex flex-row  pl-1 pr-1 relative' onMouseLeave={() => { setVisibleRange(false); }} onMouseEnter={() => { setVisibleRange(true); }} >
          <div className="text-lg  pl-1 pr-1 pt-1 font-[400] hover:font-[600]">Period</div>
          {visibleRange && <IoIosArrowDown style={{ marginTop: "8px", fontSize: "20px" }} />}
          {!visibleRange && <IoIosArrowUp style={{ marginTop: "8px", fontSize: "20px" }} />}
          {
            visibleRange && <div className='absolute top-7 '>
              <div className="flex flex-col text-l  bg-white text-black w-[180px] rounded-lg">
                <div className="cursor-pointer text-[16px] font-[400] hover:border-blue-500 p-2 text-left border-2  border-white rounded-md" style={{ backgroundColor: activeRange == '1' ? '#2D5BFF' : 'white', color: activeRange == '1' ? 'white' : 'black' }} onClick={() => { updateDate(30); setActiveRange('1') }}><DateRangeIcon />  1 Month</div>
                <div className="cursor-pointer text-[16px] font-[400] hover:border-blue-500 p-2 text-left border-2 border-white  rounded-md" style={{ backgroundColor: activeRange == '2' ? '#2D5BFF' : 'white', color: activeRange == '2' ? 'white' : 'black' }} onClick={() => { updateDate(90); setActiveRange('2') }}><DateRangeIcon />  3 Month</div>
                <div className="cursor-pointer text-[16px] font-[400] hover:border-blue-500 p-2 text-left border-2 border-white  rounded-md" style={{ backgroundColor: activeRange == '3' ? '#2D5BFF' : 'white', color: activeRange == '3' ? 'white' : 'black' }} onClick={() => { updateDate(180); setActiveRange('3') }}><DateRangeIcon />  6 Month</div>
                <div className="cursor-pointer text-[16px] font-[400] hover:border-blue-500 p-2 text-left border-2 border-white  rounded-md" style={{ backgroundColor: activeRange == '4' ? '#2D5BFF' : 'white', color: activeRange == '4' ? 'white' : 'black' }} onClick={() => { updateDate(270); setActiveRange('4') }}><DateRangeIcon />  9 Month</div>
                <div className="cursor-pointer text-[16px] font-[400] hover:border-blue-500 p-2 text-left border-2 border-white  rounded-md" style={{ backgroundColor: activeRange == '5' ? '#2D5BFF' : 'white', color: activeRange == '5' ? 'white' : 'black' }} onClick={() => { updateDate(366); setActiveRange('5') }}><DateRangeIcon />  1 Year</div>
                <div className="cursor-pointer text-[16px] font-[400] hover:border-blue-500 p-2 text-left border-2 border-white  rounded-md" style={{ backgroundColor: activeRange == '6' ? '#2D5BFF' : 'white', color: activeRange == '6' ? 'white' : 'black' }} onClick={() => { updateDate(732); setActiveRange('6') }}><DateRangeIcon />  2 Year</div>
                <div className="cursor-pointer text-[16px] font-[400] hover:border-blue-500 p-2 text-left border-2 border-white  rounded-md" style={{ backgroundColor: activeRange == '7' ? '#2D5BFF' : 'white', color: activeRange == '7' ? 'white' : 'black' }} onClick={() => { updateDate(1098); setActiveRange('7') }}><DateRangeIcon />  3 Year</div>
                <div className="cursor-pointer text-[16px] font-[400] hover:border-blue-500 p-2 text-left border-2 border-white  rounded-md" style={{ backgroundColor: activeRange == '8' ? '#2D5BFF' : 'white', color: activeRange == '8' ? 'white' : 'black' }} onClick={() => { updateDate(1464); setActiveRange('8') }}><DateRangeIcon />  4 Year</div>
              </div></div>
          }
        </div>
        {/* Special Day */}
        <div className='feature-1 flex flex-row text-l   relative' onMouseLeave={() => { setVisibleSpecialDay(false); }} onMouseEnter={() => { setVisibleSpecialDay(true); }}>
          <div className="text-lg  pl-1 pr-1 pt-1 font-[400] hover:font-[600]"> Special&nbsp;Day </div>
          {visibleSpecialDay && <IoIosArrowDown style={{ marginTop: "8px", fontSize: "20px" }} />}
          {!visibleSpecialDay && <IoIosArrowUp style={{ marginTop: "8px", fontSize: "20px" }} />}
          {visibleSpecialDay && (
            <div className='absolute top-7 w-[200px] bg-[#DAECF2] rounded-lg shadow-lg overflow-hidden'>
              {special_days && special_days.map((item, key) => (
                <div
                  key={key}
                  className={`p-2 flex items-center cursor-pointer rounded-md text-[16px] border-2 border-transparent hover:border-blue-500 ${activeSpecialDay === item.name ? "bg-blue-500 text-white" : "bg-white text-black"}`}
                  onClick={() => { setActiveSpecialDay(item.name); }}
                >
                  <EventIcon className="mr-2" />
                  {item.name}
                </div>
              ))}
            </div>
          )}
        </div>

        {/*<div className='pt-1 pl-2 pr-2 border-white font-[400] hidden md:flex cursor-pointer' onClick={()=>{props.setRunTour(true);}}><TravelExploreIcon/>Tour</div>*/}
      </div>
      {/* {props.runTour &&<Joyride
        steps={props.tourSteps}
        run={props.runTour}
        continuous={true}
        scrollToFirstStep={true}
        showProgress={true}
        disableOverlayClose={true}
        callback={props.handleJoyrideCallback}
      />} */}

    </>
  )
}

export default Top;