import React,{useState,useEffect} from 'react'
import { MdDelete } from "react-icons/md";
import useBucketResultStore from '../../../store/bucketResultStore';
const Filter = (props) => {
  const [filter,setFilter]=useState([])
  const {updateResultFilterStrategy} = useBucketResultStore()

  useEffect(()=>{
    setFilter(props.resultFilter);
  },[])
  return (
    <div className="flex flex-col bg-gray-100 flex-grow m-2 rounded-lg gap-1">
    {
        filter.map((item,key)=>{
            return(
                <div className="flex flex-row gap-1 h-6 mt-1 pl-5">
                    <select className="border-[1px] rounded-lg border-blue-500" value={item?.type} onChange={(e)=>{setFilter((prevResult)=>{const inputdata=[...prevResult];inputdata[key].type=e.target.value;inputdata[key].sign1=inputdata[key].type=='PrevDayHL'?'High':'>=';return inputdata;})}}>
                        <option value="GapUpDown">Gap Up/Down</option>
                        <option value="PrevDayHL">Prev Day H/L</option>
                    </select>
                    {item.type=="GapUpDown" && <select className="border-[1px] rounded-lg border-blue-500" value={item?.sign1} onChange={(e)=>{setFilter((prevResult)=>{const inputdata=[...prevResult];inputdata[key].sign1=e.target.value;return inputdata;})}}>
                        <option value=">=">{`>=`}</option>
                        <option value="<=">{`<=`}</option>
                    </select>
                    }
                    {
                        item.type=="PrevDayHL" && <select className="border-[1px] rounded-lg border-blue-500" value={item?.sign1} onChange={(e)=>{setFilter((prevResult)=>{const inputdata=[...prevResult];inputdata[key].sign1=e.target.value;return inputdata;})}}>
                        <option value="High">Day Open {`>=`} PDH</option>
                        <option value="Low">Day Open {`<=`} PDL</option>
                        <option value="">PDH {`>=`} Day Open {`>=`} PDL</option>
                       </select>
                    }
                    {item.type=="GapUpDown" &&<input type="text" placeholder="" className="border-[1px] rounded-lg border-blue-500 w-16 text-center" value={item.value1} onChange={(e)=>{setFilter((prevResult)=>{const inputdata=[...prevResult];inputdata[key].value1=parseFloat(e.target.value); return inputdata;})}}/>}
                    {item.or && <MdDelete onClick={()=>{setFilter((prevResult)=>{const inputdata=[...prevResult];inputdata[key].or=false;return inputdata;})}}/>}
                    {!item.or && <div className="bg-blue-500 text-white w-16 rounded text-center cursor-pointer" onClick={()=>{setFilter((prevResult)=>{const inputdata=[...prevResult];inputdata[key].or=true;return inputdata;})}}>+OR</div>}
                    {item.or && <div className="">OR</div>}
                    {item.or && item.type=="GapUpDown" && <select className="border-[1px] rounded-lg border-blue-500" value={item?.sign2} onChange={(e)=>{setFilter((prevResult)=>{const inputdata=[...prevResult];inputdata[key].sign2=e.target.value;return inputdata;})}}>
                        <option value=">=">{`>=`}</option>
                        <option value="<=">{`<=`}</option>
                    </select>}
                    {item.or && item.type=="PrevDayHL" && <select className="border-[1px] rounded-lg border-blue-500" value={item?.sign2} onChange={(e)=>{setFilter((prevResult)=>{const inputdata=[...prevResult];inputdata[key].sign2=e.target.value;return inputdata;})}}>
                        <option value="High">Day Open {`>=`} PDH</option>
                        <option value="Low">Day Open {`<=`} PDL</option>
                        <option value="">PDH {`>=`} Day Open {`>=`} PDL</option>
                    </select>}

                    {item.or && item.type=="GapUpDown" && <input type="text" placeholder="" className="border-[1px] rounded-lg border-blue-500 w-16 text-center" value={item.value2} onChange={(e)=>{setFilter((prevResult)=>{const inputdata=[...prevResult];inputdata[key].value2=parseFloat(e.target.value); return inputdata;})}}/>}
                    {item.or && <MdDelete onClick={()=>{setFilter((prevResult)=>{const inputdata=[...prevResult];inputdata[key].or=false;return inputdata;})}}/>}
                    <div className="bg-blue-500 text-white w-32 rounded text-center cursor-pointer" onClick={()=>{setFilter((prevResult)=>{const inputdata=[...prevResult];inputdata.splice(key,1); return inputdata;})}}>Remove</div>
                </div>
                )
            })
        }
    <div className="cursor-pointer pl-5 text-orange-700 w-36" onClick={()=>{setFilter([...filter,{"type":"GapUpDown","sign1":">=","sign1":">=","value1":0,"value2":0,"or":false}]);}}>Add More Filter</div>
    <div className="bg-blue-500 text-white text-center w-32 ml-5 rounded-lg cursor-pointer" onClick={()=>{updateResultFilterStrategy(props.index,filter);}}>Apply</div>
</div>

  )
}

export default Filter