import React,{useState} from 'react';
// React-Icons
import { MdOutlineRestartAlt } from 'react-icons/md';
import { FaDownload } from "react-icons/fa";
import { MdDelete } from 'react-icons/md';
// Zustand
import useBacktestResultStore from '../../store/backtestResultStore';
import useBacktestStore from '../../store/backtestStore';
import Excel from './Excel/Excel'


const Metrics = (props) => {
    const {appearNA,updateAppearNA,tradeOnlyFirstEntry,updateTradeOnlyFirstEntry}=useBacktestResultStore();
    const {vixStart,vixExit,updateVixStart,updateVixExit,resultStartDate,resultEndDate,updateResultStartDate,updateResultEndDate}=useBacktestResultStore();
    const {variant,updateVariantAndDayoption,inputData}=useBacktestResultStore();
    const {updateSlippage}=useBacktestStore();
    const [slippageValue,setSlippageValue]=useState(0);
    const [restartAgain,setRestartAgain]=useState(0);

    
    return (
        <div className="flex flex-col  gap-2 bg-white border-4 rounded-lg border-blue-500 ml-2">
            <div className="flex text-center justify-center text-xl font-semibold h-10 text-white bg-[#718EBF] rounded-t-md ml-1 mr-1">Metrices</div>

            {/* START HIDE NA */}
            <div className="flex text-center justify-center">
                <div className="flex flex-row gap-2">
                    <input type="checkbox" checked={appearNA} onChange={updateAppearNA} />
                    <div className="font-semibold text-l">Hide NA Rows</div>
                </div>
            </div>
            {inputData.waitTrade && <div className="flex text-center justify-center">
                <div className="flex flex-row gap-2">
                    <input type="checkbox" checked={tradeOnlyFirstEntry} onChange={updateTradeOnlyFirstEntry} />
                    <div className="font-semibold text-l">Trade Only First Entry</div>
                </div>
            </div>}
            {/* END HIDE NA */}

            <div className=" bg-gradient-to-r from-blue-200 to-blue-500 m-2 rounded-lg p-2">
                <div className="grid grid-cols-1 m-2 gap-1">
                    <div className="grid grid-cols-2 gap-[1px]">
                        <div className="text-l mt-1 text-black text-left">Slippages</div>
                        <div className="flex flex-row gap-1">
                            <input type="number" className="border-2 rounded text-l h-7 w-36 text-center text-black" value={slippageValue} onChange={(e) => { setSlippageValue(e.target.value); }}></input>
                            <div className="bg-blue-400 text-white w-12 rounded-lg w flex text-center justify-center hover:bg-blue-600"><span className="cursor-pointer text-white" onClick={() => { updateSlippage(slippageValue); }}><MdOutlineRestartAlt className='text-2xl font-semibold'/></span></div>
                        </div>
                    </div>
                    <div className="grid grid-cols-2">
                        <div className="text-l mt-1 text-black text-left">Grouping</div>
                        <select value={props.filter} className="h-7 border-2 rounded border-solid w-36"
                            onChange={(e) => { props.setFilter(e.target.value) }}>
                            <option className="text-black" value="Expiry" >Group by Expiry</option>
                            <option className="text-black" value="Date" > Group By Date </option>
                            <option className="text-black" value="Day">Group By Day</option>
                        </select>
                    </div>
                    {/* ------------------Vix---------------- */}
                    <div className="grid grid-cols-2">
                        <div className="">vix (1-99)</div>
                        <div className="flex flex-row gap-1">
                            <input type="number" value={vixStart} onChange={(e) => { updateVixStart(parseFloat(e.target.value)) }} className="w-20 text-center border-[1px] rounded-lg" />
                            <div className="">to</div>
                            <input type="number" value={vixExit} onChange={(e) => { updateVixExit(parseFloat(e.target.value)) }} className="w-20 text-center border-[1px] rounded-lg" />
                            <div className="bg-blue-400 text-white w-12 rounded-lg w flex text-center justify-center hover:bg-blue-600"><span className="cursor-pointer text-white" onClick={() => { props.setSlippage(slippageValue); setRestartAgain(restartAgain + 1) }}><MdOutlineRestartAlt className='text-2xl font-semibold'/></span></div>
                        </div>
                    </div>
                    {/* --------------Vix---------------- */}
                </div>
            </div>
            <div className="flex flex-col bg-gradient-to-r from-blue-200 to-blue-500 m-2 rounded-xl p-2">
                <div className="flex flex-row m-2 gap-2">
                    <div className="flex flex-row rounded-2xl border-[1px]">
                        <div className="bg-blue-500 text-white w-10 text-center font-serif rounded-l-2xl">St</div>
                        <input type="date" className="outline-0 flex text-center justify-center rounded-r-lg" value={resultStartDate} onChange={(e) => { updateResultStartDate(e.target.value) }} />
                    </div>
                    <div className="flex flex-row rounded-2xl border-[1px]">
                        <div className="bg-blue-500 text-white w-10 text-center font-serif rounded-l-2xl">En</div>
                        <input type="date" className="outline-0 flex text-center justify-center rounded-r-lg" value={resultEndDate} onChange={(e) => { updateResultEndDate(e.target.value); }} />
                    </div>
                    <div className="bg-blue-400 text-white w-12 rounded-lg w flex text-center justify-center hover:bg-blue-600"><span className="cursor-pointer text-white" onClick={() => { updateResultStartDate(inputData.startdate); updateResultEndDate(inputData.enddate); }}><MdOutlineRestartAlt className='text-2xl font-semibold' /></span></div>

                </div>
                {/* Download Result in the form of PDF or EXCEl. */}
                <div className="grid grid-cols-2 ml-2 gap-1">
                    <button className="bg-green-500 hover:bg-green-600 flex flex-row gap-2 text-center justify-center text-white p-1 rounded text-[16px]" onClick={props.handleDownloadPDF}> Download PDF <FaDownload className="mt-1" /></button>
                    <Excel/>
                </div>
                {/* ---------------------Day----------- */}
                {/* Profit Week Day Wise */}
                <div className="flex text-center justify-start pl-2 flex-grow-0">
                    <div className="text-[16px] grid grid-cols-6 gap-1 mt-2">
                        <button className=" text-white rounded border-[1px] p-1"
                            style={{
                                backgroundColor: variant[0] == "contained" ? "#2D5BFF" : "white",
                                color: variant[0] == "contained" ? "white" : "black"
                            }}
                            onClick={() => { updateVariantAndDayoption(0)}}>Mon</button>
                        <button className=" text-white rounded border-[1px] p-1"
                            style={{
                                backgroundColor: variant[1] == "contained" ? "#2D5BFF" : "white",
                                color: variant[1] == "contained" ? "white" : "black"
                            }}
                            onClick={() => { updateVariantAndDayoption(1);}}>Tue</button>
                        <button className=" text-white rounded border-[1px] p-1"
                            style={{
                                backgroundColor: variant[2] == "contained" ? "#2D5BFF" : "white",
                                color: variant[2] == "contained" ? "white" : "black"
                            }}
                            onClick={() => { updateVariantAndDayoption(2);}}>Wed</button>
                        <button className=" text-white rounded border-[1px] p-1"
                            style={{
                                backgroundColor: variant[3] == "contained" ? "#2D5BFF" : "white",
                                color: variant[3] == "contained" ? "white" : "black"
                            }}
                            onClick={() => { updateVariantAndDayoption(3) }}>Thu</button>
                        <button className=" text-white rounded border-[1px] p-1"
                            style={{
                                backgroundColor: variant[4] == "contained" ? "#2D5BFF" : "white",
                                color: variant[4] == "contained" ? "white" : "black"
                            }}
                            onClick={() => {updateVariantAndDayoption(4);}}>Fri</button>
                    </div>
                </div>
            </div>
            {/* Add more filter */}
            <div className="flex flex-col bg-gray-100 p-2 flex-grow m-2 rounded-lg gap-1">
                {
                    props.tempFilter.map((item, key) => {
                        return (
                            <div className="flex flex-row gap-1 h-6 mt-1">
                                <select className="border-[1px] rounded-lg border-blue-500" value={item?.type} onChange={(e) => { props.setTempFilter((prevResult) => { const inputdata = [...prevResult]; inputdata[key].type = e.target.value; inputdata[key].sign1 = inputdata[key].type == 'PrevDayHL' ? 'High' : '>='; return inputdata; }) }}>
                                    <option value="GapUpDown">Gap Up/Down</option>
                                    <option value="PrevDayHL">Prev Day H/L</option>
                                </select>
                                {item.type == "GapUpDown" && <select className="border-[1px] rounded-lg border-blue-500" value={item?.sign1} onChange={(e) => { props.setTempFilter((prevResult) => { const inputdata = [...prevResult]; inputdata[key].sign1 = e.target.value; return inputdata; }) }}>
                                    <option value=">=">{`>=`}</option>
                                    <option value="<=">{`<=`}</option>
                                </select>
                                }
                                {
                                    item.type == "PrevDayHL" && <select className="border-[1px] rounded-lg border-blue-500" value={item?.sign1} onChange={(e) => { props.setTempFilter((prevResult) => { const inputdata = [...prevResult]; inputdata[key].sign1 = e.target.value; return inputdata; }) }}>
                                        <option value="High">Day Open {`>=`} PDH</option>
                                        <option value="Low">Day Open {`<=`} PDL</option>
                                        <option value="">PDH {`>=`} Day Open {`>=`} PDL</option>
                                    </select>
                                }
                                {item.type == "GapUpDown" && <div className="flex flex-row gap-1"><input type="number" placeholder="" className="border-[1px] rounded-lg border-blue-500 w-16 text-center" value={item.value1} onChange={(e) => { props.setTempFilter((prevResult) => { const inputdata = [...prevResult]; inputdata[key].value1 = parseFloat(e.target.value); return inputdata; }) }} /><div className="bg-blue-500 text-center w-10 rounded text-white">%</div></div>}
                                {item.or && <MdDelete onClick={() => { props.setTempFilter((prevResult) => { const inputdata = [...prevResult]; inputdata[key].or = false; return inputdata; }) }} />}
                                {!item.or && <div className="bg-blue-500 text-white w-16 rounded text-center cursor-pointer" onClick={() => { props.setTempFilter((prevResult) => { const inputdata = [...prevResult]; inputdata[key].or = true; return inputdata; }) }}>+OR</div>}
                                {item.or && <div className="">OR</div>}
                                {item.or && item.type == "GapUpDown" && <select className="border-[1px] rounded-lg border-blue-500" value={item?.sign2} onChange={(e) => { props.setTempFilter((prevResult) => { const inputdata = [...prevResult]; inputdata[key].sign2 = e.target.value; return inputdata; }) }}>
                                    <option value=">=">{`>=`}</option>
                                    <option value="<=">{`<=`}</option>
                                </select>}
                                {item.or && item.type == "PrevDayHL" && <select className="border-[1px] rounded-lg border-blue-500" value={item?.sign2} onChange={(e) => { props.setTempFilter((prevResult) => { const inputdata = [...prevResult]; inputdata[key].sign2 = e.target.value; return inputdata; }) }}>
                                    <option value="High">Day Open {`>=`} PDH</option>
                                    <option value="Low">Day Open {`<=`} PDL</option>
                                    <option value="">PDH {`>=`} Day Open {`>=`} PDL</option>
                                </select>}

                                {item.or && item.type == "GapUpDown" && <div className="flex flex-row gap-1"><input type="number" placeholder="" className="border-[1px] rounded-lg border-blue-500 w-16 text-center" value={item.value2} onChange={(e) => { props.setTempFilter((prevResult) => { const inputdata = [...prevResult]; inputdata[key].value2 = parseFloat(e.target.value); return inputdata; }) }} /><div className="bg-blue-500 text-center w-10 rounded text-white">%</div></div>}
                                {item.or && <MdDelete onClick={() => { props.setTempFilter((prevResult) => { const inputdata = [...prevResult]; inputdata[key].or = false; return inputdata; }) }} />}
                                <div className="bg-blue-500 text-white w-32 rounded text-center cursor-pointer" onClick={() => { props.setTempFilter((prevResult) => { const inputdata = [...prevResult]; inputdata.splice(key, 1); inputdata.length == 0 && props.setResultFilter([]); return inputdata; }) }}>Remove</div>
                            </div>
                        )
                    })
                }
                <div className="cursor-pointer pl-5 text-orange-700 w-36" onClick={() => { props.setTempFilter([...props.tempFilter, { "type": "GapUpDown", "sign1": ">=", "sign1": ">=", "value1": 0, "value2": 0, "or": false }]); }}><u>Add More Filter</u></div>
                {props.tempFilter.length > 0 && <div className="bg-blue-500 text-white text-center w-32 ml-5 rounded-lg cursor-pointer" onClick={() => { props.setResultFilter(props.tempFilter); }}>Apply</div>}
            </div>
        </div>

    )
}

export default Metrics