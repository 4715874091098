import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { MdCurrencyRupee } from "react-icons/md";
import { FaCheckCircle } from "react-icons/fa";
import { Dialog } from "@mui/material";
import Navbar from '../Layout/Navbar';
import FooterVersion2 from '../Layout/FooterVersion2';
import { MdClose } from "react-icons/md";
import { MdFavorite } from "react-icons/md";
// constant
import { PAYMENT_DETAIL, COUNTING_URL, PLAN_LIST_API, TRANSACTION_CANCEL } from "../../constant/constant";
// images
import silver from './../../assets/silver.webp'
import gold from './../../assets/gold.webp'
import platinum from './../../assets/platinum.webp'
import secureLocalStorage from 'react-secure-storage';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
// import Section4 from '../Layout/Home/Section4';

const Plan = () => {
    const tempResult = { "name": "", "email": " ", "mobileNumber": " ", "planName": " ", "planPrice": 3750, "planValidation": 90, "coinQuantity": 500 }
    const [plans, setPlans] = useState([]);

    const get_plan_detail = async () => {
        let config = {
            method: 'get',
            url: PLAN_LIST_API,
        };

        axios.request(config)
            .then((response) => {
                console.log(response.data);
                setPlans(response.data);
            })
            .catch((error) => {
                console.log(error);
            });

    }
    const temp1Result = { "key": "1", "transactionId": "23456", "productInfo": "gold", "amount": 3750, "planName": "gold", "planPrice": 3750, "planValidation": 90, "coinQuantity": 500, "name": "", "email": "", "mobileNumber": "", "successUrl": "http://localhost:3000/success", "failureUrl": "http://localhost:3000/failure", "hash": "jhskhljl" }
    const [paymentResult, setPaymentResult] = useState(temp1Result);
    const [visiblePaymentBox, setVisiblePaymentBox] = useState(false);
    const [confirmationPageBox, setConfirmationPageBox] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [planDetail, setPlanDetail] = useState({});  //{"planName":"","coinQuantity":"",expiryDay:"","price":"","costPerCoin":""}
    const navigate = useNavigate();
    const [todayBacktetCount, setTodayBacktestCount] = useState(0);
    const [totalBacktestCount, setTotalBacktestCount] = useState(0);
    const [totalUser, setTotalUser] = useState(0);

    const cancelPayment = async () => {
        let data = {
            "txnId": paymentResult.transactionId,
            "email": paymentResult.email
        }

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: TRANSACTION_CANCEL,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        axios.request(config)
            .then((response) => {
            })
            .catch((error) => {
                console.log(error);
            });

    }
    const get_payment_detail = async () => {
        console.log("********************")
        console.log(mobileNumber)
        if (mobileNumber === null || mobileNumber === undefined || mobileNumber === "") {
            toast('Mobile Number is required. Please update your mobile number from profile section');
            return;

        }
        tempResult.name = name;
        tempResult.email = email;
        tempResult.mobileNumber = mobileNumber;
        tempResult.planDetail = planDetail.planName;
        tempResult.amount = planDetail.price;
        tempResult.planName = planDetail.planName;
        tempResult.planPrice = planDetail.price;
        tempResult.plan = planDetail.plan
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: PAYMENT_DETAIL,
            headers: {
                'Content-Type': 'application/json'
            },
            data: tempResult
        };
        console.log(tempResult)
        axios.request(config)
            .then((response) => {
                console.log(response.data)
                setPaymentResult(response.data)
                setConfirmationPageBox(true);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    const counting = () => {
        const id = secureLocalStorage.getItem('backtest_id');
        // Get the token from secureLocalStorage
        let token = secureLocalStorage.getItem('backtest_token');

        // Remove the 'backtest_' prefix if it exists
        if (token && token.startsWith('backtest_')) {
            token = token.replace('backtest_', '');
        }else{
            navigate('/backtest/login');
        }
        const data = { "id": id }
        // console.log(data);
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: COUNTING_URL,
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}`, },
            data: data
        };
        axios.request(config)
            .then((response) => {
                // console.log(response.data.total_backtest,response.data.today_backtest_count,response.data.totalUser,'counting')
                setTotalUser(response.data.totalUser);
                setTotalBacktestCount(response.data.total_backtest);
                setTodayBacktestCount(response.data.today_backtest_count);
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    navigate('/backtest/login');
                }
            });
    }

    useEffect(() => {
        console.log(secureLocalStorage)
        const localName = secureLocalStorage.getItem('backtest_name')
        localName && setName(localName);
        const localEmail = secureLocalStorage.getItem('backtest_email')
        localEmail && setEmail(localEmail)
        const localMobileNumber = secureLocalStorage.getItem('backtest_phone');
        localMobileNumber && setMobileNumber(localMobileNumber);
        counting();
        get_plan_detail();
    }, [])
    return (
        <>
            <ToastContainer />
            <div className='fixed w-full'><Navbar /></div>
            <div className='bg-[#DAECF2]'>
                <div className="text-center justify-center flex">
                    <div className='flex flex-col mt-24'>
                        <div className='text-4xl font-semibold'>
                            <span className='text-blue-700 text-4xl font-semibold'>Simple</span> pricing for all your
                        </div>
                        <div className='text-4xl'>
                            <span className='italic text-5xl font-semibold'>financial</span> needs
                        </div>
                        <div className='text-sm font-serif flex flex-row text-center justify-center'>start for free,upgrade when you <MdFavorite className='text-2xl text-red-500' /> it!.</div>
                    </div>
                </div>
                <div className="grid grid-cols-1 m-5 sm:m-16 gap-10 sm:grid-cols-1 lg:grid-cols-3 font-serif">
                    {
                        plans.map((item, index) => {
                            var imgName = '';
                            if (item.name === 'Silver Plan') {
                                imgName = silver;
                            } else if (item.name === 'Gold Plan') {
                                imgName = gold;
                            } else if (item.name === 'Platinum Plan') {
                                imgName = platinum;
                            }
                            return (
                                <div key={`plan-00${index}`} className="border-1 bg-white flex flex-col mt-10 border-t-8 border-white rounded-xl shadow-blue-700 shadow-lg">
                                    <div className='flex text-center justify-center mt-5'>
                                        <h3 className="text-center text-l  text-blue-800 rounded-lg flex flex-row">
                                            <img src={imgName} className="h-10 w-10"></img>
                                            <span className='mt-2 text-xl font-medium'>{item.name}</span>
                                        </h3>
                                    </div>
                                    <hr className="m-1" /><hr />
                                    <p className="text-4xl text-black font-semibold flex flex-row text-center justify-center"><MdCurrencyRupee className='text-4xl' />{item.total} <span className='text-sm'>(Incl.18% GST)</span></p>
                                    <hr className='font-semibold bg-black h-2'></hr>
                                    <div className="m-5">
                                        <div className='font-semibold ml-2'>Features/Benefit</div>
                                        <div className="text-l p-1 m-1 text-gray-700 flex flex-row gap-2"><FaCheckCircle className='text-[#2D5BFF]' />Maximum <span className="text-xl font-semibold text-blue-800">{item.coins}</span> coins</div>
                                        <div className="text-l p-1 m-1 text-gray-700  flex flex-row gap-2"><FaCheckCircle className='text-[#2D5BFF]' /><span className="text-xl font-semibold text-blue-800">{item.validity}</span> day validation</div>
                                        <div className="text-l p-1 m-1 text-gray-700  flex flex-row gap-2"><FaCheckCircle className='text-[#2D5BFF]' /><span className="text-xl font-semibold text-blue-800">{item.rsPerCoin}</span> Rupee Per Coin</div>
                                        {
                                            item.availableFeatures.map((feature, ix) => {
                                                return (
                                                    <div key={`planfeature-00${index}-${ix}`} className="text-l p-1 m-1 text-gray-700 flex flex-row gap-2"><FaCheckCircle className='text-[#2D5BFF]' />{feature}</div>
                                                )
                                            })
                                        }
                                    </div>
                                    <button className='text-l font-bold text-white bg-[#2D5BFF] rounded-lg p-1 m-2' onClick={() => {
                                        setPlanDetail({ "planName": `${item.name}`, "coinQuantity": `${item.coins}`, "expiryDay": `${item.validity}`, "price": `${item.total}`, "costPerCoin": `${item.rsPerCoin}`, "plan": item });;

                                        setVisiblePaymentBox(true);;

                                    }}>Buy Subscription</button>
                                </div>
                            )
                        })
                    }
                </div>
                <div className='m-5 p-1 rounded-lg bg-white'>
                    <div className="text-xl font-semibold mt-2 text-[#333B69] p-2">Please read below points before buying Coins</div>
                    <ul className='list-disc ml-5'>
                        <li className="text-l p-1 m-2">  1 Coin will be utilized for 0-1 Year data, 2 Coin will be utilized for 1-2 Years data, 3 Coin will be utilized for 2-3 Years data, and so forth (1 Year = 366 Days).</li>
                        <li className="text-l p-1 m-2">  Validity implies that if Coin are not utilized within the specified timeframe, any remaining Coin will expire once the validity period elapses.</li>
                        <li className="text-l p-1 m-2">  Fprognos has Banknifty data available from Mon Jan 02 2017, and Nifty data available from Fri Feb 15 2019, Finnifty data available from Wed Oct 19 2022.</li>
                        <li className="text-l p-1 m-2">  Simulator data is available from 1st Jan 2021.</li>
                        <li className="text-l p-1 m-2">  Fprognos assigns 20 Free Coins on SignUp and refills to 15 Free Coins daily to the users who have no paid Coins.</li>
                        <li className="text-l p-1 m-2">  Every feature can be accessed by free-Coins that are accessed by paid-Coins so feel free to explore everything before payment.</li>
                        <li className="text-l p-1 m-2">  Fprognos has full rights to revise the plans and validity in future.</li>
                        <li className="text-l p-1 m-2">  All payments are non refundable and non transferable.Therefore make sure to test all the features before making any payment as no refund request will be entertained.</li>
                        <li className="text-l p-1 m-2">  Fprognos offers same week and same month data to backtest on any given date. We may in future include next week/next month data to backtest as well.</li>
                        <li className="text-l p-1 m-2">  Every backtest is a new backtest for us and so credit will be consumed for every run.</li>
                        <li className="text-l p-1 m-2">  If backtested results contain "NAs(Not available)" It means strikes choosen were not traded at your entry time and hence data is "not available" for those strikes at that time. So pls make sure you are comfortable with this concept.</li>
                        <li className="text-l p-1 m-2">  Generally Coins are added instantly after payment is made but sometimes it takes 15-20 mins for the payment conformation. If after 20 mins Coins are not reflected, pls drop us the mail.</li>
                        <li className="text-l p-1 m-2">  Only Indian Cards are allowed for the payments.</li>
                        <li className="text-l p-1 m-2">  Since there are lots of technical & mathematical calculations & logic involved while backtesting, there can be some issues and/or bugs in the system. Users can report us those issues through mail. But in any case Fprognos will not be responsible for any kind of loss/refund users bear while trading any strategy or otherwise.</li>
                        <li className="text-l p-1 m-2">  We are not SEBI registered & we never recommend any strategy to any user. It's purely users' discretion to choose what to trade and what not to trade.</li>
                        <li className="text-l p-1 m-2">  For any query please mail us at support.dftl@damyant.com</li>
                        <li className="text-l p-1 m-2">For Free Coin,Result will be shown for latest 1 month data only and user cannot change the date.</li>
                        <li className="text-l p-1 m-2">For Paid Coin,User can do anything and everthing will be available.</li>
                    </ul>
                </div>

                {/* Customer Review  and Backtest count*/}
                {/* <Section4/> */}

                <FooterVersion2 />
                {/* Box to Enter Detail of User Profile!! */}
                <Dialog className="border-2 border-t-8 border-blue-500 rounded-xl" maxWidth="xl" onClose={(event, reason) => {
                    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') { setVisiblePaymentBox(false); }
                }} open={visiblePaymentBox}>
                    <div className="grid grid-cols-2 border-b-2">
                        <div className="text-[#232323] text-xl p-2 w-96">Fill the Detail for Payment!!!</div>
                        <div className="text-xl text-[#232323] flex justify-end font-semibold p-2 cursor-pointer"><MdClose onClick={() => { setVisiblePaymentBox(false); }} /></div>
                    </div>
                    <div className="flex flex-col mt-2 p-1 gap-2">
                        <div className='flex flex-row'><div className="font-medium text-xl w-36 font-serif text-[#232323]">Name</div><input type="text" className='text-l bg-[#F8F8F8] rounded-lg p-1 flex-grow outline-0' value={name} onChange={(e) => { setName(e.target.value); }} /></div>
                        <div className="flex flex-row"><div className="font-medium text-xl w-36 font-serif text-[#232323]">Email</div><input type="text" className='text-l bg-[#F8F8F8] rounded-lg p-1 flex-grow outline-0' value={email} onChange={(e) => { setEmail(e.target.value); }} /></div>
                        <div className='flex flex-row'><div className="font-medium text-xl w-36 font-serif text-[#232323]">Phone Number</div><input type="text" className='text-l bg-[#F8F8F8] rounded-lg p-1 flex-grow outline-0' value={mobileNumber} onChange={(e) => { setMobileNumber(e.target.value); }} /></div>
                        <div className='flex flex-row'><div className="font-medium text-xl w-36 font-serif text-[#232323]">Plan Name</div><div className='text-l bg-[#F8F8F8] rounded-lg p-1 flex-grow outline-0'>{planDetail.planName}</div></div>
                        <div className='flex flex-row'><div className="font-medium text-xl w-36 font-serif text-[#232323]">Plan Price</div><div className='text-l bg-[#F8F8F8] rounded-lg p-1 flex-grow outline-0'>{planDetail.price}</div></div>
                        <div className='flex flex-row'><div className="font-medium text-xl w-36 font-serif text-[#232323]">Plan Validation</div><div className='text-l bg-[#F8F8F8] rounded-lg p-1 flex-grow outline-0'>{planDetail.ExpiryDay} Day</div></div>
                        <div className='flex flex-row'><div className="font-medium text-xl w-36 font-serif text-[#232323]">No. Of Coin</div><div className='text-l bg-[#F8F8F8] rounded-lg p-1 flex-grow outline-0'>{planDetail.coinQuantity}</div></div>
                        <div className="flex justify-end gap-2 p-1">
                            <button className="border-2 p-1 w-24 bg-blue-600 text-white rounded-lg justify-center text-center cursor-pointer" onClick={() => { setVisiblePaymentBox(false); get_payment_detail(); }}>Proceed</button>
                            <button className="border-2 p-1 w-24 text-blue-600 border-blue-600 bg-white rounded-lg justify-center text-center cursor-pointer" onClick={() => { setVisiblePaymentBox(false); }}>Cancel</button>
                        </div>
                        <div className=''>
                            <div className="font-serif">**please be carefull at the time of filling the data.After submission,You will not be able to change anything.</div>
                            <div className="font-serif">**For Support,Please contact with <span className="text-blue-500 text-l font-serif">support.dftl@damyant.com</span></div>
                        </div>
                    </div>
                </Dialog>
                {/* Box to Confirm the detail before proceeding the payment!! */}
                <Dialog className="border-2 border-t-8 border-blue-500 rounded-xl" maxWidth="xl" onClose={(event, reason) => {
                    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') { setConfirmationPageBox(false); }
                }} open={confirmationPageBox}>
                    <div className="flex flex-row border-b-2">
                        <div className="text-[#232323] text-xl p-2 w-96">Please Check the Payment Detail!!!</div>
                        <div className="text-xl text-[#232323] flex justify-end font-semibold p-2 cursor-pointer flex-grow"><MdClose onClick={() => { setVisiblePaymentBox(false); }} /></div>
                    </div>
                    <div className="flex flex-col mt-2 p-1 gap-2">
                        <div className='flex flex-row p-1'><div className="font-medium text-l font-serif text-[#232323] w-36">Name</div><div className='text-left bg-[#F8F8F8] p-1 flex-grow'>{paymentResult.name}</div></div>
                        <div className="flex flex-row p-1"><div className="font-medium text-l font-serif text-[#232323] w-36">Email</div><div className='text-left bg-[#F8F8F8] p-1 flex-grow'>{paymentResult.email}</div></div>
                        <div className='flex flex-row p-1'><div className="font-medium text-l font-serif text-[#232323] w-36">Phone Number</div><div className='text-left bg-[#F8F8F8] p-1 flex-grow'>{paymentResult.mobileNumber}</div></div>
                        <div className='flex flex-row p-1'><div className="font-medium text-l font-serif text-[#232323] w-36">Plan Name</div><div className='text-left bg-[#F8F8F8] p-1 flex-grow'>{paymentResult.productInfo.planName}</div></div>
                        <div className='flex flex-row p-1'><div className="font-medium text-l font-serif text-[#232323] w-36">Plan Price</div><div className='text-left bg-[#F8F8F8] p-1 flex-grow'>{paymentResult.productInfo.planPrice}</div></div>
                        <div className='flex flex-row p-1'><div className="font-medium text-l font-serif text-[#232323] w-36">Plan Validation</div><div className='text-left bg-[#F8F8F8] p-1 flex-grow'>{paymentResult.productInfo.planValidation} Day</div></div>
                        <div className='flex flex-row p-1'><div className="font-medium text-l font-serif text-[#232323] w-36">No. Of Coin</div><div className='text-left bg-[#F8F8F8] p-1 flex-grow'>{paymentResult.productInfo.coinQuantity}</div></div>
                        <form action="https://test.payu.in/_payment" method='post'>
                            <input type="hidden" className="text-l border-2 border-black rounded-lg p-1 m-1" name="key" value={paymentResult.key} onChange={() => { }} />
                            <input type="hidden" className="text-l border-2 border-black rounded-lg p-1 m-1" name="txnid" value={paymentResult.transactionId} onChange={() => { }} />
                            <input type="hidden" className="text-l border-2 border-black rounded-lg p-1 m-1" name="productinfo" value={paymentResult.productInfo.planName} onChange={() => { }} />
                            <input type="hidden" className="text-l border-2 border-black rounded-lg p-1 m-1" name="amount" value={paymentResult.amount} onChange={() => { }} />
                            <input type="hidden" className="text-l border-2 border-black rounded-lg p-1 m-1" name="firstname" value={paymentResult.name} onChange={() => { }} />
                            <input type="hidden" className="text-l border-2 border-black rounded-lg p-1 m-1" name="email" value={paymentResult.email} onChange={() => { }} />
                            <input type="hidden" className="text-l border-2 border-black rounded-lg p-1 m-1" name="phone" value={paymentResult.mobileNumber} onChange={() => { }} />
                            <input type="hidden" className="text-l border-2 border-black rounded-lg p-1 m-1" name="surl" value={paymentResult.successUrl} onChange={() => { }} />
                            <input type="hidden" className="text-l border-2 border-black rounded-lg p-1 m-1" name="furl" value={paymentResult.failureUrl} onChange={() => { }} />
                            <input type="hidden" className="text-l border-2 border-black rounded-lg p-1 m-1" name="hash" value={paymentResult.hash} onChange={() => { }} />

                            <div className="flex justify-end gap-2 p-1 border-t-2">
                                <button type="submit" className="border-2 p-1 w-24 bg-blue-600 text-white rounded-lg justify-center text-center cursor-pointer" onClick={() => { setConfirmationPageBox(false); }}>Pay Now</button>
                            </div>
                        </form>
                        <button className="border-2 p-1 w-24 text-blue-600 border-blue-600 bg-white rounded-lg justify-center text-center cursor-pointer" onClick={() => { setConfirmationPageBox(false); cancelPayment(); }}>Cancel</button>
                        <div className=''>
                            <div className="font-serif">***Please check the details carefully, proceed with payment after confirmation.</div>
                            <div className="font-serif">***For Support,Please contact with <span className="text-blue-500 text-l font-serif">support.dftl@damyant.com</span></div>
                        </div>
                    </div>
                </Dialog>
            </div>
        </>
    )
}

export default Plan