export const tradeResult = (worksheet, data) => {
    try {
        // Ensure data is available and is in the correct format
        if (!data || !Array.isArray(data)) {
            throw new Error("Data is either missing or not in an array format.");
        }

        // Map data to structured flatData format
        const flatData = data.map((entry) => ({
            Date: entry.date,
            StartTime: entry.startTime,
            EndTime: entry.endTime,
            Day: entry.day,
            StartingFuturePrice: entry.startingFuturePrice,
            ExitFuturePrice: entry.exitFuturePrice,
            StartingSpotPrice: entry.startingSpotPrice,
            ExitSpotPrice: entry.exitSpotPrice,
            StartingVixPrice: entry.startingVixPrice,
            ExitVixPrice: entry.exitVixPrice,
            MarketEntryPrice: `${entry.marketEntryPrice.Date} ${entry.marketEntryPrice.Time}`,
            MarketClosedPricePreviousDay: `${entry.marketClosedPricePreviousDay.Date} ${entry.marketClosedPricePreviousDay.Time}`,
            MarketOpen: entry.marketOpen,
            MaxProfit: entry.maxProfit,
            MaxLoss: entry.maxLoss,
            TotalProfit: entry.totalProfit,
            Expiry: entry.expiry.join(", "),
            Profit: entry.profit,
            result: entry.result // Assuming this is the new field
        }));

        // Set the headers in the worksheet with proper styling
        const headers = Object.keys(flatData[0]);
        headers.forEach((header, index) => {
            const cell = worksheet.getCell(1, index + 1);
            cell.value = header;
            cell.font = { bold: true, size: 12, color: { argb: 'FFFFFF' } };
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '4682B4' }  // Slate Blue color for headers
            };
            cell.alignment = { horizontal: 'center', vertical: 'middle' };
            cell.border = { bottom: { style: 'medium', color: { argb: '000000' } } };
            cell.height = 20; // Increase header height for better visibility
        });

        // Populate worksheet with trade results data and style cells
        let rowIndex = 0;
        flatData.forEach((entry, key) => {
            rowIndex += 2; // +2 for header row, *2 for spacing
            Object.entries(entry).forEach(([key, value], colIndex) => {
                if (key === 'result') return; // Skip the 'result' field
                const cell = worksheet.getCell(rowIndex, colIndex + 1);
                cell.value = value;
                cell.font = { size: 11, color: { argb: '000000' } };  // Black color for regular cells
                cell.alignment = { horizontal: 'center', vertical: 'middle' };
                cell.border = {
                    top: { style: 'thin', color: { argb: '000000' } },
                    left: { style: 'thin', color: { argb: '000000' } },
                    bottom: { style: 'thin', color: { argb: '000000' } },
                    right: { style: 'thin', color: { argb: '000000' } }
                };
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: (key % 2 === 0) ? 'FFFFFF' : 'F0F0F0' }  // Alternate row colors for readability
                };
                cell.height = 18; // Set row height for regular cells
            });

            rowIndex += 2; // Increment row by 1

            // Header Result
            const additionalHeaders = ["Entry", "Start Price", "Exit Price", "Start Time", "Exit Time", "Strike Price", "Square Off Reason", "Re-Entry Times", "Profit/Loss"];

            // Set the headers for additional data
            additionalHeaders.forEach((header, index) => {
                const cell = worksheet.getCell(rowIndex, index + 3);
                cell.value = header;
                cell.font = { bold: true, size: 12, color: { argb: 'FFFFFF' } }; // Bold font and white text

                // Set background color for subheaders
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '4682B4' } // Slate Blue for other headers
                };


                cell.alignment = { horizontal: 'center', vertical: 'middle' };
                cell.border = {
                    bottom: { style: 'medium', color: { argb: '000000' } },
                    top: { style: 'medium', color: { argb: '000000' } },
                    left: { style: 'medium', color: { argb: '000000' } },
                    right: { style: 'medium', color: { argb: '000000' } } // Solid black border on all sides
                };
                cell.height = 25; // Increase header height for better visibility
            });

            // Increment the row index by 1
            rowIndex += 1;



            // Add additional details to the next row if present
            if (entry.result && Array.isArray(entry.result)) {
                entry.result.forEach((detailArray, detailIndex) => {
                    detailArray.forEach((dataEntry, detailColIndex) => {
                        worksheet.getCell(rowIndex, 3).value = detailColIndex == 0 ? `N-${detailIndex + 1}` : `Re-${detailColIndex}`;
                        worksheet.getCell(rowIndex, 4).value = dataEntry.startPrice;
                        worksheet.getCell(rowIndex, 5).value = dataEntry.exitPrice;
                        worksheet.getCell(rowIndex, 6).value = dataEntry.startTime;
                        worksheet.getCell(rowIndex, 7).value = dataEntry.exitTime;
                        worksheet.getCell(rowIndex, 8).value = dataEntry.strikePrice;
                        worksheet.getCell(rowIndex, 9).value = dataEntry.squareOffReason;
                        worksheet.getCell(rowIndex, 10).value = dataEntry.reEntryTimes;
                        worksheet.getCell(rowIndex, 11).value = (dataEntry.exitPrice - dataEntry.startPrice).toFixed(2);

                        // Style cells
                        for (let i = 3; i <= 12; i++) {
                            const cell = worksheet.getCell(rowIndex, i);
                            cell.font = { size: 11, color: { argb: '000000' } };  // Black color for regular cells
                            cell.alignment = { horizontal: 'center', vertical: 'middle' };
                            cell.border = {
                                top: { style: 'thin', color: { argb: '000000' } },
                                left: { style: 'thin', color: { argb: '000000' } },
                                bottom: { style: 'thin', color: { argb: '000000' } },
                                right: { style: 'thin', color: { argb: '000000' } }
                            };
                            cell.fill = {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: (rowIndex % 2 === 0) ? 'FFFFFF' : 'F0F0F0' }  // Alternate row colors for readability
                            };
                        }
                        rowIndex += 1; // Increment row by 1
                    });
                });
            }
        });

        // Set proper column widths
        const columnWidths = [
            { width: 15 }, // Date
            { width: 15 }, // StartTime
            { width: 15 }, // EndTime
            { width: 10 }, // Day
            { width: 20 }, // StartingFuturePrice
            { width: 20 }, // ExitFuturePrice
            { width: 20 }, // StartingSpotPrice
            { width: 20 }, // ExitSpotPrice
            { width: 20 }, // StartingVixPrice
            { width: 20 }, // ExitVixPrice
            { width: 30 }, // MarketEntryPrice
            { width: 30 }, // MarketClosedPricePreviousDay
            { width: 15 }, // MarketOpen
            { width: 15 }, // MaxProfit
            { width: 15 }, // MaxLoss
            { width: 15 }, // TotalProfit
            { width: 20 }, // Expiry
            { width: 15 }, // Profit
            { width: 20 }, // Start Price
            { width: 20 }, // Exit Price
            { width: 20 }, // Start Time
            { width: 20 }, // Exit Time
            { width: 20 }, // Strike Price
            { width: 30 }, // Square Off Reason
            { width: 20 }, // Re-Entry Times
            { width: 20 }  // Market Open
        ];

        // Apply the widths to the columns
        columnWidths.forEach((col, index) => {
            worksheet.getColumn(index + 1).width = col.width;
        });

        // Add a summary row at the bottom (example for total profit)
        const totalProfitRow = flatData.reduce((sum, entry) => sum + entry.TotalProfit, 0);
        const lastRow = flatData.length * 2 + 2; // +2 for header and spacing rows
        worksheet.getCell(lastRow, headers.indexOf('TotalProfit') + 1).value = `Total Profit: ${totalProfitRow}`;
        worksheet.getCell(lastRow, headers.indexOf('TotalProfit') + 1).font = { bold: true, size: 12, color: { argb: 'FF0000' } }; // Highlight total profit in red
        worksheet.getCell(lastRow, headers.indexOf('TotalProfit') + 1).alignment = { horizontal: 'right' };

    } catch (error) {
        console.error('Error in tradeResult function:', error);
    }
};
