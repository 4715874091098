import React, { useEffect, useState } from 'react';
import { GOLD_TRADING1 } from '../../../constant/constant';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import { FaArrowLeft } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa";
import { MdOutlineKeyboardArrowUp } from "react-icons/md";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { Tooltip } from 'react-tooltip';
import Excel from './Excel';
import { useNavigate } from 'react-router-dom';

const Rollover = () => {
    const [symbol, setSymbol] = useState('NIFTY');
    const [targetProfit, setTargetProfit] = useState(10);
    const [targetLoss, setTargetLoss] = useState(10);
    const [targetDiff, setTargetDiff] = useState(4);
    const [startDate, setStartDate] = useState("2023-02-05");
    const [endDate, setEndDate] = useState("2024-02-18");
    const [startTime, setStartTime] = useState("00:01");
    const [endTime, setEndTime] = useState("23:59");
    const [lotSize, setLotSize] = useState(1);
    const [action, setAction] = useState('BUY');
    const [expiryDay, setExpiryDay] = useState(0);
    // Result
    const [tradeList, setTradeList] = useState([])
    const [totalPnl, setTotalPnl] = useState([])
    const [monthlyProfit, setMonthlyProfit] = useState([])
    const [investmentDay, setInvestmentDay] = useState([])
    const [maximumProfit, setMaximumProfit] = useState(0)
    const [minimumProfit, setMinimumProfit] = useState(0)
    const [margin, setMargin] = useState(0)
    const [maximumDrawdown, setMaximumDrawdown] = useState(0)
    const [resultShow, setResultShow] = useState(false);
    const [futurePrice, setFuturePrice] = useState([])
    const [futurePriceDatetime, setFuturePriceDatetime] = useState([])
    const [profitList, setProfitList] = useState()
    const [bookedMonthProfit, setBookedMonthProfit] = useState([])
    const [monthlyLossExpiry, setMontlyLossExpiry] = useState([])
    const [rollOverQuantity, setRollOverQuantity] = useState([]);
    const [trailRollOver, setTrailRollOver] = useState([])
    const [maximumRollOverQuantity, setMaximumRollOverQuantity] = useState(0);
    const [minimumBookedProfit, setMinimumBookedProfit] = useState(0)
    const [maximumBookedProfit, setMaximumBookedProfit] = useState(0)
    const [trailRollOverSum, setTrailRollOverSum] = useState([])
    const [dataType, setDataType] = useState('gold_data');
    const [monthExpiry, setMonthExpiry] = useState([]);
    const [expiry, setExpiry] = useState({})
    const [expiryHelp, setExpiryHelp] = useState({})
    const [moreDetail, setMoreDetail] = useState(false);
    const [minimumPrice, setMinimumPrice] = useState(0);
    const [maximumPrice, setMaximumPrice] = useState(0);
    const [rollOverResult, setRollOverResult] = useState({});
    const [maximumLoss, setMaximumLoss] = useState(0);
    // page Size
    const [pageSize, setPageSize] = useState(1)
    const [currentIndex, setCurrentIndex] = useState(1)
    // loader
    const [loader, setloader] = useState(false);
    // monthlyResult
    const [monthlyResult, setMonthlyResult] = useState([])
    const [showResult, setShowResult] = useState(1);
    
    const navigate = useNavigate();
    
     const month = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    // Sorting the Data on the basis of Index
    const sorted = (index, order) => {
        const data = [];
        for (let i = 0; i < tradeList.length; i++) {
            const sortedData = tradeList[i].sort((a, b) => {
                const itemA = a[index];
                const itemB = b[index];
                if (order === 0) {
                    if (typeof itemA === 'string' && typeof itemB === 'string') {
                        return itemA.localeCompare(itemB);
                    } else if (typeof itemA === 'number' && typeof itemB === 'number') {
                        return itemA - itemB;
                    } else {
                        return 0; // No comparison possible, keep the order unchanged
                    }
                } else {
                    if (typeof itemA === 'string' && typeof itemB === 'string') {
                        return itemB.localeCompare(itemA);
                    } else if (typeof itemA === 'number' && typeof itemB === 'number') {
                        return itemB - itemA;
                    } else {
                        return 0; // No comparison possible, keep the order unchanged
                    }
                }
            });
            data.push(sortedData);
        }
        setTradeList(data);
        month_result();
    };
    const calculateTrailRollOver = () => {
        const trailRollOverSum = [];
        for (let i = 0; i < trailRollOver.length; i++) {
            let res = trailRollOver[i].reduce((sum, item) => sum + item[9], 0);
            trailRollOverSum.push(res.toFixed(2)); // Push the result into trailRollOverSum array
        }
        setTrailRollOverSum(trailRollOverSum);
    }
    const calculateMaximumDrawdown = () => {
        let maxProfit = 0;
        let MaximumDrawdown = 0;
        let previous = 0
        let cumulative = new Array(monthlyProfit.length).fill(0);
        for (let i = 0; i < monthlyProfit.length; i++) {
            // Method to find the Cumulative Data
            cumulative[i] = previous + monthlyProfit[i];
            previous = cumulative[i];
            // Method to find the Maximum Drawdown
            // Maximum Drawdown is the biggest loss observed in an investment from peak to trough
            // Formula of Drawdown:: MIN((B2-MAX(<span className='text-blue-600'>$</span>B<span className='text-blue-600'>$</span>2-B2))/MAX(<span className='text-blue-600'>$</span>B<span className='text-blue-600'>$</span>2-B2)
            if (monthlyProfit[i] > maxProfit)
                maxProfit = monthlyProfit[i];
            if (cumulative[i] > maxProfit) {
                maxProfit = cumulative[i]; // Update maxPrice if a new peak is reached
            }
            const currentDrawdown = (cumulative[i] - maxProfit);

            if (currentDrawdown < MaximumDrawdown) {
                MaximumDrawdown = currentDrawdown; // Update drawdown if a new minimum drawdown is reached
            }
        }
        setMaximumDrawdown(MaximumDrawdown);
        month_result();
    }
    const calculationResult = (monthlyProfit) => {
        if (monthlyProfit.length > 0) {
            let min = monthlyProfit[0], max = monthlyProfit[0];
            for (let i = 0; i < monthlyProfit.length; i++) {
                if (monthlyProfit[i] > max)
                    max = monthlyProfit[i];
                if (monthlyProfit[i] < min)
                    min = monthlyProfit[i];
            }
            setMinimumProfit(min);
            setMaximumProfit(max);
        }
        month_result();
    }
    function max(a, b) {
        if (a < b)
            return b;
        return a;
    }

    const month_result = () => {
        let result = [];
        let totalRollOverQuantity = 0, totalBookedMonthProfit = 0, totalMonthlyProfit = 0, totalRollOverLoss = 0;
        let maximumRollOverQuantity = 0;
        let month1 = {}
        let monthResult1 = {}
        for (let i = 0; i < monthlyProfit.length; i++) {
            result.push([i + 1, rollOverQuantity[i], bookedMonthProfit[i], monthlyProfit[i], monthlyLossExpiry[i]]);
            totalRollOverQuantity += rollOverQuantity[i] ? rollOverQuantity[i] : 0;
            totalBookedMonthProfit += bookedMonthProfit[i];
            totalMonthlyProfit += monthlyProfit[i];
            totalRollOverLoss += monthlyLossExpiry[i] ? monthlyLossExpiry[i] : 0;
            if (rollOverQuantity[i] > maximumRollOverQuantity) {
                maximumRollOverQuantity = rollOverQuantity[i];
            }
            // Method to find the MontlyWise Profit
            if (monthExpiry[i]) {
                if (month1.hasOwnProperty(monthExpiry[i][0] + monthExpiry[i][1] + monthExpiry[i][2] + monthExpiry[i][3])) {
                    month1[monthExpiry[i][0] + monthExpiry[i][1] + monthExpiry[i][2] + monthExpiry[i][3]][parseInt(monthExpiry[i][4] + monthExpiry[i][5])] = bookedMonthProfit[i]
                } else {
                    month1[monthExpiry[i][0] + monthExpiry[i][1] + monthExpiry[i][2] + monthExpiry[i][3]] = new Array(13).fill(0);
                    month1[monthExpiry[i][0] + monthExpiry[i][1] + monthExpiry[i][2] + monthExpiry[i][3]][parseInt(monthExpiry[i][4] + monthExpiry[i][5])] = bookedMonthProfit[i];
                }
            }
            if (monthExpiry[i]) {
                if (monthResult1.hasOwnProperty(monthExpiry[i][0] + monthExpiry[i][1] + monthExpiry[i][2] + monthExpiry[i][3])) {
                    monthResult1[monthExpiry[i][0] + monthExpiry[i][1] + monthExpiry[i][2] + monthExpiry[i][3]][0] = max(rollOverQuantity[i], monthResult1[monthExpiry[i][0] + monthExpiry[i][1] + monthExpiry[i][2] + monthExpiry[i][3]][0]);
                    monthResult1[monthExpiry[i][0] + monthExpiry[i][1] + monthExpiry[i][2] + monthExpiry[i][3]][1] += bookedMonthProfit[i];
                    monthResult1[monthExpiry[i][0] + monthExpiry[i][1] + monthExpiry[i][2] + monthExpiry[i][3]][2] += monthlyLossExpiry[i];
                } else {
                    monthResult1[monthExpiry[i][0] + monthExpiry[i][1] + monthExpiry[i][2] + monthExpiry[i][3]] = new Array(3).fill(0);
                    monthResult1[monthExpiry[i][0] + monthExpiry[i][1] + monthExpiry[i][2] + monthExpiry[i][3]][0] = rollOverQuantity[i];
                    monthResult1[monthExpiry[i][0] + monthExpiry[i][1] + monthExpiry[i][2] + monthExpiry[i][3]][1] += bookedMonthProfit[i];
                    monthResult1[monthExpiry[i][0] + monthExpiry[i][1] + monthExpiry[i][2] + monthExpiry[i][3]][2] += monthlyLossExpiry[i];
                }
            }
        }
        setExpiry(month1)
        setExpiryHelp(monthResult1);
        result.push(['Total', maximumRollOverQuantity, totalBookedMonthProfit.toFixed(2), totalMonthlyProfit.toFixed(2), totalRollOverLoss.toFixed(2)]);
        setMaximumRollOverQuantity(maximumRollOverQuantity);
        setMonthlyResult(result);
        //maximum and minimum of BookedProfit
        if (bookedMonthProfit.length > 0) {
            let minBookedPnl = 999999999999;
            let maxBookedPnl = 0;
            for (let i = 0; i < bookedMonthProfit.length; i++) {
                if (bookedMonthProfit[i] < minBookedPnl)
                    minBookedPnl = bookedMonthProfit[i];
                if (bookedMonthProfit[i] > maxBookedPnl)
                    maxBookedPnl = bookedMonthProfit[i];
            }
            setMinimumBookedProfit(minBookedPnl);
            setMaximumBookedProfit(maxBookedPnl);
        }
        // For RollOver Monthly Result
        let rollOver = {}
        for (let i = 0; i < tradeList.length; i++) {
            let date = tradeList[i][tradeList[i].length - 1][3];
            // Method to find the MontlyWise Profit
            if (rollOver.hasOwnProperty(date[0] + date[1] + date[2] + date[3])) {
                rollOver[date[0] + date[1] + date[2] + date[3]][parseInt(date[4] + date[5])] = monthlyLossExpiry[i]
            } else {
                rollOver[date[0] + date[1] + date[2] + date[3]] = new Array(13).fill(0);
                rollOver[date[0] + date[1] + date[2] + date[3]][parseInt(date[4] + date[5])] = monthlyLossExpiry[i];
            }
        }
        setRollOverResult(rollOver);
    }
    const startSeriesTrading = () => {
        setloader(true);
        setResultShow(false);
        // Get the token from secureLocalStorage
        let token = secureLocalStorage.getItem('backtest_token');

        // Remove the 'backtest_' prefix if it exists
        if (token && token.startsWith('backtest_')) {
        token = token.replace('backtest_', '');
      } else {
        navigate('/backtest/login');
      }
        const data = {
            "symbol": symbol, "targetProfit": targetProfit, "targetLoss": targetLoss, "targetDiff": targetDiff, "startDate": startDate, "endDate": endDate, "startTime": startTime, "endTime": endTime,
            "lotSize": lotSize, "action": action, "expiryDay": expiryDay, "dataType": dataType
        }
        let config = { method: "post", maxBodyLength: Infinity, url: GOLD_TRADING1, headers: { "Content-Type": "application/json", "Authorization": `Bearer <span className='text-blue-600'>$</span>{token}`, }, data: data, };
        axios(config)
            .then((response) => {
                setTotalPnl(response.data?.totalProfit);
                setTradeList(response.data?.result);
                setMonthlyProfit(response.data?.monthlyProfit);
                setInvestmentDay(response.data?.investmentDay);
                setMargin(response.data?.margin);
                setResultShow(true);
                calculationResult(response.data?.monthlyProfit);
                setFuturePrice(response.data?.futurePrice);
                setFuturePriceDatetime(response.data?.futurePriceDatetime);
                setProfitList(response.data?.profitList);
                setBookedMonthProfit(response.data?.bookedMonthProfit);
                setMontlyLossExpiry(response.data?.monthlyLossExpiry);
                setRollOverQuantity(response.data?.rollOverQuantity);
                setTrailRollOver(response.data?.trailRollOver);
                setMonthExpiry(response.data?.expiry);
                setMinimumPrice(response.data.minimum);
                setMaximumPrice(response.data.maximum);
                setMaximumLoss(response.data.difference);
                setloader(false);
            }).catch((error) => {
                console.log(error, 'Error at Series Trading');
                setloader(false);
            })
    };
    useEffect(() => {
        month_result();
        calculateTrailRollOver();
    }, [showResult, totalPnl])

    return (
        <div className='bg-[#DAECF2] min-h-screen'>
            <div className='flex flex-col m-2'>
                <div className='text-xl font-semibold p-1'>Series Trading</div>
                <div className='grid sm:grid-cols-2 lg:grid-cols-3'>
                    <div className='flex flex-row bg-white p-2 m-2'>
                        <div className='w-40 text-[18px]'>Target Profit</div>
                        <input type="number" className='text-l bg-[#ECF0FF] p-1 flex-grow outline-0' value={targetProfit} onChange={(e) => setTargetProfit(e.target.value)} />
                    </div>
                    <div className='flex flex-row bg-white p-2 m-2'>
                        <div className='w-40 text-[18px]'>Action</div>
                        <select value={action} className='text-l bg-[#ECF0FF] p-1 flex-grow outline-0' onChange={(e) => { setAction(e.target.value) }}>
                            <option value="BUY">BUY</option>
                            <option value="SELL">SELL</option>
                        </select>
                    </div>
                    <div className='flex flex-row bg-white p-2 m-2'>
                        <div className='w-40 text-[18px]'>Lot</div>
                        <input type="number" className='text-l bg-[#ECF0FF] p-1 flex-grow outline-0' value={lotSize} onChange={(e) => setLotSize(e.target.value)} />
                    </div>
                    <div className='flex flex-row bg-white p-2 m-2'>
                        <div className='w-40 text-[18px]'>Entry Difference</div>
                        <input type="number" className='text-l bg-[#ECF0FF] p-1 flex-grow outline-0' value={targetDiff} onChange={(e) => setTargetDiff(e.target.value)} />
                    </div>
                    <div className='flex flex-row bg-white p-2 m-2'>
                        <div className='w-40 text-[18px]'>Start Date</div>
                        <input type="date" className='text-l bg-[#ECF0FF] p-1 flex-grow outline-0' value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                    </div>
                    <div className='flex flex-row bg-white p-2 m-2'>
                        <div className='w-40 text-[18px]'>End Date</div>
                        <input type="date" className='text-l bg-[#ECF0FF] p-1 flex-grow outline-0' value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                    </div>
                    <div className='flex flex-row bg-white p-2 m-2'>
                        <div className='w-40 text-[18px]'>Start Time</div>
                        <input type="time" className='text-l bg-[#ECF0FF] p-1 flex-grow outline-0' value={startTime} onChange={(e) => setStartTime(e.target.value)} />
                    </div>
                    <div className='flex flex-row bg-white p-2 m-2'>
                        <div className='w-40 text-[18px]'>End Time</div>
                        <input type="time" className='text-l bg-[#ECF0FF] p-1 flex-grow outline-0' value={endTime} onChange={(e) => { setEndTime(e.target.value); }}></input>
                    </div>
                    <div className='flex flex-row bg-white p-2 m-2'>
                        <div className='w-40 text-[18px]'>Data Type</div>
                        <select className='text-l bg-[#ECF0FF] p-1 flex-grow outline-0' value={dataType} onChange={(e) => { setDataType(e.target.value); }}>
                            <option value="gold_data">Unadjusted Data</option>
                        </select>
                    </div>
                </div>
                <div className='flex flex-col p-2 m-1'>
                    <div className='text-14px'>*Data is present from 31st January,2008 to 26th February,2024.</div>
                    <div className='text-14px'>*Range of Time is 00:01 to 16:59 and 18:00 to 23:59 </div>
                    <div className='text-14px'>*Profit and Price is in dollar.</div>
                    <div className='text-14px'>*Using unadjusted data and 1% of price taken for rollOver at expiry month.</div>
                </div>
                {!loader && <div className='bg-[#2D5BFF] h-8 rounded-md text-center text-white text-[16px] cursor-pointer' onClick={startSeriesTrading}>Start Series Trading</div>}
                {loader && <div className="bouncing-loader">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>}
            </div>
            {resultShow && <div className='flex flex-col'>
                <div className='bg-white text-xl rounded-md m-2 text-center'>Series Trading Result</div>
                {/* Only For Result */}
                <div className='grid grid-cols-2 m-2'>
                    <div className='flex flex-row gap-2'>
                        <div className='text-[18px]'>Filter</div>
                        <select className='w-48 outline-0 text-[16px] bg-[#2D5BFF] text-white rounded' value={showResult} onChange={(e) => setShowResult(parseInt(e.target.value))}>
                            <option value={1}>Show Daily Result</option>
                            <option value={2}>Show RollOver Result</option>
                            <option value={5}>Show Trail RollOver</option>
                        </select>
                    </div>
                    <div className='flex justify-end gap-2'>
                        <div className='bg-blue-600 text-white p-1 text-[16px] rounded font-serif w-32 text-center cursor-pointer' onClick={() => { setMoreDetail(!moreDetail); }}>{moreDetail ? 'MoreDetail' : 'ConciseDetail'}</div>
                        <Excel tradeList={tradeList} monthlyResult={monthlyResult} trailRollOver={trailRollOver} trailRollOverSum={trailRollOverSum} action={action} symbol={symbol} lot={lotSize} targetProfit={targetProfit} targetDiff={targetDiff} maximumDrawdown={maximumDrawdown} minimumProfit={minimumProfit} maximumProfit={maximumProfit} margin={margin} totalProfit={totalPnl} investmentDay={investmentDay} monthlyProfit={monthlyProfit} monthlyLossExpiry={monthlyLossExpiry} rollOverQuantity={rollOverQuantity} bookedMonthProfit={bookedMonthProfit} />
                    </div>
                </div>
                <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 m-2'>
                    <div className='flex flex-row bg-white rounded-md p-2'>
                        <div className='text-l'>Estimate Margin :</div>
                        <div className='font-semibold flex justify-end flex-grow'><span className='text-blue-600'>$</span>{margin}</div>
                    </div>
                    <div className='flex flex-row bg-white rounded-md p-2'>
                        <div className='text-l'>Total Profit :</div>
                        <div className='font-semibold flex justify-end flex-grow'><span className='text-blue-600'>$</span>{totalPnl}</div>
                    </div>
                    <div className='flex flex-row bg-white rounded-md p-2'>
                        <div className='text-l'>Total Trading Days :</div>
                        <div className='font-semibold flex justify-end flex-grow'>{investmentDay}</div>
                    </div>
                    <div className='flex flex-row bg-white rounded-md p-2'>
                        <div className='text-l'>Maximum Monthly Profit:</div>
                        <div className='font-semibold flex justify-end flex-grow'><span className='text-blue-600'>$</span>{maximumProfit}</div>
                    </div>
                    <div className='flex flex-row bg-white rounded-md p-2'>
                        <div className='text-l'>Minimum Monthly Profit:</div>
                        <div className='font-semibold flex justify-end flex-grow'><span className='text-blue-600'>$</span>{minimumProfit}</div>
                    </div>
                    <div className='flex flex-row bg-white rounded-md p-2'>
                        <div className='text-l'>Maximum BookedProfit:</div>
                        <div className='font-semibold flex justify-end flex-grow'><span className='text-blue-600'>$</span>{maximumBookedProfit}</div>
                    </div>
                    <div className='flex flex-row bg-white rounded-md p-2'>
                        <div className='text-l'>Minimum BookedProfit:</div>
                        <div className='font-semibold flex justify-end flex-grow'><span className='text-blue-600'>$</span>{minimumBookedProfit}</div>
                    </div>
                    <div className='flex flex-row bg-white rounded-md p-2'>
                        <div className='text-l'>Low and High Price:</div>
                        <div className='font-semibold flex justify-end flex-grow'><span className='text-blue-600'>$</span>{minimumPrice} -- <span className='text-blue-600'>$</span>{maximumPrice}</div>
                    </div>
                    <div className='flex flex-row bg-white rounded-md p-2'>
                        <div className='text-l'>Maximum Loss:</div>
                        <div className='font-semibold flex justify-end flex-grow'><span className='text-blue-600'>$</span>{maximumLoss}</div>
                    </div>
                </div>
                {/* Monthly Result */}
                {showResult == 1 && <div className='bg-white rounded-lg m-2 overflow-auto'>
                    <div className='flex flex-row justify-around gap-2 border-2 text-center bg-blue-500 text-white min-w-[1560px]'>
                        <div className='text-[18px] text-[600] text-center text-black w-32'>Years.</div>
                        {
                            month.map((item, key) => {
                                return (
                                    <div className='text-[18px] text-[600] text-center  text-black w-32'>{item}</div>
                                )
                            })
                        }
                        <div className='text-[18px] text-[600] text-center text-black w-32'>Roll.Qnt</div>
                        <div className='text-[18px] text-[600] text-center text-black w-32'>Book.Pnl</div>
                        <div className='text-[18px] text-[600] text-center text-black w-32'>Roll.Pnl</div>
                    </div>
                    {
                        Object.entries(expiry).map(([key, value]) => (
                            <div className=" bg-white flex flex-row justify-around text-center min-w-[1560px]" key={key}>
                                <div className="p-2 text-black text-center w-32">{key}</div>
                                <div className="p-2 text-black text-center w-32">{value[1]}</div>
                                <div className="p-2 text-black text-center w-32">{value[2]}</div>
                                <div className="p-2 text-black text-center w-32">{value[3]}</div>
                                <div className="p-2 text-black text-center w-32">{value[4]}</div>
                                <div className="p-2 text-black text-center w-32">{value[5]}</div>
                                <div className="p-2 text-black text-center w-32">{value[6]}</div>
                                <div className="p-2 text-black text-center w-32">{value[7]}</div>
                                <div className="p-2 text-black text-center w-32">{value[8]}</div>
                                <div className="p-2 text-black text-center w-32">{value[9]}</div>
                                <div className="p-2 text-black text-center w-32">{value[10]}</div>
                                <div className="p-2 text-black text-center w-32">{value[11]}</div>
                                <div className="p-2 text-black text-center w-32">{value[12]}</div>
                                <div className="p-2 text-black text-center w-32">{expiryHelp[key][0]}</div>
                                <div className="p-2 text-black text-center w-32">{expiryHelp[key][1].toFixed(2)}</div>
                                <div className="p-2 text-black text-center w-32">{expiryHelp[key][2].toFixed(2)}</div>
                            </div>
                        ))
                    }
                </div>}

                {showResult == 1 && <div className='flex flex-col bg-white m-2 rounded-lg overflow-auto'>
                    <div className='grid grid-cols-9 border-b-2 border-black min-w-[1440px] text-center p-2'>
                        <div className='font-[600] text-[16px] flex flex-row text-center justify-start'>S.No&nbsp;Entry<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(0, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(0, 1); }} /></div></div>
                        <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>Exit&nbsp;<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(2, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(2, 1); }} /></div></div>
                        <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>Entry&nbsp;Price<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(4, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(4, 1); }} /></div></div>
                        <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>Exit&nbsp; Price<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(5, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(5, 1); }} /></div></div>
                        <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>Profit<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(6, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(6, 1); }} /></div></div>
                        <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>Monthly&nbsp;Expiry<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(7, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(7, 1); }} /></div></div>
                        <div className='font-[600] text-[16px]'>Reason</div>
                        <div className='font-[600] text-[16px]'>Low & High</div>
                        <div className='font-[600] text-[16px]'>Target Price</div>
                    </div>
                    {
                        tradeList.length > 0 && tradeList.map((list, key) => {
                            return (
                                <div>
                                    {
                                        list.map((item, index) => {
                                            return (
                                                <div className='grid grid-cols-9 border-b-2 min-w-[1440px] p-2 text-center' key={index + key} style={{ backgroundColor: item[13][0] == 'R' ? '#DAECF2' : 'white' }}>
                                                    <div className='text-[16px]'>{index + 1} {item[0]}&nbsp;&nbsp;{item[1]}</div>
                                                    <div className='text-[16px]'>{item[2]}&nbsp;&nbsp;{item[3]}</div>
                                                    <div className='text-[16px]'><span className='text-blue-600'>$</span>{item[6]}</div>
                                                    <div className='text-[16px]'><span className='text-blue-600'>$</span>{item[7]}</div>
                                                    <div className='text-[16px]' style={{ color: item[9] > 0 ? "green" : "red" }}><span className='text-blue-600'>$</span>{item[9]}</div>
                                                    <div className='text-[16px]'>{item[10]}</div>
                                                    <div className='text-[16px]'>{item[13]}</div>
                                                    <div className='text-[16px]'>{item[14]}---{item[15]}</div>
                                                    <div className='text-[16px'><span className='text-blue-600'>$</span>{item[16]}</div>
                                                </div>
                                            )
                                        })
                                    }
                                    <div className='m-2 border-2 rounded-lg border-black grid grid-cols-2 md:grid-cols-4 gap-3 p-2'>
                                        <div className=''><span className='font-[600]'>Month :</span>{key + 1}</div>
                                        <div className=''><span className='font-[600]'>Booked Profit/Loss:</span> {bookedMonthProfit[key]}<span className='text-blue-600'>$</span> </div>
                                        <div className=''><span className='font-[600]'>Net Monthly Profit/Loss:</span> {monthlyProfit[key]}<span className='text-blue-600'>$</span></div>
                                        <div className=''><span className='font-[600]'>Expiry Profit/Loss:</span> {monthlyLossExpiry[key]}<span className='text-blue-600'>$</span></div>
                                    </div>
                                </div>

                            )
                        })
                    }
                </div>}
                {moreDetail && showResult == 2 && <div className='flex flex-col bg-white m-2 rounded-lg overflow-auto'>
                    <div className='grid grid-cols-9 border-b-2 border-black min-w-[1440px] text-center p-2'>
                        <div className='font-[600] text-[16px] flex flex-row text-center justify-start'>S.No&nbsp;Entry<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(0, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(0, 1); }} /></div></div>
                        <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>Exit&nbsp;<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(2, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(2, 1); }} /></div></div>
                        <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>Entry&nbsp;Price<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(4, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(4, 1); }} /></div></div>
                        <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>Exit&nbsp; Price<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(5, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(5, 1); }} /></div></div>
                        <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>Profit<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(6, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(6, 1); }} /></div></div>
                        <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>Monthly&nbsp;Expiry<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(7, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(7, 1); }} /></div></div>
                        <div className='font-[600] text-[16px]'>Reason</div>
                        <div className='font-[600] text-[16px]'>Low & High</div>
                        <div className='font-[600] text-[16px]'>Target Price</div>
                    </div>
                    {
                        tradeList.length > 0 && tradeList.length > 0 && tradeList.map((list, key) => {
                            return (
                                <div>
                                    {
                                        list.map((item, index) => {
                                            return (
                                                <div>
                                                    {item[13][0] == 'R' && <div className='grid grid-cols-9 border-b-2 min-w-[1440px] p-2 text-center' key={index + key} style={{ backgroundColor: item[13][0] == 'R' ? '' : 'white' }}>
                                                        <div className='text-[16px]'>{index + 1} {item[0]}&nbsp;&nbsp;{item[1]}</div>
                                                        <div className='text-[16px]'>{item[2]}&nbsp;&nbsp;{item[3]}</div>
                                                        <div className='text-[16px]'><span className='text-blue-600'>$</span>{item[6]}</div>
                                                        <div className='text-[16px]'><span className='text-blue-600'>$</span>{item[7]}</div>
                                                        <div className='text-[16px]' style={{ color: item[9] > 0 ? "green" : "red" }}><span className='text-blue-600'>$</span>{item[9]}</div>
                                                        <div className='text-[16px]'>{item[10]}</div>
                                                        <div className='text-[16px]'>{item[13]}</div>
                                                        <div className='text-[16px]'>{item[14]}&nbsp;nbsp;{item[15]}</div>
                                                        <div className='text-[16px'><span className='text-blue-600'>$</span>{item[16]}</div>
                                                    </div>}
                                                </div>
                                            )
                                        })
                                    }
                                    <div className='m-2 rounded-lg text-white bg-blue-600 grid grid-cols-2 md:grid-cols-3 gap-3 p-2'>
                                        <div className=''><span className='font-[600]'>Month :</span>{key + 1}</div>
                                        <div className=''><span className='font-[600]'>RollOverQuantity :</span>{rollOverQuantity[key]}</div>
                                        <div className=''><span className='font-[600]'>Roll Over Profit/Loss:</span> {monthlyLossExpiry[key]}</div>
                                    </div>
                                </div>

                            )
                        })
                    }
                </div>}
                {/*                
                {!moreDetail && showResult == 2 && <div className='flex flex-col bg-white m-2 rounded-lg overflow-auto'>
                    <div className='grid grid-cols-5 border-b-2 border-black min-w-[1440px] text-center p-2'>
                        <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>S.No<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(0, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(0, 1); }} /></div></div>
                        <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>Entry DateTime&nbsp;<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(2, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(2, 1); }} /></div></div>
                        <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>Exit DateTime<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(4, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(4, 1); }} /></div></div>
                        <div className='font-[600] text-[16px]'>RollOverQuantity</div>
                        <div className='font-[600] text-[16px]'>RollOver Pnl</div>
                    </div>
                    {
                        tradeList.length > 0 && tradeList.length > 0 && tradeList.map((list, key) => {
                            return (
                                <div className='grid grid-cols-5 border-b-2 min-w-[1440px] p-2 text-center'>
                                    <div className='text-[16px]'>{key + 1}</div>
                                    <div className='text-[16px]'>{list[0][0]}&nbsp;&nbsp;{list[0][1]}</div>
                                    <div className=''>{list[list[0].length - 1][2]}&nbsp;&nbsp;{list[list[0].length - 1][3]}</div>
                                    <div className='text-[16px]'>{rollOverQuantity[key]}</div>
                                    <div className='text-[16px'><span className='text-blue-600'>$</span>{monthlyLossExpiry[key]}</div>
                                </div>
                            )
                        })
                    }
                </div>}  */}

                {showResult == 2 && !moreDetail && <div className='bg-white rounded-lg m-2 overflow-auto'>
                    <div className='flex flex-row justify-around gap-2 border-2 text-center bg-blue-500 text-white min-w-[1560px]'>
                        <div className='text-[18px] text-[600] text-center text-black w-32'>Years.</div>
                        {
                            month.map((item, key) => {
                                return (
                                    <div className='text-[18px] text-[600] text-center  text-black w-32'>{item}</div>
                                )
                            })
                        }
                        <div className='text-[18px] text-[600] text-center text-black w-32'>Roll.Qnt</div>
                        <div className='text-[18px] text-[600] text-center text-black w-32'>Roll.Pnl</div>
                    </div>
                    {
                        Object.entries(rollOverResult).map(([key, value]) => (
                            <div className=" bg-white flex flex-row justify-around text-center min-w-[1560px]" key={key}>
                                <div className="p-2 text-black text-center w-32">{key}</div>
                                <div className="p-2 text-black text-center w-32">{value[1]}</div>
                                <div className="p-2 text-black text-center w-32">{value[2]}</div>
                                <div className="p-2 text-black text-center w-32">{value[3]}</div>
                                <div className="p-2 text-black text-center w-32">{value[4]}</div>
                                <div className="p-2 text-black text-center w-32">{value[5]}</div>
                                <div className="p-2 text-black text-center w-32">{value[6]}</div>
                                <div className="p-2 text-black text-center w-32">{value[7]}</div>
                                <div className="p-2 text-black text-center w-32">{value[8]}</div>
                                <div className="p-2 text-black text-center w-32">{value[9]}</div>
                                <div className="p-2 text-black text-center w-32">{value[10]}</div>
                                <div className="p-2 text-black text-center w-32">{value[11]}</div>
                                <div className="p-2 text-black text-center w-32">{value[12]}</div>
                                <div className="p-2 text-black text-center w-32">{expiryHelp[key][0]}</div>
                                <div className="p-2 text-black text-center w-32">{rollOverResult[key][12].toFixed(2)}</div>
                            </div>
                        ))
                    }
                </div>}
                {/* -----------------------------------------Trail RollOver------------------------------------------------------------------------------ */}
                {
                    !moreDetail && showResult == 5 && <div className='flex flex-col bg-white m-2 rounded-lg overflow-auto'>
                        <div className='grid grid-cols-10 border-b-2 border-black min-w-[1440px] text-center p-2'>
                            <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>S.No<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(0, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(0, 1); }} /></div></div>
                            <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>EntryTime<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(0, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(0, 1); }} /></div></div>
                            <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>EntryDate<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(0, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(0, 1); }} /></div></div>
                            <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>ExitTime<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(0, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(0, 1); }} /></div></div>
                            <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>ExitDate<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(2, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(2, 1); }} /></div></div>
                            <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>Entry&nbsp;Price<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(4, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(4, 1); }} /></div></div>
                            <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>Exit&nbsp; Price<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(5, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(5, 1); }} /></div></div>
                            <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>NetProfit/Loss<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(6, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(6, 1); }} /></div></div>
                            <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>RollOverQuantity<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(6, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(6, 1); }} /></div></div>
                            <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>Status<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(6, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(6, 1); }} /></div></div>
                        </div>
                        {
                            trailRollOver.map((item, key) => {
                                return (
                                    <div className='grid grid-cols-10 border-b-2 min-w-[1440px] p-2 text-center' key={key}>
                                        <div className='text-[16px]'>{key + 1}</div>
                                        <div className='text-[16px]'>{item[0][0]}</div>
                                        <div className='text-[16px]'>{item[0][1]}</div>
                                        <div className='text-[16px]'>{item[item.length - 1][2]}</div>
                                        <div className='text-[16px]'>{item[item.length - 1][3]}</div>
                                        <div className='text-[16px]'>{item[0][6]}</div>
                                        <div className='text-[16px]'>{item[item.length - 1][7]}</div>
                                        <div className='text-[16px]' style={{ color: trailRollOverSum[key] > 0 ? 'green' : 'red' }}>{trailRollOverSum[key]}</div>
                                        <div className='text-[16px]'>{item.length}</div>
                                        <div className='text-[16px]'>{item[item.length - 1][13][4] == ' ' ? "Completed" : "Pending"}</div>
                                    </div>
                                )
                            })
                        }
                    </div>
                }
                {moreDetail && showResult == 5 && <div className='flex flex-col bg-white m-2 rounded-lg overflow-auto'>
                    <div className='grid grid-cols-11 border-b-2 border-black min-w-[1440px] text-center p-2'>
                        <div className='font-[600] text-[16px] flex flex-row text-center justify-start'>S.No&nbsp;Entry<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(0, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(0, 1); }} /></div></div>
                        <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>Exit&nbsp;<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(2, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(2, 1); }} /></div></div>
                        <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>Entry&nbsp;Price<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(4, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(4, 1); }} /></div></div>
                        <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>Exit&nbsp; Price<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(5, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(5, 1); }} /></div></div>
                        <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>RollOverPrice<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(6, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(6, 1); }} /></div></div>
                        <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>Profit<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(6, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(6, 1); }} /></div></div>
                        <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>Monthly&nbsp;Expiry<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(7, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(7, 1); }} /></div></div>
                        <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>RollOver<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(6, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(6, 1); }} /></div></div>
                        <div className='font-[600] text-[16px]'>Reason</div>
                        <div className='font-[600] text-[16px]'>Low & High</div>
                        <div className='font-[600] text-[16px]'>Target Price</div>
                    </div>
                    {
                        trailRollOver.length > 0 && trailRollOver.map((list, key) => {
                            return (
                                <div>
                                    {
                                        list.map((item, index) => {
                                            return (
                                                <div className='grid grid-cols-11 border-b-2 min-w-[1440px] p-2 text-center' key={index + key} style={{ backgroundColor: item[13][0] == 'R' ? '#DAECF2' : 'white' }}>
                                                    <div className='text-[16px]'>{index + 1} {item[0]}&nbsp;&nbsp;{item[1]}</div>
                                                    <div className='text-[16px]'>{item[2]}&nbsp;&nbsp;{item[3]}</div>
                                                    <div className='text-[16px]'><span className='text-blue-600'>$</span>{item[6]}</div>
                                                    <div className='text-[16px]'><span className='text-blue-600'>$</span>{item[7]}</div>
                                                    <div className='text-[16px]'><span className='text-blue-600'>$</span>{item[8]}</div>
                                                    <div className='text-[16px]' style={{ color: item[9] > 0 ? "green" : "red" }}><span className='text-blue-600'>$</span>{item[9]}</div>
                                                    <div className='text-[16px]'>{item[10]}</div>
                                                    <div className='text-[16px]'>{(item[6] + item[7] / 100).toFixed(2)}<br />{`(<span className='text-blue-600'>$</span>{item[6]}+<span className='text-blue-600'>$</span>{item[7]}/100)`}</div>
                                                    <div className='text-[16px]'>{item[13]}</div>
                                                    <div className='text-[16px]'>{item[14]}&nbsp;nbsp;{item[15]}</div>
                                                    <div className='text-[16px'><span className='text-blue-600'>$</span>{item[16]}</div>
                                                </div>
                                            )
                                        })
                                    }
                                    <div className='m-2 rounded-lg text-white bg-blue-600 grid grid-cols-3 gap-3 p-2'>
                                        <div className=''><span className='font-[600]'>Month :</span>{key + 1}</div>
                                        <div className=''><span className='font-[600]'>RollOverQuantity :</span>{list.length}</div>
                                        <div className=''><span className='font-[600]'>Roll Over Profit/Loss:</span> {trailRollOverSum[key]}</div>
                                    </div>
                                </div>

                            )
                        })
                    }
                </div>}
                {/* ------------------------------------------------------------------------------------------------------------------------------------------- */}

                {/* Page Size-> No. Of Items */}
                <div className="grid grid-cols-2" >
                    <div className="flex flex-row m-10">
                        <div className="font-[600] text-[16px]">Page Size</div>
                        <select className="ml-3 outline-0 rounded-md" value={pageSize} onChange={(e) => { setPageSize(e.target.value); }}>
                            <option value={1}>1 month</option>
                            <option value={3}>3 month</option>
                            <option value={6}>6 month</option>
                            <option value={12}>12 month</option>
                        </select>
                    </div>
                    <div className="grid justify-end  mb-10 mt-10 mr-10">
                        <div className="flex flex-row gap-2">
                            <div className='rounded-md border-2 border-white h-[32px] w-[32px] bg-[#2D5BFF] text-white'><FaArrowLeft onClick={() => { setCurrentIndex(currentIndex - 1); }} /></div>
                            <div className="rounded h-[32px] w-[32px] font-[Ubuntu]  text-[16px] font-[700] text-center text-[#2D5BFF] justify-center border-[1px] border-[#ECF0FF] cursor-pointer active:bg-[#ECF0FF]">{currentIndex}</div>
                            <div className='rounded-md border-2 border-white h-[32px] w-[32px] bg-[#2D5BFF] text-white'><FaArrowRight onClick={() => { setCurrentIndex(currentIndex + 1); }} /></div>
                        </div>
                    </div>
                </div>
            </div>
            }
            <Tooltip id="my-tooltip" style={{ width: '300px', borderRadius: '10px', backgroundColor: 'rgb(147 197 253)', color: 'black', boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.1)' }} />
        </div>
    );
};

export default Rollover;
