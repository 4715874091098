import React from 'react';
import { useNavigate } from 'react-router-dom';
import Dashboard from './assets/Dashboard.webp';
import Dashboard1 from './assets/Dashboard1.webp';
import Navbar from './Navbar';
import { FaArrowRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Main = () => {
  const navigate = useNavigate();
  return (
    <div className='flex flex-col' style={{ backgroundImage: `url(${Dashboard})` }}>
    <Navbar/>
    <div className="grid grid-cols-1 md:grid-cols-2 m-10">
      <div className='flex flex-col p-4 text-white'>
        <div className='text-4xl font-semibold'>India’s Leading Backtesting & Trading Intelligence Platform for Enhanced Strategy Optimization</div>
        <div className='font-medium text-xl mt-10'>
          At Fprognos, we specialize in providing India’s best backtesting platform,
          designed to empower both retail and professional traders with AI-driven insights and advanced analytical tools.
          Our state-of-the-art technology offers unparalleled precision, allowing you to test and refine your trading strategies before taking them live.
        </div>
        <Link to='/backtest/signup'><div className='bg-[#4F9CF9] p-4 w-52 tracking-wide rounded-md text-lg text-black flex flex-row mt-10 gap-2 cursor-pointer hover:bg-blue-500'>Get Free Backtest<FaArrowRight className='mt-2 font-thin text-sm'/></div></Link>
      </div>
      <div className=''>
        <img src={Dashboard1} className='hidden md:block' alt=""/>
      </div>
    </div>
    </div>
  );
}

export default Main;
