 import { Next_Thursday } from "../Utility";
 
 // Method to find the Result on the Basis of Expiry
 export const GroupByExpiry = (csv,inputData,slippage) => {
    // const data = [...uniqueElements]; // Assuming uniqueElements is defined somewhere
    const result = [];
    let temp = {};
    let expirey_profit = {}
    let total_expirey_profit = {}
    for (let k = 0; k < csv.length; k++) {
        let value = Next_Thursday(csv[k]['date'])
        temp[value] = temp[value] || [];
        temp[value].push(csv[k]);
        expirey_profit[value] = expirey_profit[value] || []
        total_expirey_profit[value] = total_expirey_profit[value] || 0
        let sum = 0;
        csv[k]['result'].map((value, key) => {
            return (
              <>
                {
                  value.map((item, index) => {
                    const startPrice = parseFloat(item['startPrice']);
                    const exitPrice = parseFloat(item['exitPrice']);
                    console.log(slippage,item,'data')
                    slippage = parseFloat(slippage);
                    const lotQuantity = parseFloat(inputData.lotarray1);
                    const lot = parseFloat(inputData.lotarray[key]);
          
                    if (startPrice !== 99999999999 && exitPrice !== 99999999999) {
                      // Calculate the profit/loss based on the condition
                      const profitOrLoss = value[0] === 'S'
                        ? -1 * ((exitPrice + slippage * exitPrice * 0.01) - (startPrice - slippage * startPrice * 0.01)) * lot * lotQuantity
                        : ((exitPrice + slippage * exitPrice * 0.01) - (startPrice + slippage * startPrice * 0.01)) * lot * lotQuantity;
          
                      sum += parseFloat(profitOrLoss.toFixed(2));
                    }
          
                    return null; // No need to render anything for each `map` item
                  })
                }
              </>
            );
          });
          
        expirey_profit[value].push(sum)
        total_expirey_profit[value] += sum;
    }
    result.push(temp);
    return {"result":result,"expireyProfit":expirey_profit,'totalExpiryProfit':total_expirey_profit};
}