import { useState } from 'react'
import { UPLOAD_GOLD_DATA } from './../../constant/constant'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import secureLocalStorage from 'react-secure-storage'
import { useNavigate } from 'react-router-dom'

const Gold = () => {
    const navigate = useNavigate();
    // For upload json data of nifty and banknifty span data
    const [goldData, setGoldData] = useState([]);
    const [database, setDatabase] = useState('gold_data')
    const uploadGoldData = async () => {
        let token = secureLocalStorage.getItem('backtest_token');
        
        if (token && token.startsWith('backtest_')) {
            token = token.replace('backtest_', '');
        } else {
            navigate('/backtest/login');
        }

        const data = { "gold": JSON.parse(goldData) }
        let config = { method: "post", maxBodyLength: Infinity, url: UPLOAD_GOLD_DATA, headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` }, data: data };
        try {
            const response = await axios.request(config);
            console.log('upload')
            toast.success('Gold Data Uploaded Successfully.');
            setGoldData([]);
        } catch (error) {
            if (error?.response?.status === 401) {
                navigate('/backtest/login');
            } else if (error?.response?.data?.message) {
                toast.error(error.response.data.message)
            } else {
                toast.error('Network Issue');
            }
            console.log(error, 'error at upload span data')
        }
    }
    return (
        <div>
            <ToastContainer />
            <div className="m-5 rounded-xl shadow-lg shadow-white bg-white p-2">
                <div className="font-semibold text-2xl">Upload Gold Data</div>
                <div className="flex flex-col gap-2 mt-2">
                    <select className='outline-0 h-8 border-2' value={database} onChange={(e) => { setDatabase(e.target.value) }}>
                        <option value='gold_data'>Unadjusted</option>
                        <option value='adjusted_gold_data'>Adjusted</option>
                    </select>
                    <textarea className="bg-[#ECF0FF] rounded-lg h-44 p-2 outline-0" placeholder="Please Upload Latest Data Of Gold." value={goldData} onChange={(e) => { setGoldData(e.target.value); }} />
                    <div className="h-8 text-l bg-[#2D5BFF] justify-center text-white cursor-pointer text-center shadow-lg border-white border-2 rounded-lg font-semibold" onClick={() => {
                        uploadGoldData();
                    }}>Upload</div>
                </div>
            </div>
            <div className='bg-white rounded-lg m-5 p-2'>
                <div className='font-[600] text-l'>Step to upload data::</div>
                <div className='text-sm'>a. Download a data from firstPage.com</div>
                <div className='text-sm'>b. Convert Excel into json.</div>
                <div className='text-sm'>c. Data : [{ },{ }]</div>
                <div className='text-sm'>d. Format:  "timestamp" : 2008-01-31 18:00:00 ,open : 400 , close : 300 , .... </div>
            </div>
        </div>
    )
}

export default Gold