import React, { useState, useEffect } from 'react'

const DateTime = (props) => {
    const [hour, setHour] = useState([]);
    const [minutes, setMinutes] = useState([]);
    const [minutes1, setMinutes1] = useState([]);
    const [rangeBreakOutEndMinute, setRangeBreakOutEndMinute] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");

    // range breakout end time
    const [showHour,setShowHour]=useState('');
    const [showMinute,setShowMinutes]=useState('');

    const validateRangeBreakoutTime = () => {
        const startTime = parseInt(props.startHour + props.startMinute);
        const endTime = parseInt(props.rangeEndHour + props.rangeEndMinute);
        if (startTime >= endTime) {
            setErrorMessage("Range breakout start time must be less than range end time.");
        } else {
            setErrorMessage("");
            subtractMinute(props.rangeEndHour,props.rangeEndMinute);
        }
    };

    const generateHourOptions = () => {
        const hours = [];

        for (let i = 9; i <= 15; i++) {
            if (i < 10) {
                hours.push(<option key={i} value={`0${i}`}>0{i}</option>);
            } else {
                hours.push(<option key={i} value={i}>{i}</option>);
            }
        }

        return hours;
    };

    const generateMinuteOptions = (startHour) => {
        const minutes = [];
        if (startHour == '09') {
            for (let i = 15; i < 60; i += 1) {
                minutes.push(<option key={i} value={i}>{i}</option>);
            }
        } else if (startHour == '15') {
            for (let i = 0; i <= 29; i += 1) {
                if (i < 10) {
                    minutes.push(<option key={i} value={`0${i}`}>0{i}</option>);
                } else {
                    minutes.push(<option key={i} value={i}>{i}</option>);
                }
            }
        } else {
            for (let i = 0; i < 60; i += 1) {
                if (i < 10) {
                    minutes.push(<option key={i} value={`0${i}`}>0{i}</option>);
                } else {
                    minutes.push(<option key={i} value={i}>{i}</option>);
                }
            }
        }
        return minutes;
    };

    function subtractMinute(hours, minutes) {
        hours=parseInt(hours);
        minutes=parseInt(minutes);
        if (minutes === 0) {
          minutes = 59;
          hours = (hours === 0) ? 23 : hours - 1; // Handles hour going below 0
        } else {
          minutes -= 1;
        }
        hours<10 ? setShowHour(`0${hours}`):setShowHour(hours);
        minutes<10 ? setShowMinutes(`0${minutes}`):setShowMinutes(minutes);
      }

    useEffect(() => {
        setMinutes(generateMinuteOptions(props.startHour));
    }, [props.startHour])
    useEffect(() => {
        setMinutes1(generateMinuteOptions(props.endHour));
    }, [props.endHour])
    useEffect(()=>{
        setRangeBreakOutEndMinute(generateMinuteOptions(props.rangeEndHour));
        subtractMinute(props.rangeEndHour,props.rangeEndMinute);
    },[props.rangeEndHour])

    useEffect(() => {
        setHour(generateHourOptions());
    }, [])

    useEffect(() => {
        if (props.showRangeBreakout) validateRangeBreakoutTime();
    }, [props.startHour, props.startMinute, props.rangeEndHour, props.rangeEndMinute]);

    return (
        <div className="flex flex-col justify-center text-center flex-wrap sm:flex-row bg-white m-2 rounded gap-4 p-2">
            {/* Start Time */}
            <div className="flex flex-col w-full sm:w-auto">
                {props.intraday == "Yes" && props.showRangeBreakout && <div className="ml-2 text-lg text-left">Range StartTime</div>}
                {!props.showRangeBreakout && <div className="ml-2 text-lg text-left">StartTime</div>}
                <div className="flex flex-row mt-1 ml-2 mr-2 sm:mr-5 w-full sm:w-[170px] rounded-xl">
                    <select value={props.startHour} onChange={(e) => props.setStartHour(e.target.value)} className="h-8 rounded-l-xl w-[95px] border-[1px] border-black outline-0 text-center">
                        {hour}
                    </select>
                    <div className="m-1">:</div>
                    <select value={props.startMinute} onChange={(e) => props.setStartMinute(e.target.value)} className="h-8 w-[95px] border-[1px] border-black outline-0 text-center rounded-r-xl">
                        {minutes}
                    </select>
                </div>
            </div>

            {/* Range End Time */}
            {props.intraday == "Yes" && props.showRangeBreakout && <div className="flex flex-col w-full sm:w-auto">
                <div className="ml-2 text-lg text-left">Range EndTime</div>
                <div className="flex flex-row mt-1 ml-2 mr-2 sm:mr-5 w-full sm:w-[170px] rounded-xl">
                    <select value={props.rangeEndHour} onChange={(e) => props.setRangeEndHour(e.target.value)} className="h-8 rounded-l-xl w-[95px] border-[1px] border-black outline-0 text-center">
                        {hour}
                    </select>
                    <div className="m-1">:</div>
                    <select value={props.rangeEndMinute} onChange={(e) => props.setRangeEndMinute(e.target.value)} className="h-8 w-[95px] border-[1px] border-black outline-0 text-center rounded-r-xl">
                        {rangeBreakOutEndMinute}
                    </select>
                </div>
            </div>}

            {/* End Time */}
            <div className="flex flex-col w-full sm:w-auto">
                <div className="ml-2 text-lg text-left">EndTime</div>
                <div className="flex flex-row mt-1 ml-2 mr-2 sm:mr-5 w-full sm:w-[170px] rounded-xl">
                    <select value={props.endHour} onChange={(e) => props.setEndHour(e.target.value)} className="h-8 rounded-l-xl w-[95px] border-[1px] border-black outline-0 text-center">
                        {hour}
                    </select>
                    <div className="m-1">:</div>
                    <select value={props.endMinute} onChange={(e) => props.setEndMinute(e.target.value)} className="h-8 w-[95px] border-[1px] border-black outline-0 text-center rounded-r-xl">
                        {minutes1}
                    </select>
                </div>
            </div>

            {/* Date Section */}
            <div className="flex flex-col sm:flex-row gap-4">
                {/* Start Date */}
                {!props?.bucket && (
                    <div className="flex flex-col w-full sm:w-auto">
                        <div className="text-lg text-left ml-2">StartDate</div>
                        <input
                            type="date"
                            value={props.startdate}
                            className="h-8 mt-1 ml-2 mr-2 sm:mr-5 w-full sm:w-[200px] border-[1px] border-black outline-0 text-center rounded-xl"
                            onChange={(e) => props.setstartdate(e.target.value)}
                        />
                    </div>
                )}

                {/* End Date */}
                {!props?.bucket && (
                    <div className="flex flex-col w-full sm:w-auto">
                        <div className="text-lg text-left ml-2">EndDate</div>
                        <input
                            type="date"
                            value={props.enddate}
                            className="h-8 mt-1 ml-2 mr-2 sm:mr-5 w-full sm:w-[200px] border-[1px] border-black outline-0 text-center rounded-xl"
                            onChange={(e) => props.setEndDate(e.target.value)}
                        />
                    </div>
                )}
            </div>
            <br />

            {/* Validation Error Message */}
            {props.showRangeBreakout && errorMessage && <div className="flex text-center justify-center w-full text-red-500 -mb-4">{errorMessage}</div>}
            {/* Information for Range Breakout */}
            {props.intraday == "Yes" && props.showRangeBreakout && <p className='flex text-center justify-center w-full text-blue-500'>High & Low of the Range will be considered between <span className='text-black ml-2 mr-2 font-semibold'>{props.startHour}:{props.startMinute}</span> open and <span className='text-black ml-2 mr-2 font-semibold'>{showHour}:{showMinute}</span> closing time</p>}
        </div>
    )
}

export default DateTime