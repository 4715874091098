export const inputDataPresentation = (worksheet1, inputData, props) => {
    const borderStyle = { style: 'thin', color: { argb: 'A0A0A0' } }; // Updated border color
    const headerStyle = {
        fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '4F81BD' } }, // Softer header color
        font: { color: { argb: 'FFFFFF' }, bold: true, size: 13 }, // Contrasting text color
        alignment: { horizontal: 'center', vertical: 'middle' },
        border: { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle }
    };
    const sectionHeaderStyle = {
        fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'A4C8E1' } }, // Softer section header color
        font: { color: { argb: '000000' }, bold: true, size:13 },
        alignment: { horizontal: 'center', vertical: 'middle' },
        border: { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle }
    };

    // Set up header section
    const setupHeader = (cell, value, mergeRange) => {
        worksheet1.mergeCells(mergeRange);
        cell.value = value;
        Object.assign(cell, headerStyle); // Apply header styling
    };

    setupHeader(worksheet1.getCell('A1'), 'FPROGNOS', 'A1:J1');
    setupHeader(worksheet1.getCell('K1'), 'STRATEGY_NAME', 'K1:S1');

    // Section Title Row
    const sectionTitle = worksheet1.getCell('B3');
    worksheet1.mergeCells('B3:S3')
    sectionTitle.value = 'POSITION';
    Object.assign(sectionTitle, headerStyle);
    sectionTitle.border = { top: borderStyle, bottom: borderStyle }; // Adding border for visual separation

    // Label Section in Column F
    const labels = ['ATM', 'SquareOff', 'Wait & Trade', 'Re-Entry/ReExecute', 'MoveSlToCost'];
    labels.forEach((label, index) => {
        const cell = worksheet1.getCell(`F${5 + index}`);
        cell.value = label;
        worksheet1.mergeCells(`F${5 + index}:G${5 + index}`);
        Object.assign(cell, sectionHeaderStyle);
    });

    // Data Column Setup for 'Future'
    const futureData = [
        { cell: 'H5', value: 'Future' },
        { cell: 'H6', value: inputData.squareoff === 'one' ? 'Leg' : 'All' },
        { cell: 'H7', value: inputData.waitTrade },
        { cell: 'H8', value: inputData.reEntry },
        { cell: 'H9', value: inputData.slToCost }
    ];

    worksheet1.mergeCells('H5:I5');
    worksheet1.mergeCells('H6:I6');
    worksheet1.mergeCells('H7:I7');
    worksheet1.mergeCells('H8:I8');
    worksheet1.mergeCells('H9:I9');


    futureData.forEach(({ cell, value }) => {
        worksheet1.getCell(cell).value = value;
        worksheet1.getCell(cell).alignment = { horizontal: 'center' };
        worksheet1.getCell(cell).border = { top: borderStyle, bottom: borderStyle }; // Adding border
        worksheet1.getCell(cell).font = { size: 13 };// Set font size to 13
    });

    // Header Row for Data Columns
    const headers = [
        { cell: 'B11', value: 'Include' },
        { cell: 'C11', value: 'Lot' },
        { cell: 'D11', value: 'ATM' },
        { cell: 'F11', value: 'Action' },
        { cell: 'G11', value: 'Option' },
        { cell: 'H11', value: 'Wait & Trade' },
        { cell: 'J11', value: 'Target Profit' },
        { cell: 'L11', value: 'Target Stoploss' },
        { cell: 'N11', value: 'Trail Stoploss' },
        { cell: 'P11', value: 'Re-Entry' },
        { cell: 'R11', value: 'Index' },
        { cell: 'S11', value: 'Expiry' }
    ];

    worksheet1.mergeCells('D11:E11');
    worksheet1.mergeCells('H11:I11');
    worksheet1.mergeCells('J11:K11');
    worksheet1.mergeCells('L11:M11');
    worksheet1.mergeCells('N11:O11');
    worksheet1.mergeCells('P11:Q11');

    headers.forEach(({ cell, value }) => {
        const headerCell = worksheet1.getCell(cell);
        headerCell.value = value;
        Object.assign(headerCell, headerStyle);
    });

    // Data Rows based on inputData
    for (let i = 0; i < inputData.leg; i++) {
        const row = i + 12;
        const setValue = (cell, value) => {
            worksheet1.getCell(cell).value = value;
            worksheet1.getCell(cell).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
            worksheet1.getCell(cell).font = { size: 13 };
        };

        setValue(`B${row}`, true);
        setValue(`C${row}`, `${inputData.lotarray[i]} lot`);
        setValue(`D${row}`, inputData.atm[i]);
        setValue(`F${row}`, inputData.legarray[i][0] === 'B' ? 'BUY' : 'SELL');
        setValue(`G${row}`, inputData.legarray[i][1] === 'C' ? 'CALL' : 'PUT');

        // Conditional Columns
        worksheet1.getCell(`H${row}`).value = inputData.waitTrade ? `${inputData.waitTradeType[i]} ${inputData.waitTradeValue[i]}` : ' ';
        setValue(`J${row}`, inputData.legsTargetProfit[i] === '-1' ? '' : `${inputData.legsTargetProfitType[i]} ${inputData.legsTargetProfit[i]}`);
        setValue(`L${row}`, inputData.legsStoploss[i] === '-1' ? '' : `${inputData.legsStoplossType[i]} ${inputData.legsStoploss[i]}`);
        setValue(`N${row}`, inputData.trailStoploss[i] ? `Tsl ${inputData.trailStoplossType[i]} : ${inputData.trailStoplossLoss[i]}, ${inputData.trailStoplossLoss[i]}` : '');
        setValue(`P${row}`, inputData.reEntry ? `${inputData.reEntryChoice[i]} ${inputData.reEntryValue[i]}` : '');
        setValue(`R${row}`, inputData.tradesymbol);
        setValue(`S${row}`, inputData.legExpiryType[i]);

        worksheet1.mergeCells(`D${row}:E${row}`);
        worksheet1.mergeCells(`H${row}:I${row}`);
        worksheet1.mergeCells(`J${row}:K${row}`);
        worksheet1.mergeCells(`L${row}:M${row}`);
        worksheet1.mergeCells(`N${row}:O${row}`);
        worksheet1.mergeCells(`P${row}:Q${row}`);
    }


    // Entry and Exit Time
    worksheet1.getCell('B22').value = 'Entry Time';
    worksheet1.mergeCells('B22:C22');
    worksheet1.getCell('D22').value = inputData['starttime'];
    worksheet1.mergeCells('D22:E22');
    worksheet1.getCell('H22').value = 'Exit Time';
    worksheet1.mergeCells('H22:I22');
    worksheet1.getCell('J22').value = inputData['endtime'];
    worksheet1.mergeCells('J22:K22');

    // Set styles for Entry and Exit Time section
    const entryExitStyle = { fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ADD8E6' } }, font: { color: { argb: '000000' }, bold: true } };
    worksheet1.getCell('B22').fill = entryExitStyle.fill;
    worksheet1.getCell('H22').fill = entryExitStyle.fill;

    // Start Date and Exit Date
    worksheet1.getCell('B24').value = 'From Date';
    worksheet1.mergeCells('B24:C24');
    worksheet1.getCell('D24').value = inputData['startdate'];
    worksheet1.mergeCells('D24:E24');
    worksheet1.getCell('H24').value = 'To Date';
    worksheet1.mergeCells('H24:I24');
    worksheet1.getCell('J24').value = inputData['enddate'];
    worksheet1.mergeCells('J24:K24');

    // Set styles for From and To Date section
    const dateStyle = { fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFACD' } }, font: { color: { argb: '000000' }, bold: true } };
    worksheet1.getCell('B24').fill = dateStyle.fill;
    worksheet1.getCell('H24').fill = dateStyle.fill;

    // Strategy Profit and Strategy Stoploss
    worksheet1.getCell('B26').value = 'Target Profit';
    worksheet1.mergeCells('B26:C26');
    worksheet1.getCell('D26').value = inputData['maxprofit'] == -1 ? "" : inputData['maxprofit'];
    worksheet1.getCell('D26').alignment = { horizontal: 'left' };
    worksheet1.mergeCells('D26:E26');
    worksheet1.getCell('H26').value = 'Stoploss';
    worksheet1.mergeCells('H26:I26');
    worksheet1.getCell('J26').value = inputData['stoploss'] == 1 ? "" : inputData['stoploss'];
    worksheet1.getCell('J26').alignment = { horizontal: 'left' };

    // Set styles for Profit and Stoploss section
    const profitStoplossStyle = { fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '90EE90' } }, font: { color: { argb: '000000' }, bold: true } };
    worksheet1.getCell('B26').fill = profitStoplossStyle.fill;
    worksheet1.getCell('H26').fill = profitStoplossStyle.fill;

    // Protect Profit
    worksheet1.getCell('B28').value = 'Protect Profit';
    worksheet1.mergeCells('B28:C28');
    worksheet1.getCell('D28').value = inputData['protectProfit'] ? inputData['protectType'] + " lockMinimumProfit " + inputData['lockMinimumProfit'] + " trailProfit " + inputData['trailProfit'] + " increaseProfit " + inputData['profitIncrease'] + " profitReaches " + inputData['profitReaches'] : "";
    worksheet1.getCell('D28').alignment = { horizontal: 'left' };
    worksheet1.mergeCells('D28:J28');

    // Set styles for Protect Profit section
    const protectProfitStyle = { fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFB6C1' } }, font: { color: { argb: '000000' }, bold: true } };
    worksheet1.getCell('B28').fill = protectProfitStyle.fill;

    // Range Break Out
    worksheet1.getCell('B30').value = 'Range StartTime';
    worksheet1.mergeCells('B30:C30');
    worksheet1.getCell('D30').value = inputData['showRangeBreakOut'] ? inputData['rangeStartTime'] : "";
    worksheet1.getCell('D30').alignment = { horizontal: 'left' };
    worksheet1.mergeCells('D30:E30');
    worksheet1.getCell('H30').value = 'Range End Time';
    worksheet1.mergeCells('H30:I30');
    worksheet1.getCell('J30').value = inputData['showRangeBreakOut'] ? inputData['rangeEndTime'] : "";
    worksheet1.getCell('J30').alignment = { horizontal: 'left' };

    // Set styles for Range Break Out section
    const rangeStyle = { fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'E6E6FA' } }, font: { color: { argb: '000000' }, bold: true } };
    worksheet1.getCell('B30').fill = rangeStyle.fill;
    worksheet1.getCell('H30').fill = rangeStyle.fill;

    // Intraday or Positional
    worksheet1.getCell('B32').value = 'Trade Type';
    worksheet1.mergeCells('B32:C32');
    worksheet1.getCell('D32').value = inputData['intraday'] == "Yes" ? "Intraday" : "Positional";
    worksheet1.getCell('D32').alignment = { horizontal: 'left' };
    worksheet1.mergeCells('D32:H32');

    // Set styles for Trade Type section
    const tradeTypeStyle = { fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ADD8E6' } }, font: { color: { argb: '000000' }, bold: true } };
    worksheet1.getCell('B32').fill = tradeTypeStyle.fill;

    // Apply borders to all relevant cells for better separation
    const cellsToBorder = ['B22', 'C22', 'D22', 'E22', 'H22', 'I22', 'J22', 'K22',
        'B24', 'C24', 'D24', 'E24', 'H24', 'I24', 'J24', 'K24',
        'B26', 'C26', 'D26', 'E26', 'H26', 'I26', 'J26', 'K26',
        'B28', 'C28', 'D28', 'E28', 'H28', 'I28', 'J28', 'K28',
        'B30', 'C30', 'D30', 'E30', 'H30', 'I30', 'J30', 'K30',
        'B32', 'C32', 'D32', 'E32', 'H32'];

    cellsToBorder.forEach(cell => {
        worksheet1.getCell(cell).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
        };
        worksheet1.getCell(cell).font = {
            size:13
        };
        
    });



};
