

// we are not using but for Updation,We will use this method

import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware'

const backtestStore = (set) => ({
  coin: 0,
  slippage: 0,
  disableMoveSlToCost: false,
  // leg
  leg: [],
  // range breakout
  startHour: "09",
  endHour: "15",
  startMinutes: "15",
  endMinutes: "15",
  rangeEndHour: "09",
  rangeEndMinutes: "15",
  
  strategy: {
    startDate: "2022-01-03",
    endDate: "2022-02-03",
    startTime: "09:30",
    endTime: "15:20",
    intraday: true,
    tradeSymbol: "NIFTY",
    atmPrice: "spot",
    rate: 0.5,
    squareOff: 'one',
    gap: 50,
    // wait trade
    waitTrade: true,
    // range breakout
    rangeBreakOut: true,
    rangeStartTime: '09:30',
    rangeExitTime: '09:40',
    // move sl to cose
    slToCost: true,
    // reEntry
    reEntry: true,
    reExecuteTimes: 3,
    // strategy profit
    maxProfit: 1000,
    maxProfitChoice: false,
    // strategy stoploss
    stoploss: 1000,
    stoplossChoice: false,
    // protect Profit
    protectProfit: false,
    protectProfitType: 'LockMinimumProfit',
    lockMinimumProfit: 1000,
    trailProfit: 1000,
    profitIncrease: 1000,
    profitReaches: 1000,
    // positional
    startDay: 0,
    endDay: 4,
    leg: []
  },

  // active Strategy
  activeStrategy : {},
  activeStrategyName : "",
  // For Updating coin
  updateCoin: (coin) => {
    set((state) => (
      { coin: coin }
    ))
  },
  updateSlippage: (newSlippageValue) => {
    set({ slippage: newSlippageValue })
  },
  // Function to update the 'strategy' object
  updateStrategy: (field, value) => set((state) => ({
    strategy: {
      ...state.strategy,
      [field]: value,
    },
  })),
  addLeg: (newLeg) => {
    set((state) => {
      console.log(state.leg,'state of new Leg'); // Log the current state of 'leg' before updating
      return {
        leg: [...state.leg,newLeg], // Safely add the new leg to the existing legs array
      };
    });
  },
  
  // Function to update a specific field of a leg at a given index
  updateLeg: (index, field, value) => {
    set((state) => ({
      leg: state.leg.map((legItem, i) =>
        i === index
          ? { ...legItem, [field]: value } // Update specific field of the leg
          : legItem // Keep other legs unchanged
      ),
    }));
  },
  deleteLeg: (index) => {
    set((state) => ({
      leg: state.leg.filter((_, i) => i !== index), // Filter out the leg at the given index
    }));
  },
  copyLeg: (index) => {
    set((state) => ({
      leg: [...state.leg, { ...state.leg[index] }], // Copy the leg at the given index and append it to the array
    }));
  },
  updateDisableMoveSlToCost: (newValue) => {
    set({
      disableMoveSlToCost: newValue
    })
  },
  // active strategy
  updateActiveStrategy: (newStrategy) => {
    set({
      activeStrategy:newStrategy
    })
  },
  updateActiveStrategyName : (strategyName) => {
    set({
      activeStrategyName:strategyName
    })
  },
  updateStoplossChoice : (newValue) =>{
    set({
      stoplossChoice:newValue
    })
  },
  updateMaxProfitChoice : (newValue) => {
    set({
      maxProfitChoice:newValue
    })
  }



})

// Now,We will make a above code as a store and perform action in another component to perform it.
const useBacktestStore = create(
  devtools(
    persist(backtestStore, {
      name: "backtest",
    })
  )
)


export default useBacktestStore;