import { useState } from 'react';
import NumberSpeak from './assets/NumberSpeak.webp'
import { IoSparkles } from "react-icons/io5";
import { LuGoal } from "react-icons/lu";
import { FaUserAlt } from "react-icons/fa";

const Number = () => {
  const [totalUser, setTotalUser] = useState(200);
  const [todayBacktestCount, setTodayBacktestCount] = useState(2000);
  const [totalBacktestCount, setTotalBacktestCount] = useState("2.5 Lakh");

  return (
    <div className=' h-[428px] flex text-center justify-center' style={{ backgroundImage: `url(${NumberSpeak})` }}>
      {/* Numbers Speak Section */}
      <div className='w-full'>
        <div className="text-center text-white p-4 mt-20">
          <div className="text-3xl font-bold font-serif mb-6 text-white">
            Let the Numbers Speak!
          </div>
          <div className="flex flex-col sm:flex-row justify-around items-center">
            <div className="flex flex-row items-center mb-4 sm:mb-0 bg-gradient-to-t from-[#3679AD] to-[#063267] p-2 rounded-md w-60 hover:border-black">
              <div className='flex flex-col'>
                <div className="flex items-center space-x-2">
                  <div className="text-4xl font-extrabold text-[#0A85D1]">
                    {totalUser}
                  </div>
                </div>
                <div className="text-sm font-medium text-left text-white">
                  Total Users
                </div>
              </div>
              <FaUserAlt className='text-4xl text-white flex-grow' />
            </div>
            <div className="flex flex-row items-center mb-4 sm:mb-0 bg-gradient-to-t from-[#3679AD] to-[#063267] p-2 rounded-md w-60">
              <div className='flex flex-col'>
                <div className="flex items-center space-x-2">
                  <div className="text-4xl font-extrabold text-[#0A85D1]">
                    {totalBacktestCount}
                  </div>
                </div>
                <div className="text-sm font-medium text-left text-white">
                  Total Backtests
                </div>
              </div>
              <LuGoal className='text-4xl text-white flex-grow' />
            </div>
            <div className="flex flex-row items-center bg-gradient-to-t from-[#3679AD] to-[#063267] p-2 rounded-md w-60">
              <div className='flex flex-col'>
                <div className="flex items-center space-x-2">
                  <div className="text-4xl font-extrabold text-[#0A85D1]">
                    {todayBacktestCount}
                  </div>
                </div>
                <div className="text-sm font-medium text-left text-white">
                  Today's Backtests
                </div>
              </div>
              <IoSparkles className='text-4xl text-white flex-grow' />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Number;
