import useBucketStore from "../../../store/BucketStore";
import { handleNumber } from "../Util";

const CombinedStats = (props) => {
  let array = []
  if (props.StatisticalResult) { array = new Array(props.StatisticalResult.length).fill(0) }

  const { strategyMultiplier, updateStrategyMultiplier, strategyResultInclude, updateStrategyResultIncludeByKey } = useBucketStore();

  return (
    <div className='p-2 min-w-[1000px] overflow-auto'>
      {/* Header Section */}
      <div className="mt-5 mb-1 text-center bg-blue-400">
        <h3 className="text-2xl font-bold text-white text-center p-2">Compare Statistical Analysis</h3>
      </div>

      {/* Main Container */}
      <div className='grid grid-cols-1 font-medium text-xl font-serif overflow-x-auto min-w-[1360px]'>
        <div className="flex flex-row border-white p-2 text-white bg-[#718EBF] overflow-auto min-w-[1360px]">
          <h1 className="pl-2 text-white border-r-2 border-white w-[260px]" >Features</h1>
          <h1 className="text-right pr-4 justify-center text-white w-[260px]" >Combine</h1>
          {array && array.map((item, key) => { return (<h1 className="text-right text-white pr-4 justify-center w-[280px]" key={`combine-${key}`} >Strategy-[{key + 1}]</h1>) })}
        </div>
        <div className="flex flex-row border-white p-2 bg-yellow-200">
          <h1 className="pl-2 text-[#232323] border-r-2 border-white w-[260px]" >Include</h1>
          <h1 className="text-right pr-4 justify-center text-[#718EBF] w-[260px]" ></h1>
          {array && array.map((item, key) => { return (<h1 className="text-right pr-4 justify-center text-[#718EBF] w-[280px]" key={`include-${key}`} ><input type="checkbox" checked={strategyResultInclude[key]} onChange={() => { updateStrategyResultIncludeByKey(key) }} /></h1>) })}
        </div>
        <div className="flex flex-row border-white p-2 bg-gray-100">
          <h1 className="pl-2 text-[#232323] border-r-2 border-white w-[260px] " >Strategy Multiplier</h1>
          <h1 className="text-right pr-4 justify-center text-[#718EBF] w-[260px]" ></h1>
          {array && array.map((item, key) => { return (<h1 className="text-right pr-4 justify-center text-[#718EBF] w-[280px]" key={`multiplier-${key}`}><input type="text" className="text-center border-[1px] border-black outline-0 text-black w-[120px] rounded" value={strategyMultiplier[key]} onChange={(e) => { updateStrategyMultiplier(key, handleNumber(e.target.value)); }} /></h1>) })}
        </div>
        <div className="flex flex-row border-white p-2 bg-green-100">
          <h1 className="pl-2 text-[#232323] border-r-2 border-white w-[260px]" >Estimated Margin</h1>
          <h1 className="text-right pr-4 justify-center text-[#718EBF] w-[260px]" >&#8377; {parseFloat(props?.combineStats?.stats?.margin).toFixed(0)}</h1>
          {array && array.map((item, key) => { return (<h1 className="text-right pr-4 justify-center text-[#718EBF] w-[280px]" key={`margin-${key}`} >&#8377; {parseFloat(props?.StatisticalResult[key].margin).toFixed(0)}</h1>) })}
        </div>
        <div className="flex flex-row bg-white border-white p-2">
          <h1 className="pl-2 text-[#232323] text-l font-medium border-r-2 w-[260px]" >Minimum Capital Required</h1>
          <h1 className="text-right pr-4 justify-center w-[260px]">&#8377; {props.maximumDrawdown > 0 ? props?.combineStats?.stats?.margin : (props?.combineStats?.stats?.margin + -1 * props.combineStats?.stats?.maximumDrawdown).toFixed(0)}</h1>
          {array && array.map((item, key) => { return (<h1 className="text-right pr-4 justify-center text-[#718EBF] w-[280px]" key={`capital-${key}`}>&#8377; {props?.StatisticalResult[key].maximumDrawdown > 0 ? props?.StatisticalResult[key].margin : (props?.combineStats?.stats?.margin + -1 * props?.StatisticalResult[key].maximumDrawdown).toFixed(0)}</h1>) })}
        </div>

        <div className="flex flex-row bg-red-100 border-white p-2 border-2">
          <h1 className="pl-2 text-[#232323]  text-l font-medium border-r-2 border-white w-[260px]" >Maximum Drawdown</h1>
          <h1 className="text-right pr-4 justify-center text-[#718EBF] text-l font-medium w-[260px]" style={{ color: props.combineStats?.stats?.maximumDrawdown > 0 ? "green" : "red" }} >{parseFloat(props.combineStats?.stats?.maximumDrawdown).toFixed(0)}</h1>
          {array && array.map((item, key) => { return (<h1 className="text-right pr-4 justify-center text-[#718EBF] w-[280px]" key={`drawdown-${key}`} style={{ color: props?.StatisticalResult[key].maximumDrawdown > 0 ? "green" : "red" }} >&#8377; {parseFloat(props?.StatisticalResult[key].maximumDrawdown).toFixed(0)}</h1>) })}
        </div>
        <div className="flex flex-row bg-green-100 border-white p-1 border-2">
          <h1 className="pl-2 text-[#232323] text-l text-l font-medium border-r-2 border-gray-100 w-[260px]" >Total Profit</h1>
          <h1 className="text-right pr-4 justify-center text-[#718EBF] w-[260px]" style={{ color: parseFloat(props.combineStats?.stats?.profit) >= 0 ? "green" : "red" }} >&#8377; {parseFloat(props.combineStats?.stats?.profit).toFixed(0)}</h1>
          {array && array.map((item, key) => { return (<h1 className="text-right pr-4 justify-center text-[#718EBF] w-[280px]" key={`profit-${key}`} style={{ color: parseFloat(props?.StatisticalResult[key].profit) >= 0 ? "green" : "red" }}>&#8377; {parseFloat(props?.StatisticalResult[key].profit).toFixed(0)}</h1>) })}
        </div>
        <div className="flex flex-row border-white p-1 bg-green-100 border-2">
          <h1 className="pl-2 text-[#232323]  text-l font-medium border-r-2 border-white w-[260px]" >Max Profit</h1>
          <h1 className="text-right pr-4 justify-center text-green-600 w-[260px]">&#8377; {parseFloat(props.combineStats?.stats?.maxProfit).toFixed(0)}</h1>
          {array && array.map((item, key) => { return (<h1 className="text-right pr-4 justify-center w-[280px] text-green-600" key={`maxprofit-${key}`}>&#8377; {parseFloat(props?.StatisticalResult[key].maxProfit).toFixed(0)}</h1>) })}
        </div>
        <div className="flex flex-row border-white p-1 bg-red-100 border-2">
          <h1 className="pl-2 text-[#232323]  text-l font-medium border-r-2 border-gray-100 w-[260px]" >Max Loss</h1>
          <h1 className="text-right pr-4 justify-center text-red-600 w-[260px]">&#8377; {props.combineStats?.stats?.maxLoss == 999999999 ? 0 : parseFloat(props.combineStats?.stats?.maxLoss).toFixed(0)}</h1>
          {array && array.map((item, key) => { return (<h1 className="text-right pr-4 justify-center text-red-600 w-[280px]" key={`maxloss-${key}`}>&#8377; {parseFloat(props?.StatisticalResult[key].maxLoss).toFixed(0)}</h1>) })}
        </div>
        <div className="flex flex-row border-white p-2 bg-green-100 border-2">
          <h1 className="pl-2 text-[#232323]  text-l font-medium border-r-2 border-gray-100  w-[260px]" >Win%(days)</h1>
          <h1 className="text-right pr-4 justify-center  text-green-600  w-[260px]" >{props.combineStats?.stats?.investmentDay != 0 ? parseFloat((props.combineStats?.stats?.winTrade * 100) / props.combineStats?.stats?.investmentDay).toFixed(0) : 0}%({props.combineStats?.stats?.winTrade})</h1>
          {array && array.map((item, key) => { return (<h1 className="text-right pr-4 justify-center text-green-600 w-[280px]" key={`winday-${key}`} >{props?.StatisticalResult[key].investmentDay != 0 ? parseFloat((props?.StatisticalResult[key].winTrade * 100) / props?.StatisticalResult[key].investmentDay).toFixed(0) : 0}%({props?.StatisticalResult[key].winTrade})</h1>) })}
        </div>
        <div className="flex flex-row border-white p-2 bg-red-100 border-2">
          <h1 className="pl-2 text-[#232323]  text-l font-medium border-r-2 border-white  w-[260px]" >Loss%(days)</h1>
          <h1 className="text-right pr-4 justify-center text-green-600  w-[260px]" >{props.combineStats?.stats?.investmentDay != 0 ? parseFloat((props.combineStats?.stats?.lossTrade * 100) / props.combineStats?.stats?.investmentDay).toFixed(0) : 0}%({props.combineStats?.stats?.lossTrade})</h1>
          {array && array.map((item, key) => { return (<h1 className="text-right pr-4 justify-center text-red-600 w-[280px]" key={`lossday-${key}`}>{props.StatisticalResult[key]?.investmentDay != 0 ? parseFloat((props.StatisticalResult[key].lossTrade * 100) / props.StatisticalResult[key].investmentDay).toFixed(0) : 0}%({props.StatisticalResult[key].lossTrade})</h1>) })}
        </div>

        <div className="flex flex-row border-white p-2 bg-green-100 border-2">
          <h1 className="pl-2 text-[#232323]  text-l font-medium border-r-2 border-gray-100  w-[260px]" >Average Profit On Win Days</h1>
          <h1 className="text-right pr-4 justify-center text-green-600  w-[260px]" >&#8377; {parseFloat(props.combineStats?.stats?.avgProfit).toFixed(0)}</h1>
          {array && array.map((item, key) => { return (<h1 className="text-right pr-4 justify-center text-green-600 w-[280px]" key={`avgprofit-${key}`}>&#8377; {parseFloat(props?.StatisticalResult[key].avgProfit).toFixed(0)}</h1>) })}
        </div>
        <div className="flex flex-row border-white p-2 bg-red-100 border-2">
          <h1 className="pl-2 text-[#232323]  text-l font-medium border-r-2 border-white  w-[260px]" >Average Loss On Loss Days</h1>
          <h1 className="text-right pr-4 justify-center text-red-600  w-[260px]" >&#8377; {-1 * parseFloat(props.combineStats?.stats?.avgLoss).toFixed(0)}</h1>
          {array && array.map((item, key) => { return (<h1 className="text-right pr-4 justify-center text-red-600 w-[280px]" key={`avgloss-${key}`}>&#8377; {parseFloat(props?.StatisticalResult[key].avgLoss).toFixed(0)}</h1>) })}
        </div>
        <div className="flex flex-row bg-white border-white p-1 border-2">
          <h1 className="pl-2 text-[#232323]  text-l font-medium border-r-2 border-white w-[260px]" >Risk Reward Ratio</h1>
          <h1 className="text-right pr-4 justify-center text-green-600  w-[260px]">{props.combineStats?.stats?.avgLoss != 0 ? (parseFloat(props.combineStats?.stats?.avgProfit) / parseFloat(props.combineStats?.stats?.avgLoss)).toFixed(2) : 'Infinity'}</h1>
          {array && array.map((item, key) => { return (<h1 className="text-right pr-4 justify-center text-[#718EBF] w-[280px]" key={`ror-${key}`}>&#8377; {parseFloat(props?.StatisticalResult[key].margin).toFixed(0)}</h1>) })}
        </div>

        <div className="flex flex-row border-white p-2 bg-yellow-50 border-2">
          <h1 className="pl-2 text-[#232323] text-l font-medium border-r-2 border-gray-100  w-[260px]" > Maximum Win Streak</h1>
          <h1 className="text-right pr-4 justify-center text-green-600  w-[260px]" >{props.combineStats?.stats?.maxStreak} Days</h1>
          {array && array.map((item, key) => { return (<h1 className="text-right pr-4 justify-center w-[280px]" key={`winstreak-${key}`}> {parseFloat(props?.StatisticalResult[key].maxStreak).toFixed(0)}&nbsp; Days</h1>) })}
        </div>
        <div className="flex flex-row border-white p-2 bg-blue-100 border-2">
          <h1 className="pl-2 text-[#232323] text-l font-medium border-r-2 border-white  w-[260px]" >Maximum Loss Streak</h1>
          <h1 className="text-right pr-4 justify-center text-green-600  w-[260px]">{props.combineStats?.stats?.lossStreak} Days</h1>
          {array && array.map((item, key) => { return (<h1 className="text-right pr-4 justify-center w-[280px]" key={`lossStreak-${key}`}> {parseFloat(props?.StatisticalResult[key].lossStreak).toFixed(0)}&nbsp; Days</h1>) })}
        </div>
        <div className="flex flex-row bg-white border-white p-1 border-2">
          <h1 className="pl-2 text-[#232323] text-l font-medium border-r-2 border-white  w-[260px]" >MDD Days(Recovery Days)</h1>
          <h1 className="text-right pr-4 justify-center  w-[260px]">{parseFloat(props.combineStats?.stats?.mdd?.count).toFixed(0)}{`(${parseFloat(props.combineStats?.stats?.mdd?.recovery).toFixed(0)} Days`} {props.combineStats?.stats?.mdd?.continue ? '- Running)' : ')'}
            <br />
            <span className="text-sm">{props.combineStats?.stats?.mdd?.startDate && `(${props.combineStats?.stats?.mdd?.startDate} - ${props.combineStats?.stats?.mdd?.endDate})`}</span></h1>
          {array && array.map((item, key) => {
            return (
              <h1 className="text-right pr-4 justify-center w-[280px]" key={`mdd-${key}`}>{parseFloat(props.StatisticalResult[key]?.mdd?.count).toFixed(0)}{`(${parseFloat(props.StatisticalResult[key]?.mdd?.recovery).toFixed(0)} Days`} {props.StatisticalResult[key]?.mdd?.continue ? '- Running)' : ')'}
              <br />
              <span className="text-sm">{props.StatisticalResult[key]?.mdd?.startDate && `(${props.StatisticalResult[key]?.mdd?.startDate} - ${props.StatisticalResult[key]?.mdd?.endDate})`}</span></h1>
            )
          })}
        </div>

        {/* <div className="flex flex-row  border-white p-1 bg-yellow-50 border-2">
            <h1 className="pl-2 text-[#232323] text-l font-medium border-r-2 border-white" >Gap Up (*pdc)</h1>
            <h1 className="text-right pr-4 justify-center"><span className='text-green-600'>Profit: &#8377;{parseFloat(props.combineStats?.stats?.gap?.upProfit).toFixed(0)}{`(${props.combineStats?.stats?.gap?.upProfitCnt})`}</span><br /> <span className='text-red-600'>Loss: &#8377;{parseFloat(props.combineStats?.stats?.gap?.upLoss).toFixed(0)}{`(${props.combineStats?.stats?.gap?.upLossCnt})`}</span> </h1>
            {array && array.map((item,key)=>{ return( <h1 className="text-right pr-4 justify-center text-[#718EBF]" key={`gapup-${key}`}><span className='text-green-600'>Profit: &#8377;{parseFloat(props?.StatisticalResult[key]?.gap?.upProfit).toFixed(0)}{`(${props?.StatisticalResult[key]?.gap?.upProfitCnt})`}</span><br /> <span className='text-red-600'>Loss: &#8377;{parseFloat(props?.StatisticalResult[key]?.gap?.upLoss).toFixed(0)}{`(${props?.StatisticalResult[key]?.gap?.upLossCnt})`}</span></h1> )})}
          </div>
          <div className="flex flex-row border-white p-1 bg-blue-100 border-2">
            <h1 className="pl-2 text-[#232323] text-l font-medium border-r-2" >Gap Down (*pdc)</h1>
            <h1 className="text-right pr-4 justify-center"><span className='text-green-600'>Profit: &#8377;{parseFloat(props.combineStats?.stats?.gap?.downProfit).toFixed(0)}{`(${props.combineStats?.stats?.gap?.downProfitCnt})`}</span> <br /> <span className='text-red-600'>Loss: &#8377;{parseFloat(props.combineStats?.stats?.gap?.downLoss).toFixed(0)}{`(${props.combineStats?.stats?.gap?.downLossCnt})`}</span> </h1>
            {array && array.map((item,key)=>{ return( <h1 className="text-right pr-4 justify-center text-[#718EBF]" key={`gapdown-${key}`}><span className='text-green-600'>Profit: &#8377;{parseFloat(props?.StatisticalResult[key]?.gap?.downProfit).toFixed(0)}{`(${props?.StatisticalResult[key]?.gap?.downProfitCnt})`}</span> <br /> <span className='text-red-600'>Loss: &#8377;{parseFloat(props?.StatisticalResult[key]?.gap?.downLoss).toFixed(0)}{`(${props?.StatisticalResult[key]?.gap?.downLossCnt})`}</span> </h1> )})}
          </div>
          <div className="flex flex-row border-white p-1 bg-yellow-50 border-2">
            <h1 className="pl-2 text-[#232323] text-l font-medium border-r-2 border-white" >Day Open  {'>'} PDH</h1>
            <h1 className="text-right pr-4 justify-center"><span className='text-green-600'>Profit: &#8377;{parseFloat(props.combineStats?.stats?.dayOpen?.pdhProfit).toFixed(0)}{`(${props.combineStats?.stats?.dayOpen?.pdhProfitCnt})`}</span> <br /> <span className='text-red-600'>Loss: &#8377;{parseFloat(props.combineStats?.stats?.dayOpen?.pdhLoss).toFixed(0)}{`(${props.combineStats?.stats?.dayOpen?.pdhLossCnt})`}</span></h1>
            {array && array.map((item,key)=>{ return( <h1 className="text-right pr-4 justify-center text-[#718EBF]" key={`pdh-${key}`}><span className='text-green-600'>Profit: &#8377;{parseFloat(props?.StatisticalResult[key]?.dayOpen?.pdhProfit).toFixed(0)}{`(${props?.StatisticalResult[key]?.dayOpen?.pdhProfitCnt})`}</span> <br /> <span className='text-red-600'>Loss: &#8377;{parseFloat(props?.StatisticalResult[key]?.dayOpen?.pdhLoss).toFixed(0)}{`(${props?.StatisticalResult[key]?.dayOpen?.pdhLossCnt})`}</span></h1> )})}
          </div>
          <div className="flex flex-row border-white p-1 bg-blue-100 border-2">
            <h1 className="pl-2 text-[#232323] text-l font-medium border-r-2" >Day Open  {'<'} PDL</h1>
            <h1 className="text-right pr-4 justify-center "><span className='text-green-600'>Profit: &#8377;{parseFloat(props.combineStats?.stats?.dayOpen?.pdlProfit).toFixed(0)}{`(${props.combineStats?.stats?.dayOpen?.pdlProfitCnt})`}</span> <br /> <span className='text-red-600'>Loss: &#8377;{parseFloat(props.combineStats?.stats?.dayOpen?.pdlLoss).toFixed(0)}{`(${props.combineStats?.stats?.dayOpen?.pdlLossCnt})`}</span> </h1>
            {array && array.map((item,key)=>{ return( <h1 className="text-right pr-4 justify-center text-[#718EBF]" key={`pdl-${key}`}><span className='text-green-600'>Profit: &#8377;{parseFloat(props?.StatisticalResult[key]?.dayOpen?.pdlProfit).toFixed(0)}{`(${props?.StatisticalResult[key]?.dayOpen?.pdlProfitCnt})`}</span> <br /> <span className='text-red-600'>Loss: &#8377;{parseFloat(props?.StatisticalResult[key]?.dayOpen?.pdlLoss).toFixed(0)}{`(${props?.StatisticalResult[key]?.dayOpen?.pdlLossCnt})`}</span> </h1> )})}
          </div>
          <div className="flex flex-row bg-white border-white p-1 border-2">
            <h1 className="pl-2 text-[#232323] text-l font-medium border-r-2 border-white" >Vix</h1>
            <div>{props.combineStats?.stats && props.combineStats?.stats.vix.length>0 && <h1 className="text-right pr-4 justify-center">From 1 to 10 : <span style={{ color: props.combineStats?.stats?.vix[0]?.profit > 0 ? 'green' : 'red' }}>&#8377;{parseFloat(props.combineStats?.stats?.vix[0]?.profit).toFixed(0)}</span>{`(${props.combineStats?.stats?.vix[0]?.cnt})`}<br /> From 11 to 20 : <span style={{ color: props.combineStats?.stats?.vix[1]?.profit > 0 ? 'green' : 'red' }}>&#8377;{parseFloat(props.combineStats?.stats?.vix[1]?.profit).toFixed(0)}</span>{`(${props.combineStats?.stats?.vix[1]?.cnt})`}<br /> Above than 20: <span style={{ color: props.combineStats?.stats?.vix[2]?.profit > 0 ? 'green' : 'red' }}>&#8377;{parseFloat(props.combineStats?.stats?.vix[2]?.profit).toFixed(0)}</span>{`(${props.combineStats?.stats?.vix[2]?.cnt})`}</h1>}</div>
            {array && array.map((item,key)=>{ return( <div>
             {props.StatisticalResult[key]?.vix.length>0 && <h1 className="text-right pr-4 justify-center" key={`vix-${key}`}>From 1 to 10 : <span style={{ color: props.StatisticalResult[key]?.vix[0]?.profit > 0 ? 'green' : 'red' }}>&#8377;{parseFloat(props.StatisticalResult[key]?.vix[0]?.profit).toFixed(0)}</span>{`(${props.StatisticalResult[key]?.vix[0]?.cnt})`}<br /> From 11 to 20 : <span style={{ color: props.StatisticalResult[key]?.vix[1]?.profit > 0 ? 'green' : 'red' }}>&#8377;{parseFloat(props.StatisticalResult[key]?.vix[1]?.profit).toFixed(0)}</span>{`(${props.StatisticalResult[key]?.vix[1]?.cnt})`}<br /> Above than 20: <span style={{ color: props.StatisticalResult[key]?.vix[2]?.profit > 0 ? 'green' : 'red' }}>&#8377;{parseFloat(props.StatisticalResult[key]?.vix[2]?.profit).toFixed(0)}</span>{`(${props.StatisticalResult[key]?.vix[2]?.cnt})`}</h1>}
          </div>)})} 
          </div>*/}
      </div>
    </div>

  )
}

export default CombinedStats