import React, { useState } from 'react';
import axios from 'axios';
import { SEND_EMAIL_ALL_USER } from '../../constant/constant';
import secureLocalStorage from 'react-secure-storage';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Dialog } from "@mui/material";
import { IoMdClose } from "react-icons/io";
import { Oval } from 'react-loader-spinner'


const SendEmail = () => {
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [openConfirmSendEmailBox,setOpenConfirmSendEmailBox]=useState(false);
  const [loader,setLoader]=useState(false);
  const navigate = useNavigate();

  const sendEmail = async () => {
    // Get the token from secureLocalStorage
    setLoader(true);
    let token = secureLocalStorage.getItem('backtest_token');

    // Remove the 'backtest_' prefix if it exists
    if (token && token.startsWith('backtest_')) {
      token = token.replace('backtest_', '');
    } else {
      navigate('/backtest/login');
    }
    const name = secureLocalStorage.getItem('backtest_name');
    const data = { "subject": subject, "message": message }
    const config = {
      method: 'post',
      url: SEND_EMAIL_ALL_USER,
      headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}`, },
      data: data
    };
    try {
      const response = await axios(config);
      console.log(response, 'response')
      toast.success('Email Sent Successfully');
      setLoader(false);
      setOpenConfirmSendEmailBox(false);
    } catch (error) {
      console.log(error, 'error')
      setLoader(false);
    }
  }



  return (
    <>
      <div className="max-w-xl mx-auto bg-white shadow-md rounded-lg p-8 mt-8 mb-8">
        <h2 className="text-2xl font-bold text-center text-blue-600 mb-6">Send Email To All Registered Users</h2>

        <div className="flex flex-col space-y-6">
          {/* Subject Field */}
          <div>
            <label className="text-gray-700 font-semibold">Subject</label>
            <input
              type="text"
              placeholder="Enter Subject of Email"
              className="w-full px-4 py-2 mt-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              onChange={(e) => setSubject(e.target.value)}
            />
          </div>

          {/* HTML Message Field */}
          <div>
            <label className="text-gray-700 font-semibold">HTML Message</label>
            <textarea
              placeholder="Enter the HTML message you want to send"
              className="w-full px-4 py-2 mt-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              rows="5"
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
          </div>

          {/* Send Email Button */}
          <button
            className="w-full bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700 transition duration-300"
            onClick={()=>{setOpenConfirmSendEmailBox(true);}}
          >
            Send Email
          </button>
        </div>
      </div>

      <Dialog open={openConfirmSendEmailBox} onClose={(event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          setOpenConfirmSendEmailBox(false);
        }
      }} sx={{ borderRadius: "10px" }}>
        <div className="flex flex-row border-b-2">
          <div className="text-[#232323] text-xl p-2 w-96">Send Email</div>
          <div className="text-xl text-[#232323] flex justify-end font-semibold p-2 cursor-pointer"><IoMdClose className='text-2xl' onClick={() => { setOpenConfirmSendEmailBox(false); }} /></div>
        </div>
        <div className='text-xl p-4'>Are you sure want to send email to all the users?</div>
        <hr className="mt-2 bg-blue-500"></hr>
        <div className="flex justify-end gap-2 p-1">
          {!loader && <button className="p-1 w-36 bg-[#2D5BFF] rounded-lg justify-center text-center text-white cursor-pointer" onClick={() => { sendEmail(); }}>Yes</button>}
          {loader && <div className='w-32 border-[1px] flex text-center justify-center rounded-md p-1 border-black'><Oval  height={24} width={24} color="black" ariaLabel="loading" secondaryColor="#3b82f6" strokeWidth={2} strokeWidthSecondary={2}/></div>}
          <button className="p-1 w-36 bg-white border-2 border-[#2D5BFF] rounded-lg justify-center text-center text-[#2D5BFF] cursor-pointer" onClick={() => { setOpenConfirmSendEmailBox(false); }}>Cancel</button>
        </div>
      </Dialog>

    </>
  )
}

export default SendEmail