import React, { useState, useEffect } from 'react'
import { FaArrowLeft } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa";
import { FaArrowUp } from "react-icons/fa6";
import { FaArrowDown } from "react-icons/fa";
import { MdOutlineUnfoldMore } from "react-icons/md";
import useBacktestStore from '../../store/backtestStore';
import useBacktestResultStore from '../../store/backtestResultStore';

const LegProfit = (props) => {
    const [legData, setLegData] = useState([]);
    const [data, setData] = useState([])
    const [order, setOrder] = useState(0);
    // page size and number box
    const [pageSize, setPageSize] = useState(100)
    const [NumberBox, setNumberBox] = useState([])
    const [activeColor, setActiveColor] = useState(0)
    const [indexNumber, setIndexNumber] = useState(0)
    const { slippage } = useBacktestStore();
    const { inputData } = useBacktestResultStore();


    const min = (a, b) => {
        if (a < b) return a;
        else return b;
    }


    // Sorting the Data on the basis of field
    const sortLegData = (field) => {
        const sortedData = data.sort((a, b) => {
            let itemA = a[field];
            let itemB = b[field];

            if (order == 0) {
                if (typeof itemA === 'string' && typeof itemB === 'string') {
                    setOrder(1 - order);
                    return itemA.localeCompare(itemB);
                } else if (typeof itemA === 'number' && typeof itemB === 'number') {
                    setOrder(1 - order);
                    return itemA - itemB;
                } else {
                    setOrder(1 - order);
                    return 0; // No comparison possible, keep the order unchanged
                }
            } else {
                if (typeof itemA === 'string' && typeof itemB === 'string') {
                    setOrder(1 - order);
                    return itemB.localeCompare(itemA);
                } else if (typeof itemA === 'number' && typeof itemB === 'number') {
                    setOrder(1 - order);
                    return itemB - itemA;
                } else {
                    setOrder(1 - order);
                    return 0; // No comparison possible, keep the order unchanged
                }
            }
        });
        setData(sortedData);
        setNumberBox(Array(parseInt(props.data.length / pageSize + 1)).fill(1))
        let data1 = props.data.slice(parseInt(indexNumber) * parseInt(pageSize), min(parseInt(props.data.length), (parseInt(indexNumber) + 1) * parseInt(pageSize)));
        setLegData(data1);
    };

    useEffect(() => {
        setData(props.data);
        setNumberBox(Array(parseInt(props.data.length / pageSize + 1)).fill(1))
        // Normal Trade Pagination
        let data = props.data.slice(parseInt(indexNumber) * parseInt(pageSize), min(parseInt(props.data.length), (parseInt(indexNumber) + 1) * parseInt(pageSize)));
        setLegData(data);
    }, [props.data, props.netProfit])


    const handleData = () => {
        setNumberBox(Array(parseInt(data.length / pageSize + 1)).fill(1))
        // Normal Trade pagination
        let data1 = data.slice(parseInt(indexNumber) * parseInt(pageSize), min(parseInt(data.length), (parseInt(indexNumber) + 1) * parseInt(pageSize)));
        setLegData(data1);
    }

    useEffect(() => {
        handleData();
    }, [indexNumber, pageSize])

    return (
        <>
            {/* Based on Expiry */}
            <div className="">
                    <div className="ml-2 mr-2">
                        {props.ExpiryResult && props.ExpiryResult.map((group, index) => (
                            <div key={index} flex flex-col>
                                {Object.entries(group).map(([date, events]) => (
                                    <div key={date}>
                                        <div className="text-xl bg-[#718EBF] p-2 ml-1 rounded-md mb-1 mr-1 grid grid-cols-2"> <div className="ml-2 text-white">Expiry Date -: <span className="text-white text-xl">{date} </span></div>
                                            <div className="flex justify-end mr-20 text-white"> (Profile/Loss-: <span className="text-white text-xl" style={{ color: props.totalExpireyProfit[date] > 0 ? "#00FF00" : "red" }}> {props.totalExpireyProfit[date].toFixed(0)}</span>)</div></div>
                                        <div className="overflow-x-auto overflow-y-none ml-1 mr-1 mb-5">
                                            <table className="w-full">
                                                {
                                                    <thead className="flex flex-row text-center border-b-2 p-2 text-white bg-[#718EBF]">
                                                        <tr>
                                                            <td className="p-2 w-[70px]  font-medium rounded sticky left-0 top-0 z-10 bg-[#718EBF]">Include</td>
                                                            <td className="p-2 w-[170px]  font-medium rounded sticky text-left left-[70px] top-0 z-10 bg-[#718EBF] cursor-pointer" onClick={() => { sortLegData('date'); }}>Entry Date<MdOutlineUnfoldMore className='inline text-2xl font-semibold' onClick={() => { sortLegData(0); }} /></td>
                                                            <td className="p-2 w-[180px]  font-medium text-left" onClick={() => { sortLegData('profit'); }}>Profit/Loss<MdOutlineUnfoldMore className='inline text-2xl font-semibold' onClick={() => { sortLegData(legData[0].length - 3); }} /></td>
                                                            <td className="p-2 w-[280px]  font-medium text-left" onClick={() => { sortLegData('startingVixPrice'); }}>Vix/Spot/Future<MdOutlineUnfoldMore className='inline text-2xl font-semibold' onClick={() => { sortLegData(9); }} /></td>
                                                            <td className="p-2 w-[160px]  font-medium text-left" onClick={() => { sortLegData('profit'); }}>Prev Day<MdOutlineUnfoldMore className='inline text-2xl font-semibold' onClick={() => { sortLegData(11); }} /></td>
                                                            {
                                                                inputData.legarray && inputData.legarray.map((item, key) => {
                                                                    return (
                                                                        <th className="p-2 w-[360px]  font-medium" key={`leg-${key}`}><abbr title={inputData.tradesymbol}>[{inputData.tradesymbol == 'NIFTY' ? "N" : "BN"}]</abbr> {'->'} Leg {key + 1}: {item['date'] == 'B' ? 'BUY' : 'SELL'} {inputData.segmentType[key]=='option' && (item[1] == 'C' ? 'CALL' : 'PUT')} | {inputData.lotarray[key]} Lot |
                                                                            {inputData.segmentType[key] == 'option' ? (inputData.atmChoice[key] == 'point' || inputData.atmChoice[key] == 'percent') ? inputData.atm[key] : `${inputData.closestPremiumChoice[key]} ${inputData.closestPremiumValue[key]}` : ''}
                                                                        </th>
                                                                    )
                                                                })
                                                            }
                                                        </tr>
                                                    </thead>
                                                }
                                                <tbody>
                                                    {
                                                        events && events.map((item, index) => {
                                                            console.log(item, 'item')
                                                            return (
                                                                <tr className="bg-white flex flex-row font-[inter] font-[400] text-center  border-b-2 text-[16px]" style={{ opacity: props.includeTrade.hasOwnProperty(item['date']) ? "0.2" : "" }} key={index}>
                                                                    <td className="p-2 text-[#232323]  w-[70px] font-[600]  sticky left-0 top-0 z-10 bg-white "><input type="checkbox" checked={!props.includeTrade.hasOwnProperty(item['date'])} onChange={() => {
                                                                        props.includeTrade.hasOwnProperty(item['date'])
                                                                            ? props.setIncludeTrade((prevResult) => {
                                                                                const inputdata = { ...prevResult }; // Create a shallow copy of prevResult
                                                                                delete inputdata[item['date']]; // Delete the property
                                                                                return inputdata; // Return the updated object
                                                                            })
                                                                            : props.setIncludeTrade((prevResult) => {
                                                                                const inputdata = { ...prevResult }; // Create a shallow copy of prevResult
                                                                                inputdata[item['date']] = 1; // Add or update the property
                                                                                return inputdata; // Return the updated object
                                                                            });
                                                                    }} /></td>
                                                                    <td className="p-2 text-[#232323]  w-[170px] font-[500] text-left  sticky left-[70px] top-0 z-10 bg-white"><span className='font-[600]'>{item['day'].slice(0, 3)},{item['date']}</span><br />
                                                                        {item['startTime']} - {item['endTime']} <br /> <span className="text-red-500">Exp.:({item['expiry'][0]})</span><br />
                                                                    </td>

                                                                    <td className="p-1 text-[#232323] w-[180px] text-left space-y-1">
                                                                        {/* Main Profit/Loss */}
                                                                        <div
                                                                            style={{
                                                                                color: item['profit'] >= 0 ? "#2D8C37" : "#E74C3C",
                                                                                borderColor: item['profit'] >= 0 ? "#2D8C37" : "#E74C3C",
                                                                            }}
                                                                            className="flex text-center justify-center border-2 p-1 rounded-lg shadow-sm transition-transform transform hover:scale-105"
                                                                        >
                                                                            {parseFloat(item['profit']).toFixed(0)}
                                                                        </div>

                                                                        {/* Max and Min Profit */}
                                                                        <div className="p-1 h-10 mt-1 border-[1px] rounded-lg flex items-center justify-between font-semibold text-md bg-gray-100 shadow-sm">
                                                                            <span className="mr-2">Max:</span>
                                                                            <span className="text-green-500">{parseFloat(item['maxProfit']).toFixed(0)}</span>
                                                                            <span>/</span>
                                                                            <span className="text-red-500">{parseFloat(item['maxLoss']).toFixed(0)}</span>
                                                                        </div>

                                                                        {/* Reason (Target or StopLoss) */}
                                                                        <div
                                                                            className={`p-1 h-10 border-[1px] rounded-lg flex items-center justify-center font-semibold text-md shadow-sm 
                                                                                ${item['reason'] === "" ? "border-none" :
                                                                                    item['reason'] === "strategy maxprofit hit!"
                                                                                        ? "text-green-600 bg-green-100 border-green-500"
                                                                                        : "text-red-600 bg-red-100 border-red-500"} transition-all duration-300 ease-in-out`}
                                                                        >
                                                                            {item['reason'] === "" ? "" :
                                                                                item['reason'] === "strategy maxprofit hit!" ? "Target Hit" : item['reason'] === "strategy stoploss hit!"? "StopLoss Hit" : item['reason']}
                                                                        </div>
                                                                    </td>


                                                                    <td className="p-2 text-[#232323]  w-[280px] text-left">
                                                                        <span className='text-blue-600'>(vix){parseFloat(item['startingVixPrice']).toFixed(2)}-{parseFloat(item['exitVixPrice']).toFixed(2)}={(parseFloat(item['exitVixPrice']).toFixed(2) - parseFloat(item['startingVixPrice']).toFixed(2)).toFixed(2)}</span><br />
                                                                        <span className='text-gray-900'>(fut.){parseFloat(item['startingFuturePrice']).toFixed(2)}-{parseFloat(item['exitFuturePrice']).toFixed(2)}={(parseFloat(item['exitFuturePrice']).toFixed(2) - parseFloat(item['startingFuturePrice']).toFixed(2)).toFixed(2)}</span><br />
                                                                        <span className=''>(spot) {parseFloat(item['startingSpotPrice']).toFixed(2)}-{parseFloat(item['exitSpotPrice']).toFixed(2)}={(parseFloat(item['exitSpotPrice']).toFixed(2) - parseFloat(item['startingSpotPrice']).toFixed(2)).toFixed(2)}</span></td>

                                                                    <td className="p-2 text-[#232323]  w-[160px] text-left" sx={{ color: (item['marketEntryPrice']?.Open - item['marketClosedPricePreviousDay']?.Close) > 0 ? "green" : "red" }}>
                                                                        {parseFloat(item['marketClosedPricePreviousDay']?.Open - item['marketEntryPrice']?.Close) > 0 ? parseFloat(item['marketClosedPricePreviousDay']?.Open - item['marketEntryPrice']?.Close).toFixed(2) : parseFloat(item['marketClosedPricePreviousDay']?.Open - item['marketEntryPrice']?.Close).toFixed(2)}{parseFloat(item['marketClosedPricePreviousDay']?.Open - item['marketEntryPrice']?.Close) > 0 ? <FaArrowUp className='text-green-500 inline' /> : <FaArrowDown className='text-red-600 inline' />}({parseFloat((((item['marketClosedPricePreviousDay']?.Open - item['marketEntryPrice']?.Close) / item['marketEntryPrice'].Close)) * 100).toFixed(2)}%)<br />
                                                                        <span className='text-blue-500'>{item['marketOpen'] == 'High' ? 'Day Open > PDH' : item['marketOpen'] == 'Low' ? 'Day Open < PDL' : '--'}</span><br />
                                                                    </td>

                                                                    <td>
                                                                        <div className='flex flex-row'>
                                                                            {item?.result.map((temp, key) => {
                                                                                return (
                                                                                    <div className='flex flex-col'>{
                                                                                        temp.map((value, evalkey) => {
                                                                                            return (
                                                                                                <React.Fragment key={`normal-${key}`}>
                                                                                                    <td className="flex flex-col w-[360px] m-1 rounded-md">                                                            {
                                                                                                        parseFloat(value['startPrice']) != 99999999999 && parseFloat(value['exitPrice']) != 99999999999 &&
                                                                                                        <td className="p-1 w-[360px]  text-black bg-gray-200 rounded" style={{
                                                                                                            backgroundColor: value['squareOffReason'] === "stoploss" ? "#FFDDDD" : value['squareOffReason'] === "sstoploss" ? "#FFD580" : value['squareOffReason'] === "maxprofit" ? "#90EE90" : value['squareOffReason'] === "smaxprofit" ? "#fdfa72" : ""
                                                                                                        }}>
                                                                                                            <span className='font-[600] text-gray-600 p-1'>{
                                                                                                                (parseFloat(value['exitPrice']) !== 99999999999 && parseFloat(value['startPrice']) !== 99999999999
                                                                                                                    && (
                                                                                                                        inputData.legarray[key][0] === 'S'
                                                                                                                            ? ((parseFloat(parseFloat(value['startPrice']) - slippage * (parseFloat(value['startPrice'])) * 0.01) - (parseFloat(parseFloat(value['exitPrice']) + slippage * (parseFloat(value['exitPrice'])) * 0.01))) * inputData.lotarray1[key]).toFixed(0)
                                                                                                                            : ((parseFloat(parseFloat(value['exitPrice']) - slippage * (parseFloat(value['exitPrice'])) * 0.01) - (parseFloat(parseFloat(value['startPrice']) + slippage * parseFloat(value['startPrice']) * 0.01))) * inputData.lotarray1[key]).toFixed(0)
                                                                                                                    )
                                                                                                                )} 
                                                                                                                {/* strikeprice */}
                                                                                                                &nbsp;&nbsp; {inputData.segmentType[key]=='option' && value['strikePrice']!=0 &&  `(${value['strikePrice']}${inputData?.legarray[key][1]}E)`}</span>
                                                                                                                <br />
                                                                                                                {
                                                                                                                    parseFloat(value['exitPrice']) !== 99999999999 && parseFloat(value['startPrice']) !== 99999999999 && inputData.legarray[key][0] === 'B'
                                                                                                                        ? `${(parseFloat(value['startPrice']) + slippage * (parseFloat(value['startPrice']) * 0.01)).toFixed(2)} ${(value['startTime'] === '0' || value['startTime']=='') ? '' : `(${value['startTime']})`} - ${(parseFloat(value['exitPrice']) - slippage * (parseFloat(value['exitPrice']) * 0.01)).toFixed(2)}${(value['exitTime'] === '0' || value['exitTime'] === '') ? '' : `(${value['exitTime']})`}=${((parseFloat(value['exitPrice']) - slippage * (parseFloat(value['exitPrice']) * 0.01)) - (parseFloat(value['startPrice']) + slippage * (parseFloat(value['startPrice']) * 0.01))).toFixed(2)}`
                                                                                                                        : `${(parseFloat(value['startPrice']) - slippage * (parseFloat(value['startPrice']) * 0.01)).toFixed(2)} ${(value['startTime'] === '0' || value['startTime']=='') ? '' : `(${value['startTime']})`} - ${(parseFloat(value['exitPrice']) + slippage * (parseFloat(value['exitPrice']) * 0.01)).toFixed(2)}${(value['exitTime'] === '0' || value['exitTime'] === '') ? '' : `(${value['exitTime']})`}=${((parseFloat(value['startPrice']) - slippage * (parseFloat(value['startPrice']) * 0.01)) - (parseFloat(value['exitPrice']) + slippage * (parseFloat(value['exitPrice']) * 0.01))).toFixed(2)}`
                                                                                                                }
                                                                                                            <br />
                                                                                                        </td>
                                                                                                    }
                                                                                                    </td>
                                                                                                </React.Fragment>
                                                                                            )
                                                                                        })
                                                                                    }</div>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
            

        </>)
}

export default LegProfit