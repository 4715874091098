import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { MdCurrencyRupee } from "react-icons/md";
import { FaCheckCircle } from "react-icons/fa";
import { Dialog } from "@mui/material";
// constant
import { DELETE_PLAN, INSERT_PLAN, PLAN_LIST_API, UPDATE_PLAN } from "../../constant/constant";
import { MdClose } from "react-icons/md";
// images
import silver from './../../assets/silver.webp'
import gold from './../../assets/gold.webp'
import platinum from './../../assets/platinum.webp'
import { ToastContainer, toast } from 'react-toastify';
import secureLocalStorage from 'react-secure-storage';
import { extractErrorMessage } from '../../utility/utils';

const Plan = () => {
    const [plans, setPlans] = useState([]);
    const [planDetail, setPlanDetail] = useState();
    const [visiblePaymentBox, setVisiblePaymentBox] = useState(false);
    const [visibleDeletePlan, setVisibleDeletePlan] = useState(false);
    const [visibleInsertBox, setVisibleInsertBox] = useState(false);
    const [detail, setDetail] = useState({ "availableFeatures": [], "coins": [], "gst": 0, "isPopular": false, "name": "", "rsPerCoin": 0, "total": 0, "validity": 0 })
    const [id, setId] = useState(-1);

    const get_plan_detail = async () => {
        let config = {
            method: 'get',
            url: PLAN_LIST_API,
        };

        axios.request(config)
            .then((response) => {
                setPlans(response.data);
            })
            .catch((error) => {
                console.log(error);
                toast.error(extractErrorMessage(error));
            });
    }

    // Delete Plan
    const onHandleDeletePlan = async () => {
        // Get the token from secureLocalStorage
        let token = secureLocalStorage.getItem('backtest_token');

        // Remove the 'backtest_' prefix if it exists
        if (token && token.startsWith('backtest_')) {
            token = token.replace('backtest_', '');
        }
        const config = {
            method: 'delete',
            url: `${DELETE_PLAN}/${id}`,
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}`, },
        };
        try {
            await axios(config);
            toast.success("successfully deleted the plan")
        } catch (error) {
            console.log(error, 'delete plan');
            toast.error(extractErrorMessage(error));
        }
    }

    // Update Plan
    const onHandleUpdatePlan = async () => {
        // Get the token from secureLocalStorage
        let token = secureLocalStorage.getItem('backtest_token');

        // Remove the 'backtest_' prefix if it exists
        if (token && token.startsWith('backtest_')) {
            token = token.replace('backtest_', '');
        }
        delete planDetail._id;
        console.log(planDetail, 'planDetail')
        const config = {
            method: 'put',
            url: `${UPDATE_PLAN}/${id}`,
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}`, },
            data: planDetail
        };
        try {
            await axios(config);
            toast.success("successfully update plan");
        } catch (error) {
            console.log(error, 'update plan')
            toast.error(extractErrorMessage(error));
        }
    }

    const onHandleInsertPlan = async () => {
        // Get the token from secureLocalStorage
        let token = secureLocalStorage.getItem('backtest_token');

        // Remove the 'backtest_' prefix if it exists
        if (token && token.startsWith('backtest_')) {
            token = token.replace('backtest_', '');
        }
        const config = {
            method: 'post',
            url: `${INSERT_PLAN}`,
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}`, },
            data: detail
        };
        try {
            await axios(config);
            toast.success("successfully insert plan");
        } catch (error) {
            console.log(error, 'insert-plan')
            toast.error(extractErrorMessage(error));
        }
    }
    // handleChange
    const handleChange = (e) => {
        setPlanDetail({ ...planDetail, [e.target.name]: e.target.value });
    }

    const handleValue = (e) => {
        setDetail({ ...detail, [e.target.name]: e.target.value });
    }

    useEffect(() => {
        get_plan_detail();
    }, [])
    return (
        <>
            <ToastContainer />
            <div className='bg-[#DAECF2]'>
                <div className='flex justify-end'><div className='flex justify-start text-white text-center p-1 bg-blue-500 text-xl m-2 rounded-md cursor-pointer' onClick={() => { setVisibleInsertBox(true); }}>Insert Plan</div></div>
                <div className="grid grid-cols-1 m-5 sm:m-16 gap-10 sm:grid-cols-1 lg:grid-cols-3 font-serif">
                    {
                        plans.map((item, index) => {
                            var imgName = '';
                            if (item.name === 'Silver Plan') {
                                imgName = silver;
                            } else if (item.name === 'Gold Plan') {
                                imgName = gold;
                            } else if (item.name === 'Platinum Plan') {
                                imgName = platinum;
                            }
                            return (
                                <div key={`plan-00${index}`} className="border-1 bg-white flex flex-col mt-10 border-t-8 border-white rounded-xl shadow-blue-700 shadow-lg">
                                    <div className='flex text-center justify-center mt-5'>
                                        <h3 className="text-center text-l  text-blue-800 rounded-lg flex flex-row">
                                            <img src={imgName} className="h-10 w-10"></img>
                                            <span className='mt-2 text-xl font-medium'>{item.name}</span>
                                        </h3>
                                    </div>
                                    <hr className="m-1" /><hr />
                                    <p className="text-4xl text-center text-black font-semibold flex flex-row justify-center"><MdCurrencyRupee sx={{ fontSize: "60px" }} />{item.total} <span className='text-sm'>(Incl.18% GST)</span></p>
                                    <hr className='font-semibold bg-black h-2'></hr>
                                    <div className="m-5">
                                        <div className='font-semibold ml-2'>Features/Benefit</div>
                                        <div className="text-l p-1 m-1 text-gray-700 flex flex-row gap-2"><FaCheckCircle className="text-[#2D5BFF]" />Maximum <span className="text-xl font-semibold text-blue-800">{item.coins}</span> coins</div>
                                        <div className="text-l p-1 m-1 text-gray-700 flex flex-row gap-2"><FaCheckCircle className="text-[#2D5BFF]" /><span className="text-xl font-semibold text-blue-800">{item.validity}</span> day validation</div>
                                        <div className="text-l p-1 m-1 text-gray-700 flex flex-row gap-2"><FaCheckCircle className="text-[#2D5BFF]" /><span className="text-xl font-semibold text-blue-800">{item.rsPerCoin}</span> Rupee Per Coin</div>
                                        {
                                            item.availableFeatures.map((feature, ix) => {
                                                return (
                                                    <div key={`planfeature-00${index}-${ix}`} className="text-l p-1 m-1 text-gray-700 flex flex-row gap-2"><FaCheckCircle className="text-[#2D5BFF]" />{feature}</div>
                                                )
                                            })
                                        }
                                    </div>
                                    <div className='grid grid-cols-2'>
                                        <button className='text-l font-bold text-white bg-red-500 rounded-lg p-1 m-2' onClick={() => {
                                            setPlanDetail(item);
                                            setVisibleDeletePlan(true);
                                            setId(item?._id);
                                        }}>Delete</button>
                                        <button className='text-l font-bold text-white bg-[#2D5BFF] rounded-lg p-1 m-2' onClick={() => {
                                            setPlanDetail(item);
                                            setVisiblePaymentBox(true);
                                            setId(item?._id)
                                        }}>Update</button>
                                    </div>

                                </div>
                            )
                        })
                    }
                </div>
            </div>

            {/* Update Plan Detail */}
            <Dialog className="border-2 border-t-8 border-blue-500 rounded-xl" maxWidth="xl" onClose={(event, reason) => {
                if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') { setVisiblePaymentBox(false); }
            }} open={visiblePaymentBox}>
                <div className="grid grid-cols-2 border-b-2">
                    <div className="text-[#232323] text-xl p-2 w-96">Edit Plan Detail</div>
                    <div className="text-xl text-[#232323] flex justify-end font-semibold p-2 cursor-pointer"><MdClose onClick={() => { setVisiblePaymentBox(false); }} /></div>
                </div>
                <div className="flex flex-col mt-2 p-1 gap-2">
                    <div className='flex flex-row'><div className="font-medium text-xl w-36 font-serif text-[#232323]">Plan Name</div><input type="text" className='text-l bg-[#F8F8F8] rounded-lg p-1 flex-grow outline-0' value={planDetail?.name} name="name" onChange={(e) => { handleChange(e) }} /></div>
                    <div className='flex flex-row'><div className="font-medium text-xl w-36 font-serif text-[#232323]">Plan Price</div><input type="text" className='text-l bg-[#F8F8F8] rounded-lg p-1 flex-grow outline-0' value={planDetail?.price} name="price" onChange={(e) => { handleChange(e) }} /></div>
                    <div className='flex flex-row'><div className="font-medium text-xl w-36 font-serif text-[#232323]">Plan Validation</div><input type="text" className='text-l bg-[#F8F8F8] rounded-lg p-1 flex-grow outline-0' value={planDetail?.validity} name="validity" onChange={(e) => { handleChange(e) }} /></div>
                    <div className='flex flex-row'><div className="font-medium text-xl w-36 font-serif text-[#232323]">No. Of Coin</div><input type="text" className='text-l bg-[#F8F8F8] rounded-lg p-1 flex-grow outline-0' value={planDetail?.coins} name="coins" onChange={(e) => { handleChange(e) }} /></div>
                    <div className="flex justify-end gap-2 p-1">
                        <button className="border-2 p-1 w-24 bg-blue-600 text-white rounded-lg justify-center text-center cursor-pointer" onClick={() => { setVisiblePaymentBox(false); onHandleUpdatePlan(); }}>Update</button>
                        <button className="border-2 p-1 w-24 text-blue-600 border-blue-600 bg-white rounded-lg justify-center text-center cursor-pointer" onClick={() => { setVisiblePaymentBox(false); }}>Cancel</button>
                    </div>
                </div>
            </Dialog>

            {/* Delete Plan Detail */}
            <Dialog className="border-2 border-t-8 border-[#2D5BFF] rounded-xl" onClose={(event, reason) => {
                if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') { setVisibleDeletePlan(false); }
            }} open={visibleDeletePlan}>
                <div className="flex flex-row border-b-2">
                    <div className="text-[#232323] text-xl p-2 w-96">Alert!!!</div>
                    <div className="text-xl text-[#232323] flex justify-end font-semibold p-2 cursor-pointer"><MdClose onClick={() => { setVisibleDeletePlan(false); }} /></div>
                </div>
                <div className="flex flex-col text-center justify-center mt-2 p-1">
                    <div className="text-l text-left">Are you sure you want to delete the gold plan?</div>
                    <hr className="mt-2 bg-blue-400"></hr>
                    <div className="flex justify-end gap-2 p-1">
                        <button className="w-36 bg-white border-2 border-[#2D5BFF] rounded-lg justify-center text-center text-[#2D5BFF] cursor-pointer" onClick={() => { setVisibleDeletePlan(false); }}>Cancel</button>
                        <button className="w-36 bg-[#FF3030] rounded-lg justify-center text-center text-white cursor-pointer" onClick={() => { onHandleDeletePlan(); setVisibleDeletePlan(false); }}>Delete</button>
                    </div>
                </div>
            </Dialog>

            {/* Plan Detail */}
            <Dialog className="border-2 border-t-8 border-blue-500 rounded-xl" maxWidth="xl" onClose={(event, reason) => {
                if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') { setVisibleInsertBox(false); }
            }} open={visibleInsertBox}>
                <div className="grid grid-cols-2 border-b-2">
                    <div className="text-[#232323] text-xl p-2 w-96">Plan Detail</div>
                    <div className="text-xl text-[#232323] flex justify-end font-semibold p-2 cursor-pointer"><MdClose onClick={() => { setVisibleInsertBox(false); }} /></div>
                </div>
                <div className="flex flex-col mt-2 p-1 gap-2">
                    <div className='flex flex-row'><div className="font-medium text-xl w-36 font-serif text-[#232323]">Plan Name</div><input type="text" className='text-l bg-[#F8F8F8] rounded-lg p-1 flex-grow outline-0' value={detail?.name} name="name" onChange={(e) => { handleValue(e) }} /></div>
                    <div className='flex flex-row'><div className="font-medium text-xl w-36 font-serif text-[#232323]">Plan Price</div><input type="text" className='text-l bg-[#F8F8F8] rounded-lg p-1 flex-grow outline-0' value={detail?.price} name="price" onChange={(e) => { handleValue(e) }} /></div>
                    <div className='flex flex-row'><div className="font-medium text-xl w-36 font-serif text-[#232323]">Plan Validation</div><input type="text" className='text-l bg-[#F8F8F8] rounded-lg p-1 flex-grow outline-0' value={detail?.validity} name="validity" onChange={(e) => { handleValue(e) }} /></div>
                    <div className='flex flex-row'><div className="font-medium text-xl w-36 font-serif text-[#232323]">No. Of Coin</div><input type="text" className='text-l bg-[#F8F8F8] rounded-lg p-1 flex-grow outline-0' value={detail?.coins} name="coins" onChange={(e) => { handleValue(e) }} /></div>
                    <div className="flex justify-end gap-2 p-1">
                        <button className="border-2 p-1 w-24 bg-blue-600 text-white rounded-lg justify-center text-center cursor-pointer" onClick={() => { setVisibleInsertBox(false); onHandleInsertPlan(); }}>Insert</button>
                        <button className="border-2 p-1 w-24 text-blue-600 border-blue-600 bg-white rounded-lg justify-center text-center cursor-pointer" onClick={() => { setVisibleInsertBox(false); }}>Cancel</button>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default Plan