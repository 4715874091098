import React, { useState, useEffect } from 'react'
import { FaArrowLeft } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa";
import { FaArrowUp } from "react-icons/fa6";
import { FaArrowDown } from "react-icons/fa";
import { MdOutlineUnfoldMore } from "react-icons/md";


const LegProfit = (props) => {
    const [boxList, setBoxList] = useState([])
    const [data, setData] = useState([])
    // page size and number box
    const [pageSize, setPageSize] = useState(100)
    const [numberBox, setNumberBox] = useState([])
    const [activeColor, setActiveColor] = useState(0)
    const [indexNumber, setIndexNumber] = useState(0)
    const final_result =  props.originalResult.tradeResult;

    // console.log(props.finalReEntryResult,'final reEntry result')

    // Sorting the Data on the basis of Index
    const [legData, setLegData] = useState([]);
    const [order, setOrder] = useState(0);

    const sortLegData = (field) => {
        const sortedData = [...final_result].sort((a, b) => {
            let itemA = a[field];
            let itemB = b[field];

            if (order == 0) {
                if (typeof itemA === 'string' && typeof itemB === 'string') {
                    setOrder(1 - order);
                    return itemA.localeCompare(itemB);
                } else if (typeof itemA === 'number' && typeof itemB === 'number') {
                    setOrder(1 - order);
                    return itemA - itemB;
                } else {
                    setOrder(1 - order);
                    return 0; // No comparison possible, keep the order unchanged
                }
            } else {
                if (typeof itemA === 'string' && typeof itemB === 'string') {
                    setOrder(1 - order);
                    return itemB.localeCompare(itemA);
                } else if (typeof itemA === 'number' && typeof itemB === 'number') {
                    setOrder(1 - order);
                    return itemB - itemA;
                } else {
                    setOrder(1 - order);
                    return 0; // No comparison possible, keep the order unchanged
                }
            }
        });
        setLegData(sortedData);
        // console.log(sortedData);
    };
   
    useEffect(() => {
        // sortLegData(0);
        setLegData(props.originalResult.tradeResult);
    }, props.originalResult.tradeResult)
    useEffect(() => {
        let len = legData.length;
        // console.log(final_result,'final_result')
        setBoxList(Array(parseInt(len / parseInt(pageSize)) + 1).fill(0))
        setData(legData.slice(numberBox * pageSize, (numberBox + 1) * pageSize))
    }, [pageSize, numberBox, legData, props.originalResult.tradeResult])
    // console.log(JSON.stringify(props.originalResult.result),'props original result',)

    return (
        <>

            {/* List of trades */}
            <div>
                <div className="p-3 mt-5 overflow-y-none overflow-x-auto justify-center ml-2 mr-2">
                    <h1 className="text-[#333B69] -mt-3 -ml-3 -mr-3 p-1" style={{ fontWeight: "400", fontSize: "24px" }} > List Of Trades </h1>
                </div>
                <div className="overflow-x-auto overflow-y-none ml-2 mr-2 rounded-lg">
                    <table className="w-full">
                        <thead className="flex flex-row text-center border-b-2 p-2 text-white bg-[#718EBF]">
                            <tr>
                                <th className="p-2 w-[70px]  font-medium rounded sticky left-0 top-0 z-10 text-white bg-[#718EBF]">Include</th>
                                <th className="p-2 w-[170px]  font-medium rounded sticky text-left left-[70px] top-0 z-10 text-white bg-[#718EBF]] cursor-pointer"  onClick={() => { sortLegData('date'); }}>Entry Date<MdOutlineUnfoldMore className='cursor-pointer inline' onClick={() => { sortLegData(0); }} /></th>
                                <th className="p-2 w-[180px]  font-medium text-left " onClick={() => { sortLegData('profit'); }}>Profit/Loss<MdOutlineUnfoldMore className='cursor-pointer inline'/></th>
                                <th className="p-2 w-[280px]  font-medium text-left" onClick={() => { sortLegData('startingVixPrice'); }}>Vix/Spot/Future<MdOutlineUnfoldMore className='cursor-pointer inline'/></th>
                                <th className="p-2 w-[160px]  font-medium text-left" onClick={() => { sortLegData('marketClosedPricePreviousDay'); }}>Prev Day<MdOutlineUnfoldMore className='cursor-pointer inline'/></th>
                                {
                                    props.inputData.legarray && props.inputData.legarray.map((item, key) => {
                                        return (
                                            <th className="p-2 w-[360px]  font-medium" key={`leg-${key}`}><abbr title={props.inputData.tradesymbol}>[{props.inputData.tradesymbol == 'NIFTY' ? "N" : "BN"}]</abbr> {'->'} Leg {key + 1}: {item[0] == 'B' ? 'BUY' : 'SELL'} {props.inputData.segmentType[key]=='option' && (item[1] == 'C' ? 'CALL' : 'PUT')} |  Lot |
                                                {props.inputData.segmentType[key] == 'option' ? (props.inputData.atmChoice[key] == 'point' || props.inputData.atmChoice[key] == 'percent') ? props.inputData.atm[key] : `${props.inputData.closestPremiumChoice[key]} ${props.inputData.closestPremiumValue[key]}` : ''}
                                            </th>
                                        )
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data && data.map((item, index) => {
                                    return (
                                        <tr className="bg-white flex flex-row font-[inter] font-[400] text-center  border-b-2 text-[16px]" key={index}>
                                            {/* <td className="p-2 text-[#232323]  w-[70px] font-[600]  sticky left-0 top-0 z-10 bg-white "><input type="checkbox" checked={!props.includeTrade.hasOwnProperty(item[0])} onChange={() => {
                                                props.includeTrade.hasOwnProperty(item[0])
                                                    ? props.setIncludeTrade((prevResult) => {
                                                        const inputdata = { ...prevResult }; // Create a shallow copy of prevResult
                                                        delete inputdata[item[0]]; // Delete the property
                                                        return inputdata; // Return the updated object
                                                    })
                                                    : props.setIncludeTrade((prevResult) => {
                                                        const inputdata = { ...prevResult }; // Create a shallow copy of prevResult
                                                        inputdata[item[0]] = 1; // Add or update the property
                                                        return inputdata; // Return the updated object
                                                    });
                                            }} /></td> */}
                                            <td className="p-2 text-[#232323]  w-[70px] font-[600]  sticky left-0 top-0 z-10 bg-white "><input type="checkbox" defaultChecked={true} /></td>
                                            <td className="p-2 text-[#232323]  w-[170px] font-[500] text-left  sticky left-[70px] top-0 z-10 bg-white"><span className='font-[600]'>{item['day'].slice(0,3)},{item['date']}</span><br />
                                                {item['startTime']} - {item['endTime']} <br /> <span className="text-red-500">Exp.:({item['expiry'][0]})</span><br />
                                                
                                            </td>
                                            <td className="p-1 text-[#232323] w-[180px] text-left space-y-1">
                                                {/* Main Profit/Loss */}
                                                <div
                                                    style={{
                                                        color: item['totalProfit'] >= 0 ? "#2D8C37" : "#E74C3C",
                                                        borderColor: item['totalProfit'] >= 0 ? "#2D8C37" : "#E74C3C",
                                                    }}
                                                    className="flex text-center justify-center border-2 p-1 rounded-lg shadow-sm transition-transform transform hover:scale-105"
                                                >
                                                    {parseFloat(item['profit']).toFixed(0)}
                                                </div>

                                                {/* Max and Min Profit */}
                                                <div className="p-1 h-10 mt-1 border-[1px] rounded-lg flex items-center justify-between font-semibold text-md bg-gray-100 shadow-sm">
                                                    <span className="mr-2">Max:</span>
                                                    <span className="text-green-500">{parseFloat(item['maxProfit']).toFixed(0)}</span>
                                                    <span>/</span>
                                                    <span className="text-red-500">{parseFloat(item['maxLoss']).toFixed(0)}</span>
                                                </div>

                                                {/* Reason (Target or StopLoss) */}
                                                <div
                                                    className={`p-1 h-10 border-[1px] rounded-lg flex items-center justify-center font-semibold text-md shadow-sm 
                                                            ${item['reason'] === "" ? "border-none" :
                                                            item['reason'] === "strategy maxprofit hit!"
                                                                ? "text-green-600 bg-green-100 border-green-500"
                                                                : "text-red-600 bg-red-100 border-red-500"} transition-all duration-300 ease-in-out`}
                                                >
                                                    {item['reason'] === "" ? "" :
                                                        item['reason'] === "strategy maxprofit hit!" ? "Target Hit" : item['reason'] === "strategy stoploss hit!"? "StopLoss Hit" : item['reason']}
                                                </div>
                                            </td>


                                            <td className="p-2 text-[#232323]  w-[280px] text-left">
                                                <span className='text-blue-600'>(vix){parseFloat(item['startingVixPrice']).toFixed(2)}-{parseFloat(item['exitVixPrice']).toFixed(2)}={(parseFloat(item['exitVixPrice']).toFixed(2) - parseFloat(item['startingVixPrice']).toFixed(2)).toFixed(2)}</span><br />
                                                <span className='text-gray-900'>(fut.){parseFloat(item['startingFuturePrice']).toFixed(2)}-{parseFloat(item['exitFuturePrice']).toFixed(2)}={(parseFloat(item['exitFuturePrice']).toFixed(2) - parseFloat(item['startingFuturePrice']).toFixed(2)).toFixed(2)}</span><br />
                                                <span className=''>(spot) {parseFloat(item['startingSpotPrice']).toFixed(2)}-{parseFloat(item['exitSpotPrice']).toFixed(2)}={(parseFloat(item['exitSpotPrice']).toFixed(2) - parseFloat(item['startingSpotPrice']).toFixed(2)).toFixed(2)}</span></td>

                                            <td className="p-2 text-[#232323]  w-[160px] text-left" sx={{ color: (item['marketEntryPrice']?.Open - item['marketClosedPricePreviousDay']?.Close) > 0 ? "green" : "red" }}>
                                                {parseFloat(item['marketClosedPricePreviousDay']?.Open - item['marketEntryPrice']?.Close) > 0 ? parseFloat(item['marketClosedPricePreviousDay']?.Open - item['marketEntryPrice']?.Close).toFixed(2) : parseFloat(item['marketClosedPricePreviousDay']?.Open - item['marketEntryPrice']?.Close).toFixed(2)}{parseFloat(item['marketClosedPricePreviousDay']?.Open - item['marketEntryPrice']?.Close) > 0 ? <FaArrowUp className='text-green-500 inline' /> : <FaArrowDown className='text-red-600 inline' />}({parseFloat((((item['marketClosedPricePreviousDay']?.Open - item['marketEntryPrice']?.Close) / item['marketEntryPrice'].Close)) * 100).toFixed(2)}%)<br />
                                                <span className='text-blue-500'>{item['marketOpen'] == 'High' ? 'Day Open > PDH' : item['marketOpen'] == 'Low' ? 'Day Open < PDL' : '--'}</span><br />
                                            </td>


                                            <td>
                                                <div className='flex flex-row'>
                                                    {item?.result.map((temp, key) => {
                                                        return (
                                                            <div className='flex flex-col' key={`leg-${key}`}>{
                                                                temp.map((value, evalkey) => {
                                                                    return (
                                                                        <React.Fragment key={`normal-${key}-${evalkey}`}>
                                                                            <td className="flex flex-col w-[360px] m-1 rounded-md">                                                            {
                                                                                parseFloat(value['startPrice']) != 99999999999 && parseFloat(value['exitPrice']) != 99999999999 &&
                                                                                <td className="p-1 w-[360px] bg-gray-200 rounded  text-black" style={{
                                                                                    backgroundColor: value['squareOffReason'] === "stoploss" ? "#FFDDDD" : value['squareOffReason'] === "sstoploss" ? "#FFD580" : value['squareOffReason'] === "maxprofit" ? "#90EE90" : value['squareOffReason'] === "smaxprofit" ? "#fdfa72" : ""
                                                                                }}>
                                                                                    <span className='p-1'>{
                                                                                        (parseFloat(value['exitPrice']) !== 99999999999 && parseFloat(value['startPrice']) !== 99999999999
                                                                                            && (
                                                                                                props.inputData.legarray[key][0] === 'S'
                                                                                                    ? ((parseFloat(parseFloat(value['startPrice']) - props.slippage * (parseFloat(value['startPrice'])) * 0.01) - (parseFloat(parseFloat(value['exitPrice']) + props.slippage * (parseFloat(value['exitPrice'])) * 0.01))) * props.inputData.lotarray1[key]).toFixed(0)
                                                                                                    : ((parseFloat(parseFloat(value['exitPrice']) - props.slippage * (parseFloat(value['exitPrice'])) * 0.01) - (parseFloat(parseFloat(value['startPrice']) + props.slippage * parseFloat(value['startPrice']) * 0.01))) * props.inputData.lotarray1[key]).toFixed(0)
                                                                                            )
                                                                                        )}
                                                                                        {/* strikeprice */}
                                                                                        &nbsp;&nbsp; {props.inputData.segmentType[key]=='option' && value['strikePrice']!=0 &&  `(${value['strikePrice']}${props.inputData?.legarray[key][1]}E)`}</span>
                                                                                    <br/>
                                                                                    {
                                                                                        parseFloat(value['exitPrice']) !== 99999999999 && parseFloat(value['startPrice']) !== 99999999999 && props.inputData.legarray[key][0] === 'B'
                                                                                            ? `${(parseFloat(value['startPrice']) + props.slippage * (parseFloat(value['startPrice']) * 0.01)).toFixed(2)} ${(value['startTime'] === '0' || value['startTime']=='') ? '' : `(${value['startTime']})`} - ${(parseFloat(value['exitPrice']) - props.slippage * (parseFloat(value['exitPrice']) * 0.01)).toFixed(2)}${(value['exitTime'] === '0' || value['exitTime'] === '') ? '' : `(${value['exitTime']})`}=${((parseFloat(value['exitPrice']) - props.slippage * (parseFloat(value['exitPrice']) * 0.01)) - (parseFloat(value['startPrice']) + props.slippage * (parseFloat(value['startPrice']) * 0.01))).toFixed(2)}`
                                                                                            : `${(parseFloat(value['startPrice']) - props.slippage * (parseFloat(value['startPrice']) * 0.01)).toFixed(2)} ${(value['startTime'] === '0' || value['startTime']=='') ? '' : `(${value['startTime']})`} - ${(parseFloat(value['exitPrice']) + props.slippage * (parseFloat(value['exitPrice']) * 0.01)).toFixed(2)}${(value['exitTime'] === '0' || value['exitTime'] === '') ? '' : `(${value['exitTime']})`}=${((parseFloat(value['startPrice']) - props.slippage * (parseFloat(value['startPrice']) * 0.01)) - (parseFloat(value['exitPrice']) + props.slippage * (parseFloat(value['exitPrice']) * 0.01))).toFixed(2)}`
                                                                                    }
                                                                                    <br />
                                                                                </td>
                                                                            }
                                                                            </td>
                                                                        </React.Fragment>
                                                                    )
                                                                })
                                                            }</div>
                                                        )
                                                    })}
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Indexing  and PageSize*/}
            <div className="grid grid-cols-1 sm:grid-cols-3" >
                <div className="flex flex-row mt-5">
                    <div className="font-[600] ml-5 text-[16px]">Page&nbsp;Size</div>
                    <select className="ml-3 outline-0 h-6 rounded-md" value={pageSize} onChange={(e) => { setPageSize(e.target.value); }}>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={75}>75</option>
                        <option value={100}>100</option>
                    </select>
                </div>
                <div className="flex justify-center mt-5">Showing {indexNumber * pageSize + 1} to {data.length < ((indexNumber + 1) * pageSize) ? data.length : (indexNumber + 1) * pageSize} of {data.length} entries</div>
                <div className="grid ml-5 sm:justify-end  mb-10 mt-5 mr-10">
                    <div className="flex flex-row gap-2">
                        <div className='rounded-md border-2 border-white h-[32px] w-[32px] bg-[#2D5BFF] text-white mt-1'><FaArrowLeft className='m-1' /></div>
                        {
                            numberBox && numberBox.map((item, key) => {
                                return (
                                    <div className="rounded h-[32px] w-[32px] font-[Ubuntu]  text-[16px] font-[700] text-center text-[#2D5BFF] justify-center border-[1px] border-[#ECF0FF] cursor-pointer active:bg-[#ECF0FF]" key={`numberBox-${key}`} style={{ backgroundColor: activeColor === key ? "white" : "#ECF0FF" }} onClick={() => { setIndexNumber(key); setActiveColor(key); }}>{key + 1}</div>
                                )
                            })
                        }
                        <div className='rounded-md border-2 border-white h-[32px] w-[32px] bg-[#2D5BFF] text-white mt-1'><FaArrowRight className='m-1' /></div>
                    </div>
                </div>
            </div>
        </>)
}

export default LegProfit