import { NoOfDaysBetweenTwoDate, formatDate, daysFromToday } from "../Utility";
import { totalWeekProfit, totalMonthProfit, Next_Thursday } from './Util'
import { resultFilterMethod } from "./ResultFilter";

export const result = (csv, margin, inputData, vixStart, vixExit, resultStartDate, resultEndDate, tradeOnlyFirstEntry, tradeOnlyFirstEntryMethod, dayoption, legarray, legs, lotarray, LotQuantity, appearNA, includeTrade, premiumPrice, startdate, enddate, slippage,resultFilter) => {
    let data = []
    let total_profit = 0, winningtrades = 0, losingtrades = 0, maxprofit = 0, maxloss = 0, barChartDateArray = [], previous = 0, cnt = 0, premiumAmount = 0;
    let cumulative = new Array(csv.length);
    let profitArray = new Array(csv.length)
    let week1 = new Array(5).fill(0)   //For Storing profit on the basis of daywise
    let month1 = {}   //For Storing profit on the basis of monthwise
    let net_profit = new Array(csv.length)  //For Storing profit
    // expectancy
    let profitQuantity = 0, totalProfit = 0, totalLoss = 0, lossQuantity = 0;
    let maxStreak = 0, lossStreak = 0;
    let winStreakDict = [0, 0, 0, 0, 0];
    let lossStreakDict = [0, 0, 0];
    let prev = 1, count = 0;
    // drawdown
    let maxProfit = 0; // Initialize maxPrice with the first element of the array
    let MaximumDrawdown = 0;
    let monthlyDrawdown = {};
    let result = new Array(csv.length)
    // investmentDay
    let investmentDay = 0;
    // group by expiry
    const expirySet = new Set();
    // drawdown date
    let drawDownDate = csv.length > 0 ? csv[0][0] : '';
    let weekResult = {};
    let mddCount = 0, recoveryStartDate = "", maxLoss = 9999999, ans = { "count": 0, "startDate": "", "endDate": "", "recovery": 0, "continue": false, "status": 'Running', "recoveryDate": "" };
    let individualLeg = new Array(inputData.leg).fill().map(() => ({ "profit": 0, "count": 0 }));
    let drawdownArray = []
    let dayOpen = { "pdhProfit": 0, "pdhProfitCnt": 0, "pdhLoss": 0, "pdhLossCnt": 0, "pdlProfit": 0, "pdlLoss": 0, "pdlProfitCnt": 0, "pdlLossCnt": 0 }
    let gap = { "upProfit": 0, "upProfitCnt": 0, "upLoss": 0, "upLossCnt": 0, "downProfit": 0, "downProfitCnt": 0, "downLoss": 0, "downLossCnt": 0 }
    let vix = new Array(4).fill().map(() => ({ profit: 0, cnt: 0 }));
    for (var i = 0; i < csv.length; i++) {
        if(!csv[i]?.date){
          continue;
        }
        // Method to store the data
        let error = 0;
        // Applying Filter on the basis of vix , range of start date and end date
        if (csv[i]['startingVixPrice'] < vixStart || csv[i]['startingVixPrice'] > vixExit || csv[i]['startingVixExit'] < vixStart || csv[i]['startingVixExit'] > vixExit)
            continue;
        if (csv[i]['date'] < resultStartDate || csv[i]['date'] > resultEndDate)
            continue;
        // Applying the filter on the basis of GapUpDown and PrevDayHL
        if(!resultFilterMethod(resultFilter,csv[i])){
            continue;
        }
        // logic of trade only first entry
        let index = -1;
        let tradeOnlyFirstReEntryData = null;
        if (inputData.waitTrade && tradeOnlyFirstEntry) {
            let temp = tradeOnlyFirstEntryMethod(csv[i], inputData?.leg);
            index = temp.index;
            tradeOnlyFirstReEntryData = temp.data;
        }
        // Formula to Calculate Sum of Profit at a particular Day
        let sum = 0;
        let flag = 0; // It indicate supremacy of strategy profit or stoploss
        for (let key = 0; key < csv[i]['result']?.length; key++) {
            for (let j = 0; j < csv[i]['result'][key]?.length; j++) {
                let current_sum = 0;
                if (tradeOnlyFirstEntry && index !== -1 && index != key)
                    continue;
                if (csv[i]['result'][key][j]['startPrice'] == 99999999999 || csv[i]['result'][key][j]['exitPrice'] == 99999999999) {
                    continue;
                }
                else if (legarray[key][0] == 'S') {
                    if (csv[i]['reason'] == 'strategy stoploss hit!') {
                        sum = -1 * parseFloat(inputData['stoploss']);
                        flag = 1;
                    } else if (csv[i]['reason'] == 'strategy maxprofit hit!') {
                        sum = parseFloat(inputData['maxprofit']);
                        flag = 1;
                    } else if (csv[i]['reason'] == 'Lock and Trail Profit Hit!' || csv[i]['reason'] == 'Trail Hit!' || csv[i]['reason'] == 'Lock Minimum Profit Hit!') {
                        sum = parseFloat(csv[i]['totalProfit'])
                    }
                    else if (flag == 0 && csv[i]['result'][key][j]['startPrice'] != '99999999999' && csv[i]['result'][key][j]['exitPrice'] != '99999999999') {
                        sum = parseFloat(sum) + parseFloat((parseFloat(parseFloat(csv[i]['result'][key][j]['startPrice']) - slippage * parseFloat(csv[i]['result'][key][j]['startPrice']) * 0.01) - (parseFloat(parseFloat(csv[i]['result'][key][j]['exitPrice']) + slippage * parseFloat(csv[i]['result'][key][j]['exitPrice']) * 0.01))) * inputData?.lotarray1[key])
                    }
                    current_sum = parseFloat((parseFloat(parseFloat(csv[i]['result'][key][j]['startPrice']) - slippage * parseFloat(csv[i]['result'][key][j]['startPrice']) * 0.01) - (parseFloat(parseFloat(csv[i]['result'][key][j]['exitPrice']) + slippage * parseFloat(csv[i]['result'][key][j]['exitPrice']) * 0.01))) * inputData?.lotarray1[key])
                } else {
                    if (csv[i]['reason'] === 'strategy stoploss hit!') {
                        sum = -1 * parseFloat(inputData['stoploss']);
                        flag = 1;
                    } else if (csv[i]['reason'] === 'strategy maxprofit hit!') {
                        sum = parseFloat(inputData['maxprofit']);
                        flag = 1;
                    } else if (csv[i]['reason'] == 'Lock and Trail Profit Hit!' || csv[i]['reason'] == 'Trail Hit!' || csv[i]['reason'] == 'Lock Minimum Profit Hit!') {
                        sum = parseFloat(csv[i]['totalProfit'])
                    }
                    else if (flag == 0 && csv[i]['result'][key][j]['startPrice'] != '99999999999' && csv[i]['result'][key][j]['exitPrice'] != '99999999999') {
                        sum = parseFloat(sum) + parseFloat((parseFloat(parseFloat(csv[i]['result'][key][j]['exitPrice']) - slippage * parseFloat(csv[i]['result'][key][j]['exitPrice']) * 0.01) - parseFloat(parseFloat(csv[i]['result'][key][j]['startPrice']) + slippage * parseFloat(csv[i]['result'][key][j]['startPrice']) * 0.01)) * inputData?.lotarray1[key])
                    }
                    current_sum = parseFloat((parseFloat(parseFloat(csv[i]['result'][key][j]['exitPrice']) - slippage * parseFloat(csv[i]['result'][key][j]['exitPrice']) * 0.01) - parseFloat(parseFloat(csv[i]['result'][key][j]['startPrice']) + slippage * parseFloat(csv[i]['result'][key][j]['startPrice']) * 0.01)) * inputData?.lotarray1[key])
                }

                individualLeg[key].profit += parseFloat(current_sum);
                individualLeg[key].count += 1;
            }
        }

        // Hide the NA item
        for (let k = 0; k < inputData.leg; k++) {
            for (let j = 0; j < csv[i]['result'][k].length; j++) {
                if (parseFloat(csv[i]['result'][k][j]['startPrice']) == 99999999999 || parseFloat(csv[i]['result'][k][j]['exitPrice']) == 99999999999) {
                    error += 1;
                }
            }
        }
        if (appearNA && error > 0)
            continue;
        net_profit[cnt] = parseFloat(sum);
        if (tradeOnlyFirstEntry) {
            data[cnt] = tradeOnlyFirstReEntryData;
        } else {
            data[cnt] = csv[i];
        }
        data[cnt]['profit'] = parseFloat(sum)
        // trade contain or not
        if (includeTrade.hasOwnProperty(csv[i]['date'])) {
            cnt++;
            continue;
        }

        // Method to Sum of Profit DayWise
        if (dayoption[0] === 1 && csv[i]['day'] === 'Monday') {
            week1[0] += parseFloat(sum);
            if (weekResult.hasOwnProperty(csv[i]['date'][0] + csv[i]['date'][1] + csv[i]['date'][2] + csv[i]['date'][3])) {
                weekResult[csv[i]['date'][0] + csv[i]['date'][1] + csv[i]['date'][2] + csv[i]['date'][3]][0] += parseFloat(sum);
            } else {
                weekResult[csv[i]['date'][0] + csv[i]['date'][1] + csv[i]['date'][2] + csv[i]['date'][3]] = new Array(5).fill(0);
                weekResult[csv[i]['date'][0] + csv[i]['date'][1] + csv[i]['date'][2] + csv[i]['date'][3]][0] += parseFloat(sum);
            }
        }
        else if (dayoption[1] === 1 && csv[i]['day'] === 'Tuesday') {
            week1[1] += parseFloat(sum);
            if (weekResult.hasOwnProperty(csv[i]['date'][0] + csv[i]['date'][1] + csv[i]['date'][2] + csv[i]['date'][3])) {
                weekResult[csv[i]['date'][0] + csv[i]['date'][1] + csv[i]['date'][2] + csv[i]['date'][3]][1] += parseFloat(sum);
            } else {
                weekResult[csv[i]['date'][0] + csv[i]['date'][1] + csv[i]['date'][2] + csv[i]['date'][3]] = new Array(5).fill(0);
                weekResult[csv[i]['date'][0] + csv[i]['date'][1] + csv[i]['date'][2] + csv[i]['date'][3]][1] += parseFloat(sum);
            }
        }
        else if (dayoption[2] === 1 && csv[i]['day'] === 'Wednesday') {
            week1[2] += parseFloat(sum);
            if (weekResult.hasOwnProperty(csv[i]['date'][0] + csv[i]['date'][1] + csv[i]['date'][2] + csv[i]['date'][3])) {
                weekResult[csv[i]['date'][0] + csv[i]['date'][1] + csv[i]['date'][2] + csv[i]['date'][3]][2] += parseFloat(sum);
            } else {
                weekResult[csv[i]['date'][0] + csv[i]['date'][1] + csv[i]['date'][2] + csv[i]['date'][3]] = new Array(5).fill(0);
                weekResult[csv[i]['date'][0] + csv[i]['date'][1] + csv[i]['date'][2] + csv[i]['date'][3]][2] += (sum);
            }
        }
        else if (dayoption[3] === 1 && csv[i]['day'] === 'Thursday') {
            week1[3] += parseFloat(sum);
            if (weekResult.hasOwnProperty(csv[i]['date'][0] + csv[i]['date'][1] + csv[i]['date'][2] + csv[i]['date'][3])) {
                weekResult[csv[i]['date'][0] + csv[i]['date'][1] + csv[i]['date'][2] + csv[i]['date'][3]][3] += parseFloat(sum);
            } else {
                weekResult[csv[i]['date'][0] + csv[i]['date'][1] + csv[i]['date'][2] + csv[i]['date'][3]] = new Array(5).fill(0);
                weekResult[csv[i]['date'][0] + csv[i]['date'][1] + csv[i]['date'][2] + csv[i]['date'][3]][3] += parseFloat(sum);
            }
        }
        else if (dayoption[4] === 1 && csv[i]['day'] === 'Friday') {
            week1[4] += parseFloat(sum);
            if (weekResult.hasOwnProperty(csv[i]['date'][0] + csv[i]['date'][1] + csv[i]['date'][2] + csv[i]['date'][3])) {
                weekResult[csv[i]['date'][0] + csv[i]['date'][1] + csv[i]['date'][2] + csv[i]['date'][3]][4] += parseFloat(sum);
            } else {
                weekResult[csv[i]['date'][0] + csv[i]['date'][1] + csv[i]['date'][2] + csv[i]['date'][3]] = new Array(5).fill(0);
                weekResult[csv[i]['date'][0] + csv[i]['date'][1] + csv[i]['date'][2] + csv[i]['date'][3]][4] += parseFloat(sum);
            }
        }
        else
            continue;

        // Method to find the MontlyWise Profit
        if (month1.hasOwnProperty(csv[i]['date'][0] + csv[i]['date'][1] + csv[i]['date'][2] + csv[i]['date'][3])) {
            month1[csv[i]['date'][0] + csv[i]['date'][1] + csv[i]['date'][2] + csv[i]['date'][3]][parseInt(csv[i]['date'][5] + csv[i]['date'][6])] += parseFloat(sum)
        } else {
            month1[csv[i]['date'][0] + csv[i]['date'][1] + csv[i]['date'][2] + csv[i]['date'][3]] = new Array(13).fill(0);
            month1[csv[i]['date'][0] + csv[i]['date'][1] + csv[i]['date'][2] + csv[i]['date'][3]][parseInt(csv[i]['date'][5] + csv[i]['date'][6])] += parseFloat(sum);
        }


        // Method to calculate total profit
        total_profit = week1[0] + week1[1] + week1[2] + week1[3] + week1[4];

        // Method to find the Cumulative Data
        cumulative[cnt] = previous + sum;
        previous = cumulative[cnt];

        // Method to find the Maximum Drawdown
        // Maximum Drawdown is the biggest loss observed in an investment from peak to trough
        // Formula of Drawdown:: MIN((B2-MAX($B$2-B2))/MAX($B$2-B2)
        if (cumulative[cnt] > maxProfit) {
            // console.log(maxProfit,maxLoss,cumulative[cnt],drawDownDate,recoveryStartDate,csv[i]['date'],'tradeOnllllll')
            let dayCount = NoOfDaysBetweenTwoDate(drawDownDate, csv[i]['date'])
            if (ans.count < dayCount) {
                ans.count = dayCount;
                ans.startDate = formatDate(drawDownDate);
                ans.endDate = formatDate(csv[i]['date']);
                ans.recovery = NoOfDaysBetweenTwoDate(recoveryStartDate, csv[i]['date'],);
                ans.continue = false;
                ans.status = '';
                ans.recoveryDate = recoveryStartDate;
            }

            maxProfit = cumulative[cnt]; // Update maxPrice if a new peak is reached
            maxLoss = maxProfit;
            drawDownDate = csv[i]['date'];
            recoveryStartDate = '';
        }

        if (cumulative[cnt] < maxLoss) {
            recoveryStartDate = csv[i]['date'];
            maxLoss = cumulative[cnt];
        }



        const currentDrawdown = (cumulative[cnt] - maxProfit);
        drawdownArray.push(currentDrawdown);

        if (currentDrawdown < MaximumDrawdown) {
            MaximumDrawdown = currentDrawdown; // Update drawdown if a new minimum drawdown is reached
        }

        result[cnt] = MaximumDrawdown;

        // monthlyDrawdown
        if (monthlyDrawdown.hasOwnProperty(csv[i]['date'][0] + csv[i]['date'][1] + csv[i]['date'][2] + csv[i]['date'][3])) {
            monthlyDrawdown[csv[i]['date'][0] + csv[i]['date'][1] + csv[i]['date'][2] + csv[i]['date'][3]] = MaximumDrawdown
        } else {
            monthlyDrawdown[csv[i]['date'][0] + csv[i]['date'][1] + csv[i]['date'][2] + csv[i]['date'][3]] = new Array(13).fill(0);
            monthlyDrawdown[csv[i]['date'][0] + csv[i]['date'][1] + csv[i]['date'][2] + csv[i]['date'][3]] = MaximumDrawdown;
        }

        // Method to find all date
        barChartDateArray[cnt] = csv[i]['date']

        // Method to find the Total Premium Amount
        premiumAmount += sum * premiumPrice;

        // Method to find the Expectancy
        // Formula to Calculate Expectancy
        // Expectancy=(WinRate × AverageWin)−(LossRate × AverageLoss)
        if (net_profit[cnt] >= 0) {
            profitQuantity += 1;
            totalProfit += sum;
            // Calculate streak
            if (prev > 0) {
                count += 1
                prev = 1;  // it denotes positive series
            } else {
                lossStreak = count > lossStreak ? count : lossStreak;
                if (count <= 3) {
                    lossStreakDict[count - 1] += 1;
                }
                count = 1;
                prev = 1;
            }
        } else {
            lossQuantity += 1;
            totalLoss += sum;
            if (prev < 0) {
                count += 1;
                prev = -1;
            } else {
                maxStreak = count > maxStreak ? count : maxStreak;
                if (count <= 4) {
                    winStreakDict[count - 1] += 1;
                }
                count = 1;
                prev = -1;
            }
        }

        // Method to find the winning trades and lossing trades
        profitArray[cnt] = sum
        if (sum > 0) {
            winningtrades += 1;
        }
        else {
            losingtrades += 1;
        }
        if (sum > maxprofit) {
            maxprofit = sum;
        }
        if (sum < maxloss) {
            maxloss = sum;
        }

        // Count Number of InvestmentDay
        investmentDay += 1
        // Expiry
        let expirey_date = Next_Thursday(csv[i]['date']);
        expirySet.add(expirey_date);

        // Day open profit
        if (csv[i]['marketOpen'] == 'High') {
            if (sum > 0) { dayOpen.pdhProfit += sum; dayOpen.pdhProfitCnt += 1; }
            else { dayOpen.pdhLoss += sum; dayOpen.pdhLossCnt += 1 }
        }
        else if (csv[i]['marketOpen'] == 'Low') {
            if (sum > 0) { dayOpen.pdlProfit += sum; dayOpen.pdlProfitCnt += 1; }
            else { dayOpen.pdlLoss += sum; dayOpen.pdlLossCnt += 1 }
        }

        // gap up profit
        if ((csv[i]['marketEntryPrice']?.Open - csv[i]['marketClosedPricePreviousDay']?.Close) > 0) {
            if (sum >= 0) { gap.upProfit += sum; gap.upProfitCnt += 1; }
            else { gap.upLoss += sum; gap.upLossCnt += 1; }
        }
        else {
            if (sum >= 0) { gap.downProfit += sum; gap.downProfitCnt += 1; }
            else { gap.downProfit += sum; gap.downLossCnt += 1; }
        }

        // vix profit
        if (csv[i]['startingVixPrice'] <= 10) {
            vix[0].profit += sum; vix[0].cnt += 1;
        } else if (csv[i]['startingVixPrice'] <= 15) { vix[1].profit += sum; vix[1].cnt += 1; }
        else if (csv[i]['startingVixPrice'] <= 20) { vix[2].profit += sum; vix[2].cnt += 1; }
        else { vix[3].profit += sum; vix[3].cnt += 1; }

        cnt++;
    }
    if (prev < 0) {
        if (count <= 3) { lossStreakDict[count - 1] += 1; }
    } else {
        if (count <= 5) { winStreakDict[count - 1] += 1; }
    }
    // daywise profitabiliry
    weekResult['Total'] = totalWeekProfit(weekResult);
    // monthwise profitability
    month1 = totalMonthProfit(month1, monthlyDrawdown, MaximumDrawdown,margin);
    // Calculate Expectancy
    let Expectancy = 0;
    totalLoss = -1 * totalLoss;
    if (totalLoss == 0) {
        Expectancy = 'Infinity';
    }
    else if (totalProfit == 0) {
        Expectancy = 0;
    }
    else {
        Expectancy = ((profitQuantity * totalProfit) / (totalLoss * cnt)) - lossQuantity / cnt;
    }
    // check again at every point
    let current_cnt = daysFromToday(drawDownDate);
    if (ans.count == 0 || ans.count <= current_cnt) {
        ans.count = daysFromToday(drawDownDate);
        ans.startDate = formatDate(drawDownDate);
        ans.endDate = 'Running';
        ans.recovery = daysFromToday(recoveryStartDate);
        ans.continue = true;
        ans.status = 'Running'
    }
    data.splice(cnt, csv.length)

    return {
        "stats": {
            "margin": margin, "profit": parseFloat(total_profit).toFixed(2), "maximumDrawdown": parseFloat(MaximumDrawdown).toFixed(2), "maxProfit": parseFloat(maxprofit).toFixed(2),
            "maxLoss": parseFloat(maxloss).toFixed(2), "winTrade": winningtrades, "lossTrade": losingtrades, "avgProfit": profitQuantity != 0 ? (totalProfit / profitQuantity).toFixed(2) : 0,
            "avgLoss": lossQuantity != 0 ? (totalLoss / lossQuantity).toFixed(2) : 0, "investmentDay": investmentDay, "maxStreak": maxStreak, "lossStreak": lossStreak, "gap": gap, "vix": vix, "dayOpen": dayOpen, "mdd": ans
        },
        'week': weekResult, 'month': month1, 'profit': net_profit, 'drawdown': result, 'cumulative': cumulative, "result": data, "tradingDate": barChartDateArray, "individualLeg": individualLeg
    };
}
