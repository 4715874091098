// Store Result in Day Wise
export function handleDays(data) {
    let mon = [], tue = [], wed = [], thu = [], fri = [];
    data.map((item, key) => {
        if (item[3] === 'Monday') mon.push(item);
        else if (item[3] === 'Tuesday') tue.push(item)
        else if (item[3] === 'Wednesday') wed.push(item)
        else if (item[3] === 'Thursday') thu.push(item)
        else fri.push(item)
    })
    mon.sort(); tue.sort(); wed.sort(); thu.sort(); fri.sort();
    let result = [...mon, ...tue, ...wed, ...thu, ...fri]
    return result;
}

// Sorting the Data on the basis of Index
export const sorting = (index,data) => {
    const sortedData = [...data].sort((a, b) => {
        const itemA = a[index];
        const itemB = b[index];

        if (typeof itemA === 'string' && typeof itemB === 'string') {
            return itemA.localeCompare(itemB);
        } else if (typeof itemA === 'number' && typeof itemB === 'number') {
            return itemA - itemB;
        } else {
            return 0; // No comparison possible, keep the order unchanged
        }
    });
    // setData(sortedData);
    return sortedData;
};

 // Method to Find the Next Thursday
export const Next_Thursday = (given_date) => {
    const givenDate = new Date(given_date);
    let nextThursday = new Date(givenDate)
    const currentWeekDay = nextThursday.getDay();
    const daysUntilNextThursday = (4 - currentWeekDay + 7) % 7;
    nextThursday.setDate(nextThursday.getDate() + daysUntilNextThursday);
    const nextThursdayDay = nextThursday.getDate();
    const nextThursdayMonth = nextThursday.getMonth() + 1;
    const nextThursdayYear = nextThursday.getFullYear();
    const formattedDate = `${nextThursdayMonth}/${nextThursdayDay}/${nextThursdayYear}`;
    return formattedDate;
}

export const totalMonthProfit = (monthlyResult,monthlyDrawdown,maximumDrawdown,margin) => {
    let total = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    let month_profit = {}
    let total_profit = 0
    let sum = 0;
    Object.keys(monthlyResult).forEach(key => {
        let value = monthlyResult[key];
        for (let i = 0; i <= 12; i++) {
            total[i] += parseFloat(value[i]);
            for (let i = 1; i <= 12; i++) {
                sum += parseFloat(value[i]);
            }
            month_profit[key] = sum;
            
            sum = 0;
        }
        total_profit+=parseFloat(month_profit[key]);
    });
    
    Object.keys(monthlyResult).forEach(key => {
        monthlyResult[key].push(month_profit[key]);
        monthlyResult[key].push(monthlyDrawdown[key]);
        monthlyResult[key].push(parseFloat((month_profit[key]/margin)*100).toFixed(0));
    })
    monthlyResult['Total']=total;
    monthlyResult['Total'].push(total_profit);
    monthlyResult['Total'].push(maximumDrawdown);
    monthlyResult['Total'].push(parseFloat((total_profit/margin)*100).toFixed(0));
    return monthlyResult;
}


export const totalWeekProfit = (weekResult) => {
    let total = [0, 0, 0, 0, 0, 0]
    let week_profit = {}
    let sum = 0;
    Object.keys(weekResult).forEach(key => {
        let value = weekResult[key];
        for (let i = 0; i <= 5; i++) {
            total[i] += parseFloat(value[i]);
            for (let i = 1; i <= 5; i++) {
                sum += parseFloat(value[i]);
            }
            week_profit[key] = sum;
            sum = 0;
        }
    });
    return total;
}
