import React, { useState, useEffect } from 'react'
import Main from './Main'
import WeekProfit from './Week'
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import GraphResult from '../../Result/GraphResult'
import FinalExcel from '../Excel/Excel';
import useBucketStore from '../../../store/BucketStore'
import LegProfit from './LegProfit';
import CombinedResult from './CombinedResult';
import Month from './Month';
import Filter from './Filter';
import CombinedStats from './CombinedStats';
import IndividualLeg from './IndividualLeg';
import { MdOutlineRestartAlt } from 'react-icons/md';
// all the helper function
import { result } from './Utility/NormalResult';
import { combinedStatsResult } from './Utility/CombinedStatsResult';
import useBucketResultStore from '../../../store/bucketResultStore';
import { handleNumber } from '../Util';
import CombinedMain from './CombinedMain';

const BucketResult = (props) => {
    const statisticalItem = ['Margin', 'Total Profit', 'Maximum Drawdown', 'Max Profit', 'Max Loss', 'Winning Trades', 'Losing Trades', 'Avg Profit', 'Avg Loss', 'Investment Day', 'Monthly Return%', 'Expectancy', 'Max Streak', 'Loss Streak']
    const { bucketStartDate, bucketEndDate } = useBucketStore();
    const startdate = bucketStartDate;   //It contain startDate of bucket
    const enddate = bucketEndDate;       //It contain endDate of bucket
    const bucketResult = props.result;           //It contain bucketResult

    // to store the result 
    const [margin, setMargin] = useState([]);          //It contain margin of all strategy
    const [fullResult, setFullResult] = useState([]);  //It contain excel result
    const { fullMonth, updateFullMonth } = useBucketStore() //It contain Monthly profit of all strategy
    const { originalResult, updateOriginalResult } = useBucketStore() //It contain original result of all strategy
    const { fullWeek, updateFullWeek } = useBucketStore()//It contain weekly profit of all strategy
    const [finalProfitArray, setFinalProfitArray] = useState([]);  // it contain profit of all strategy
    const [finalCumulativeArray, setFinalCumulativeArray] = useState([]);  //It contain cumulative result of all strategy
    const [finalDrawdownArray, setFinalDrawdownArray] = useState([]);      //It contain drawdown that will help into graph
    // to store reEntry Result
    const [premiumPrice, setPremiumPrice] = useState(0.5)  //For calculation the premium amount
    // To Store Statistical Result
    const [StatisticalResult, setStatisticalResult] = useState([])   //It store statistical result
    // combine Result
    const { activeBasket, strategyMultiplier, strategyResultInclude } = useBucketStore();
    const { resultFilter } = useBucketResultStore()
    const [showVisibility, setShowVisibility] = useState(false);
    const [activeStrategyResult, setActiveStrategyResult] = useState(-1);
    const [processCombineResult1, setProcessCombineResult] = useState({})

    // show vix result
    const [vixStart, setVixStart] = useState(0)
    const [vixExit, setVixExit] = useState(100)
    const [restartAgain, setRestartAgain] = useState(0)
    // slippage
    const [slippageValue, setSlippageValue] = useState(0)
    const { slippage, updateSlippage } = useBucketStore();
    // Filteration Process
    const [resultStartDate, setResultStartDate] = useState("")
    const [resultEndDate, setResultEndDate] = useState("")
    // combined Margin
    const [combineMargin, setCombineMargin] = useState(0)
    // trading date
    const [tradingDate, setTradingDate] = useState([])
    // individual leg contribution
    const [individualLegContribution, setIndividualLegContribution] = useState([])
    // for handle dayOption --Not used proper
    const { dayOption, updateDayOption } = useBucketStore();

    // combine profit
    const [combineResult, setCombinedResult] = useState([]);
    const [combineProfit, setCombineProfit] = useState([]);
    const [combineStats, setCombineStats] = useState([]);

    // all the function that contain by this component
    // a)  Bucket_Result() --> Find the result of each strategy using result()
    // b)  Excel Result() --> Store the result in form excel using bucket result.
    // c)  FinalReEntryResult() --> Store the reEntryResult of bucket using reEntry_result().
    // d)  FinalExcelReEntryResult() --> Store the excel result using excelReEntryResult();
    // e)  CombineResult(),CombineTrading and CombineStats that is used for combine Result
    // f)  handleDownload is used for download the pdf of result.
    // g)  calculateCorrelationMatrix() that uses calculateCorrelation() 


    // There is a method to download a pdf
    const handleDownload = () => {
        const pdfElement = document.getElementById("screenshot");
        html2canvas(pdfElement).then((canvas) => {
            const pdf = new jsPDF();
            pdf.addImage(canvas.toDataURL("image/png"), "PNG", 0, 0,
                pdf.internal.pageSize.getWidth(),
                pdf.internal.pageSize.getHeight()
            );
            props.strategyName ? pdf.save(`${props.strategyName}_fprognos.pdf`) : pdf.save(`backtest_fprognos.pdf`);
        });
    };

    // Method to find the bucket result
    const BucketResult = async () => {
        const TempResult = []
        const week = []
        const month = []
        const cumulative = []
        const drawdown = []
        const profitArray = []
        const original_result = []
        const margin = []
        const date = []
        const legContribution = []
        for (let i = 0; i < bucketResult.length; i++) {
            if (props.executedStrategy[i] == 2) {
                const csv = JSON.parse(bucketResult[i].result);
                console.log(csv, 'csv at bucket result');
                const legarray = bucketResult[i].legarray;   //It contain buycall,buyput,sellcall and sellput of particular strategy
                const lotarray = bucketResult[i].lotarray;  //It contains the lot size of each leg of the ith strategy.
                // console.log(lotarray,props.inputData,'lot array and input data')
                const atm = bucketResult[i].atm;
                const legs = bucketResult[i].leg;           //It contain how many leg present ith strategy
                const dayoption = dayOption[i];       //It contain include mon,tue,wed,thu,fri or not in particular strategy
                const tempMargin = bucketResult[i].margin;  //It contain margin of each strategy
                const slippageValue = props.slippage[i];
                // console.log(bucketResult,'executed strategy')
                // For handle selected strategies
                // console.log(csv, legarray, lotarray, atm, legs)
                const resp = result(csv, legarray, lotarray, atm, legs, dayoption, tempMargin, slippageValue, props.inputData[i], vixStart, vixExit, resultStartDate, resultEndDate, premiumPrice, startdate, enddate, resultFilter[i]);
                console.log(resp, 'data')
                TempResult.push(resp.stats);
                week.push(resp.week);
                month.push(resp.month);
                cumulative.push(resp.cumulative);
                drawdown.push(resp.drawdown);
                profitArray.push(resp.profit);
                date.push(resp.tradingDate);
                legContribution.push(resp.individualLeg);

                // console.log(resp.profit,'resp profit')
                // console.log(props.result[i].margin,'margin total profit')
                margin.push(tempMargin);
                original_result.push({ "result": resp.result, "tradeResult": resp.tradeData, 'legarray': legarray, 'legs': parseInt(legs), 'atm': atm, 'lotarray': lotarray, 'date': resp.tradingDate });
            }

        }
        // console.log(week,month,original_result,'final result')
        setStatisticalResult(TempResult);
        updateFullWeek(week);
        updateFullMonth(month);
        setFinalCumulativeArray(cumulative);
        setFinalDrawdownArray(drawdown);
        setFinalProfitArray(profitArray);
        updateOriginalResult(original_result);
        console.log(JSON.stringify(originalResult), 'original Result')
        setTradingDate(date);
        setMargin(margin);
        setIndividualLegContribution(legContribution);

        // process combine result
        processCombineResult();
        setShowVisibility(true);
    }


    const processCombineResult = () => {
        let result = {};
        let totalMargin = 0
        for (let i = 0; i < bucketResult.length; i++) {
            if (props.executedStrategy[i] == 2) {
                const csv = originalResult[i]?.result;
                totalMargin += margin[i];
                // console.log(totalMargin,'statistical margin')

                for (let j = 0; j < csv?.length; j++) {
                    let sum = 0;
                    if ((dayOption[i][0] === 1 && csv[j]['day'] === 'Monday') || (dayOption[i][1] === 1 && csv[j]['day'] === 'Tuesday') || (dayOption[i][2] === 1 && csv[j]['day'] === 'Wednesday') ||
                        (dayOption[i][3] === 1 && csv[j]['day'] === 'Thursday') || (dayOption[i][4] === 1 && csv[j]['day'] === 'Friday')) {
                        sum = csv[j]['profit']?csv[j]['profit']:0.00;
                    }


                    if (result.hasOwnProperty(csv[j]['date'])) {
                        result[csv[j]['date']][i + 1] = sum ? parseFloat(sum).toFixed(2) : '0.00'
                    } else {
                        result[csv[j]['date']] = new Array(bucketResult.length + 1).fill(0)
                        result[csv[j]['date']][0] = csv[j]['day']
                        result[csv[j]['date']][i + 1] = sum ? parseFloat(sum).toFixed(2) : '0.00'
                    }
                }
            }
        }

        // result------------
        let res = []
        let combinedProfit = []
        Object.entries(result).map(([key, value]) => {
            let sum = 0;
            for (let i = 1; i <= value.length; i++) {

                if (strategyResultInclude[i - 1]) {
                    const multiplier = strategyMultiplier[i - 1] === '' ? 1 : parseInt(strategyMultiplier[i - 1]);
                    const valueNumber = parseFloat(value[i]);

                    if (!isNaN(valueNumber)) {
                        sum += multiplier * valueNumber;
                    }
                }
            }
            let day = value[0].slice(0, 3);
            value.splice(0, 1);
            res.push([key, day, parseFloat(sum).toFixed(2), ...value]);
            combinedProfit.push(sum);
        })
        let temp = combinedStatsResult(combinedProfit, res, totalMargin, startdate, enddate, premiumPrice);

        // combine vix,dayopen->pdh | pdl ,gap
        let dayOpen = { "pdhProfit": 0, "pdhProfitCnt": 0, "pdhLoss": 0, "pdhLossCnt": 0, "pdlProfit": 0, "pdlLoss": 0, "pdlProfitCnt": 0, "pdlLossCnt": 0 }
        let gap = { "upProfit": 0, "upProfitCnt": 0, "upLoss": 0, "upLossCnt": 0, "downProfit": 0, "downProfitCnt": 0, "downLoss": 0, "downLossCnt": 0 }
        let vix = new Array(3).fill().map(() => ({ profit: 0, cnt: 0 }));
        for (let i = 0; i < bucketResult.length; i++) {

            dayOpen.pdhProfit += StatisticalResult[i]?.dayOpen?.pdhProfit;
            dayOpen.pdhProfitCnt += StatisticalResult[i]?.dayOpen?.pdhProfitCnt;
            dayOpen.pdhLoss += StatisticalResult[i]?.dayOpen?.pdhLoss;
            dayOpen.pdhLossCnt += StatisticalResult[i]?.dayOpen?.pdhLossCnt;

            dayOpen.pdlLoss += StatisticalResult[i]?.dayOpen?.pdlLoss;
            dayOpen.pdlLossCnt += StatisticalResult[i]?.dayOpen?.pdlLossCnt;
            dayOpen.pdlProfit += StatisticalResult[i]?.dayOpen?.pdlProfit;
            dayOpen.pdlProfitCnt += StatisticalResult[i]?.dayOpen?.pdlProfitCnt;

            gap.upProfit += StatisticalResult[i]?.gap.upProfit;
            gap.upProfitCnt += StatisticalResult[i]?.gap.upProfitCnt;
            gap.upLoss += StatisticalResult[i]?.gap?.upLoss;
            gap.upLossCnt += StatisticalResult[i]?.gap?.upLossCnt;

            gap.downProfit += StatisticalResult[i]?.gap?.downProfit;
            gap.downProfitCnt += StatisticalResult[i]?.gap?.downProfitCnt;
            gap.downLoss += StatisticalResult[i]?.gap?.downLoss;
            gap.downLossCnt += StatisticalResult[i]?.gap?.downLossCnt;

            vix[0].profit += StatisticalResult[i]?.vix[0]?.profit;
            vix[0].cnt += StatisticalResult[i]?.vix[0]?.cnt;
            vix[1].profit += StatisticalResult[i]?.vix[1]?.profit;
            vix[1].cnt += StatisticalResult[i]?.vix[1]?.cnt;
            vix[2].profit += StatisticalResult[i]?.vix[2]?.profit;
            vix[2].cnt += StatisticalResult[i]?.vix[2]?.cnt;
        }

        temp.stats.vix = vix;
        temp.stats.gap = gap;
        temp.stats.dayOpen = dayOpen;


        // console.log(temp,'stats result')
        setCombineProfit(combineProfit);
        setCombinedResult(res);
        setCombineStats(temp);
        setCombineMargin(totalMargin);
    }

    useEffect(() => {
        BucketResult();
    }, [JSON.stringify(fullResult), JSON.stringify(fullMonth), slippage, dayOption, activeBasket.strategy.length, JSON.stringify(bucketResult), resultStartDate, resultEndDate, props.resultFilter])

    useEffect(() => {
        processCombineResult();
    }, [finalProfitArray, strategyMultiplier, strategyResultInclude])

    useEffect(() => {
        setResultStartDate(startdate);
        setResultEndDate(enddate);
    }, [bucketResult])
    
    return (
        <>
            <div className='w-full'>

                <div id="screenshot" className='mb-10'>
                    <div className='flex flex-row items-start'>
                        {/* Date Input Section */}
                        <div className="flex flex-col gap-2 w-[240px] m-2">
                            <div className="flex justify-between items-center">
                                <label className="font-semibold text-gray-700" htmlFor="start-date">From</label>
                                <input
                                    type="date"
                                    id="start-date"
                                    value={resultStartDate}
                                    onChange={(e) => setResultStartDate(e.target.value)}
                                    className="border border-gray-300 rounded-md p-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                            </div>
                            <div className="flex justify-between items-center">
                                <label className="font-semibold text-gray-700" htmlFor="end-date">To</label>
                                <input
                                    type="date"
                                    id="end-date"
                                    value={resultEndDate}
                                    onChange={(e) => setResultEndDate(e.target.value)}
                                    className="border border-gray-300 rounded-md p-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                            </div>
                            <div className="grid grid-cols-2">
                                <div className="">vix (1-99)</div>
                                <div className="flex flex-row gap-1">
                                    <input type="text" value={vixStart} onChange={(e) => { setVixStart(handleNumber(e.target.value)) }} className="w-16 border border-gray-300 rounded-md p-1 text-center focus:outline-none focus:ring-2 focus:ring-blue-500" />
                                    <div className="">to</div>
                                    <input type="text" value={vixExit} onChange={(e) => { setVixExit(handleNumber(e.target.value)) }} className="w-16 border border-gray-300 rounded-md p-1 text-center focus:outline-none focus:ring-2 focus:ring-blue-500" />
                                    <div className="bg-blue-500 text-white w-[40px] pt-1 rounded-lg flex text-center justify-center"><span className="cursor-pointer text-white" onClick={() => { setRestartAgain(restartAgain + 1) }}><MdOutlineRestartAlt className='text-2xl font-semibold' /></span></div>
                                </div>
                            </div>
                        </div>

                        {/* Download Section */}
                        <div className='flex flex-grow justify-end items-start m-2 gap-2'>
                            <div className='w-[200px] flex flex-col gap-2 p-2'>
                                <button className="bg-blue-600 text-white rounded p-1 h-8 w-full" onClick={handleDownload}> Download PDF </button>
                                <FinalExcel
                                    activeBasket={props.activeBasket}
                                    week={fullWeek}
                                    month={fullMonth}
                                    StatisticalResult={StatisticalResult}
                                    name={props.name}
                                    combineMonth={combineStats?.month}
                                    combineStatsResult={combineStats?.stats}
                                    combineWeek={combineStats?.week}
                                    combineTradingResult={combineResult}
                                    inputData={props.inputData}
                                    result={originalResult}
                                />
                            </div>
                        </div>
                    </div>


                    {/* Combine Metrics */}
                    <CombinedMain inputData={props.inputData} StatisticalResult={StatisticalResult} statisticalItem={statisticalItem} executedStrategy={props.executedStrategy} combineStats={combineStats} />
                    {/* Combine Stats */}
                    <CombinedStats inputData={props.inputData} StatisticalResult={StatisticalResult} statisticalItem={statisticalItem} executedStrategy={props.executedStrategy} combineStats={combineStats} />
                    <div className='flex flex-col'>
                        <WeekProfit week={combineStats?.week} />
                        <Month month={combineStats?.month} totalProfit={combineStats?.stats && combineStats?.stats.length > 0 && combineStats?.stats[1]} margin={combineStats?.stats && combineStats?.stats.length > 0 && combineStats?.stats[0]} maximumDrawdown={combineStats?.stats && combineStats?.stats.length > 0 && combineStats?.stats[1]} />
                    </div>
                    <div className="m-1 mt-2 rounded-lg">
                        <div className='p-4'>
                            <div className='flex flex-row flex-wrap gap-4'>
                                <div
                                    className='bg-green-500 text-white border-2 border-blue-600 rounded-md flex items-center justify-center cursor-pointer w-[140px]'
                                    style={{ backgroundColor: activeStrategyResult === -1 ? '#60A5FA' : 'white', color: activeStrategyResult === -1 ? 'white' : 'black' }}
                                    onClick={() => setActiveStrategyResult('-1')}
                                >
                                    Combine
                                </div>
                                {StatisticalResult && StatisticalResult.map((item, index) => (
                                    <div
                                        className="bg-green-500 text-white border-2 border-blue-600 rounded-md flex items-center justify-center cursor-pointer w-[140px]"
                                        key={index}
                                        style={{ backgroundColor: activeStrategyResult === index ? '#60A5FA' : 'white', color: activeStrategyResult === index ? 'white' : 'black' }}
                                        onClick={() => { setActiveStrategyResult(index); setSlippageValue(slippage[index]) }}
                                    >
                                        {`##S-${index + 1}`}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="">
                            {activeStrategyResult == -1 && <CombinedResult combineResult={combineResult} />}
                            {activeStrategyResult != -1 && <div className='flex flex-col'>
                                <div className='flex flex-row flex-wrap mt-2 gap-2'>
                                    <div className='flex flex-col gap-2 bg-white border-4 rounded-lg border-blue-500 ml-2'>
                                        <div className="flex text-center justify-center text-xl font-semibold h-10 text-white bg-[#718EBF]">Metrices</div>

                                        <div className="flex flex-col m-2 rounded-lg bg-gray-100 p-2 w-[440px] gap-2">
                                            <div className="grid grid-cols-2 gap-[1px]">
                                                <div className="text-l mt-1 text-black text-left">Slippages</div>
                                                <div className="flex flex-row gap-1">
                                                    <input type="number" className="border-2 rounded text-l h-7 w-36 text-center text-black" value={slippageValue} onChange={(e) => { setSlippageValue(e.target.value); }}></input>
                                                    <div className="bg-blue-400 text-white w-12 rounded-lg w flex text-center justify-center hover:bg-blue-600"><span className="cursor-pointer text-white" onClick={() => { const inputData = [...slippage]; inputData[activeStrategyResult] = slippageValue; updateSlippage(inputData); }}><MdOutlineRestartAlt className='text-2xl font-semibold' /></span></div>
                                                </div>
                                            </div>
                                            <div className="grid grid-cols-2">
                                                <div className="">vix (1-99)</div>
                                                <div className="flex flex-row gap-1">
                                                    <input type="text" value={vixStart} onChange={(e) => { setVixStart(handleNumber(e.target.value)) }} className="w-20 text-center border-[1px] rounded-lg" />
                                                    <div className="">to</div>
                                                    <input type="text" value={vixExit} onChange={(e) => { setVixExit(handleNumber(e.target.value)) }} className="w-20 text-center border-[1px] rounded-lg" />
                                                    <div className="bg-blue-500 text-white w-12 rounded-lg w flex text-center justify-center"><span className="cursor-pointer text-white" onClick={() => { setRestartAgain(restartAgain + 1) }}><MdOutlineRestartAlt className='text-2xl font-semibold' /></span></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex flex-col bg-gray-100 m-2 rounded-xl p-2">
                                            {/* Download Result in the form of PDF or EXCEl. */}
                                            <div className='grid grid-cols-1 sm:grid-cols-2 gap-2 mt-1'>
                                                <button className="bg-blue-600 text-white rounded p-1 h-8" onClick={handleDownload}> Download Pdf </button>
                                                <FinalExcel activeBasket={props.activeBasket} week={fullWeek} month={fullMonth} StatisticalResult={StatisticalResult} name={props.name} combineMonth={processCombineResult1.month} combineStatsResult={processCombineResult1?.stats} combineWeek={processCombineResult1.week} combineTradingResult={props.combineResult} />
                                            </div>

                                            {/* result start date and result end date */}
                                            <div className="flex flex-row m-2 gap-2">
                                                <div className="flex flex-row rounded-2xl border-[1px]">
                                                    <div className="bg-blue-500 text-white w-10 text-center font-serif rounded-l-2xl">St</div>
                                                    <input type="date" className="outline-0 flex text-center justify-center rounded-r-lg" value={resultStartDate} onChange={(e) => { setResultStartDate(e.target.value) }} />
                                                </div>
                                                <div className="flex flex-row rounded-2xl border-[1px]">
                                                    <div className="bg-blue-500 text-white w-10 text-center font-serif rounded-l-2xl">En</div>
                                                    <input type="date" className="outline-0 flex text-center justify-center rounded-r-lg" value={resultEndDate} onChange={(e) => { setResultEndDate(e.target.value); }} />
                                                </div>
                                                <div className="bg-blue-400 text-white w-12 rounded-lg w flex text-center justify-center hover:bg-blue-600"><span className="cursor-pointer text-white" onClick={() => { setResultStartDate(props.inputData.startdate); setResultEndDate(props.inputData.enddate); }}><MdOutlineRestartAlt className='text-2xl font-semibold' /></span></div>
                                            </div>

                                            {/* Download Result in the form of PDF or EXCEl. */}
                                            <div className="grid grid-cols-5 gap-2 mt-1">
                                                {["Mon", "Tue", "Wed", "Thu", "Fri"].map((day, index) => (
                                                    <button key={index} className="bg-blue-300 text-black shadow-lg h-6 rounded" style={{ backgroundColor: dayOption[activeStrategyResult] && dayOption[activeStrategyResult][index] == 1 ? "rgb(147 197 253)" : "white" }}
                                                        onClick={() => {
                                                            const current_status = dayOption[activeStrategyResult][index];

                                                            // Create a deep copy of the `dayOption` array
                                                            const currentDayOption = [...dayOption];

                                                            // Create a shallow copy of the array at the specific `activeStrategyResult` index
                                                            const strategyDayOption = [...currentDayOption[activeStrategyResult]];

                                                            // Modify the specific `index` within the copied `strategyDayOption`
                                                            strategyDayOption[index] = 1 - current_status;

                                                            // Assign the modified `strategyDayOption` back to `currentDayOption`
                                                            currentDayOption[activeStrategyResult] = strategyDayOption;

                                                            // Update the state with the modified `currentDayOption`
                                                            updateDayOption(currentDayOption);

                                                        }}> {day} </button>))}
                                            </div>
                                        </div>
                                        <Filter resultFilter={resultFilter[activeStrategyResult]} index={activeStrategyResult} />
                                    </div>
                                    <div className="flex flex-grow">
                                        <Main res={StatisticalResult[activeStrategyResult]} margin={margin[activeStrategyResult]} />
                                    </div>
                                </div>
                                <WeekProfit week={fullWeek[activeStrategyResult]} month={fullMonth[activeStrategyResult]} margin={margin[activeStrategyResult]} maximumDrawdown={finalDrawdownArray[activeStrategyResult]} />
                                <Month week={fullWeek[activeStrategyResult]} totalProfit={StatisticalResult[activeStrategyResult][1]} month={fullMonth[activeStrategyResult]} margin={margin[activeStrategyResult]} maximumDrawdown={finalDrawdownArray[activeStrategyResult]} />
                                {showVisibility && <IndividualLeg individualLegContribution={individualLegContribution[activeStrategyResult]} totalProfit={StatisticalResult[activeStrategyResult]?.profit} />}
                                <GraphResult profit={finalProfitArray[activeStrategyResult]} cumulative={finalCumulativeArray[activeStrategyResult]} date={tradingDate[activeStrategyResult]} drawdown={finalDrawdownArray[activeStrategyResult]} StatisticalResult={StatisticalResult} />
                                {showVisibility && <LegProfit slippage={props.slippage[activeStrategyResult]} originalResult={originalResult[activeStrategyResult]} inputData={props.inputData[activeStrategyResult]} />}
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BucketResult