import { useEffect, useState } from 'react';
import axios from "axios";
import Navbar from "../Layout/Navbar";
import Footer from "../Layout/FooterVersion2";
import { toast } from 'react-toastify';
import { USER_ADMIN_LIST, UPDATE_USER_ACCOUNT, ADMIN_ADD_COIN_TO_USER_ACCOUNT } from './../../constant/constant'
import { FaArrowLeft } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa";
import Gold from './Gold';
import Margin from './Margin';
import { RxDashboard } from "react-icons/rx";
import { MdGradient } from "react-icons/md";
import { MdDataSaverOn } from "react-icons/md";
import secureLocalStorage from 'react-secure-storage';
import { MdClose } from "react-icons/md";
import { Dialog } from "@mui/material";
import { Audio } from 'react-loader-spinner'
import Contact from './Contact';
import Plan from './Plan';
import { useNavigate } from 'react-router-dom';
import SendEmail from './SendEmail';

const AdminPortal = () => {
  const [searchData, setSearchData] = useState("");
  const [itemNo, setItemNo] = useState(1);
  const [data, setData] = useState([])
  const [originalData, setOriginalData] = useState([]);
  const [choice, setChoice] = useState(1);
  const [confirmationAddCoin, setConfirmationAddCoin] = useState(false);
  const [coinQuantity, setCoinQuantity] = useState(0);
  const [updateCoinUser, setUpdateCoinUser] = useState(null)
  const [loader, setLoader] = useState(false);
  // page size and number box
  const [pageSize, setPageSize] = useState(25)
  const [NumberBox, setNumberBox] = useState([])
  const [activeColor, setActiveColor] = useState(0)
  const [indexNumber, setIndexNumber] = useState(0)
  
  const navigate = useNavigate();
  
  const addCoin = async () => {
    setLoader(true);
    // Get the token from secureLocalStorage
    let token = secureLocalStorage.getItem('backtest_token');

    // Remove the 'backtest_' prefix if it exists
    if (token && token.startsWith('backtest_')) {
      token = token.replace('backtest_', '');
    }else{
      navigate('/backtest/login');
    }
    const name = secureLocalStorage.getItem('backtest_name');
    const data = { "name": updateCoinUser?.name, "email": updateCoinUser?.email, "coin": coinQuantity }
    const config = {
      method: 'post',
      url: ADMIN_ADD_COIN_TO_USER_ACCOUNT,
      headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}`, },
      data: data
    };
    try {
      await axios(config);
      alert('Successfully Add the Coin')
      setUpdateCoinUser(null);
      setCoinQuantity(0);
      setConfirmationAddCoin(false);
      setLoader(false);
      window.location.reload();
      // console.log('list of original Data',originalData);
    } catch (error) {
      if (error?.response?.data?.detail) toast(error.response.data.detail);
      else toast('Try Again Later');
      setConfirmationAddCoin(false);
      setLoader(false);
    }
  }

  const max = (a, b) => {
    if (a > b) return a
    else return b
  }
  const min = (a, b) => {
    if (a < b) return a;
    else return b;
  }
  // Functionality for Search Item on the basis of Client or Script name
  function searchItem() {
    const filteredItems = originalData.filter(item => item.email === searchData);
    if (filteredItems.length == 0) {
      setData(originalData);
      setSearchData("");
    }
    else {
      setData(filteredItems);
      setSearchData("");
    }
  }
  const update_user_account = async (active, email) => {
    // Get the token from secureLocalStorage
    let token = secureLocalStorage.getItem('backtest_token');

    // Remove the 'backtest_' prefix if it exists
    if (token && token.startsWith('backtest_')) {
      token = token.replace('backtest_', '');
    }else{
      navigate('/backtest/login');
    }
    const data = { "active": active, "email": email }
    const config = {
      method: 'patch',
      url: UPDATE_USER_ACCOUNT,
      headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}`, },
      data: data
    };
    try {
      await axios(config);
      alert('Successfully Update the Account')
      window.location.reload();
      // console.log('list of original Data',originalData);
    } catch (error) {
      if (error?.response?.data?.detail) toast(error.response.data.detail);
      else toast('Try Again Later');
    }
  }
  const user_list = async () => {
    // Get the token from secureLocalStorage
    let token = secureLocalStorage.getItem('backtest_token');

    // Remove the 'backtest_' prefix if it exists
    if (token && token.startsWith('backtest_')) {
      token = token.replace('backtest_', '');
    }else{
      navigate('/backtest/login');
    }
    const config = {
      method: 'get',
      url: USER_ADMIN_LIST,
      headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}`, },
    };
    try {
      const response = await axios(config);
      const parsedData = response?.data
      const result = []
      for (let x = 0; x < parsedData.length; x++) {
        const validJSONString = parsedData[x].replace(/'/g, '"');
        const jsonObject = JSON.parse(validJSONString);
        // console.log(jsonObject, 'parsed data');
        if (jsonObject.active === 'true')
          jsonObject.active = true;
        else
          jsonObject.active = false;
        result.push(jsonObject);
      }
      setOriginalData(result);
      setData(result.slice(0, pageSize))
      setNumberBox(Array(parseInt(result.length / pageSize + 1)).fill(1))
    } catch (error) {
      if (error?.response?.data?.detail) toast(error?.response?.data?.detail);
      else toast('Try Again Later');
      console.log(error)
    }
  }

  useEffect(() => {
    user_list();
  }, [])

  const handleData = () => {
    setNumberBox(Array(parseInt(originalData.length / pageSize + 1)).fill(1))
    let data = originalData.slice(parseInt(indexNumber) * parseInt(pageSize), min(parseInt(originalData.length), (parseInt(indexNumber) + 1) * parseInt(pageSize)));
    setData(data);
  }
  useEffect(() => {
    handleData();
  }, [indexNumber, pageSize])

  return (
    <>
      <div className="bg-[#DAECF2] min-h-screen">
        <div className='fixed z-20 w-full'><Navbar /></div>
        {/* choice  */}
        <div className='flex flex-row flex-wrap text-center pt-24 justify-center gap-4 mb-1'>
          <div className='w-[160px] gap-2 border-[2px] p-2 rounded-md cursor-pointer' style={{ backgroundColor: choice == 1 ? '#60a5fa' : 'white' }} onClick={() => { setChoice(1); }}>
            <div className='flex flex-row text-xl gap-2' onClick={() => { setChoice(1); }}><RxDashboard className='text-xl font-semibold mt-1 gap-1' /> Backtest</div>
          </div>
          <div className='w-[160px] gap-2 border-[2px] p-2 rounded-md cursor-pointer' style={{ backgroundColor: choice == 3 ? '#60a5fa' : 'white' }} onClick={() => { setChoice(3); }}>
            <div className='flex flex-row text-xl gap-2'><MdGradient className='text-xl font-semibold mt-1 gap-1' /> Gold</div>
          </div>
          <div className='w-[160px] gap-2 border-[2px] p-2 rounded-md cursor-pointer' style={{ backgroundColor: choice == 4 ? '#60a5fa' : 'white' }} onClick={() => { setChoice(4); }}>
            <div className='flex flex-row text-xl gap-2'><MdDataSaverOn className='text-xl font-semibold mt-1 gap-1' /> Margin</div>
          </div>
          <div className='w-[160px] gap-2 border-[2px] p-2 rounded-md cursor-pointer' style={{ backgroundColor: choice == 5 ? '#60a5fa' : 'white' }} onClick={() => { setChoice(5); }}>
            <div className='flex flex-row text-xl gap-2'><MdGradient className='text-xl font-semibold mt-1 gap-1' /> Contact</div>
          </div>
          <div className='w-[160px] gap-2 border-[2px] p-2 rounded-md cursor-pointer' style={{ backgroundColor: choice == 6 ? '#60a5fa' : 'white' }} onClick={() => { setChoice(6); }}>
            <div className='flex flex-row text-xl gap-2'><MdDataSaverOn className='text-xl font-semibold mt-1 gap-1' /> Plan</div>
          </div>
          <div className='w-[160px] gap-2 border-[2px] p-2 rounded-md cursor-pointer' style={{ backgroundColor: choice == 7 ? '#60a5fa' : 'white' }} onClick={() => { setChoice(7); }}>
            <div className='flex flex-row text-xl gap-2'><MdDataSaverOn className='text-xl font-semibold mt-1 gap-1' /> Send&nbsp;Email</div>
          </div>
        </div>
        <div className="bg-blue-400 text-center justify-center ">
          <div className='text-white text-2xl'>
            Admin Portal
          </div>
          <div className="text-center justify-center pl-60 text-white text-sm">
            Powered by Damyant Fintech Technology
          </div>
        </div>
        {choice == 1 &&
          <div className='flex flex-col'>
            <div className='overflow-x-auto bg-white m-2 rounded-md'>
              <div className="rounded-lg overflow-x-auto font-serif overflow-auto p-2 min-w-[1300px] h-[820px]">
                <div className=" flex flex-row text-center border-b-2 text-l font-bold ">
                  <div className=" grid grid-cols-2" style={{ width: "100px" }}>No.</div>
                  <div className="text-[20px] font-[500]" style={{ width: "180px" }}>Username</div>
                  <div className="text-[20px] font-[500]" style={{ width: "300px" }}>Email</div>
                  <div className="text-[20px] font-[500]" style={{ width: "125px" }}>Active</div>
                  <div className="text-[20px] font-[500]" style={{ width: "125px" }}>Coin</div>
                  <div className="text-[20px] font-[500]" style={{ width: "180px" }}>SubscriptionId</div>
                  <div className="text-[20px] font-[500]" style={{ width: "150px" }}>Start Date</div>
                  <div className="text-[20px] font-[500]" style={{ width: "150px" }}>Expiry Date</div>
                  <div className="text-[20px] font-[500]" style={{ width: "180px" }}>Fees</div>
                  <div className="text-[20px] font-[500]" style={{ width: "180px" }} >Payment Status</div>
                  <div className="text-[20px] font-[500]" style={{ width: "180px" }} >Add Coin</div>
                </div>
                <div className="flex flex-col">
                  {
                    data.map((item, key) => {
                      return (
                        <div className=" flex flex-row text-center border-b-2" key={key}>
                          <div className=" grid grid-cols-2" style={{ width: "100px" }}>{itemNo + key}</div>
                          <div className="" style={{ width: "180px" }}>{item?.name}</div>
                          <div className=" col-span-2" style={{ width: "300px" }}>{item?.email}</div>
                          <div className="" style={{ width: "125px" }}>
                            <input type="checkbox" checked={item?.active} onChange={() => { update_user_account(!item?.active, item?.email); }} /></div>
                          <div className="" style={{ width: "125px" }}>{item?.coin}</div>
                          <div className=" text-green-400" style={{ width: "180px" }}>-----</div>
                          <div className=" text-green-400" style={{ width: "150px" }}>-----</div>
                          <div className=" text-green-400" style={{ width: "150px" }}>-----</div>
                          <div className=" text-green-400" style={{ width: "180px" }}>-----</div>
                          <div className=" text-green-400" style={{ width: "180px" }}>------</div>
                          <div className='text-green-400 flex flex-row justify-center text-center gap-[2px]' style={{ width: "180px" }}>
                            <div className='text-white rounded-lg bg-blue-500 w-20 m-[1px] cursor-pointer' onClick={() => { setUpdateCoinUser(item); setConfirmationAddCoin(true); }}>Add Coin</div>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2" >
              <div className="flex flex-row mt-5">
                <div className="font-[600] ml-5 text-[16px]">Page&nbsp;Size</div>
                <select className="ml-3 outline-0 h-6 rounded-md" value={pageSize} onChange={(e) => { setPageSize(e.target.value); }}>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={75}>75</option>
                  <option value={100}>100</option>
                </select>
              </div>
              <div className="grid ml-5 sm:justify-end  mb-10 mt-5 mr-10">
                <div className="flex flex-row gap-2">
                  <div className='rounded-md border-2 border-white h-[32px] w-[32px] bg-[#2D5BFF] text-white'><FaArrowLeft className='mt-2' /></div>
                  {
                    NumberBox.map((item, key) => {
                      return (
                        <div className="rounded h-[32px] w-[32px] font-[Ubuntu]  text-[16px] font-[700] text-center text-[#2D5BFF] justify-center border-[1px] border-[#ECF0FF] cursor-pointer active:bg-[#ECF0FF]" key={key} style={{ backgroundColor: activeColor === key ? "white" : "#ECF0FF" }} onClick={() => { setIndexNumber(key); setActiveColor(key); }}>{key + 1}</div>
                      )
                    })
                  }
                  <div className='rounded-md border-2 border-white h-[32px] w-[32px] bg-[#2D5BFF] text-white'><FaArrowRight className='mt-2' /></div>
                </div>
              </div>
            </div>
          </div>}
        {choice == 3 && <Gold />}
        {choice == 4 && <Margin />}
        {choice == 5 && <Contact />}
        {choice == 6 && <Plan />}
        {choice == 7 && <SendEmail />}
        <Footer />

        {/* Add Coin Confirmation */}
        <Dialog className="border-2 border-t-8 border-[#2D5BFF] rounded-xl" onClose={(event, reason) => {
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') { setConfirmationAddCoin(false); }
        }} open={confirmationAddCoin}>
          <div className="flex flex-row border-b-2">
            <div className="text-[#232323] text-xl p-2 w-96">Add Coin</div>
            <div className="text-xl text-[#232323] flex justify-end font-semibold p-2 cursor-pointer"><MdClose onClick={() => { setConfirmationAddCoin(false); }} /></div>
          </div>
          <div className="flex flex-col mt-2 p-1">
            <div className='grid grid-cols-2'>
              <div className='font-semibold'>Name:</div>
              <div className=''>{updateCoinUser?.name}</div>
            </div>
            <div className='grid grid-cols-2'>
              <div className='font-semibold'>Email:</div>
              <div className=''>{updateCoinUser?.email}</div>
            </div>
            <div className='grid grid-cols-2'>
              <div className="text-l text-left font-semibold">Coin Quantity:</div>
              <input type="number" value={coinQuantity} onChange={(e) => { setCoinQuantity(e.target.value) }} className='text-center rounded border-[2px] outline-0' />
            </div>
            <hr className="mt-2 bg-blue-400"></hr>
            <div className="flex justify-end gap-2 p-1">
              <button className="w-36 bg-white border-2 border-[#2D5BFF] rounded-lg justify-center text-center text-[#2D5BFF] cursor-pointer" onClick={() => { setConfirmationAddCoin(false); }}>Cancel</button>
              {!loader && <button className="w-36 bg-[#FF3030] rounded-lg justify-center text-center text-white cursor-pointer" onClick={() => { addCoin(); }}>Add Coin</button>}
              {loader && <Audio />}
            </div>
          </div>
        </Dialog>
      </div>
    </>
  )
}

export default AdminPortal