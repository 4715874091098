import React, { useState } from 'react'
import useBucketStore from '../../store/BucketStore';

const DateTime = (props) => {
    const [activeRange, setActiveRange] = useState(1);
    const {updateBucketStartDate,updateBucketEndDate}=useBucketStore();

    function formatDate(date) {
        // Extract year, month, and day from the date
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const day = String(date.getDate()).padStart(2, '0');
    
        // Return formatted date as 'YYYY-MM-DD'
        return `${year}-${month}-${day}`;
    }

    
    function updateDate(numMonths) {
        // Get current date
        let currentDate = new Date();
        updateBucketEndDate(formatDate(currentDate));
        // Subtract months from current date
        currentDate.setMonth(currentDate.getMonth() - numMonths);
        currentDate.setDate(currentDate.getDate() - 1)
        // Format current date
        let formattedCurrentDate = formatDate(currentDate);
        updateBucketStartDate(formattedCurrentDate);
    }

    return (
        <div className='flex flex-col py-2 mx-2'>
            <div className="flex flex-row justify-around flex-wrap gap-4 w-full px-1">
                {[
                    { label: '1 Month', range: '1', value: 1 },
                    { label: '3 Month', range: '2', value: 3 },
                    { label: '6 Month', range: '3', value: 6 },
                    { label: '9 Month', range: '4', value: 9 },
                    { label: '1 Year', range: '5', value: 12 },
                    { label: '2 Year', range: '6', value: 24 },
                    { label: '3 Year', range: '7', value: 36 },
                    { label: '4 Year', range: '8', value: 48 },
                ].map((item) => (
                    <div
                        key={item.range}
                        className={`cursor-pointer text-[16px] w-[100px] font-[500] p-2 text-center rounded-md transition-all duration-300 border-blue-400 border-[1px] 
        ${activeRange === item.range ? 'bg-blue-600 text-white' : 'bg-gray-100 text-black'} 
        hover:bg-blue-500 hover:text-white shadow-md hover:shadow-lg`}
                        onClick={() => { updateDate(item.value); setActiveRange(item.range); }}
                    >
                        {item.label}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default DateTime