import React, { useState,useEffect } from 'react'
import { Dialog } from '@mui/material';
// lot size
import { NIFTY_LOT_SIZE, BANKNIFTY_LOT_SIZE } from '../../constant/constant';
// bucket store
import useBucketStore from '../../store/BucketStore';
// react-icons
import { IoMdSettings } from 'react-icons/io';
import { MdClose } from 'react-icons/md';

const Setting = () => {
    const [openSetting, setOpenSetting] = useState(false);
    const [setting,setSetting] = useState({ "bankniftylotsize": 15, "niftylotsize": 25, "slippage": 0 });
    const { settingDetail, updateSettingDetail } = useBucketStore();
    const slippageList = [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0, 1.1, 1.2, 1.3, 1.4, 1.5, 1.6, 1.7, 1.8, 1.9]

    const updateSetting=(field,value)=>{
        setSetting((prev) => ({
            ...prev,
            [field]: value
        }));
        
    }

    useEffect(()=>{
      setSetting(settingDetail);
    },[])

    

    return (
        <>
            <IoMdSettings onClick={() => setOpenSetting(true)} className="text-blue-500 text-xl cursor-pointer hover:text-blue-700 transition-colors duration-200"/>

            {/* change default setting */}
            <Dialog className="border-2 border-t-8 border-[#2D5BFF] rounded-xl" onClose={(event, reason) => {
                if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') { setOpenSetting(false); }
            }} open={openSetting}>
                <div className="flex flex-row border-b-2 bg-blue-400 text-white">
                    <div className="text-xl font-medium p-2 flex flex-grow"><IoMdSettings className="mt-1" />Setting</div>
                    <div className="text-xl  flex justify-end font-semibold p-2 cursor-pointer"><MdClose className="text-2xl" onClick={() => { setOpenSetting(false); }} /></div>
                </div>
                <div className="flex flex-col mt-2 p-2">
                    <div className="">Banknifty Lot Size</div>
                    <select className="bg-[#DAECF2] outline-0 rounded p-1" value={setting.bankniftylotsize} onChange={(e) => { updateSetting('bankniftylotsize', e.target.value); }}>
                        {
                            BANKNIFTY_LOT_SIZE.map((item, key) => {
                                return (
                                    <option value={item} key={`bankniftylot-${key}`}>{item}</option>
                                )
                            })
                        }
                    </select>
                    <div className="text-xs">*From 21 Jul'23 ,Banknifty Lot Size is considered 15</div>
                    <div className="">Nifty Lot Size</div>
                    <select className="bg-[#DAECF2] outline-0 rounded p-1" value={setting.niftylotsize} onChange={(e) => { updateSetting('niftylotsize', e.target.value); }}>
                        {
                            NIFTY_LOT_SIZE.map((item, key) => {
                                return (
                                    <option value={item} key={`niftylot-${key}`}>{item}</option>
                                )
                            })
                        }
                    </select>
                    <div className="text-xs">* From 23 Jul'23, Nifty Lot Size is considered 50</div>
                    <div className="">Inbuilt Slippages</div>
                    <select className="bg-[#DAECF2] outline-0 rounded mb-2 p-1" value={settingDetail.slippage} onChange={(e) => { updateSetting('slippage', e.target.value); }}>
                        {
                            slippageList.map((item, key) => {
                                return (
                                    <option value={item} key={`slippage-${key}`}>{item}</option>
                                )
                            })
                        }
                    </select>
                </div>
                <div className="flex justify-end gap-2 mt-2 border-t-2 p-1 bg-gray-400">
                    <button className="border-2 p-1 w-24 bg-[#2D5BFF] rounded-lg justify-center text-center text-white cursor-pointer" onClick={() => { setOpenSetting(false); }}>Cancel</button>
                    <button className="p-1 w-24 bg-white border-2 border-[#2D5BFF] rounded-lg justify-center text-center text-[#2D5BFF] cursor-pointer" onClick={() => { updateSettingDetail(setting);setOpenSetting(false); }}>Save</button>
                </div>
            </Dialog>
        </>
    )
}

export default Setting