import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware'

const bucketStore = (set) => ({
  activeBasketName: '',
  sidebarVisible: false,
  boxShow: false,
  bucketName: '',
  savedstrategies: [],
  activeBasket: [],
  basketList: [],
  bucketStartDate: "2022-01-03",
  bucketEndDate: "2022-02-03",
  include: [],
  strategyMultiplier: [],
  // result of bucket
  fullMonth: [],
  fullWeek: [],
  originalResult: [],
  slippage: [],
  strategyResultInclude: [],
  settingDetail: { "bankniftylotsize": 15, "niftylotsize": 25, "slippage": 0 },
  preDefinedStrategy: [],
  strategyChecked: [],
  dayOption:[],

  // For Updating active BasketName
  updateActiveBasketName: (name) => {
    set((state) => (
      { activeBasketName: name }
    ))
  },
  updateSidebarVisible: (data) => {
    set((state) => (
      { sidebarVisible: data }
    ))
  },
  updateBoxShow: (data) => {
    set((state) => (
      { boxShow: data }
    ))
  },
  updateSavedStrategies: (data) => {
    set((state) => (
      { savedstrategies: data }
    ))
  },
  updateDayOptionStrategyOnActiveBasket: (data, index) => {
    set((state) => ({
      activeBasket: {
        ...state.activeBasket,
        strategy: state.activeBasket.strategy.map((item, i) =>
          i === index ? { ...item, dayoption: data } : item
        )
      }
    }));
  },
  updateDayVariantStrategyOnActiveBasket: (data, index) => {
    set((state) => ({
      activeBasket: {
        ...state.activeBasket,
        strategy: state.activeBasket.strategy.map((item, i) =>
          i === index ? { ...item, variant: data } : item
        )
      }
    }));
  },
  deleteStrategiesOnActiveBasket: (data) => {
    set((state) => ({
      activeBasket: {
        ...state.activeBasket,
        strategy: data
      }
    }))
  },
  addStrategiesOnActiveBasket: (data) => {
    set((state) => ({
      activeBasket: {
        ...state.activeBasket,
        strategy: [...state.activeBasket.strategy, data]
      }
    }))
  },
  updateActiveBasket: (data) => {
    set((state) => (
      { activeBasket: data }
    ))
  },
  updateBasketList: (data) => {
    set((state) => (
      { basketList: data }
    ))
  },
  updateBucketStartDate: (data) => {
    set((state) => ({
      bucketStartDate: data
    }))
  },
  updateBucketEndDate: (data) => {
    set((state) => ({
      bucketEndDate: data
    }))
  },
  updateStatsInclude: (data) => {
    set((state) => (
      { include: data }
    ))
  },

  updateFullMonth: (data) => {
    set({ fullMonth: data });
  },
  updateFullWeek: (data) => {
    set({ fullWeek: data });
  },
  updateOriginalResult: (data) => {
    set({ originalResult: data })
  },
  updateStatsMultiplier: (data) => {
    set(() => (
      { strategyMultiplier: data }
    ))
  },
  // Action to update a specific strategy multiplier by key (index)
  updateStrategyMultiplier: (key, value) =>
    set((state) => {
      const updatedMultiplier = [...state.strategyMultiplier]; // Copy the current state
      updatedMultiplier[key] = value; // Update the specific index with new value
      return { strategyMultiplier: updatedMultiplier }; // Return the updated state
    }),
  removeStrategyMultiplier: (key) =>
    set((state) => {
      const updatedMultiplier = [...state.strategyMultiplier]; // Copy current state
      updatedMultiplier.splice(key, 1); // Remove the item at the specified index
      return { strategyMultiplier: updatedMultiplier }; // Update the state
    }),
  updateSlippage: (data) => {
    set(() => (
      { slippage: data }
    ))
  },
  // Action to remove slippage item by key
  removeSlippage: (key) =>
    set((state) => {
      const updatedSlippage = [...state.slippage];
      updatedSlippage.splice(key, 1); // Remove the item at the specified index
      return { slippage: updatedSlippage };
    }),
  // set slippage as per index and value
  setSlippage: (index, value) =>
    set((state) => {
      const updatedSlippage = [...state.slippage];
      updatedSlippage[index] = value;
      return { slippage: updatedSlippage };
    }),
  updateStrategyResultInclude: (data) => {
    set(
      { strategyResultInclude: data }
    )
  },
  updateStrategyResultIncludeByKey: (key) => {
    set((state) => ({
      strategyResultInclude: {
        ...state.strategyResultInclude,
        [key]: !state.strategyResultInclude[key],
      },
    }));
  },
  // update setting Detail
  updateSettingDetail: (setting) => 
    set({
    settingDetail: setting
  }),
  // update predefined strategy
  updatePreDefinedStrategy: (newStrategy) => {
    set({
      preDefinedStrategy: newStrategy
    })
  },
  // strategy checked
  updateStrategyCheckedAll: (newChecked) => {
    set({
      strategyChecked: newChecked
    })
  },
  // Function to remove an item at a specific index (key)
  removeStrategyChecked: (key) => set((state) => {
    const updatedResult = [...state.strategyChecked];
    updatedResult.splice(key, 1);
    return { strategyChecked: updatedResult };
  }),

  // Function to update the value at a specific index (key) with a new value
  updateStrategyChecked: (key, value) => set((state) => {
    const updatedResult = [...state.strategyChecked];
    updatedResult[key] = value; // Update the specific item
    return { strategyChecked: updatedResult };
  }),

  updateDayOption: (newData) => set({
    dayOption: newData
  }),
  removeDayOption: (index) =>
    set((state) => {
      const updatedDayOptions = [...state.dayOption];
      updatedDayOptions.splice(index, 1); // Remove the item at the given index
      return { dayOption: updatedDayOptions };
    }),
})

// Now,We will make a above code as a store and perform action in another component to perform it.
const useBucketStore = create(
  devtools(
    persist(bucketStore, {
      name: "bucket",
    })
  )
)


export default useBucketStore;