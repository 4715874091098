import React from 'react';
import { saveAs } from 'file-saver';
import { combinedResult } from './CombinedResult.js';
import { combineStatsResult } from './CombineStatsResult.js';
import {statisticalResultPresentation} from './StatisticalResult.js';
import {tradeResult} from './TradeResult.js';
import {inputDataPresentation} from './InputData.js';
import { dayAndMonthProfitability } from './DayAndMonthResult.js';

const ExcelJS = require('exceljs');

const FinalExcel = (props) => {

  // Excel Result -------------------------------------------------------------------------------
  const generateExcel1 = (index, workbook, inputData, week, month, statisticalResult, result) => {
    const worksheet1 = workbook.addWorksheet(`Strategy - ${index}`);
    const worksheet2 = workbook.addWorksheet(`Result - ${index}`);
    const worksheet3 = workbook.addWorksheet(`Periodical Result - ${index}`);

    inputDataPresentation(worksheet1,inputData);
    statisticalResultPresentation(worksheet1,statisticalResult);
    tradeResult(worksheet2,result);
    dayAndMonthProfitability(worksheet3,week, month);
  }

  // Combine Result -------------------------------------------------------------------------
  const generateCombineResult = (workbook, week, month, statisticalResult,result) => {
    const worksheet4 = workbook.addWorksheet(`combinedResult`);
    const worksheet2 = workbook.addWorksheet('combinedTradeResult');

    combinedResult(worksheet2,result)
    combineStatsResult(worksheet4,statisticalResult);
    dayAndMonthProfitability(worksheet4,week, month);
  }

  // Final Result Excel --------------------------------------------------------------------------------------------
  const finalResultExcel = () => {
    const workbook = new ExcelJS.Workbook();
    console.log(props,props.combineWeek,props.combineMonth,props.combineStatsResult,props.combineTradingResult,'result at combine result')
    generateCombineResult(workbook,props.combineWeek,props.combineMonth,props.combineStatsResult,props.combineTradingResult);
    console.log(props.inputData, props.week, props.month, props.StatisticalResult, props.result,'final result excel ---')

    for (let i = 0; i < props.week.length; i++) {
      generateExcel1(i, workbook, props.inputData[i], props.week[i], props.month[i], props.StatisticalResult[i], props.result[i].tradeResult);
    }

    // Save the workbook
    workbook.xlsx.writeBuffer().then((buffer) => {
      const data = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
      props.strategyName
        ? saveAs(data, `backtest_fprognos.xlsx`)
        : saveAs(data, `backtest_fprognos.xlsx`);
    });
  }

  return (
    <div className='w-full'>
      <button className="bg-blue-600 text-white p-1 h-8 rounded font-serif w-full" onClick={finalResultExcel}>Download Excel</button>
    </div>
  );
};

export default FinalExcel;
