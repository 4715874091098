import { MdOutlineApartment } from "react-icons/md";

const IndividualLeg = (props) => {
    
  return (
    <div className="m-2 bg-white rounded-md shadow-md overflow-hidden">
                 <div className="bg-[#718EBF] flex flex-row px-4 py-2 text-xl font-bold text-white">
                <MdOutlineApartment className="text-2xl mt-1 ml-1"/> Individual Leg Contributions
            </div>
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-gray-50">
                    <thead className="bg-[#EFEFEF] border-b-2">
                        <tr>
                            <th className="py-3 px-4 text-left text-md font-semibold">Leg</th>
                            <th className="py-3 px-4 text-left text-md font-semibold">Total Entries</th>
                            <th className="py-3 px-4 text-left text-md font-semibold">Profit</th>
                            <th className="py-3 px-4 text-left text-md font-semibold">Percentage</th>
                        </tr>
                    </thead>
                        <tbody>
                            {props.individualLegContribution && props.individualLegContribution.map((item, key) => (
                                <tr key={`individualLeg-${key}`} className={`hover:bg-indigo-50 border-b-2 ${key%2==1?'bg-[#EFEFEF]':''}`}>
                                    <td className="py-3 px-4 text-sm text-gray-900">L{key + 1}</td>
                                    <td className="py-3 px-4 text-sm text-gray-900">{item?.count}</td>
                                    <td className={`py-3 px-4 text-sm text-gray-900 ${item?.profit > 0 ? 'text-green-600' : 'text-red-600'} `}>{parseFloat(item?.profit).toFixed(2)}</td>
                                    <td className={`py-3 px-4 text-sm text-gray-900 ${item?.profit > 0 ? 'text-green-600' : 'text-red-600'} `}>{props.totalProfit!=0 && (parseFloat(item?.profit/props.totalProfit) * 100).toFixed(2)}%</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
  )
}

export default IndividualLeg