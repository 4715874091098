import React from 'react';
import { saveAs } from 'file-saver';
import { FaDownload } from "react-icons/fa";
import useBacktestResultStore from '../../../store/backtestResultStore';
import useBacktestStore from '../../../store/backtestStore';
const ExcelJS = require('exceljs');

const Excel = (props) => {

  const { inputData, csv, stats } = useBacktestResultStore();
  const { slippage } = useBacktestStore();

  // Add border to the cell
  const borderStyle = { style: 'medium', color: { argb: '000000' } };

  // Function to handle weekly data
  const processWeeklyData = (worksheet, weeklyData, weekResult, borderStyle) => {
    worksheet.addRows(weeklyData);
    worksheet.mergeCells('A1:F1');
    applyCellStyle(worksheet, ['A1'], {
      alignment: { horizontal: 'center' },
      font: { size: 11, name: 'calibri', bold: true, color: { argb: 'FFFFFF' } },
      fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '#ADD8E6' } }
    });

    let weekDataArray = [];
    Object.keys(weekResult).forEach((key, index) => {
      weekDataArray.push([key, ...weekResult[key]]);
    });

    addDataToWorksheet(worksheet, weekDataArray, 3, 6, borderStyle);
  };

  // Function to handle monthly data
  const processMonthlyData = (worksheet, monthlyData, monthResult, borderStyle) => {
    worksheet.getCell('A9').value = 'Monthly Breakup';
    worksheet.mergeCells('A9:P9');
    applyCellStyle(worksheet, ['A9'], {
      alignment: { horizontal: 'center' },
      font: { size: 11, name: 'calibri', bold: true, color: { argb: 'FFFFFF' } },
      fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '#ADD8E6' } }
    });
    worksheet.addRows(monthlyData);

    let monthDataArray = [];
    Object.keys(monthResult).forEach((key, index) => {
      monthDataArray.push([key, ...monthResult[key]]);
    });

    addDataToWorksheet(worksheet, monthDataArray, 11, 16, borderStyle);
  };

  // Function to apply styles to a range of cells
  const applyCellStyle = (worksheet, cellRange, styleOptions) => {
    cellRange.forEach(address => {
      const cell = worksheet.getCell(address);
      if (styleOptions.fill) cell.fill = styleOptions.fill;
      if (styleOptions.font) cell.font = styleOptions.font;
      if (styleOptions.alignment) cell.alignment = styleOptions.alignment;
    });
  };

  // Function to add data and apply borders
  const addDataToWorksheet = (worksheet, data, rowStartIndex, columnCount, borderStyle) => {
    let rowIndex = rowStartIndex;
    data.forEach(rowData => {
      rowData.forEach((cellData, colIndex) => {
        const cellAddress = `${String.fromCharCode(65 + colIndex)}${rowIndex}`;
        const cell = worksheet.getCell(cellAddress);
        cell.value = cellData;
        cell.border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
      });
      rowIndex++;
    });
  };

  const TradeDetail = (worksheet, legData) => {
    // Create an array of arrays (rows and columns) from the table data
    const worksheetData = [];

    // Adding table headers with font weight and column width settings
    const headers = [
      'Include', 'Entry Date', 'Exit Date', 'Start Time', 'End Time', 'Day', 'Starting Future Price', 'Exit Future Price',
      'Starting Spot Price', 'Exit Spot Price', 'Starting Vix Price', 'Exit Vix Price', 'Market Open Price',
      'Market Previous Day Close', 'Max Profit', 'Max Loss', 'Total Profit'
    ];

    // Dynamically adding Strategy and Re-Entry headers based on result data
    legData.length > 0 && legData[0].result.map((item, key) => {
      item.map((value, key1) => {
        key === 0 ? headers.push(`Strategy(N)-${key}`) : headers.push(`ReEntry-${key1}`);
      });
    });
    worksheetData.push(headers);

    // Adding table body data (mapping your legData)
    legData.forEach((item) => {
      const row = [
        // Include column
        item.include ? 'Yes' : 'No',

        // Entry Date and Exit Date
        item.date, // Assuming this is the entry date
        item.exitDate || '', // Add exit date if available

        // Start and End Time
        item.startTime,
        item.endTime,

        // Day of trade
        item.day,

        // Starting and Exit Future Prices
        item.startingFuturePrice,
        item.exitFuturePrice,

        // Starting and Exit Spot Prices
        item.startingSpotPrice,
        item.exitSpotPrice,

        // Starting and Exit Vix Prices
        item.startingVixPrice,
        item.exitVixPrice,

        // Market Open Price (Market Entry)
        `Open: ${item.marketEntryPrice?.Open}, Close: ${item.marketEntryPrice?.Close}`,

        // Market Close Price from Previous Day
        `Open: ${item.marketClosedPricePreviousDay?.Open}, Close: ${item.marketClosedPricePreviousDay?.Close}`,

        // Max Profit, Max Loss, and Total Profit
        item.maxProfit.toFixed(2),
        item.maxLoss.toFixed(2),
        item.totalProfit.toFixed(2),
      ];

      // Combine Strike Price and Profit (Exit Price - Start Price) for each leg in the result array
      item.result
        .map(subArray =>
          subArray.map(r => {
            const profit = (r.exitPrice - r.startPrice).toFixed(2); // Calculate profit
            row.push(`Strike: ${r.strikePrice}, Profit: ${profit}`);   // Add formatted string to row
          })
        );

      worksheetData.push(row);
    });

    // Append worksheet data to the Excel sheet
    worksheet.addRows(worksheetData);

    // Styling headers with bold font, background color, and borders
    const headerRow = worksheet.getRow(1);
    headerRow.font = { bold: true, size: 12, name: 'Calibri', color: { argb: 'FFFFFF' } };  // White bold font
    headerRow.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '0073e6' },  // Light blue background color
    };
    headerRow.eachCell({ includeEmpty: true }, function(cell, colNumber) {
      cell.border = {
        top: { style: 'thin', color: { argb: 'FF000000' } },
        left: { style: 'thin', color: { argb: 'FF000000' } },
        bottom: { style: 'thin', color: { argb: 'FF000000' } },
        right: { style: 'thin', color: { argb: 'FF000000' } }
      };  // Adding borders to the header cells
    });

    // Adjust column widths
    worksheet.columns = [
      { width: 10 },  // Include
      { width: 15 },  // Entry Date
      { width: 15 },  // Exit Date
      { width: 12 },  // Start Time
      { width: 12 },  // End Time
      { width: 12 },  // Day
      { width: 18 },  // Starting Future Price
      { width: 18 },  // Exit Future Price
      { width: 18 },  // Starting Spot Price
      { width: 18 },  // Exit Spot Price
      { width: 15 },  // Starting Vix Price
      { width: 15 },  // Exit Vix Price
      { width: 25 },  // Market Open Price
      { width: 25 },  // Market Previous Day Close
      { width: 12 },  // Max Profit
      { width: 12 },  // Max Loss
      { width: 12 },  // Total Profit
      { width: 40 }   // Trade Details (Strike Price, Profit)
    ];

    // Optionally, you can apply additional styles to other rows or columns as needed

    // Apply borders to the data rows
    worksheet.eachRow({ includeEmpty: true }, function(row, rowNumber) {
      if (rowNumber !== 1) {  // Skip the header row
        row.eachCell({ includeEmpty: true }, function(cell, colNumber) {
          cell.border = {
            top: { style: 'thin', color: { argb: 'FF000000' } },
            left: { style: 'thin', color: { argb: 'FF000000' } },
            bottom: { style: 'thin', color: { argb: 'FF000000' } },
            right: { style: 'thin', color: { argb: 'FF000000' } }
          };  // Adding borders to the data cells
        });
      }
    });

    // Add a new header before the table
    worksheet.getCell('A1').value = 'Trade Details Report';
    worksheet.mergeCells('A1:P1');
    worksheet.getCell('A1').font = { size: 14, bold: true, color: { argb: 'FFFFFF' } };
    worksheet.getCell('A1').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '003366' },  // Darker blue background for the main header
    };
    worksheet.getCell('A1').alignment = { horizontal: 'center' };
};

  

  const StatisticalResult = (worksheet1) => {
    // Example: Applying a background color to a cell in worksheet1
    const cellA1 = worksheet1.getCell('A1');
    worksheet1.mergeCells('A1:J1');
    cellA1.value = 'FPROGNOS';
    cellA1.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '#2D5BFF' }
    };
    worksheet1.getCell('A1').font = {
      color: { argb: 'FFFFFF' }
    }
    worksheet1.getCell('A1').alignment = { horizontal: 'center' }
    worksheet1.getCell('A1').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };


    const cellA2 = worksheet1.getCell('K1');
    worksheet1.mergeCells('K1:S1');
    cellA2.value = props.name;
    cellA2.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '2D5BFF' }
    };
    worksheet1.getCell('K1').font = {
      color: { argb: 'FFFFFF' }
    }
    worksheet1.getCell('K1').alignment = { horizontal: 'center' }
    worksheet1.getCell('K1').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };



    // Adding data to worksheet1
    const cellD1 = worksheet1.getCell('B3');
    worksheet1.mergeCells('B3:S3');
    cellD1.value = 'POSITION';
    cellD1.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '2D5BFF' }
    };
    worksheet1.getCell('B3').font = {
      color: { argb: 'FFFFFF' }
    }
    worksheet1.getCell('B3').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
    worksheet1.getCell('B3').alignment = { horizontal: 'center' }

    worksheet1.getCell('F5').value = 'ATM';
    worksheet1.mergeCells('F5:G5');
    worksheet1.getCell('F6').value = 'SquareOff';
    worksheet1.mergeCells('F6:G6');
    worksheet1.getCell('F7').value = 'Wait & Trade';
    worksheet1.mergeCells('F7:G7');
    worksheet1.getCell('F8').value = 'Re-Entry/ReExecute';
    worksheet1.mergeCells('F8:G8');
    worksheet1.getCell('F9').value = 'MoveSlToCost';
    worksheet1.mergeCells('F9:G9');


    worksheet1.getCell('H5').value = 'Future';
    worksheet1.getCell('H5').alignment = { horizontal: 'center' }
    worksheet1.getCell('H6').value = inputData['squareoff'] == 'one' ? "Leg" : "All";
    worksheet1.getCell('H6').alignment = { horizontal: 'center' }
    worksheet1.getCell('H7').value = inputData['waitTrade'];
    worksheet1.getCell('H7').alignment = { horizontal: 'center' }
    worksheet1.getCell('H8').value = inputData['reEntry'];
    worksheet1.getCell('H8').alignment = { horizontal: 'center' }
    worksheet1.getCell('H9').value = inputData['slToCost'];
    worksheet1.getCell('H9').alignment = { horizontal: 'center' }


    worksheet1.getCell('B11').value = 'Include';
    worksheet1.getCell('B11').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
    worksheet1.getCell('C11').value = 'Total Lot';
    worksheet1.getCell('C11').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
    worksheet1.getCell('D11').value = 'ATM';
    worksheet1.getCell('D11').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
    worksheet1.mergeCells('D11:E11');
    worksheet1.getCell('F11').value = 'Action';
    worksheet1.getCell('F11').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
    worksheet1.getCell('G11').value = 'Option';
    worksheet1.getCell('G11').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
    worksheet1.getCell('H11').value = 'Wait & Trade';
    worksheet1.getCell('H11').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
    worksheet1.mergeCells('H11:I11');
    worksheet1.getCell('J11').value = 'Target Profit';
    worksheet1.getCell('J11').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
    worksheet1.mergeCells('J11:K11');
    worksheet1.getCell('L11').value = 'Target Stoploss';
    worksheet1.getCell('L11').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
    worksheet1.mergeCells('L11:M11');
    worksheet1.getCell('N11').value = 'Trail Stoploss';
    worksheet1.getCell('N11').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
    worksheet1.mergeCells('N11:O11');
    worksheet1.getCell('P11').value = 'Re-Entry';
    worksheet1.getCell('P11').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
    worksheet1.mergeCells('P11:Q11');
    worksheet1.getCell('R11').value = 'Index';
    worksheet1.getCell('R11').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
    worksheet1.getCell('S11').value = 'Expiry';
    worksheet1.getCell('S11').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };

    const formattingOptions = { type: 'pattern', pattern: 'solid', fgColor: { argb: '#2D5BFF' } };

    // Define an array of cell addresses where you want to apply the formatting
    const cellAddresses = ['B11', 'C11', 'D11', 'F11', 'G11', 'H11', 'J11', 'L11', 'N11', 'P11', 'R11', 'S11'];

    // Apply formatting to the specified cells
    cellAddresses.forEach(address => {
      const cell = worksheet1.getCell(address);
      cell.fill = formattingOptions;
      cell.font = {
        color: { argb: 'FFFFFF' }
      }
    });


    for (let i = 0; i < inputData['leg']; i++) {
      worksheet1.getCell(`B${i + 12}`).value = true;
      worksheet1.getCell(`B${i + 12}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
      worksheet1.getCell(`C${i + 12}`).value = `${inputData['lotarray'][i]} lot`
      worksheet1.getCell(`C${i + 12}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
      worksheet1.getCell(`D${i + 12}`).value = `${inputData['atm'][i]} `
      worksheet1.getCell(`D${i + 12}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
      worksheet1.mergeCells(`D${i + 12}:E${i + 12}`);
      worksheet1.getCell(`F${i + 12}`).value = inputData['legarray'][i][0] == 'B' ? 'BUY' : "SELL";
      worksheet1.getCell(`F${i + 12}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
      worksheet1.getCell(`G${i + 12}`).value = inputData['legarray'][i][1] == 'C' ? 'CALL' : "PUT";
      worksheet1.getCell(`G${i + 12}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
      worksheet1.getCell(`H${i + 12}`).value = inputData['waitTrade'] ? (inputData['waitTradeType'][i] + " " + inputData['waitTradeValue'][i]) : ' ';
      worksheet1.getCell(`H${i + 12}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
      worksheet1.mergeCells(`H${i + 12}:I${i + 12}`);
      worksheet1.getCell(`J${i + 12}`).value = inputData['legsTargetProfit'][i] == '-1' ? '' : (inputData['legsTargetProfitType'][i] + " " + inputData['legsTargetProfit'][i]);
      worksheet1.getCell(`J${i + 12}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
      worksheet1.mergeCells(`J${i + 12}:K${i + 12}`);
      worksheet1.getCell(`L${i + 12}`).value = inputData['legsStoploss'][i] == '-1' ? '' : (inputData['legsStoplossType'][i] + " " + inputData['legsStoploss'][i]);
      worksheet1.getCell(`L${i + 12}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
      worksheet1.mergeCells(`L${i + 12}:M${i + 12}`);
      worksheet1.getCell(`N${i + 12}`).value = inputData['trailStoploss'][i] ? `Tsl ${inputData['trailStoplossType'][i]} : ${inputData['trailStoplossLoss'][i]},${inputData['trailStoplossLoss'][i]}` : "";
      worksheet1.getCell(`N${i + 12}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
      worksheet1.mergeCells(`N${i + 12}:O${i + 12}`);
      worksheet1.getCell(`P${i + 12}`).value = inputData['reEntry'] ? (inputData['reEntryChoice'][i] + " " + inputData['reEntryValue'][i]) : '';
      worksheet1.mergeCells(`P${i + 12}:Q${i + 12}`);
      worksheet1.getCell(`P${i + 12}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
      worksheet1.getCell(`R${i + 12}`).value = inputData['tradesymbol'];
      worksheet1.getCell(`R${i + 12}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
      worksheet1.getCell(`S${i + 12}`).value = inputData['legExpiryType'][i];
      worksheet1.getCell(`S${i + 12}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
    }

    //  Entry and Exit Time
    worksheet1.getCell('B22').value = 'Entry Time';
    worksheet1.mergeCells('B22:C22');
    worksheet1.getCell('D22').value = inputData['starttime'];
    worksheet1.mergeCells('D22:E22');
    worksheet1.getCell('H22').value = 'Exit Time';
    worksheet1.mergeCells('H22:I22');
    worksheet1.getCell('J22').value = inputData['endtime'];
    worksheet1.mergeCells('J22:K22');

    //  Start Date and Exit Date
    worksheet1.getCell('B24').value = 'From Date';
    worksheet1.mergeCells('B24:C24');
    worksheet1.getCell('D24').value = inputData['startdate'];
    worksheet1.mergeCells('D24:E24');
    worksheet1.getCell('H24').value = 'To Date';
    worksheet1.mergeCells('H24:I24');
    worksheet1.getCell('J24').value = inputData['enddate'];
    worksheet1.mergeCells('J24:K24');


    // strategy Profit and Strategy Stoploss
    worksheet1.getCell('B26').value = 'Target Profit';
    worksheet1.mergeCells('B26:C26');
    worksheet1.getCell('D26').value = inputData['maxprofit'] == -1 ? "" : inputData['maxprofit'];
    worksheet1.getCell('D26').alignment = { horizontal: 'left' }
    worksheet1.mergeCells('D26:E26');
    worksheet1.getCell('H26').value = 'Stoploss';
    worksheet1.mergeCells('H26:I26');
    worksheet1.getCell('J26').value = inputData['stoploss'] == 1 ? "" : inputData['stoploss'];
    worksheet1.mergeCells('J26:K26');
    worksheet1.getCell('J26').alignment = { horizontal: 'left' }

    // Protect Profit
    worksheet1.getCell('B28').value = 'Protect Profit';
    worksheet1.mergeCells('B28:C28');
    worksheet1.getCell('D28').value = inputData['protectProfit'] ? inputData['protectType'] + "" + "lockMinimumProfit" + inputData['lockMinimumProfit'] + " trailProfit" + inputData['trailProfit'] + "increaseProfit" + inputData['profitIncrease'] + "profitReaches" + inputData['profitReaches'] : "";
    worksheet1.getCell('D28').alignment = { horizontal: 'left' }
    worksheet1.mergeCells('D28:J28');

    // Range Break Out
    worksheet1.getCell('B30').value = 'Range StartTime';
    worksheet1.mergeCells('B30:C30');
    worksheet1.getCell('D30').value = inputData['showRangeBreakOut'] ? inputData['rangeStartTime'] : "";
    worksheet1.getCell('D30').alignment = { horizontal: 'left' }
    worksheet1.mergeCells('D30:E30');
    worksheet1.getCell('H30').value = 'Range End Time';
    worksheet1.mergeCells('H30:I30');
    worksheet1.getCell('H30').value = inputData['showRangeBreakOut'] ? inputData['rangeEndTime'] : "";
    worksheet1.getCell('H30').alignment = { horizontal: 'left' }

    // Intraday or Positional
    worksheet1.getCell('B32').value = 'Trade Type';
    worksheet1.mergeCells('B32:C32');
    worksheet1.getCell('D32').value = inputData['intraday'] == "Yes" ? "Intraday" : "Positional";
    worksheet1.getCell('D32').alignment = { horizontal: 'left' }
    worksheet1.mergeCells('D32:H32');



    worksheet1.mergeCells('B34:S34');
    worksheet1.getCell('B34').value = 'STATISTICAL RESULT';
    worksheet1.getCell('B34').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '#2D5BFF' } };
    worksheet1.getCell('B34').font = { color: { argb: 'FFFFFF' } }
    worksheet1.getCell('B34').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
    worksheet1.getCell('B34').alignment = { horizontal: 'center' }

    let cnt1 = 36
    // Slippages
    worksheet1.getCell(`B${cnt1 + 0}`).value = `Slippages`;
    worksheet1.mergeCells(`B${cnt1 + 0}:D${cnt1 + 0}`);
    worksheet1.getCell(`B${cnt1 + 0}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };

    worksheet1.getCell(`B${cnt1 + 1}`).value = `Vix`;
    worksheet1.mergeCells(`B${cnt1 + 1}:D${cnt1 + 1}`);
    worksheet1.getCell(`B${cnt1 + 1}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };

    worksheet1.getCell(`E${cnt1 + 0}`).value = props.slippage;
    worksheet1.getCell(`E${cnt1 + 0}`).alignment = { horizontal: `center` }
    worksheet1.mergeCells(`E${cnt1 + 0}:F${cnt1 + 0}`);
    worksheet1.getCell(`E${cnt1 + 0}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };

    worksheet1.getCell(`E${cnt1 + 1}`).value = `${props.vixStart} to ${props.vixEnd}`
    worksheet1.mergeCells(`E${cnt1 + 1}:F${cnt1 + 1}`);
    worksheet1.getCell(`E${cnt1 + 1}`).alignment = { horizontal: `center` }
    worksheet1.getCell(`E${cnt1 + 1}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };

    worksheet1.getCell(`G${cnt1 + 0}`).value = `(0.5% Slippages are already included for options strategies)`
    worksheet1.mergeCells(`G${cnt1 + 0}:M${cnt1 + 0}`);
    worksheet1.getCell(`G${cnt1 + 0}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };

    let cnt = 40
    worksheet1.getCell(`B${cnt + 0}`).value = 'Total Margin';
    worksheet1.mergeCells(`B${cnt + 0}:D${cnt + 0}`);
    worksheet1.getCell(`B${cnt + 0}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
    worksheet1.getCell(`B${cnt + 1}`).value = `Total Profit`;
    worksheet1.mergeCells(`B${cnt + 1}:D${cnt + 1}`);
    worksheet1.getCell(`B${cnt + 1}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
    worksheet1.getCell(`B${cnt + 2}`).value = `Maximum Drawdown`;
    worksheet1.mergeCells(`B${cnt + 2}:D${cnt + 2}`);
    worksheet1.getCell(`B${cnt + 2}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
    worksheet1.getCell(`B${cnt + 3}`).value = `Max Profit`;
    worksheet1.mergeCells(`B${cnt + 3}:D${cnt + 3}`);
    worksheet1.getCell(`B${cnt + 3}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
    worksheet1.getCell(`B${cnt + 4}`).value = `Max Loss`;
    worksheet1.mergeCells(`B${cnt + 4}:D${cnt + 4}`);
    worksheet1.getCell(`B${cnt + 4}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
    worksheet1.getCell(`B${cnt + 5}`).value = `Win Days`;
    worksheet1.mergeCells(`B${cnt + 5}:D${cnt + 5}`);
    worksheet1.getCell(`B${cnt + 5}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
    worksheet1.getCell(`B${cnt + 6}`).value = `Loss Days`;
    worksheet1.mergeCells(`B${cnt + 6}:D${cnt + 6}`);
    worksheet1.getCell(`B${cnt + 6}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
    worksheet1.getCell(`B${cnt + 7}`).value = `Average Profit on Win Days`;
    worksheet1.mergeCells(`B${cnt + 7}:D${cnt + 7}`);
    worksheet1.getCell(`B${cnt + 7}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
    worksheet1.getCell(`B${cnt + 8}`).value = `Average Loss on Loss Days`;
    worksheet1.mergeCells(`B${cnt + 8}:D${cnt + 8}`);
    worksheet1.getCell(`B${cnt + 8}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
    worksheet1.getCell(`B${cnt + 9}`).value = `Investment Days`;
    worksheet1.mergeCells(`B${cnt + 9}:D${cnt + 9}`);
    worksheet1.getCell(`B${cnt + 9}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
    worksheet1.getCell(`B${cnt + 10}`).value = `Expectancy`;
    worksheet1.mergeCells(`B${cnt + 10}:D${cnt + 10}`);
    worksheet1.getCell(`B${cnt + 10}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
    worksheet1.getCell(`B${cnt + 11}`).value = `Max Continuous Profit Streak`;
    worksheet1.mergeCells(`B${cnt + 11}:D${cnt + 11}`);
    worksheet1.getCell(`B${cnt + 11}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
    worksheet1.getCell(`B${cnt + 12}`).value = `Max Continuuous Loss Streak`;
    worksheet1.mergeCells(`B${cnt + 12}:D${cnt + 12}`);
    worksheet1.getCell(`B${cnt + 12}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
    worksheet1.getCell(`B${cnt + 13}`).value = `Leg in Strategies`;
    worksheet1.mergeCells(`B${cnt + 13}:D${cnt + 13}`);
    worksheet1.getCell(`B${cnt + 13}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
    worksheet1.getCell(`B${cnt + 14}`).value = `MDD (Recovery period)`;
    worksheet1.mergeCells(`B${cnt + 14}:D${cnt + 14}`);
    worksheet1.getCell(`B${cnt + 14}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
    worksheet1.getCell(`B${cnt + 15}`).value = `Return to MDD Ratio`;
    worksheet1.mergeCells(`B${cnt + 15}:D${cnt + 15}`);
    worksheet1.getCell(`B${cnt + 15}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };


    // for (let k = 0; k < props.StatisticalResult.length; k++) {
    //   worksheet1.getCell(`E${k + cnt}`).value = typeof props.StatisticalResult[k] == Number ? props.StatisticalResult[k].toFixed(2) : props.StatisticalResult[k];
    //   worksheet1.mergeCells(`E${k + cnt}:F${k + cnt}`);
    //   worksheet1.getCell(`E${k + cnt}`).alignment = { horizontal: 'center' }
    //   worksheet1.getCell(`E${k + cnt}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
    // }
    worksheet1.getCell(`E${cnt - 7 + 20}`).value = inputData[`leg`]
    worksheet1.getCell(`E${cnt - 7 + 20}`).alignment = { horizontal: `center` }
    worksheet1.mergeCells(`E${cnt - 7 + 20}:F${cnt - 7 + 20}`);
    worksheet1.getCell(`E${cnt - 7 + 20}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };

    worksheet1.getCell(`E${cnt - 7 + 21}`).value = `----------`
    worksheet1.mergeCells(`E${cnt - 7 + 21}:F${cnt - 7 + 21}`);
    worksheet1.getCell(`E${cnt - 7 + 21}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
    worksheet1.getCell(`E${cnt - 7 + 21}`).alignment = { horizontal: `center` }


    worksheet1.getCell(`E${cnt - 7 + 22}`).value = `----------`
    worksheet1.mergeCells(`E${cnt - 7 + 22}:F${cnt - 7 + 22}`);
    worksheet1.getCell(`E${cnt - 7 + 22}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
    worksheet1.getCell(`E${cnt - 7 + 22}`).alignment = { horizontal: `center` }
  }


  const generateExcel1 = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet1 = workbook.addWorksheet('Strategy');
    const worksheet2 = workbook.addWorksheet('Result');
    const worksheet3 = workbook.addWorksheet('Periodical Result');

    StatisticalResult(worksheet1);
    const weekly = [['Day Wise Breakup'], ["Serial", "Mon", "Tue", "Wed", "Thu", "Fri"]]
    const monthly = [['No.', "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", "Total", "MDD", "ROI"]];
    processWeeklyData(worksheet3, weekly, stats.week);
    processMonthlyData(worksheet3, monthly, stats.month);
    TradeDetail(worksheet2, csv);

    // Save the workbook
    workbook.xlsx.writeBuffer().then((buffer) => {
      const data = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
      saveAs(data, `backtest_fprognos.xlsx`);
    });
  };

  return (
    <div className='w-full'>
      <button className="bg-green-500 hover:bg-green-600 flex text-center justify-center text-white p-1 text-[16px] rounded font-serif w-full gap-1" onClick={generateExcel1}>Download Excel<FaDownload className='mt-1' /></button>
    </div>
  );
};

export default Excel;
