import React, { useState } from 'react'
import useBucketStore from '../../store/BucketStore';
import ImportBasket from './ImportBasket';
import { extractErrorMessage } from '../../utility/utils';
import { DELETE_BASKET, RENAME_BASKET_NAME, FETCH_BASKET_NAME } from '../../constant/constant';
// import library
import { Dialog } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
// icons
import { MdAddBox } from 'react-icons/md';
import { FaPlus } from 'react-icons/fa';
import { MdDriveFileRenameOutline } from 'react-icons/md';
import { MdDeleteOutline } from 'react-icons/md';
import { MdClose } from 'react-icons/md';
import StrategyDefault from './StrategyDefault';

const Sidebar = (props) => {
  const { updateBoxShow, activeBasket, activeBasketName, basketList, updateBasketList, updateSlippage,
    updateActiveBasket, updateActiveBasketName, updateStatsInclude, updateStatsMultiplier, updateStrategyChecked, updateStrategyCheckedAll, strategyChecked,updateDayOption } = useBucketStore();
  const [visibleDeleteBasket, setVisibleDeleteBasket] = useState(false);
  const [visibleRename, setVisibleRename] = useState(false);
  const [currentBucketName, setCurrentBucketName] = useState('');
  const navigate = useNavigate();

  const RenameBasketName = () => {
    // Get the token from secureLocalStorage
    let token = secureLocalStorage.getItem('backtest_token');

    // validation
    if (currentBucketName == "") {
      toast.error('Minimum 1 character required!.');
      return;
    } else if (currentBucketName.length > 40) {
      toast.error('Maximum 40 character allowed!.');
      return;
    }

    // Remove the 'backtest_' prefix if it exists
    if (token && token.startsWith('backtest_')) {
      token = token.replace('backtest_', '');
    } else {
      navigate('/backtest/login');
    }
    let data = JSON.stringify({ id: secureLocalStorage.getItem("backtest_id"), 'bucket_name': activeBasketName, 'new_name': currentBucketName });
    let config = {
      method: "post", maxBodyLength: Infinity, url: RENAME_BASKET_NAME,
      headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
      data: data,
    };
    axios
      .request(config)
      .then((response) => {
        toast.success('Option Matrix Name Changes Successfully!!')
        setVisibleRename(false);
        updateActiveBasketName(currentBucketName);
        const temp = activeBasket;
        temp.bucket_name = currentBucketName;
        updateActiveBasket(temp);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          navigate('/backtest/login');
        } else {
          toast.error(extractErrorMessage(error));
        }
        console.log(error, 'error at rename basket name')
      });

  }

  const deleteBasket = () => {
    if (activeBasketName === '') {
      toast.warning('Something Wrong!');
      return;
    }
    let data = JSON.stringify({ id: secureLocalStorage.getItem("backtest_id"), 'bucket_name': activeBasketName });
    // Get the token from secureLocalStorage
    let token = secureLocalStorage.getItem('backtest_token');

    // Remove the 'backtest_' prefix if it exists
    if (token && token.startsWith('backtest_')) {
      token = token.replace('backtest_', '');
    } else {
      navigate('/backtest/login');
    }
    let config = { method: "post", maxBodyLength: Infinity, url: DELETE_BASKET, headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` }, data: data, };
    axios
      .request(config)
      .then((response) => {
        toast.success('Basket Deleted Successfully!!');
        fetchBasketName();
        props.setBucketResultVisible(false);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          navigate('/backtest/login');
        } else {
          toast.error(extractErrorMessage(error))
        }
        console.log(error, 'error at delete basket')
      });
  }

  const fetchBasketName = () => {
    const id = secureLocalStorage.getItem("backtest_id");
    // Get the token from secureLocalStorage
    let token = secureLocalStorage.getItem('backtest_token');

    // Remove the 'backtest_' prefix if it exists
    if (token && token.startsWith('backtest_')) {
      token = token.replace('backtest_', '');
    } else {
      navigate('/backtest/login');
    }
    let config = { method: "get", maxBodyLength: Infinity, url: `${FETCH_BASKET_NAME}/${id}`, headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` }, };
    axios
      .request(config)
      .then((response) => {
        if (response.data.result.length === 0) {
          updateBasketList([]); updateActiveBasket([]); updateActiveBasketName('');
        }
        else {
          updateBasketList(response?.data?.result);
          let newActiveBasket = response?.data?.result[0];
          const temp = new Array(newActiveBasket?.strategy?.length).fill(true);
          updateActiveBasket(newActiveBasket);
          updateActiveBasketName(newActiveBasket?.bucket_name);
          updateStatsMultiplier(new Array(newActiveBasket?.strategy?.length).fill(1))
          updateStatsInclude(Array(newActiveBasket?.strategy?.length).fill(true));
          updateSlippage(new Array(newActiveBasket?.strategy?.length).fill(0))
          temp && updateStrategyCheckedAll(temp);
          props.setExecutedStrategy(Array(newActiveBasket?.strategy?.length).fill(0));
          updateDayOption(Array(newActiveBasket.strategy.length).fill([1, 1, 1, 1, 1]))
          console.log(response?.data?.result, 'all the basket');
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          navigate('/backtest/login');
        } else {
          toast.error(extractErrorMessage(error));
        }
        console.log(error, 'error at fetch basket name')
      });
  }



  return (
    <>
      <div className="flex flex-col gap-2 mt-2">
        <div className="w-full flex flex-col items-start sm:items-center">
          {/* Title */}
          <div className="text-xl text-left font-semibold text-gray-800">List of Option Matrix</div>

          {/* Select Dropdown */}
          <div className="w-full">
            <select
              className="w-full h-10 p-1 border border-gray-300 rounded-lg outline-none bg-white text-gray-700 hover:border-gray-400 focus:border-blue-500 transition-all"
              value={activeBasketName} // Use a unique ID instead of JSON string
              onChange={(e) => {
                const name = e.target.value;
                const selectedBasket = basketList.find(item => item.bucket_name === name); // Find basket by ID
                console.log(name, 'name', 'basketId', selectedBasket);
                if (selectedBasket) {
                  updateActiveBasket(selectedBasket);
                  updateActiveBasketName(selectedBasket.bucket_name);
                  updateStatsInclude(Array(selectedBasket?.strategy?.length).fill(true));
                  updateStatsMultiplier(Array(selectedBasket.strategy.length).fill(1));
                  updateSlippage(Array(selectedBasket?.strategy?.length).fill(0));
                  updateStrategyCheckedAll(Array(selectedBasket?.strategy?.length).fill(true));
                  updateDayOption(Array(selectedBasket.strategy.length).fill([1, 1, 1, 1, 1]))
                }
              }}
            >
              <option value={-1}>Select Basket</option>
              {basketList.length > 0 &&
                basketList.map((item, key) => (
                  <option key={key} value={item.bucket_name}>
                    {item.bucket_name}
                  </option>
                ))}
            </select>
          </div>

        </div>

        {/* <StrategyDefault result={props.result} setResult={props.setResult} executedStrategy={props.executedStrategy} setExecutedStrategy={props.setExecutedStrategy} /> */}
        <button
          className="p-2 bg-gradient-to-r from-blue-500 to-blue-700 text-white rounded-md shadow-md hover:shadow-lg transition-transform duration-300 flex items-center justify-center gap-2"
          onClick={() => updateBoxShow(true)}
        >
          <FaPlus className="text-2xl font-semibold" />
          Add New Option Matrix
        </button>
        <ImportBasket />
        <button
          className="p-2 bg-gradient-to-r from-blue-500 to-blue-700 text-white rounded-md shadow-md hover:shadow-lg transition-transform duration-300 flex items-center justify-center gap-2"
          onClick={() => { setCurrentBucketName(activeBasketName); setVisibleRename(true); }}
        >
          <MdDriveFileRenameOutline className="text-2xl font-semibold" />
          Rename Option Matrix
        </button>
        <button
          className="p-2 bg-gradient-to-r from-blue-500 to-blue-700 text-white rounded-md shadow-md hover:shadow-lg transition-transform duration-300 flex items-center justify-center gap-2"
          onClick={() => setVisibleDeleteBasket(true)}
        >
          <MdDeleteOutline className="text-2xl font-semibold" />
          Remove Option Matrix
        </button>
      </div>

      {/* Dialog Box for Rename basket */}
      <Dialog className="border-2 border-t-8 border-blue-500 rounded-xl" onClose={(event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') { setVisibleRename(false); }
      }} open={visibleRename}>
        <div className="flex flex-row border-b-2 bg-blue-400 text-white">
          <div className="text-xl p-2 w-96">Rename a Option Matrix Name</div>
          <div className="text-xl flex justify-end font-semibold p-2 cursor-pointer"><MdClose className="text-2xl font-semibold" onClick={() => { setVisibleRename(false); }} /></div>
        </div>
        <div className="flex flex-col text-center justify-center mt-2 p-6">
          <div className="font-serif text-l text-left">Option Matrix Name</div>
          <input type="text" className="rounded-md outline-0 border-[1px] border-black bg-[#ECF0FF] justify-center p-1" placeholder="Option Matrix Name(Max 40 Character)" value={currentBucketName} onChange={(e) => { setCurrentBucketName(e.target.value); }} />
        </div>
        <div className="flex justify-end gap-2 p-2 bg-gray-400">
          <button className="border-2 p-1 w-24 bg-blue-600 rounded-lg justify-center text-center text-white cursor-pointer" onClick={() => { RenameBasketName() }}>Rename</button>
          <button className="border-2 p-1 w-24 bg-white rounded-lg justify-center text-center text-blue-600 border-blue-600 cursor-pointer" onClick={() => { setVisibleRename(false); }}>Cancel</button>
        </div>
      </Dialog>

      {/* Confirmation to Delete a Basket */}
      <Dialog className="border-2 border-t-8 border-blue-500 rounded-xl" onClose={(event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') { setVisibleDeleteBasket(false); }
      }} open={visibleDeleteBasket}>
        <div className="flex flex-row border-b-2 bg-blue-400 text-white">
          <div className="text-xl p-2">Delete Option Matrix</div>
          <div className="text-xl flex flex-grow justify-end font-semibold p-2 cursor-pointer"><MdClose className="text-2xl font-bold flex " onClick={() => { setVisibleDeleteBasket(false); }} /></div>
        </div>
        <div className="flex flex-col text-center justify-center mt-2 p-4">
          <div className="font-serif text-lg text-center">Are You Sure Want to Delete a Option Matrix<span className="text-lg font-semibold text-blue-500 pl-1">({activeBasketName})</span>?</div>
        </div>
        <div className="flex justify-end gap-2 p-1 bg-gray-400">
          <button className="border-2 p-1 w-24 bg-blue-500 rounded-lg justify-center text-center text-white cursor-pointer" onClick={() => { setVisibleDeleteBasket(false); deleteBasket(true); console.log('delete basket'); }}>YES</button>
          <button className="border-2 p-1 w-24 bg-white rounded-lg justify-center text-center text-blue-600 border-blue-600 cursor-pointer" onClick={() => { setVisibleDeleteBasket(false); }}>Cancel</button>
        </div>
      </Dialog>
    </>
  )
}

export default Sidebar