export const totalWeekProfit = (weekResult) => {
    let total = [0, 0, 0, 0, 0, 0]
    let week_profit = {}
    let sum = 0;
    Object.keys(weekResult).forEach(key => {
        let value = weekResult[key];
        for (let i = 0; i <= 5; i++) {
            total[i] += value[i];
            for (let i = 1; i <= 5; i++) {
                sum += value[i];
            }
            week_profit[key] = sum;
            sum = 0;
        }
    });
    return total;
}

// margin added only --- **
export const totalMonthProfit = (monthlyResult, monthlyDrawdown, maximumDrawdown,margin) => {
    let total = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    let month_profit = {}
    let total_profit = 0
    let sum = 0;
    Object.keys(monthlyResult).forEach(key => {
        let value = monthlyResult[key];
        for (let i = 0; i <= 12; i++) {
            total[i] += value[i];
            for (let i = 1; i <= 12; i++) {
                sum += value[i];
            }
            month_profit[key] = sum;

            sum = 0;
        }
        total_profit += month_profit[key];
    });

    Object.keys(monthlyResult).forEach(key => {
        monthlyResult[key].push(month_profit[key]);
        monthlyResult[key].push(monthlyDrawdown[key]);
        monthlyResult[key].push(parseFloat(margin / month_profit[key]).toFixed(0));
    })
    monthlyResult['Total'] = total;
    monthlyResult['Total'].push(total_profit);
    monthlyResult['Total'].push(maximumDrawdown);
    monthlyResult['Total'].push(parseFloat(margin / total_profit).toFixed(0));
    return monthlyResult;
}


// executedStrategy --> props.executedStrategy
// Method to Calculate Correlation for Multiple Arrays
export const calculateCorrelationMatrix = (arrays,executedStrategy) => {
    const correlationMatrix = [];

    // Iterate through each pair of arrays
    for (let i = 0; i < arrays.length; i++) {
        const row = [];
        if (executedStrategy[i] == 2) {
            for (let j = 0; j < arrays.length; j++) {
                // Calculate correlation for the pair of arrays
                if (executedStrategy[j] == 2) {
                    const correlation = calculateCorrelation(arrays[i], arrays[j]);
                    row.push(correlation);
                } else {
                    row.push(0);
                }
            }
        } else {
            row.push(Array(arrays.length).fill(0));
        }
        correlationMatrix.push(row);
    }
    // setCorrelationMatrix(correlationMatrix);
    // console.log(correlationMatrix,'correlation matrix');
    return correlationMatrix;
};

// Method to Calculate Coefficient
export const calculateCorrelation = (arr1, arr2) => {
    // Check if arrays are of equal length
    if (arr1.length !== arr2.length) {
        throw new Error("Arrays must have the same length");
    }

    // Calculate means
    const meanArr1 = arr1.reduce((sum, val) => sum + val, 0) / arr1.length;
    const meanArr2 = arr2.reduce((sum, val) => sum + val, 0) / arr2.length;

    // Calculate the numerator and denominators
    let numerator = 0;
    let denominatorX = 0;
    let denominatorY = 0;

    for (let i = 0; i < arr1.length; i++) {
        const diffX = arr1[i] - meanArr1;
        const diffY = arr2[i] - meanArr2;

        numerator += diffX * diffY;
        denominatorX += diffX ** 2;
        denominatorY += diffY ** 2;
    }

    // Calculate the correlation coefficient
    const correlation = numerator / Math.sqrt(denominatorX * denominatorY);

    return correlation;
}

// Method to Find the Next Thursday
export const Next_Thursday = (given_date) => {
    const givenDate = new Date(given_date);

    let nextThursday = new Date(givenDate)
    const currentWeekDay = nextThursday.getDay();
    const daysUntilNextThursday = (4 - currentWeekDay + 7) % 7;
    nextThursday.setDate(nextThursday.getDate() + daysUntilNextThursday);
    const nextThursdayDay = nextThursday.getDate();
    const nextThursdayMonth = nextThursday.getMonth() + 1;
    const nextThursdayYear = nextThursday.getFullYear();
    const formattedDate = `${nextThursdayMonth}/${nextThursdayDay}/${nextThursdayYear}`;
    return formattedDate;
}