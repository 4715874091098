import React, { useEffect, useState } from 'react';
import { SERIES_TRADING } from '../../../constant/constant';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import { FaArrowLeft } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa";
import { MdOutlineKeyboardArrowUp } from "react-icons/md";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import HelpIcon from '@mui/icons-material/Help';
import { Tooltip } from 'react-tooltip';
import Excel from './Excel'
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const month = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

const Future = () => {
    const [symbol, setSymbol] = useState('NIFTY');
    const [targetProfit, setTargetProfit] = useState(100);
    const [targetLoss, setTargetLoss] = useState(100);
    const [targetDiff, setTargetDiff] = useState(100);
    const [startDate, setStartDate] = useState("2022-01-03");
    const [endDate, setEndDate] = useState("2022-06-03");
    const [startTime, setStartTime] = useState("09:30");
    const [endTime, setEndTime] = useState("15:29");
    const [lotSize, setLotSize] = useState(1);
    const [action, setAction] = useState('BUY');
    const [expiryDay, setExpiryDay] = useState(0);
    // Result
    const [tradeList, setTradeList] = useState([])
    const [totalPnl, setTotalPnl] = useState([])
    const [monthlyProfit, setMonthlyProfit] = useState([])
    const [investmentDay, setInvestmentDay] = useState([])
    const [maximumProfit, setMaximumProfit] = useState(0)
    const [minimumProfit, setMinimumProfit] = useState(0)
    const [margin, setMargin] = useState(0)
    const [maximumDrawdown, setMaximumDrawdown] = useState(0)
    const [resultShow, setResultShow] = useState(false);
    const [futurePrice, setFuturePrice] = useState([])
    const [futurePriceDatetime, setFuturePriceDatetime] = useState([])
    const [profitList, setProfitList] = useState()
    const [bookedMonthProfit, setBookedMonthProfit] = useState([])
    const [monthlyLossExpiry, setMontlyLossExpiry] = useState([])
    const [rollOverQuantity, setRollOverQuantity] = useState([]);
    const [trailRollOver, setTrailRollOver] = useState([])
    const [maximumRollOverQuantity, setMaximumRollOverQuantity] = useState(0);
    const [minimumBookedProfit, setMinimumBookedProfit] = useState(0)
    const [maximumBookedProfit, setMaximumBookedProfit] = useState(0)
    const [trailRollOverSum, setTrailRollOverSum] = useState([])
    // page Size
    const [pageSize, setPageSize] = useState(1)
    const [currentIndex, setCurrentIndex] = useState(1)
    // loader
    const [loader, setloader] = useState(false);
    // monthlyResult
    const [monthlyResult, setMonthlyResult] = useState([])
    const [showResult, setShowResult] = useState(1);
    const [normalMonthResult, setNormalMonthResult] = useState({})
    const [normalBookedMonthResult, setNormalBookedMonthResult] = useState({})
    const [normalRolloverMonthResult, setNormalRollOverMonthResult] = useState({})

    const navigate = useNavigate();
    // Sorting the Data on the basis of Index
    const sorted = (index, order) => {
        const data = [];
        for (let i = 0; i < tradeList.length; i++) {
            const sortedData = tradeList[i].sort((a, b) => {
                const itemA = a[index];
                const itemB = b[index];
                if (order === 0) {
                    if (typeof itemA === 'string' && typeof itemB === 'string') {
                        return itemA.localeCompare(itemB);
                    } else if (typeof itemA === 'number' && typeof itemB === 'number') {
                        return itemA - itemB;
                    } else {
                        return 0; // No comparison possible, keep the order unchanged
                    }
                } else {
                    if (typeof itemA === 'string' && typeof itemB === 'string') {
                        return itemB.localeCompare(itemA);
                    } else if (typeof itemA === 'number' && typeof itemB === 'number') {
                        return itemB - itemA;
                    } else {
                        return 0; // No comparison possible, keep the order unchanged
                    }
                }
            });
            data.push(sortedData);
        }
        console.log(data, 'data')
        setTradeList(data);
        month_result();
    };
    const calculateTrailRollOver = () => {
        const trailRollOverSum = [];
        for (let i = 0; i < trailRollOver.length; i++) {
            let res = trailRollOver[i].reduce((sum, item) => sum + item[7], 0);
            trailRollOverSum.push(res.toFixed(2)); // Push the result into trailRollOverSum array
        }
        setTrailRollOverSum(trailRollOverSum);
    }
    const calculateMaximumDrawdown = () => {
        let maxProfit = 0;
        let MaximumDrawdown = 0;
        let previous = 0
        let cumulative = new Array(monthlyProfit.length).fill(0);
        for (let i = 0; i < monthlyProfit.length; i++) {
            // Method to find the Cumulative Data
            cumulative[i] = previous + monthlyProfit[i];
            previous = cumulative[i];
            // Method to find the Maximum Drawdown
            // Maximum Drawdown is the biggest loss observed in an investment from peak to trough
            // Formula of Drawdown:: MIN((B2-MAX($B$2-B2))/MAX($B$2-B2)
            if (monthlyProfit[i] > maxProfit)
                maxProfit = monthlyProfit[i];
            if (cumulative[i] > maxProfit) {
                maxProfit = cumulative[i]; // Update maxPrice if a new peak is reached
            }
            const currentDrawdown = (cumulative[i] - maxProfit);

            if (currentDrawdown < MaximumDrawdown) {
                MaximumDrawdown = currentDrawdown; // Update drawdown if a new minimum drawdown is reached
            }
        }
        console.log(MaximumDrawdown, 'Maximum Duration')
        setMaximumDrawdown(MaximumDrawdown.toFixed(2));
        month_result();
    }
    const calculationResult = (monthlyProfit) => {
        let min = monthlyProfit[0], max = monthlyProfit[0];
        for (let i = 0; i < monthlyProfit.length; i++) {
            if (monthlyProfit[i] > max)
                max = monthlyProfit[i];
            if (monthlyProfit[i] < min)
                min = monthlyProfit[i];
        }
        setMinimumProfit(min.toFixed(2));
        setMaximumProfit(max.toFixed(2));
        month_result();
    }

    const month_result = () => {
        let result = [];
        let totalRollOverQuantity = 0, totalBookedMonthProfit = 0, totalMonthlyProfit = 0, totalRollOverLoss = 0;
        let maximumRollOverQuantity = 0;
        for (let i = 0; i < monthlyProfit.length; i++) {
            console.log(i + 1, rollOverQuantity[i], bookedMonthProfit[i], monthlyProfit[i], monthlyLossExpiry[i], 'hello world')
            result.push([i + 1, rollOverQuantity[i], bookedMonthProfit[i], monthlyProfit[i], monthlyLossExpiry[i]]);
            totalRollOverQuantity += rollOverQuantity[i] ? rollOverQuantity[i] : 0;
            totalBookedMonthProfit += bookedMonthProfit[i];
            totalMonthlyProfit += monthlyProfit[i];
            totalRollOverLoss += monthlyLossExpiry[i];
            if (rollOverQuantity[i] > maximumRollOverQuantity) {
                maximumRollOverQuantity = rollOverQuantity[i];
            }
        }
        result.push(['Total', maximumRollOverQuantity, totalBookedMonthProfit.toFixed(2), totalMonthlyProfit.toFixed(2), totalRollOverLoss.toFixed(2)]);
        console.log(result, 'result')
        setMaximumRollOverQuantity(maximumRollOverQuantity);
        setMonthlyResult(result);
        //maximum and minimum of BookedProfit
        if (bookedMonthProfit.length > 0) {
            let minBookedPnl = 99999999999;
            let maxBookedPnl = 0;
            for (let i = 0; i < bookedMonthProfit.length; i++) {
                if (bookedMonthProfit[i] < minBookedPnl)
                    minBookedPnl = bookedMonthProfit[i];
                if (bookedMonthProfit[i] > maxBookedPnl)
                    maxBookedPnl = bookedMonthProfit[i];
            }
            console.log(minBookedPnl, maxBookedPnl, 'min and max booked pnl')
            // setMinimumBookedProfit(minBookedPnl);
            // setMaximumBookedProfit(maxBookedPnl);
        }
    }
    // Month Result where contain data value in monthly wise format
    const normalMonthlyResult = (list) => {
        let normal = {}
        let minBookedPnl = 999999999999
        let maxBookedPnl = 0
        let normalBooked = {}
        let normalRollOver = {}

        for (let i = 0; i < list.length; i++) {
            for (let j = 0; j < list[i].length; j++) {
                let date = list[i][j][8];

                if (list[i][j][10] !== '') {
                    if (normalRollOver.hasOwnProperty(date[0] + date[1] + date[2] + date[3])) {
                        normalRollOver[date[0] + date[1] + date[2] + date[3]][parseInt(date[5] + date[6])] += parseInt(list[i][j][7])
                        normalRollOver[date[0] + date[1] + date[2] + date[3]][13] += parseInt(list[i][j][7]);
                    } else {
                        normalRollOver[date[0] + date[1] + date[2] + date[3]] = new Array(14).fill(0);
                        normalRollOver[date[0] + date[1] + date[2] + date[3]][parseInt(date[5] + date[6])] = parseInt(list[i][j][7]);
                        normalRollOver[date[0] + date[1] + date[2] + date[3]][13] = parseInt(list[i][j][7]);
                    }
                }
                if (list[i][j][10] == '') {
                    if (normalBooked.hasOwnProperty(date[0] + date[1] + date[2] + date[3])) {
                        normalBooked[date[0] + date[1] + date[2] + date[3]][parseInt(date[5] + date[6])] += parseInt(list[i][j][7])
                        normalBooked[date[0] + date[1] + date[2] + date[3]][13] += parseInt(list[i][j][7]);
                    } else {
                        normalBooked[date[0] + date[1] + date[2] + date[3]] = new Array(14).fill(0);
                        normalBooked[date[0] + date[1] + date[2] + date[3]][parseInt(date[5] + date[6])] = parseInt(list[i][j][7]);
                        normalBooked[date[0] + date[1] + date[2] + date[3]][13] = parseInt(list[i][j][7]);
                    }
                }
                // Method to find the MontlyWise Profit
                if (normal.hasOwnProperty(date[0] + date[1] + date[2] + date[3])) {
                    normal[date[0] + date[1] + date[2] + date[3]][parseInt(date[5] + date[6])] += parseInt(list[i][j][7])
                    normal[date[0] + date[1] + date[2] + date[3]][13] += parseInt(list[i][j][7]);
                } else {
                    normal[date[0] + date[1] + date[2] + date[3]] = new Array(14).fill(0);
                    normal[date[0] + date[1] + date[2] + date[3]][parseInt(date[5] + date[6])] = parseInt(list[i][j][7]);
                    normal[date[0] + date[1] + date[2] + date[3]][13] = parseInt(list[i][j][7]);
                }
            }
        }
        Object.entries(normal).map(([key, value]) => {
            // Your logic here, for example:
            for (let i = 1; i <= 12; i++) {
                if (value[i] < minBookedPnl)
                    minBookedPnl = value[i];
                if (value[i] > maxBookedPnl)
                    maxBookedPnl = value[i];
            }
        });
        setMinimumBookedProfit(minBookedPnl);
        setMaximumBookedProfit(maxBookedPnl);
        console.log(minBookedPnl, maxBookedPnl, 'min booked pnl')
        console.log(normal, 'normal');
        setNormalMonthResult(normal);
        setNormalBookedMonthResult(normalBooked);
        setNormalRollOverMonthResult(normalRollOver);
    }
    const startSeriesTrading = () => {
        setloader(true);
        setResultShow(false);
        // Get the token from secureLocalStorage
        let token = secureLocalStorage.getItem('backtest_token');

        // Remove the 'backtest_' prefix if it exists
        if (token && token.startsWith('backtest_')) {
            token = token.replace('backtest_', '');
        }else{
            navigate('/backtest/login')
        }
        if (endDate < startDate) {
            toast.warn("Please enter valid Start Date and End Date");
            setloader(false);
            return;
        }
        console.log(targetDiff, lotSize, endDate, startDate, 'result');
        if (targetDiff == 0 || targetDiff == '') {
            toast.warn('Target Difference must be greater than 0');
            setloader(false);
            return;
        }
        if (lotSize == 0) {
            toast.warn('Lot Size must be greater than 0');
            setloader(false);
            return;
        }
        const data = {
            "symbol": symbol, "targetProfit": targetProfit, "targetLoss": targetLoss, "targetDiff": targetDiff, "startDate": startDate, "endDate": endDate, "startTime": startTime, "endTime": endTime,
            "lotSize": lotSize, "action": action, "expiryDay": expiryDay
        }
        let config = { method: "post", maxBodyLength: Infinity, url: SERIES_TRADING, headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}`, }, data: data, };
        axios(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
                setTotalPnl(response.data?.totalProfit);
                setTradeList(response.data?.result);
                setMonthlyProfit(response.data?.monthlyProfit);
                setInvestmentDay(response.data?.investmentDay);
                setMargin(response.data?.margin);
                setResultShow(true);
                calculationResult(response.data?.monthlyProfit);
                setFuturePrice(response.data?.futurePrice);
                setFuturePriceDatetime(response.data?.futurePriceDatetime);
                setProfitList(response.data?.profitList);
                setBookedMonthProfit(response.data?.bookedMonthProfit);
                setMontlyLossExpiry(response.data?.monthlyLossExpiry);
                setRollOverQuantity(response.data?.rollOverQuantity);
                setTrailRollOver(response.data?.trailRollOver);
                normalMonthlyResult(response.data?.result);
                console.log(response.data?.bookedMonthProfit, 'booked month profit');
                console.log(response.data?.monthlyLossExpiry, 'montly loss expiry');
                console.log(response.data?.monthlyProfit, 'monthly profit')
                setloader(false);

            }).catch((error) => {
                console.log(error, 'error');
                setloader(false);
            })
    };
    useEffect(() => {
        month_result();
        calculateMaximumDrawdown();
        calculateTrailRollOver();
    }, [showResult, totalPnl])

    return (
        <div className='bg-[#DAECF2] min-h-screen'>
            <ToastContainer />
            <div className='flex flex-col m-2'>
                <div className='grid sm:grid-cols-2 lg:grid-cols-3'>
                    <div className='flex flex-row bg-white p-2 m-2'>
                        <div className='w-40 text-[18px]'>Target Profit</div>
                        <input type="number" className='text-l bg-[#ECF0FF] p-1 flex-grow outline-0' value={targetProfit} onChange={(e) => setTargetProfit(e.target.value)} />
                    </div>
                    <div className='flex flex-row bg-white p-2 m-2'>
                        <div className='w-40 text-[18px]'>Symbol</div>
                        <select className='text-l bg-[#ECF0FF] p-1 flex-grow outline-0' value={symbol} onChange={(e) => setSymbol(e.target.value)}>
                            <option value="NIFTY">NIFTY</option>
                            <option value="BANKNIFTY">BANKNIFTY</option>
                        </select>
                    </div>
                    <div className='flex flex-row bg-white p-2 m-2'>
                        <div className='w-40 text-[18px]'>Action</div>
                        <select value={action} className='text-l bg-[#ECF0FF] p-1 flex-grow outline-0' onChange={(e) => { setAction(e.target.value) }}>
                            <option value="BUY">BUY</option>
                            <option value="SELL">SELL</option>
                        </select>
                    </div>
                    <div className='flex flex-row bg-white p-2 m-2'>
                        <div className='w-40 text-[18px]'>Lot</div>
                        <input type="number" className='text-l bg-[#ECF0FF] p-1 flex-grow outline-0' value={lotSize} onChange={(e) => setLotSize(e.target.value)} />
                    </div>
                    <div className='flex flex-row bg-white p-2 m-2'>
                        <div className='w-40 text-[18px]'>Entry Difference</div>
                        <input type="number" className='text-l bg-[#ECF0FF] p-1 flex-grow outline-0' value={targetDiff} onChange={(e) => setTargetDiff(e.target.value)} />
                    </div>
                    <div className='flex flex-row bg-white p-2 m-2'>
                        <div className='w-40 text-[18px]'>Start Date</div>
                        <input type="date" className='text-l bg-[#ECF0FF] p-1 flex-grow outline-0' value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                    </div>
                    <div className='flex flex-row bg-white p-2 m-2'>
                        <div className='w-40 text-[18px]'>End Date</div>
                        <input type="date" className='text-l bg-[#ECF0FF] p-1 flex-grow outline-0' value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                    </div>
                    <div className='flex flex-row bg-white p-2 m-2'>
                        <div className='w-40 text-[18px]'>Start Time</div>
                        <input type="time" className='text-l bg-[#ECF0FF] p-1 flex-grow outline-0' value={startTime} onChange={(e) => setStartTime(e.target.value)} />
                    </div>
                    <div className='flex flex-row bg-white p-2 m-2'>
                        <div className='w-40 text-[18px]'>RollOver</div>
                        <input type="time" className='text-l bg-[#ECF0FF] p-1 flex-grow outline-0' value={endTime} onChange={(e) => { setEndTime(e.target.value); }}></input>
                    </div>
                </div>
                <div className='h-[4px] bg-white mb-1 mt-5'></div>
                {!loader && <div className='bg-[#2D5BFF] h-8 rounded-md text-center text-white text-[16px] cursor-pointer' onClick={startSeriesTrading}>Start Series Trading</div>}
                {loader && <div className="bouncing-loader">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>}
            </div>
            {resultShow && <div className='flex flex-col'>
                <div className='bg-white text-xl rounded-md m-2 text-center'>Series Trading Result</div>
                {/* Only For Result */}
                <div className='grid grid-cols-2 m-2'>
                    <div className='flex flex-row gap-2'>
                        <div className='text-[18px]'>Filter</div>
                        <select className='w-48 outline-0 text-[16px] bg-[#2D5BFF] text-white rounded' value={showResult} onChange={(e) => setShowResult(parseInt(e.target.value))}>
                            <option value={1}>Show Daily Result</option>
                            <option value={2}>Show RollOver Result</option>
                            <option value={3}>Show Monthly Result</option>
                            <option value={4}>Show Without RollOver</option>
                            <option value={5}>Show Trail RollOver</option>
                        </select>
                    </div>
                    <div className='flex justify-end'>
                        <Excel tradeList={tradeList} monthlyResult={monthlyResult} trailRollOver={trailRollOver} trailRollOverSum={trailRollOverSum} action={action} symbol={symbol} lot={lotSize} targetProfit={targetProfit} targetDiff={targetDiff} maximumDrawdown={maximumDrawdown} minimumProfit={minimumProfit} maximumProfit={maximumProfit} margin={margin} totalProfit={totalPnl} investmentDay={investmentDay} monthlyProfit={monthlyProfit} monthlyLossExpiry={monthlyLossExpiry} rollOverQuantity={rollOverQuantity} bookedMonthProfit={bookedMonthProfit} />
                    </div>
                </div>
                <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-2 m-2'>
                    <div className='flex flex-row bg-white border-[1px] border-red-600 rounded-md p-2'>
                        <div className='text-l'>Estimate Margin :</div>
                        <div className='font-semibold flex justify-end flex-grow'>{margin}</div>
                    </div>
                    <div className='flex flex-row bg-white border-[1px] border-red-600 rounded-md p-2'>
                        <div className='text-l'>Total Profit :</div>
                        <div className='font-semibold flex justify-end flex-grow'>{totalPnl}</div>
                    </div>
                    <div className='flex flex-row bg-white border-[1px] border-red-600 rounded-md p-2'>
                        <div className='text-l'>Total Trading Days :</div>
                        <div className='font-semibold flex justify-end flex-grow'>{investmentDay}</div>
                    </div>
                    <div className='flex flex-row bg-white border-[1px] border-red-600 rounded-md p-2'>
                        <div className='text-l'>Maximum Monthly Profit:</div>
                        <div className='font-semibold flex justify-end flex-grow'>{maximumProfit}</div>
                    </div>
                    <div className='flex flex-row bg-white border-[1px] border-red-600 rounded-md p-2'>
                        <div className='text-l'>Minimum Monthly Profit:</div>
                        <div className='font-semibold flex justify-end flex-grow'>{minimumProfit}</div>
                    </div>
                    <div className='flex flex-row bg-white border-[1px] border-red-600 rounded-md p-2'>
                        <div className='text-l'>Maximum Drawdown:</div>
                        <div className='font-semibold flex justify-end flex-grow'>{maximumDrawdown}</div>
                    </div>
                    <div className='flex flex-row bg-white border-[1px] border-red-600 rounded-md p-2'>
                        <div className='text-l'>Maximum Roll Over Quantity:</div>
                        <div className='font-semibold flex justify-end flex-grow'>{maximumRollOverQuantity}</div>
                    </div>
                    <div className='flex flex-row bg-white border-[1px] border-red-600 rounded-md p-2'>
                        <div className='text-l'>Maximum BookedProfit:</div>
                        <div className='font-semibold flex justify-end flex-grow'>{minimumBookedProfit}</div>
                    </div>
                    <div className='flex flex-row bg-white border-[1px] border-red-600 rounded-md p-2'>
                        <div className='text-l'>Minimum BookedProfit:</div>
                        <div className='font-semibold flex justify-end flex-grow'>{maximumBookedProfit}</div>
                    </div>
                </div>

                {
                    (showResult == 3 || showResult == 1) && <div className='bg-white rounded-lg m-2 overflow-auto'>
                        <div className='text-l font-semibold text-center'>Net Profit Monthly</div>
                        <div className='flex flex-row justify-around gap-2 border-2 text-center  bg-blue-500 text-white min-w-[1560px]'>
                            <div className='text-[18px] text-[600] text-center w-32'>Years.</div>
                            {
                                month.map((item, key) => {
                                    return (
                                        <div className='text-[18px] text-[600] text-center w-32'>{item}</div>
                                    )
                                })
                            }
                            <div className='text-[18px] text-[600] text-center w-32'>Total.</div>
                        </div>
                        {
                            Object.entries(normalMonthResult).map(([key, value]) => (
                                <div className=" bg-white flex flex-row justify-around text-center min-w-[1560px]" key={key}>
                                    <div className="p-2 text-black text-center w-32">{key}</div>
                                    <div className="p-2 text-black text-center w-32">{value[1]}</div>
                                    <div className="p-2 text-black text-center w-32">{value[2]}</div>
                                    <div className="p-2 text-black text-center w-32">{value[3]}</div>
                                    <div className="p-2 text-black text-center w-32">{value[4]}</div>
                                    <div className="p-2 text-black text-center w-32">{value[5]}</div>
                                    <div className="p-2 text-black text-center w-32">{value[6]}</div>
                                    <div className="p-2 text-black text-center w-32">{value[7]}</div>
                                    <div className="p-2 text-black text-center w-32">{value[8]}</div>
                                    <div className="p-2 text-black text-center w-32">{value[9]}</div>
                                    <div className="p-2 text-black text-center w-32">{value[10]}</div>
                                    <div className="p-2 text-black text-center w-32">{value[11]}</div>
                                    <div className="p-2 text-black text-center w-32">{value[12]}</div>
                                    <div className="p-2 text-black text-center w-32">{value[13]}</div>
                                </div>
                            ))
                        }
                    </div>
                }
                {
                    (showResult == 3 || showResult == 1) && <div className='bg-white rounded-lg m-2 overflow-auto'>
                        <div className='text-l font-semibold text-center'>Booked Profit Monthly</div>
                        <div className='flex flex-row justify-around gap-2 border-2 text-center  bg-green-500 text-white min-w-[1560px]'>
                            <div className='text-[18px] text-[600] text-center w-32'>Years.</div>
                            {
                                month.map((item, key) => {
                                    return (
                                        <div className='text-[18px] text-[600] text-center w-32'>{item}</div>
                                    )
                                })
                            }
                            <div className='text-[18px] text-[600] text-center w-32'>Total.</div>
                        </div>
                        {
                            Object.entries(normalBookedMonthResult).map(([key, value]) => (
                                <div className=" bg-white flex flex-row justify-around text-center min-w-[1560px]" key={key}>
                                    <div className="p-2 text-black text-center w-32">{key}</div>
                                    <div className="p-2 text-black text-center w-32">{value[1]}</div>
                                    <div className="p-2 text-black text-center w-32">{value[2]}</div>
                                    <div className="p-2 text-black text-center w-32">{value[3]}</div>
                                    <div className="p-2 text-black text-center w-32">{value[4]}</div>
                                    <div className="p-2 text-black text-center w-32">{value[5]}</div>
                                    <div className="p-2 text-black text-center w-32">{value[6]}</div>
                                    <div className="p-2 text-black text-center w-32">{value[7]}</div>
                                    <div className="p-2 text-black text-center w-32">{value[8]}</div>
                                    <div className="p-2 text-black text-center w-32">{value[9]}</div>
                                    <div className="p-2 text-black text-center w-32">{value[10]}</div>
                                    <div className="p-2 text-black text-center w-32">{value[11]}</div>
                                    <div className="p-2 text-black text-center w-32">{value[12]}</div>
                                    <div className="p-2 text-black text-center w-32">{value[13]}</div>
                                </div>
                            ))
                        }
                    </div>
                }
                {
                    (showResult == 3 || showResult == 1) && <div className='bg-white rounded-lg m-2 overflow-auto'>
                        <div className='text-l font-semibold text-center'>Rollover Profit Monthly</div>
                        <div className='flex flex-row justify-around gap-2 border-2 text-center  bg-yellow-500 text-white min-w-[1560px]'>
                            <div className='text-[18px] text-[600] text-center w-32'>Years.</div>
                            {
                                month.map((item, key) => {
                                    return (
                                        <div className='text-[18px] text-[600] text-center w-32'>{item}</div>
                                    )
                                })
                            }
                            <div className='text-[18px] text-[600] text-center w-32'>Total.</div>
                        </div>
                        {
                            Object.entries(normalRolloverMonthResult).map(([key, value]) => (
                                <div className=" bg-white flex flex-row justify-around text-center min-w-[1560px]" key={key}>
                                    <div className="p-2 text-black text-center w-32">{key}</div>
                                    <div className="p-2 text-black text-center w-32">{value[1]}</div>
                                    <div className="p-2 text-black text-center w-32">{value[2]}</div>
                                    <div className="p-2 text-black text-center w-32">{value[3]}</div>
                                    <div className="p-2 text-black text-center w-32">{value[4]}</div>
                                    <div className="p-2 text-black text-center w-32">{value[5]}</div>
                                    <div className="p-2 text-black text-center w-32">{value[6]}</div>
                                    <div className="p-2 text-black text-center w-32">{value[7]}</div>
                                    <div className="p-2 text-black text-center w-32">{value[8]}</div>
                                    <div className="p-2 text-black text-center w-32">{value[9]}</div>
                                    <div className="p-2 text-black text-center w-32">{value[10]}</div>
                                    <div className="p-2 text-black text-center w-32">{value[11]}</div>
                                    <div className="p-2 text-black text-center w-32">{value[12]}</div>
                                    <div className="p-2 text-black text-center w-32">{value[13]}</div>
                                </div>
                            ))
                        }
                    </div>
                }
                {showResult == 1 && <div className='flex flex-col bg-white m-2 rounded-lg overflow-auto'>
                    <div className='grid grid-cols-12 border-b-2 border-black min-w-[1440px] text-center p-2'>
                        <div className='font-[600] text-[16px] flex flex-row text-center justify-start'>S.No&nbsp;Entry<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(0, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(0, 1); }} /></div></div>
                        <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>Exit&nbsp;<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(2, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(2, 1); }} /></div></div>
                        <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>Entry&nbsp;Price<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(4, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(4, 1); }} /></div></div>
                        <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>Exit&nbsp; Price<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(5, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(5, 1); }} /></div></div>
                        <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>RollOver&nbsp; Price<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(5, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(5, 1); }} /></div></div>
                        <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>Profit<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(6, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(6, 1); }} /></div></div>
                        <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>Monthly&nbsp;Expiry<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(7, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(7, 1); }} /></div></div>
                        <div className='font-[600] text-[16px] flex flex-row col-span-2 text-center justify-center'>RollOver<div data-tooltip-id="my-tooltip" data-tooltip-content="It is the time in which execution of trade will roll over."><HelpIcon sx={{ color: "#0096FF", fontSize: "20px" }} /></div></div>
                        <div className='font-[600] text-[16px]'>Reason</div>
                        <div className='font-[600] text-[16px]'>Low & High</div>
                        <div className='font-[600] text-[16px]'>Target Price</div>
                    </div>
                    {
                        tradeList.map((list, key) => {
                            return (
                                <div>
                                    {
                                        list.map((item, index) => {
                                            return (
                                                <div className='grid grid-cols-12 border-b-2 min-w-[1440px] p-2 text-center' key={index + key} style={{ backgroundColor: item[10] == 'rc' ? '#F8F8F8' : 'white' }}>
                                                    <div className='text-[16px]'>{index + 1} {item[0]}&nbsp;&nbsp;{item[1]}</div>
                                                    <div className='text-[16px]'>{item[2]}&nbsp;&nbsp;{item[3]}</div>
                                                    <div className='text-[16px]'>{item[4]}</div>
                                                    <div className='text-[16px]'>{item[5]}</div>
                                                    <div className='text-[16px]'>{item[6].toFixed(2)}</div>
                                                    <div className='text-[16px]' style={{ color: item[7] > 0 ? "green" : "red" }}>{item[7]}</div>
                                                    <div className='text-[16px]'>{item[8]}</div>
                                                    <div className='text-[16px] col-span-2'>{item[9]}<br />{item[10] == '' ? '' : item[10] == 'rc' ? '' : `(${item[10]}-${item[5]})=${(item[10] - item[5]).toFixed(2)}`}</div>
                                                    <div className='text-[16px]'>{item[11]}</div>
                                                    <div className='text-[16px]'>{item[12].toFixed(2)}--{item[13].toFixed(2)}</div>
                                                    <div className='text-[16px'>{item[14].toFixed(2)}</div>
                                                </div>
                                            )
                                        })
                                    }
                                    <div className='m-2 border-2 rounded-lg border-black grid grid-cols-5 gap-3 p-2'>
                                        <div className=''><span className='font-[600]'>Month :</span>{key + 1}</div>
                                        <div className=''><span className='font-[600]'>RollOverQuantity :</span>{rollOverQuantity[key]}</div>
                                        <div className=''><span className='font-[600]'>Booked Profit/Loss:</span> {bookedMonthProfit[key]} </div>
                                        <div className=''><span className='font-[600]'>Net Monthly Profit/Loss:</span> {monthlyProfit[key]}</div>
                                        <div className=''><span className='font-[600]'>Roll Over Profit/Loss:</span> {monthlyLossExpiry[key]}</div>
                                    </div>
                                </div>

                            )
                        })
                    }
                </div>}
                {/* Monthly Result */}
                {showResult == 3 && <div className='bg-white rounded-lg m-2'>
                    <div className='grid grid-cols-5 gap-2 border-2 text-center bg-blue-500 text-white'>
                        <div className='text-[18px] text-[600]'>Serial No.</div>
                        <div className='text-[18px] text-[600]'>RollOverQuantity</div>
                        <div className='text-[18px] text-[600]'>BookedMonthlyProfit/Loss</div>
                        <div className='text-[18px] text-[600]'>RollOverProfit/Loss</div>
                        <div className='text-[18px] text-[600]'>NetMonthlyProfit/Loss</div>
                    </div>
                    {
                        monthlyResult.map((item, index) => {
                            return (
                                <div className='grid grid-cols-5 text-center p-1 border-b-[1px]' style={{ "backgroundColor": index == monthlyResult.length - 1 ? 'lightblue' : 'white' }}>
                                    <div className='text-[16px]'>{item[0]}</div>
                                    <div className='text-[16px]'>{item[1]}</div>
                                    <div className='text-[16px]'>{item[2]}</div>
                                    <div className='text-[16px]'>{item[4]}</div>
                                    <div className='text-[16px]'>{item[3]}</div>
                                </div>
                            )
                        })
                    }
                </div>}
                {/* Without Roll Over */}
                {showResult == 4 && <div className='flex flex-col bg-white m-2 rounded-lg overflow-auto'>
                    <div className='grid grid-cols-12 border-b-2 border-black min-w-[1440px] text-center p-2'>
                        <div className='font-[600] text-[16px] flex flex-row text-center justify-start'>S.No&nbsp;Entry<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(0, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(0, 1); }} /></div></div>
                        <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>Exit&nbsp;<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(2, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(2, 1); }} /></div></div>
                        <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>Entry&nbsp;Price<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(4, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(4, 1); }} /></div></div>
                        <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>Exit&nbsp; Price<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(5, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(5, 1); }} /></div></div>
                        <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>RollOver&nbsp; Price<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(5, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(5, 1); }} /></div></div>
                        <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>Profit<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(6, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(6, 1); }} /></div></div>
                        <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>Monthly&nbsp;Expiry<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(7, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(7, 1); }} /></div></div>
                        <div className='font-[600] text-[16px] flex flex-row col-span-2 text-center justify-center'>RollOver<div data-tooltip-id="my-tooltip" data-tooltip-content="It is the time in which execution of trade will roll over."><HelpIcon sx={{ color: "#0096FF", fontSize: "20px" }} /></div></div>
                        <div className='font-[600] text-[16px]'>Reason</div>
                        <div className='font-[600] text-[16px]'>Low & High</div>
                        <div className='font-[600] text-[16px]'>Target Price</div>
                    </div>
                    {
                        tradeList.map((list, key) => {
                            return (
                                <div>
                                    {
                                        list.map((item, index) => {
                                            return (
                                                <div>
                                                    {item[10] == '' && <div className='grid grid-cols-12 border-b-2 min-w-[1440px] p-2 text-center' key={index + key} style={{ backgroundColor: item[10] == 'rc' ? '#F8F8F8' : 'white' }}>
                                                        <div className='text-[16px]'>{index + 1} {item[0]}&nbsp;&nbsp;{item[1]}</div>
                                                        <div className='text-[16px]'>{item[2]}&nbsp;&nbsp;{item[3]}</div>
                                                        <div className='text-[16px]'>{item[4]}</div>
                                                        <div className='text-[16px]'>{item[5]}</div>
                                                        <div className='text-[16px]'>{item[6].toFixed(2)}</div>
                                                        <div className='text-[16px]' style={{ color: item[7] > 0 ? "green" : "red" }}>{item[7]}</div>
                                                        <div className='text-[16px]'>{item[8]}</div>
                                                        <div className='text-[16px] col-span-2'>{item[9]}<br />{item[10] == '' ? '' : item[10] == 'rc' ? '' : `(${item[10]}-${item[5]})=${(item[10] - item[5]).toFixed(2)}`}</div>
                                                        <div className='text-[16px]'>{item[11]}</div>
                                                        <div className='text-[16px]'>{item[12].toFixed(2)}--{item[13].toFixed(2)}</div>
                                                        <div className='text-[16px'>{item[14].toFixed(2)}</div>
                                                    </div>}
                                                </div>
                                            )
                                        })
                                    }
                                    <div className='m-2 rounded-lg bg-blue-600 text-white grid grid-cols-3 gap-3 p-2'>
                                        <div className=''><span className='font-[600]'>Month :</span>{key + 1}</div>
                                        <div className=''><span className='font-[600]'>Booked Profit/Loss:</span> {bookedMonthProfit[key]} </div>
                                        <div className=''><span className='font-[600]'>Booked Quantity:</span> {list.length - rollOverQuantity[key]}</div>
                                    </div>
                                </div>

                            )
                        })
                    }
                </div>}

                {showResult == 2 && <div className='flex flex-col bg-white m-2 rounded-lg overflow-auto'>
                    <div className='grid grid-cols-12 border-b-2 border-black min-w-[1440px] text-center p-2'>
                        <div className='font-[600] text-[16px] flex flex-row text-center justify-start'>S.No&nbsp;Entry<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(0, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(0, 1); }} /></div></div>
                        <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>Exit&nbsp;<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(2, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(2, 1); }} /></div></div>
                        <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>Entry&nbsp;Price<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(4, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(4, 1); }} /></div></div>
                        <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>Exit&nbsp; Price<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(5, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(5, 1); }} /></div></div>
                        <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>RollOver&nbsp; Price<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(5, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(5, 1); }} /></div></div>
                        <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>Profit<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(6, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(6, 1); }} /></div></div>
                        <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>Monthly&nbsp;Expiry<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(7, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(7, 1); }} /></div></div>
                        <div className='font-[600] text-[16px] flex flex-row col-span-2 text-center justify-center'>RollOver<div data-tooltip-id="my-tooltip" data-tooltip-content="It is the time in which execution of trade will roll over."><HelpIcon sx={{ color: "#0096FF", fontSize: "20px" }} /></div></div>
                        <div className='font-[600] text-[16px]'>Reason</div>
                        <div className='font-[600] text-[16px]'>Low & High</div>
                        <div className='font-[600] text-[16px]'>Target Price</div>
                    </div>
                    {
                        tradeList.map((list, key) => {
                            return (
                                <div>
                                    {
                                        list.map((item, index) => {
                                            return (
                                                <div>
                                                    {item[10] != '' && <div className='grid grid-cols-12 border-b-2 min-w-[1440px] p-2 text-center' key={index + key} style={{ backgroundColor: item[10] == 'rc' ? '#F8F8F8' : 'white' }}>
                                                        <div className='text-[16px]'>{index + 1} {item[0]}&nbsp;&nbsp;{item[1]}</div>
                                                        <div className='text-[16px]'>{item[2]}&nbsp;&nbsp;{item[3]}</div>
                                                        <div className='text-[16px]'>{item[4]}</div>
                                                        <div className='text-[16px]'>{item[5]}</div>
                                                        <div className='text-[16px]'>{item[6].toFixed(2)}</div>
                                                        <div className='text-[16px]' style={{ color: item[7] > 0 ? "green" : "red" }}>{item[7]}</div>
                                                        <div className='text-[16px]'>{item[8]}</div>
                                                        <div className='text-[16px] col-span-2'>{item[9]}<br />{item[10] == '' ? '' : item[10] == 'rc' ? '' : `(${item[10]}-${item[5]})=${(item[10] - item[5]).toFixed(2)}`}</div>
                                                        <div className='text-[16px]'>{item[11]}</div>
                                                        <div className='text-[16px]'>{item[12].toFixed(2)}--{item[13].toFixed(2)}</div>
                                                        <div className='text-[16px'>{item[14].toFixed(2)}</div>
                                                    </div>}
                                                </div>
                                            )
                                        })
                                    }
                                    <div className='m-2 rounded-lg text-white bg-blue-600 grid grid-cols-3 gap-3 p-2'>
                                        <div className=''><span className='font-[600]'>Month :</span>{key + 1}</div>
                                        <div className=''><span className='font-[600]'>RollOverQuantity :</span>{rollOverQuantity[key]}</div>
                                        <div className=''><span className='font-[600]'>Roll Over Profit/Loss:</span> {monthlyLossExpiry[key]}</div>
                                    </div>
                                </div>

                            )
                        })
                    }
                </div>}
                {showResult == 5 && <div className='flex flex-col bg-white m-2 rounded-lg overflow-auto'>
                    <div className='grid grid-cols-12 border-b-2 border-black min-w-[1440px] text-center p-2'>
                        <div className='font-[600] text-[16px] flex flex-row text-center justify-start'>S.No&nbsp;Entry<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(0, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(0, 1); }} /></div></div>
                        <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>Exit&nbsp;<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(2, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(2, 1); }} /></div></div>
                        <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>Entry&nbsp;Price<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(4, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(4, 1); }} /></div></div>
                        <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>Exit&nbsp; Price<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(5, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(5, 1); }} /></div></div>
                        <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>RollOver&nbsp; Price<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(5, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(5, 1); }} /></div></div>
                        <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>Profit<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(6, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(6, 1); }} /></div></div>
                        <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>Monthly&nbsp;Expiry<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(7, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(7, 1); }} /></div></div>
                        <div className='font-[600] text-[16px] flex flex-row col-span-2 text-center justify-center'>RollOver<div data-tooltip-id="my-tooltip" data-tooltip-content="It is the time in which execution of trade will roll over."><HelpIcon sx={{ color: "#0096FF", fontSize: "20px" }} /></div></div>
                        <div className='font-[600] text-[16px]'>Reason</div>
                        <div className='font-[600] text-[16px]'>Low & High</div>
                        <div className='font-[600] text-[16px]'>Target Price</div>
                    </div>
                    {
                        trailRollOver.map((list, key) => {
                            return (
                                <div>
                                    {
                                        list.map((item, index) => {
                                            return (
                                                <div className='grid grid-cols-12 border-b-2 min-w-[1440px] p-2 text-center' key={index + key} style={{ backgroundColor: item[10] == 'rc' ? '#F8F8F8' : 'white' }}>
                                                    <div className='text-[16px]'>{index + 1} {item[0]}&nbsp;&nbsp;{item[1]}</div>
                                                    <div className='text-[16px]'>{item[2]}&nbsp;&nbsp;{item[3]}</div>
                                                    <div className='text-[16px]'>{item[4]}</div>
                                                    <div className='text-[16px]'>{item[5]}</div>
                                                    <div className='text-[16px]'>{item[6].toFixed(2)}</div>
                                                    <div className='text-[16px]' style={{ color: item[7] > 0 ? "green" : "red" }}>{item[7]}</div>
                                                    <div className='text-[16px]'>{item[8]}</div>
                                                    <div className='text-[16px] col-span-2'>{item[9]}<br />{item[10] == '' ? '' : item[10] == 'rc' ? '' : `(${item[10]}-${item[5]})=${(item[10] - item[5]).toFixed(2)}`}</div>
                                                    <div className='text-[16px]'>{item[11]}</div>
                                                    <div className='text-[16px]'>{item[12].toFixed(2)}--{item[13].toFixed(2)}</div>
                                                    <div className='text-[16px'>{item[14].toFixed(2)}</div>
                                                </div>
                                            )
                                        })
                                    }
                                    <div className='m-2 rounded-lg text-white bg-blue-600 grid grid-cols-3 gap-3 p-2'>
                                        <div className=''><span className='font-[600]'>Month :</span>{key + 1}</div>
                                        <div className=''><span className='font-[600]'>RollOverQuantity :</span>{list.length}</div>
                                        <div className=''><span className='font-[600]'>Roll Over Profit/Loss:</span> {trailRollOverSum[key]}</div>
                                    </div>
                                </div>

                            )
                        })
                    }
                </div>}

                {/* Page Size-> No. Of Items */}
                <div className="grid grid-cols-2" >
                    <div className="flex flex-row m-10">
                        <div className="font-[600] text-[16px]">Page Size</div>
                        <select className="ml-3 outline-0 rounded-md" value={pageSize} onChange={(e) => { setPageSize(e.target.value); }}>
                            <option value={1}>1 month</option>
                            <option value={3}>3 month</option>
                            <option value={6}>6 month</option>
                            <option value={12}>12 month</option>
                        </select>
                    </div>
                    <div className="grid justify-end  mb-10 mt-10 mr-10">
                        <div className="flex flex-row gap-2">
                            <div className='rounded-md border-2 border-white h-[32px] w-[32px] bg-[#2D5BFF] text-white'><FaArrowLeft className="m-1" onClick={() => { setCurrentIndex(currentIndex - 1); }} /></div>
                            <div className="rounded h-[32px] w-[32px] font-[Ubuntu]  text-[16px] font-[700] text-center text-[#2D5BFF] justify-center border-[1px] border-[#ECF0FF] cursor-pointer active:bg-[#ECF0FF]">{currentIndex}</div>
                            <div className='rounded-md border-2 border-white h-[32px] w-[32px] bg-[#2D5BFF] text-white'><FaArrowRight className='m-1' onClick={() => { setCurrentIndex(currentIndex + 1); }} /></div>
                        </div>
                    </div>
                </div>
            </div>
            }
            <Tooltip id="my-tooltip" style={{ width: '300px', borderRadius: '10px', backgroundColor: 'rgb(147 197 253)', color: 'black', boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.1)' }} />
        </div>
    );
};

export default Future;
