import React from 'react'

const CombinedMain = (props) => {
    return (
        <div className='grid grid-cols-1 md:grid-cols-2 w-full border-4 rounded-lg border-blue-500 ml-2 text-lg'>
            <div className='grid grid-cols-1'>
                <div className="flex flex-row bg-green-100 border-white p-1 border-2">
                    <h1 className="pl-2 text-[#232323] font-medium border-r-2 border-gray-100 w-[260px]" >Total Profit</h1>
                    <h1 className="text-right pr-4 justify-center text-[#718EBF] w-[260px]" style={{ color: parseFloat(props.combineStats?.stats?.profit) >= 0 ? "green" : "red" }} >&#8377; {parseFloat(props.combineStats?.stats?.profit).toFixed(0)}</h1>
                </div>
                <div className="flex flex-row border-white p-1 bg-green-100 border-2">
                    <h1 className="pl-2 text-[#232323]  font-medium border-r-2 border-white w-[260px]" >Max Profit</h1>
                    <h1 className="text-right pr-4 justify-center text-green-600 w-[260px]">&#8377; {parseFloat(props.combineStats?.stats?.maxProfit).toFixed(0)}</h1>
                </div>
                <div className="flex flex-row border-white p-1 bg-red-100 border-2">
                    <h1 className="pl-2 text-[#232323]  font-medium border-r-2 border-gray-100 w-[260px]" >Max Loss</h1>
                    <h1 className="text-right pr-4 justify-center text-red-600 w-[260px]">&#8377; {props.combineStats?.stats?.maxLoss == 999999999 ? 0 : parseFloat(props.combineStats?.stats?.maxLoss).toFixed(0)}</h1>
                </div>
                <div className="flex flex-row border-white p-2 bg-green-100 border-2">
                    <h1 className="pl-2 text-[#232323]  font-medium border-r-2 border-gray-100  w-[260px]" >Win%(days)</h1>
                    <h1 className="text-right pr-4 justify-center  text-green-600  w-[260px]" >{props.combineStats?.stats?.investmentDay != 0 ? parseFloat((props.combineStats?.stats?.winTrade * 100) / props.combineStats?.stats?.investmentDay).toFixed(0) : 0}%({props.combineStats?.stats?.winTrade})</h1>
                </div>
                <div className="flex flex-row border-white p-2 bg-red-100 border-2">
                    <h1 className="pl-2 text-[#232323]  font-medium border-r-2 border-white  w-[260px]" >Loss%(days)</h1>
                    <h1 className="text-right pr-4 justify-center text-green-600  w-[260px]" >{props.combineStats?.stats?.investmentDay != 0 ? parseFloat((props.combineStats?.stats?.lossTrade * 100) / props.combineStats?.stats?.investmentDay).toFixed(0) : 0}%({props.combineStats?.stats?.lossTrade})</h1>
                </div>
                <div className="flex flex-row border-white p-2 bg-green-100 border-2">
                    <h1 className="pl-2 text-[#232323]  font-medium border-r-2 border-gray-100  w-[260px]" >Average Profit On Win Days</h1>
                    <h1 className="text-right pr-4 justify-center text-green-600  w-[260px]" >&#8377; {parseFloat(props.combineStats?.stats?.avgProfit).toFixed(0)}</h1>
                </div>
                <div className="flex flex-row border-white p-2 bg-red-100 border-2">
                    <h1 className="pl-2 text-[#232323]  font-medium border-r-2 border-white  w-[260px]" >Average Loss On Loss Days</h1>
                    <h1 className="text-right pr-4 justify-center text-red-600  w-[260px]" >&#8377; {-1 * parseFloat(props.combineStats?.stats?.avgLoss).toFixed(0)}</h1>
                </div>
            </div>
            <div className='grid grid-cols-1'>
                <div className="flex flex-row border-white p-2 bg-green-100">
                    <h1 className="pl-2 text-[#232323] border-r-2 border-white w-[260px]" >Estimated Margin</h1>
                    <h1 className="text-right pr-4 justify-center text-[#718EBF] w-[260px]" >&#8377; {parseFloat(props?.combineStats?.stats?.margin).toFixed(0)}</h1>
                </div>
                <div className="flex flex-row bg-white border-white p-2">
                    <h1 className="pl-2 text-[#232323] font-medium border-r-2 w-[260px]" >Minimum Capital Required</h1>
                    <h1 className="text-right pr-4 justify-center w-[260px]">&#8377; {props.combineStats?.stats?.maximumDrawdown > 0 ? props?.combineStats?.stats?.margin : (props?.combineStats?.stats?.margin + -1 * props.combineStats?.stats?.maximumDrawdown).toFixed(0)}</h1>
                </div>
                <div className="flex flex-row bg-red-100 border-white p-2 border-2">
                    <h1 className="pl-2 text-[#232323]  font-medium border-r-2 border-white w-[260px]" >Maximum Drawdown</h1>
                    <h1 className="text-right pr-4 justify-center text-[#718EBF] font-medium w-[260px]" style={{ color: props.combineStats?.stats?.maximumDrawdown > 0 ? "green" : "red" }} >{parseFloat(props.combineStats?.stats?.maximumDrawdown).toFixed(0)}</h1>
                </div>
                <div className="flex flex-row bg-white border-white p-1 border-2">
                    <h1 className="pl-2 text-[#232323]  font-medium border-r-2 border-white w-[260px]" >Risk Reward Ratio</h1>
                    <h1 className="text-right pr-4 justify-center text-green-600  w-[260px]">{props.combineStats?.stats?.avgLoss != 0 ? (parseFloat(props.combineStats?.stats?.avgProfit) / parseFloat(props.combineStats?.stats?.avgLoss)).toFixed(2) : 'Infinity'}</h1>
                </div>
                <div className="flex flex-row border-white p-2 bg-yellow-50 border-2">
                    <h1 className="pl-2 text-[#232323] font-medium border-r-2 border-gray-100  w-[260px]" > Maximum Win Streak</h1>
                    <h1 className="text-right pr-4 justify-center text-green-600  w-[260px]" >{props.combineStats?.stats?.maxStreak} Days</h1>
                </div>
                <div className="flex flex-row border-white p-2 bg-blue-100 border-2">
                    <h1 className="pl-2 text-[#232323] font-medium border-r-2 border-white  w-[260px]" >Maximum Loss Streak</h1>
                    <h1 className="text-right pr-4 justify-center text-green-600  w-[260px]">{props.combineStats?.stats?.lossStreak} Days</h1>
                </div>
                <div className="flex flex-row bg-white border-white p-1 border-2">
                    <h1 className="pl-2 text-[#232323] font-medium border-r-2 border-white  w-[260px]" >MDD Days(Recovery Days)</h1>
                    <h1 className="text-right pr-4 justify-center text-green-600  w-[260px]">{parseFloat(props.combineStats?.stats?.mdd?.count).toFixed(0)}{`(${parseFloat(props.combineStats?.stats?.mdd?.recovery).toFixed(0)} Days`} {props.combineStats?.stats?.mdd?.continue? '- Running)':')'}
                        <br />
                        <span className="text-sm">{props.combineStats?.stats?.mdd?.startDate && `(${props.combineStats?.stats?.mdd?.startDate} - ${props.combineStats?.stats?.mdd?.endDate})`}</span></h1>
                </div>
            </div>
        </div>
    )
}

export default CombinedMain