export const combineStatsResult = async (worksheet, data) => {
  // Define common styles
  const titleStyle = { 
    bold: true, 
    color: { argb: 'FFFFFF' }, 
    fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '4F81BD' } },
    alignment: { horizontal: 'center', vertical: 'middle' }
  };

  const profitStyle = { font: { color: { argb: '00B050' } }, alignment: { horizontal: 'center' },border: {
    top: { style: 'thin' },
    left: { style: 'thin' },
    bottom: { style: 'thin' },
    right: { style: 'thin' }
  } };

  const lossStyle = { font: { color: { argb: 'FF0000' } }, alignment: { horizontal: 'center' },border: {
    top: { style: 'thin' },
    left: { style: 'thin' },
    bottom: { style: 'thin' },
    right: { style: 'thin' }
  } };
  
  const centeredStyle = {
    alignment: { horizontal: 'center', vertical: 'middle' },
    border: {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' }
    }
  };

  // Set uniform column widths and centered alignment across the sheet
  worksheet.columns = Array(12).fill({ width: 20, alignment: { horizontal: 'center', vertical: 'middle' } });

  // Section: Summary
  const summaryHeader = worksheet.addRow(["Result", "", "Profit", ""]);
  summaryHeader.eachCell((cell, colNumber) => {
    if (colNumber === 1) cell.style = { ...titleStyle, ...centeredStyle };
    if (colNumber === 3) cell.style = { ...titleStyle, ...centeredStyle };
  });

  worksheet.addRow(["Margin", data.margin, "Profit", data.profit]).eachCell((cell, colNumber) => {
    cell.style = colNumber === 2
      ? centeredStyle
      : parseFloat(data.profit) >= 0 && colNumber === 4
        ? profitStyle
        : lossStyle;
  });

  worksheet.addRow(["Max Drawdown", data.maximumDrawdown, "Max Profit", data.maxProfit]).eachCell((cell, colNumber) => {
    cell.style = colNumber === 2 || colNumber === 4 ? (colNumber === 2 ? lossStyle : profitStyle) : centeredStyle;
  });

  worksheet.addRow(["Max Loss", data.maxLoss, "Win Trade", data.winTrade]).eachCell((cell, colNumber) => {
    cell.style = colNumber === 2 ? lossStyle : centeredStyle;
  });

  worksheet.addRow(["Loss Trade", data.lossTrade, "Avg Profit", data.avgProfit]).eachCell((cell, colNumber) => {
    cell.style = colNumber === 4 ? profitStyle : centeredStyle;
  });

  worksheet.addRow(["Avg Loss", data.avgLoss, "Investment Days", data.investmentDay]).eachCell((cell, colNumber) => {
    cell.style = colNumber === 2 ? lossStyle : centeredStyle;
  });

  worksheet.addRow(["Max Streak", data.maxStreak, "Loss Streak", data.lossStreak]).eachCell(cell => {
    cell.style = centeredStyle;
  });

  worksheet.addRow([]); // Blank row for spacing

  // Section: Gap Analysis
  const gapAnalysisHeader = worksheet.getRow(1);
  gapAnalysisHeader.getCell(5).value = "Gap Analysis";
  gapAnalysisHeader.getCell(5).style = { ...titleStyle, ...centeredStyle };

  // Gap Analysis data
  const gapData = [
    ["Up Profit", data.gap.upProfit, profitStyle],
    ["Up Profit Count", data.gap.upProfitCnt, centeredStyle],
    ["Up Loss", data.gap.upLoss, lossStyle],
    ["Up Loss Count", data.gap.upLossCnt, centeredStyle],
    ["Down Profit", data.gap.downProfit, lossStyle],
    ["Down Profit Count", data.gap.downProfitCnt, centeredStyle],
    ["Down Loss", data.gap.downLoss, lossStyle],
    ["Down Loss Count", data.gap.downLossCnt, centeredStyle]
  ];

  gapData.forEach((row, index) => {
    const newRow = worksheet.getRow(2 + index);
    newRow.getCell(5).value = row[0];
    newRow.getCell(5).style = row[2];
    newRow.getCell(6).value = row[1];
    newRow.getCell(6).style = row[2];
  });

  worksheet.addRow([]); // Blank row for spacing

  // Section: VIX
  const vixHeaderRow = worksheet.getRow(1);
  vixHeaderRow.getCell(9).value = "VIX";
  vixHeaderRow.getCell(9).style = { ...titleStyle, ...centeredStyle };

  data.vix.forEach((item, index) => {
    const profitRow = worksheet.getRow(2 + index * 2);
    profitRow.getCell(9).value = `VIX ${index + 1} Profit`;
    profitRow.getCell(9).style = centeredStyle;
    profitRow.getCell(10).value = item.profit;
    profitRow.getCell(10).style = item.profit >= 0 ? profitStyle : lossStyle;

    const countRow = worksheet.getRow(3 + index * 2);
    countRow.getCell(9).value = `VIX ${index + 1} Count`;
    countRow.getCell(9).style = centeredStyle;
    countRow.getCell(10).value = item.cnt;
    countRow.getCell(10).style = centeredStyle;
  });

  worksheet.addRow([]); // Blank row for spacing

  // Section: Day Open
  const dayOpenHeaderRow = worksheet.getRow(1);
  dayOpenHeaderRow.getCell(7).value = "Day Open";
  dayOpenHeaderRow.getCell(7).style = { ...titleStyle, ...centeredStyle };

  const dayOpenData = [
    ["PDH Profit", data.dayOpen.pdhProfit, profitStyle],
    ["PDH Profit Count", data.dayOpen.pdhProfitCnt, centeredStyle],
    ["PDH Loss", data.dayOpen.pdhLoss, lossStyle],
    ["PDH Loss Count", data.dayOpen.pdhLossCnt, centeredStyle],
    ["PDL Profit", data.dayOpen.pdlProfit, profitStyle],
    ["PDL Loss", data.dayOpen.pdlLoss, lossStyle],
    ["PDL Profit Count", data.dayOpen.pdlProfitCnt, centeredStyle],
    ["PDL Loss Count", data.dayOpen.pdlLossCnt, centeredStyle]
  ];

  dayOpenData.forEach((row, index) => {
    const newRow = worksheet.getRow(2 + index);
    newRow.getCell(7).value = row[0];
    newRow.getCell(8).value = row[1];
    newRow.getCell(8).style = row[2];
    newRow.getCell(7).style = row[2];
  });

  worksheet.addRow([]); // Blank row for spacing

  // Section: MDD
  const mddHeaderRow = worksheet.getRow(1);
  mddHeaderRow.getCell(11).value = "MDD";
  mddHeaderRow.getCell(11).style = { ...titleStyle, ...centeredStyle };

  const mddData = [
    ["Count", data.mdd.count, centeredStyle],
    ["Start Date", data.mdd.startDate, centeredStyle],
    ["End Date", data.mdd.endDate, centeredStyle],
    ["Recovery", data.mdd.recovery, centeredStyle],
    ["Status", data.mdd.status, centeredStyle]
  ];

  mddData.forEach((row, index) => {
    const newRow = worksheet.getRow(2 + index);
    newRow.getCell(11).value = row[0];
    newRow.getCell(11).style = row[2];
    newRow.getCell(12).value = row[1];
    newRow.getCell(12).style = row[2];
  });

  worksheet.addRow([]); // Blank row for spacing

  return worksheet;
};
