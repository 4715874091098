import { useState, useRef, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import logo from './Home/assets/fprognos_logo.webp';
import secureLocalStorage from 'react-secure-storage';
import { GET_PROFILE_IMAGE } from '../../constant/constant';
import { FaArrowRight } from 'react-icons/fa';
import { FaBars, FaTimes } from 'react-icons/fa';
import { IoPersonSharp } from "react-icons/io5";
import { MdLogout } from "react-icons/md";
import jwtDecode from "jwt-decode";
import { FaHome, FaStickyNote } from 'react-icons/fa';
import { MdInput, MdDashboard, MdPerson, MdDescription } from 'react-icons/md';
import { GiCardboardBox } from 'react-icons/gi';


const Navbar = (props) => {
  const [show, setShow] = useState(false);
  const [icon, setIcon] = useState(true);
  const boxRef = useRef(null);
  const navigate = useNavigate()
  const [image, setImage] = useState(null);
  const [admin, setAdmin] = useState('user');
  const [loginUser,setLoginUser] = useState(false);

  const handleClickOutside = (event) => {
    if (boxRef.current && !boxRef.current.contains(event.target)) {
      setShow(false);
    }
  };
  const Download_Profile_Image = async () => {

    const profilePic = secureLocalStorage.getItem('profilePic');
    if (profilePic) {
      const imgUrl = `${GET_PROFILE_IMAGE}/${profilePic}`;
      setImage(imgUrl);
    }
  }
  useEffect(() => {
    Download_Profile_Image();
    const admin = secureLocalStorage.getItem('backtest_role')
    setAdmin(admin);
  }, [props.profilePic])

  const logoutFunction = () => {
    secureLocalStorage.removeItem('backtest_token');
    secureLocalStorage.removeItem('backtest_name');
    secureLocalStorage.removeItem('backtest_id');
    secureLocalStorage.removeItem('active')
    secureLocalStorage.removeItem('profilePic');
    secureLocalStorage.clear();

    navigate('/backtest');
  }
  const checkTokenValidity = async () => {
    try {
      let token = secureLocalStorage.getItem('backtest_token');

      // Remove the 'backtest_' prefix if it exists
      if (token && token.startsWith('backtest_')) {
        token = token.replace('backtest_', '');
      } else {
        navigate('/backtest/login');
      }

      if (token) {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000; // Convert milliseconds to seconds

        if (decodedToken.exp > currentTime) {
          // Token has expired
          setLoginUser(true);
          // Assuming you want to redirect to the login page
        }
      }
    } catch (error) {
      // Handle any errors that might occur during token retrieval
      console.error("Error while checking token validity:", error);
    }
  };
  useEffect(() => {
    checkTokenValidity();
  }, []);

  useEffect(() => {
    // Attach the event listener when the component mounts
    document.addEventListener('mousedown', handleClickOutside);
    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <nav className="text-white bg-[#043873] shadow-lg">
        {/* For Tablet and Desktop View */}
        <div className="hidden md:flex items-center justify-start px-2 py-3">
          <div className="flex items-center">
            <Link to='/' className="flex items-center">
              <div className='flex flex-col min-w-16'>
              <img src={logo} alt="logo" className="h-[47px] w-[45px] mr-3" />
              <div className='text-[9px]'>Beyond Algo</div>
              </div>
              <span className='font-extrabold text-3xl text-white'>FPrognos</span>
            </Link>
          </div>
          <div className="ml-8 flex space-x-6 text-center justify-center">
          <Link to='/backtest' className="text-xl font-semibold text-white hover:underline">Home</Link>
          {loginUser && <Link to='/backtest/backtest'><div className="text-xl font-semibold text-white hover:underline">Backtest</div></Link>}
          {loginUser && <Link to='/backtest/stratscompare'><div className="text-xl font-semibold text-white hover:underline">Option Matrix</div></Link>}
          {loginUser && <Link to='/backtest/profile'><div className="text-xl font-semibold text-white hover:underline">Profile</div></Link>}
          <Link to='/backtest/note'><div className="text-xl font-semibold text-white hover:underline">Notes</div></Link>
          <Link to='/backtest/template'><div className="text-xl font-semibold text-white hover:underline">Template</div></Link>
          {admin == 'admin' && <Link to='/backtest/series'><div className="text-xl font-semibold text-white hover:underline">Series</div></Link>}
          {admin == 'admin' && <Link to='/backtest/gold'><div className="text-xl font-semibold text-white hover:underline">Gold</div></Link>}
          {admin == 'admin' && <Link to='/backtest/admin'><div className="text-xl font-semibold text-white hover:underline" onClick={() => { navigate('/admin'); }}>Admin</div></Link>}
          </div>
          {!loginUser && <div className='flex justify-end flex-grow mr-5'><Link to='/backtest/login' className="text-lg text-white font-semibold hover:text-white bg-[#4F9CF9] p-4 rounded-md w-48 flex flex-row gap-2">Login/Register<FaArrowRight className='mt-1 font-thin'/></Link></div>}
          
          {loginUser && <div className='flex justify-end flex-grow'><div className="pr-2 flex flex-row sm:float-right ">
            <button
              className="hidden lg:flex mt-3 mb-2  rounded font-semibold text-xl bg-[white] text-black text-primary transition duration-150 ease-in-out focus:outline-none focus:ring-0 motion-reduce:transition-none p-[6px]"
              type="button" id="button-addon3" data-te-ripple-init onClick={()=>{navigate('/backtest/plan');}}> Buy&nbsp;Coin</button>
            {image ? (
              <img src={image.toString()} alt="profile" className="w-8 h-8 ml-2 mt-2 rounded-full cursor-pointer max-md:ml-2 border-2 border-white" onClick={() => { setShow(!show) }} />
            ) : (
              <IoPersonSharp className="rounded-full border-2 ml-2 mt-3 cursor-pointer text-white" style={{ height: "40px", width: "40px" }} onClick={() => { setShow(!show) }} />
            )}
            {show &&
              <div ref={boxRef} className="absolute right-0 z-10 mt-16 w-48 mr-10 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 text-black ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button">
                <Link to="/backtest/profile" className="px-4 py-2 text-l font-medium flex flex-row" role="menuitem" id="user-menu-item-0">Your Profile   <IoPersonSharp className='text-2xl font-semibold flex justify-end flex-grow' /></Link>
                <div className="flex flex-row px-4 py-2 text-l font-medium cursor-pointer " role="menuitem" id="user-menu-item-2" onClick={logoutFunction}>Sign out    <MdLogout className='text-2xl font-semibold flex justify-end flex-grow' /></div>
              </div>
            }
          </div>
          </div>}
          
        </div>

        {/* For Mobile View */}
        <div className="flex items-center justify-between p-4 md:hidden">
          <Link to='/backtest' className="flex items-center">
            <img src={logo} alt="logo" className="h-9 w-9 rounded-md" />
            <span className='font-extrabold text-2xl text-[#343C6A] ml-2'>FPrognos.</span>
          </Link>
          <div className="cursor-pointer text-2xl" onClick={() => setIcon(!icon)}>
            {!icon ? <FaBars /> : <FaTimes />}
          </div>
        </div>

        {icon && (
          <div className="md:hidden bg-white shadow-lg">
          <div className="flex flex-col items-start px-4 py-2 space-y-4">
            <Link to='/backtest' className="text-lg text-[#232323] w-full hover:bg-blue-200 p-2 rounded-md flex items-center">
              <FaHome className="mr-2" /> Home
            </Link>
            {loginUser && <Link to='/backtest/backtest' className="text-lg text-[#232323] w-full hover:bg-blue-200 p-2 rounded-md flex items-center">
              <MdInput className="mr-2" /> Backtest
            </Link>}
            {loginUser && <Link to='/backtest/stratscompare' className="text-lg text-[#232323] w-full hover:bg-blue-200 p-2 rounded-md flex items-center">
              <MdDashboard className="mr-2" /> OptionMatrix
            </Link>}
            {loginUser &&<Link to='/backtest/profile' className="text-lg text-[#232323] w-full hover:bg-blue-200 p-2 rounded-md flex items-center">
              <MdPerson className="mr-2" /> Profile
            </Link>}
            {loginUser && admin == 'admin' &&<Link to='/backtest/series' className="text-lg text-[#232323] w-full hover:bg-blue-200 p-2 rounded-md flex items-center">
              <MdPerson className="mr-2" /> Series
            </Link>}
            {loginUser && admin == 'admin' && <Link to='/backtest/gold' className="text-lg text-[#232323] w-full hover:bg-blue-200 p-2 rounded-md flex items-center">
              <MdPerson className="mr-2" /> Gold
            </Link>}
            {loginUser && <Link to='/backtest/profile' className="text-lg text-[#232323] w-full hover:bg-blue-200 p-2 rounded-md flex items-center">
              <MdPerson className="mr-2" /> Profile
            </Link>}
            <Link to='/backtest/note' className="text-lg text-[#232323] w-full hover:bg-blue-200 p-2 rounded-md flex items-center">
              <GiCardboardBox className="mr-2" /> Note
            </Link>
            <Link to='/backtest/login' className="text-lg text-[#232323] w-full hover:bg-blue-200 p-2 rounded-md flex items-center">
              <FaStickyNote className="mr-2" /> Login
            </Link>
            {loginUser && admin == 'admin' && <Link to='/admin' className="text-lg text-[#232323] w-full hover:bg-blue-200 p-2 rounded-md flex items-center">
              <MdDescription className="mr-2" /> Admin
            </Link>}
          </div>
        </div>
        )}
      </nav>
    </>
  )
}

export default Navbar