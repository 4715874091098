import React, { useEffect, useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Excel from './../../pdf/Excel'
import MonthlyProfit from './MonthlyProfit'
import WeekProfit from './WeekProfit'
// import Main from './Main'
import GraphResult from "./GraphResult";
import { tradeOnlyFirstEntryMethod } from "./Utility";
import LegProfit1 from "./LegProfit1";
import IndividualLegContribution from "./IndividualLegContribution";
import { Next_Thursday, sorting } from "./Utility/Util";
import { result } from "./Utility/NormalResult";
import { GroupByExpiry } from "./Utility/ExpiryBasedResult";
// Component
import useBacktestResultStore from "../../store/backtestResultStore";
import useBacktestStore from "../../store/backtestStore";
import LegProfitBasedOnExpiry from './LegProfitBasedOnExpiry';
import Main from './RefactorMain'
import Metrics from "./Metrics";

// balance={investment} startdate={startdate} enddate={enddate} legs={leg}
const Result = (props) => {
    // Data required for calculation
    const legs = props.resultData.legs;
    const startdate = props.resultData.startDate;
    const enddate = props.resultData.endDate;
    const lotarray = props.resultData.lotarray;
    const legarray = props.resultData.legarray;
    const LotQuantity = props.LotQuantity;
    // Filteration
    const [filter, setFilter] = useState("Date")
    const [ExpiryResult, setExpiryResult] = useState()
    const [month, setMonth] = useState({})
    // Main Profit
    const [date, setDate] = useState()
    const [data, setData] = useState([])
    const [netProfit, setNetProfit] = useState([])
    const [drawdownArray, setDrawdownArray] = useState([]);
    const [totalExpireyProfit, setTotalExpireyProfit] = useState({})
    const [cumulative, setCumulative] = useState([])
    const [expireyProfit, setExpireyProfit] = useState([])
    // Main Result
    const [restartAgain, setRestartAgain] = useState(0);
    const [weekResult, setWeekResult] = useState({});
    // Store Statistical result
    const [isNA, setIsNA] = useState({})  // only for reEntry
    // add filter
    const [resultFilter, setResultFilter] = useState([])
    const [tempFilter, setTempFilter] = useState([])
    // show ROI
    const [showROI, setShowROI] = useState(false);
    const [premiumPrice, setPremiumPrice] = useState(0);
    // include trade or not
    const [includeTrade, setIncludeTrade] = useState({});
    // individual leg contribution
    const [individualLegContribution, setIndividualLegContribution] = useState([])
    // status
    const [stats, setStats] = useState();
    // zustand -> backtest result store
    const { csv, inputData } = useBacktestResultStore();
    const { appearNA, tradeOnlyFirstEntry } = useBacktestResultStore();
    const { vixStart, vixExit, resultStartDate, resultEndDate, updateResultStartDate, updateResultEndDate } = useBacktestResultStore();
    const { dayOption, updateStats } = useBacktestResultStore();
    const { slippage } = useBacktestStore();
    // this component contain 
    //  a) Normal result that does not contain reEntry(result())
    //  b) reEntry Result  that contain reEntry  (Re_entry_result())
    //  c) Excel Result for  Normal Result
    //  d) Excel Result and final ReEntry Result for reEntry Result (temporary())
    //  e) handleDownload for download pdf that will take screeenshot of result
    //  f) handleTrade that will handle for paging in leg result

    // There is a method to download a pdf
    const handleDownload = () => {
        const pdfElement = document.getElementById("screenshot");
        html2canvas(pdfElement).then((canvas) => {
            const pdf = new jsPDF();
            pdf.addImage(canvas.toDataURL("image/png"), "PNG", 0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight());
            props.strategyName ? pdf.save(`${props.strategyName}_fprognos.pdf`) : pdf.save(`backtest_fprognos.pdf`);
        });
    };
    // Store Result in Day Wise
    function handleDays() {
        let mon = [], tue = [], wed = [], thu = [], fri = [];
        data.map((item, key) => {
            if (item['day'] === 'Monday') mon.push(item);
            else if (item['day'] === 'Tuesday') tue.push(item)
            else if (item['day'] === 'Wednesday') wed.push(item)
            else if (item['day'] === 'Thursday') thu.push(item)
            else fri.push(item)
        })
        mon.sort(); tue.sort(); wed.sort(); thu.sort(); fri.sort();
        let result = [...mon, ...tue, ...wed, ...thu, ...fri]
        return result;
    }

    // Filter Result on the basis of day,date and expiry
    const sortArrayOfArrays = () => {
        if (filter === "Day") {
            return handleDays();
        }
        return sorting(0, data)
    }

    useEffect(() => {
        const array = sortArrayOfArrays();
        if (filter == 'Expiry') {
            let resp = GroupByExpiry(csv, inputData, slippage);
            setTotalExpireyProfit(resp.totalExpiryProfit);
            setExpireyProfit(resp.expireyProfit);
            setExpiryResult(resp.result);
        } else {
            setData(array);
        }
    }, [filter])

    useEffect(() => {
        let res = result(csv, props.margin, inputData, vixStart, vixExit, resultStartDate, resultEndDate, tradeOnlyFirstEntry, tradeOnlyFirstEntryMethod, dayOption, legarray, legs, lotarray, LotQuantity, appearNA, includeTrade, premiumPrice, startdate, enddate, slippage,resultFilter)
        setStats(res.stats);
        updateStats(res);
        setWeekResult(res.week);
        setMonth(res.month);
        setIndividualLegContribution(res.individualLeg);
        setData(res.result);
        setCumulative(res.cumulative);
        setNetProfit(res.profit);
        setDrawdownArray(res.drawdown);
        setDate(res.tradingDate)
    }, [premiumPrice, restartAgain, dayOption, props.resultCount, appearNA, resultStartDate, resultEndDate, resultFilter, includeTrade, tradeOnlyFirstEntry,slippage,vixStart,vixExit])

    useEffect(() => {
        updateResultStartDate(inputData.startdate);
        updateResultEndDate(inputData.enddate);
    }, [])

    return (
        <>
            {/* Box of Main Answer of Every Value */}
            <hr className="bg-white h-2 m-5"></hr>
            <hr className="bg-white h-2 m-5"></hr>
            {/* Actual Result in Description Format */}
            <div id="screenshot">
                <div className="flex md:flex-row flex-col gap-1">
                    <Metrics filter={filter} setFilter={setFilter} tempFilter={tempFilter} setTempFilter={setTempFilter} resultFilter={resultFilter} setResultFilter={setResultFilter} handleDownloadPdf={props.handleDownloadPdf} />
                    {/* Addd More Filter */}
                    <div className="flex flex-grow">
                        <Main stats={stats} />
                    </div>
                </div>
                <div className="flex text-center justify-center gap-2"><input type="checkbox" checked={showROI} onChange={() => { setShowROI(!showROI) }} />Show ROI Percent</div>
                <WeekProfit weekResult={weekResult} margin={props.margin} showROI={showROI} />
                <MonthlyProfit month={month} totalProfit={stats?.profit} maximumDrawdown={stats?.maximumDrawdown} margin={props.margin} showROI={showROI} />
                <IndividualLegContribution totalProfit={stats?.profit} individualLegContribution={individualLegContribution} />
                {/* Graph make */}
                <GraphResult profit={netProfit} cumulative={cumulative} date={date} drawdown={drawdownArray} />
                {/* <GraphChart profit={netProfit} cumulative={cumulative} date={date} drawdown={drawdownArray} StatisticalResult={StatisticalResult} startDate={inputData.startdate}/> */}
                {/* Group By Expiry */}

                {filter !== 'Expiry' && <LegProfit1 includeTrade={includeTrade} setIncludeTrade={setIncludeTrade} slippage={props.slippage}
                    setSlippage={props.setSlippage} filter={filter} ExpiryResult={ExpiryResult}
                    sorting={sorting} data={data} totalExpireyProfit={totalExpireyProfit}
                    individualLegContribution={individualLegContribution} />}

                {filter == 'Expiry' && <LegProfitBasedOnExpiry includeTrade={includeTrade} setIncludeTrade={setIncludeTrade} slippage={props.slippage}
                    setSlippage={props.setSlippage} filter={filter} ExpiryResult={ExpiryResult}
                    sorting={sorting} data={data} totalExpireyProfit={totalExpireyProfit}
                    individualLegContribution={individualLegContribution} />}

            </div>

        </>
    );
};

export default Result;
