import React, { useEffect, useState } from 'react';
import { GOLD_TRADING } from '../../../constant/constant';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import { FaArrowLeft } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa";
import { MdOutlineKeyboardArrowUp } from "react-icons/md";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { Tooltip } from 'react-tooltip';
import Excel from './Excel'
import GraphResult from '../Graph/Graph';
import { useNavigate } from 'react-router-dom';

const month = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
const Normal = () => {
    const [symbol, setSymbol] = useState('NIFTY');
    const [targetProfit, setTargetProfit] = useState(10);
    const [targetLoss, setTargetLoss] = useState(10);
    const [targetDiff, setTargetDiff] = useState(4);
    const [startDate, setStartDate] = useState("2023-07-05");
    const [endDate, setEndDate] = useState("2024-02-18");
    const [startTime, setStartTime] = useState("00:01");
    const [endTime, setEndTime] = useState("23:59");
    const [lotSize, setLotSize] = useState(1);
    const [action, setAction] = useState('BUY');
    const [expiryDay, setExpiryDay] = useState(0);
    // Result
    const [tradeList, setTradeList] = useState([])
    const [totalPnl, setTotalPnl] = useState([])
    const [monthlyProfit, setMonthlyProfit] = useState([])
    const [investmentDay, setInvestmentDay] = useState([])
    const [maximumProfit, setMaximumProfit] = useState(0)
    const [minimumProfit, setMinimumProfit] = useState(0)
    const [margin, setMargin] = useState(0)
    const [maximumDrawdown, setMaximumDrawdown] = useState(0)
    const [resultShow, setResultShow] = useState(false);
    const [futurePrice, setFuturePrice] = useState([])
    const [futurePriceDatetime, setFuturePriceDatetime] = useState([])
    const [profitList, setProfitList] = useState()
    const [bookedMonthProfit, setBookedMonthProfit] = useState([])
    const [monthlyLossExpiry, setMontlyLossExpiry] = useState([])
    const [rollOverQuantity, setRollOverQuantity] = useState([]);
    const [trailRollOver, setTrailRollOver] = useState([])
    const [maximumRollOverQuantity, setMaximumRollOverQuantity] = useState(0);
    const [minimumBookedProfit, setMinimumBookedProfit] = useState(0)
    const [maximumBookedProfit, setMaximumBookedProfit] = useState(0)
    const [trailRollOverSum, setTrailRollOverSum] = useState([])
    const [dataType, setDataType] = useState('gold_data');
    const [circularResult, setCircularResult] = useState([])
    const [circularProfit, setCircularProfit] = useState([])
    const [cumulative, setCumulative] = useState([]);
    const [drawdown, setDrawdown] = useState([]);
    const [circularProperty, setCircularProperty] = useState([]);
    const [maximumPrice, setMaximumPrice] = useState(0);
    const [minimumPrice, setMinimumPrice] = useState(0)
    const [maximumCircularLoss, setMaximumCircularLoss] = useState([])
    const [maximumPeakLoss, setMaximumPeakLoss] = useState(0);
    const [concise, setConcise] = useState(true);
    const [startDatePeak, setStartDatePeak] = useState('')
    const [endDatePeak, setEndDatePeak] = useState('')
    const [normalMonthResult, setNormalMonthResult] = useState({})
    const [normalData, setNormalData] = useState([])
    // page Size
    const [pageSize, setPageSize] = useState(25)
    // loader
    const [loader, setloader] = useState(false);
    // monthlyResult
    const [monthlyResult, setMonthlyResult] = useState([])
    const [showResult, setShowResult] = useState(1);
    // Number Box
    const [NumberBox, setNumberBox] = useState([1])
    const [activeColor, setActiveColor] = useState(1)
    const [currentIndex, setCurrentIndex] = useState(1)
    
    const navigate = useNavigate();
    // calculation of start series trading
    const startSeriesTrading = () => {
        setloader(true);
        setResultShow(false);
        // Get the token from secureLocalStorage
        let token = secureLocalStorage.getItem('backtest_token');

        // Remove the 'backtest_' prefix if it exists
        if (token && token.startsWith('backtest_')) {
            token = token.replace('backtest_', '');
        } else {
            navigate('/backtest/login');
        }
        const data = {
            "symbol": symbol, "targetProfit": targetProfit, "targetLoss": targetLoss, "targetDiff": targetDiff, "startDate": startDate, "endDate": endDate, "startTime": startTime, "endTime": endTime,
            "lotSize": lotSize, "action": action, "expiryDay": expiryDay, "dataType": dataType
        }
        let config = { method: "post", maxBodyLength: Infinity, url: GOLD_TRADING, headers: { "Content-Type": "application/json", "Authorization": `Bearer <span className='text-blue-600'>$</span>{token}`, }, data: data, };
        axios(config)
            .then((response) => {
                setTotalPnl(response.data?.totalProfit);
                setTradeList(response.data?.result);
                setMonthlyProfit(response.data?.monthlyProfit);
                setInvestmentDay(response.data?.investmentDay);
                setCircularResult(response.data?.circularResult)
                setMargin(response.data?.margin);
                setResultShow(true);
                calculationResult(response.data?.monthlyProfit);
                setFuturePrice(response.data?.futurePrice);
                setFuturePriceDatetime(response.data?.futurePriceDatetime);
                setProfitList(response.data?.profitList);
                setBookedMonthProfit(response.data?.bookedMonthProfit);
                setMontlyLossExpiry(response.data?.monthlyLossExpiry);
                setRollOverQuantity(response.data?.rollOverQuantity);
                setTrailRollOver(response.data?.trailRollOver);
                setCircularProfit(response.data?.circularProfit)
                setMaximumCircularLoss(response.data.maximumCircularLoss);
                calculateCircularResult(response.data?.circularProfit, response.data?.circularResult);
                normalMonthlyResult(response.data.result);
                setloader(false);

            }).catch((error) => {
                console.log(error, 'error');
                setloader(false);
            })
    };

    // Sorting the Data on the basis of Index
    const sorted = (index, order) => {
        const data = [];
        for (let i = 0; i < tradeList.length; i++) {
            const sortedData = tradeList[i].sort((a, b) => {
                const itemA = a[index];
                const itemB = b[index];
                if (order === 0) {
                    if (typeof itemA === 'string' && typeof itemB === 'string') {
                        return itemA.localeCompare(itemB);
                    } else if (typeof itemA === 'number' && typeof itemB === 'number') {
                        return itemA - itemB;
                    } else {
                        return 0; // No comparison possible, keep the order unchanged
                    }
                } else {
                    if (typeof itemA === 'string' && typeof itemB === 'string') {
                        return itemB.localeCompare(itemA);
                    } else if (typeof itemA === 'number' && typeof itemB === 'number') {
                        return itemB - itemA;
                    } else {
                        return 0; // No comparison possible, keep the order unchanged
                    }
                }
            });
            data.push(sortedData);
        }
        setTradeList(data);
    };
    // calculate Circular Result
    const calculateCircularResult = (profit, circularResult) => {
        // circular result
        let minPrice = 999999999999, maxPrice = 0, position = 0;
        let circularProperty = []
        let maximumPrice = 0, minimumPrice = 9999999999999;
        let maximumPeakLoss = 0;
        let startDatePeak = '';
        let endDatePeak = '';
        for (let i = 0; i < circularResult.length; i++) {
            for (let j = 0; j < circularResult[i].length; j++) {
                if (circularResult[i][j][6] < minPrice) {
                    minPrice = circularResult[i][j][6]
                    endDatePeak = circularResult[i][j][1]
                }
                if (circularResult[i][j][6] > maxPrice) {
                    maxPrice = circularResult[i][j][6]
                    startDatePeak = circularResult[i][j][3]
                }
                if (circularResult[i][j][5] > position)
                    position = circularResult[i][j][5]
            }
            circularProperty.push([position, minPrice, maxPrice]);
            if (minPrice < minimumPrice)
                minimumPrice = minPrice
            if (maxPrice > maximumPrice)
                maximumPrice = maxPrice
            let temp = (maxPrice - minPrice) / 2 * ((maxPrice - minPrice) / targetDiff + 1) * lotSize
            if (temp > maximumPeakLoss)
                maximumPeakLoss = temp;
            minPrice = 999999999999;
            maxPrice = 0;
            position = 0;
        }
        setMaximumPeakLoss(maximumPeakLoss);
        setStartDatePeak(startDatePeak);
        setEndDatePeak(endDatePeak);
        setMaximumDrawdown(maximumDrawdown);
        setCumulative(cumulative);
        setDrawdown(drawdown);
        setCircularProperty(circularProperty);
        setMaximumPrice(maximumPrice);
        setMinimumPrice(minimumPrice);
    }
    // monthly result --> 
    const calculationResult = (monthlyProfit) => {
        let min = monthlyProfit[0], max = monthlyProfit[0];
        for (let i = 0; i < monthlyProfit.length; i++) {
            if (monthlyProfit[i] > max)
                max = monthlyProfit[i];
            if (monthlyProfit[i] < min)
                min = monthlyProfit[i];
        }
        setMinimumProfit(min);
        setMaximumProfit(max);
        // Month Result and Maximum RollOverQuantity
        let result = [];
        let totalRollOverQuantity = 0, totalBookedMonthProfit = 0, totalMonthlyProfit = 0, totalRollOverLoss = 0;
        let maximumRollOverQuantity = 0;
        for (let i = 0; i < monthlyProfit.length; i++) {
            result.push([i + 1, rollOverQuantity[i], bookedMonthProfit[i], monthlyProfit[i], monthlyLossExpiry[i]]);
            totalRollOverQuantity += rollOverQuantity[i] ? rollOverQuantity[i] : 0;
            totalBookedMonthProfit += bookedMonthProfit[i];
            totalMonthlyProfit += monthlyProfit[i];
            totalRollOverLoss += monthlyLossExpiry[i];
            if (rollOverQuantity[i] > maximumRollOverQuantity) {
                maximumRollOverQuantity = rollOverQuantity[i];
            }
        }
        result.push(['Total', maximumRollOverQuantity, totalBookedMonthProfit, totalMonthlyProfit, totalRollOverLoss]);
        setMaximumRollOverQuantity(maximumRollOverQuantity);
        setMonthlyResult(result);
    }

    // Month Result where contain data value in monthly wise format
    const normalMonthlyResult = (list) => {
        let normal = {}
        let minBookedPnl = 999999999999
        let maxBookedPnl = 0
        for (let i = 0; i < list.length; i++) {
            for (let j = 0; j < list[i].length; j++) {
                let date = list[i][j][3];
                // Method to find the MontlyWise Profit
                if (normal.hasOwnProperty(date[0] + date[1] + date[2] + date[3])) {
                    normal[date[0] + date[1] + date[2] + date[3]][parseInt(date[4] + date[5])] += list[i][j][9]
                    normal[date[0] + date[1] + date[2] + date[3]][13] += list[i][j][9];
                } else {
                    normal[date[0] + date[1] + date[2] + date[3]] = new Array(14).fill(0);
                    normal[date[0] + date[1] + date[2] + date[3]][parseInt(date[4] + date[5])] = list[i][j][9];
                    normal[date[0] + date[1] + date[2] + date[3]][13] = list[i][j][9];
                }

            }
        }
        Object.entries(normal).map(([key, value]) => {
            // Your logic here, for example:
            for (let i = 1; i <= 12; i++) {
                if (value[i] < minBookedPnl)
                    minBookedPnl = value[i];
                if (value[i] > maxBookedPnl)
                    maxBookedPnl = value[i];
            }
        });
        setMinimumBookedProfit(minBookedPnl);
        setMaximumBookedProfit(maxBookedPnl);
        setNormalMonthResult(normal);
    }
    // for pageSize
    useEffect(() => {
        let size = tradeList.length > 0 ? tradeList[0].length / pageSize + 1 : 1;
        console.log(size, 'size')
        setNumberBox(Array(parseInt(size)).fill(1))
        console.log(NumberBox, 'number box')
        console.log(currentIndex, pageSize, 'current index')
        if (tradeList.length > 0) {
            console.log('heloo worodl')
            if (currentIndex < 1) {
                setCurrentIndex(1);
                setNormalData(tradeList[0].slice(0, pageSize * currentIndex));
            } else {
                let data = tradeList[0].slice(parseInt(pageSize) * (currentIndex - 1), parseInt(pageSize) * currentIndex)
                console.log(data, 'jkshkfsjhk')
                setNormalData(data);
            }
        }
    }, [pageSize, currentIndex, tradeList])

    return (
        <div className='bg-[#DAECF2] min-h-screen'>
            <div className='flex flex-col m-2'>
                <div className='grid sm:grid-cols-2 lg:grid-cols-3'>
                    <div className='flex flex-row bg-white p-2 m-2'>
                        <div className='w-40 text-[18px]'>Target Profit</div>
                        <input type="number" className='text-l bg-[#ECF0FF] p-1 flex-grow outline-0' value={targetProfit} onChange={(e) => setTargetProfit(e.target.value)} />
                    </div>
                    <div className='flex flex-row bg-white p-2 m-2'>
                        <div className='w-40 text-[18px]'>Action</div>
                        <select value={action} className='text-l bg-[#ECF0FF] p-1 flex-grow outline-0' onChange={(e) => { setAction(e.target.value) }}>
                            <option value="BUY">BUY</option>
                            <option value="SELL">SELL</option>
                        </select>
                    </div>
                    <div className='flex flex-row bg-white p-2 m-2'>
                        <div className='w-40 text-[18px]'>Lot</div>
                        <input type="number" className='text-l bg-[#ECF0FF] p-1 flex-grow outline-0' value={lotSize} onChange={(e) => setLotSize(e.target.value)} />
                    </div>
                    <div className='flex flex-row bg-white p-2 m-2'>
                        <div className='w-40 text-[18px]'>Entry Difference</div>
                        <input type="number" className='text-l bg-[#ECF0FF] p-1 flex-grow outline-0' value={targetDiff} onChange={(e) => setTargetDiff(e.target.value)} />
                    </div>
                    <div className='flex flex-row bg-white p-2 m-2'>
                        <div className='w-40 text-[18px]'>Start Date</div>
                        <input type="date" className='text-l bg-[#ECF0FF] p-1 flex-grow outline-0' value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                    </div>
                    <div className='flex flex-row bg-white p-2 m-2'>
                        <div className='w-40 text-[18px]'>End Date</div>
                        <input type="date" className='text-l bg-[#ECF0FF] p-1 flex-grow outline-0' value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                    </div>
                    <div className='flex flex-row bg-white p-2 m-2'>
                        <div className='w-40 text-[18px]'>Start Time</div>
                        <input type="time" className='text-l bg-[#ECF0FF] p-1 flex-grow outline-0' value={startTime} onChange={(e) => setStartTime(e.target.value)} />
                    </div>
                    <div className='flex flex-row bg-white p-2 m-2'>
                        <div className='w-40 text-[18px]'>End Time</div>
                        <input type="time" className='text-l bg-[#ECF0FF] p-1 flex-grow outline-0' value={endTime} onChange={(e) => { setEndTime(e.target.value); }}></input>
                    </div>
                    <div className='flex flex-row bg-white p-2 m-2'>
                        <div className='w-40 text-[18px]'>Data Type</div>
                        <select className='text-l bg-[#ECF0FF] p-1 flex-grow outline-0' value={dataType} onChange={(e) => { setDataType(e.target.value); }}>
                            <option value="gold_data">Unadjusted Data</option>
                            <option value="adjusted_gold_data">Adjusted Data</option>
                        </select>
                    </div>
                </div>
                <div className='flex justify-end'>
                    <div className='flex flex-col p-1 text-right text-[12px]'>
                        <div className='text-[14px]'>*Data is present from 31st January,2008 to 26th February,2024.</div>
                        <div className='text-[14px]'>*Range of Time is 18:00 to 16:59 </div>
                        <div className='text-[14px]'>*Profit and Price is in dollar.</div>
                        <div className='text-[14px]'>*<span className='font-[600]'>1. Unadjusted</span> - actual historic traded prices with no adjustments.</div>
                        <div className='text-[14px]'>*<span className='font-[600]'>2. Adjusted</span> - prices adjusted for price jumps on contract roll date</div>
                    </div></div>
                <div className='h-[4px] bg-white'></div>
                {!loader && <div className='bg-[#2D5BFF] h-8 rounded-md text-center text-white text-[16px] cursor-pointer' onClick={startSeriesTrading}>Start Series Trading</div>}
                {loader && <div className="bouncing-loader">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>}
            </div>
            {resultShow && <div className='flex flex-col'>
                <div className='bg-white text-xl rounded-md m-2 text-center'>Series Trading Result</div>
                {/* Only For Result */}
                <div className='grid grid-cols-2 m-2'>
                    <div className='flex flex-row gap-2'>
                        <div className='text-[18px]'>Filter</div>
                        <select className='w-48 outline-0 text-[16px] bg-[#2D5BFF] text-white rounded' value={showResult} onChange={(e) => setShowResult(parseInt(e.target.value))}>
                            <option value={1}>Show Daily Result</option>
                            <option value={4}>Circular Result</option>
                        </select>
                    </div>
                    <div className='flex justify-end gap-2'>
                        <div className='bg-blue-600 text-white p-1 text-[16px] rounded font-serif' onClick={() => { setConcise(!concise); }}>{concise ? 'Concise' : "Detail"}</div>
                        <Excel tradeList={tradeList} monthlyResult={monthlyResult} trailRollOver={trailRollOver} trailRollOverSum={trailRollOverSum} action={action} symbol={symbol}
                            lot={lotSize} targetProfit={targetProfit} targetDiff={targetDiff} maximumDrawdown={maximumDrawdown} minimumProfit={minimumProfit} maximumProfit={maximumProfit}
                            margin={margin} totalProfit={totalPnl} investmentDay={investmentDay} monthlyProfit={monthlyProfit} monthlyLossExpiry={monthlyLossExpiry} rollOverQuantity={rollOverQuantity}
                            bookedMonthProfit={bookedMonthProfit} normalMonthResult={normalMonthResult} circularResult={circularResult} circularProperty={circularProperty} circularProfit={circularProfit} />
                    </div>
                </div>
                <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 m-2'>
                    <div className='flex flex-row bg-white rounded-md p-2'>
                        <div className='text-l'>Estimate Margin :</div>
                        <div className='font-semibold flex justify-end flex-grow'><span className='text-blue-600'>$</span>{margin}</div>
                    </div>
                    <div className='flex flex-row bg-white rounded-md p-2'>
                        <div className='text-l'>Total Profit :</div>
                        <div className='font-semibold flex justify-end flex-grow'><span className='text-blue-600'>$</span>{totalPnl}</div>
                    </div>
                    <div className='flex flex-row bg-white rounded-md p-2'>
                        <div className='text-l'>Total Trading Days :</div>
                        <div className='font-semibold flex justify-end flex-grow'><span className='text-blue-600'>$</span>{investmentDay}</div>
                    </div>
                    <div className='flex flex-row bg-white rounded-md p-2'>
                        <div className='text-l'>Minimum Monthly Profit:</div>
                        <div className='font-semibold flex justify-end flex-grow'><span className='text-blue-600'>$</span>{minimumBookedProfit.toFixed(2)}</div>
                    </div>
                    <div className='flex flex-row bg-white rounded-md p-2'>
                        <div className='text-l'>Maximum Monthly Profit:</div>
                        <div className='font-semibold flex justify-end flex-grow'><span className='text-blue-600'>$</span>{maximumBookedProfit.toFixed(2)}</div>
                    </div>
                    <div className='flex flex-row bg-white rounded-md p-2'>
                        <div className='text-l'>Low and High Price:</div>
                        <div className='font-semibold flex justify-end flex-grow'><span className='text-blue-600'>$</span>{minimumPrice} -- <span className='text-blue-600'>$</span>{maximumPrice}</div>
                    </div>
                    <div className='flex flex-row bg-white rounded-md p-2'>
                        <div className='text-l'>Expiry Achieved Loss:</div>
                        <div className='font-semibold flex justify-end flex-grow'><span className='text-blue-600'>$</span>{monthlyLossExpiry[0]}</div>
                    </div>
                    <div className='flex flex-row bg-white rounded-md p-2'>
                        <div className='text-l'>Maximum Peak Loss:</div>
                        <div className='font-semibold flex justify-end flex-grow'><span className='text-blue-600'>$</span>{maximumPeakLoss.toFixed(2)}</div>
                    </div>
                    <div className='flex flex-row col-span-2 bg-white rounded-md p-2'>
                        <div className='text-l'>Maximum Peak Period:</div>
                        <div className='font-semibold flex justify-end flex-grow'>[{startDatePeak}---{endDatePeak}]</div>
                    </div>
                </div>
                {/* Monthly Result */}
                {
                    showResult == 1 && <div className='bg-white rounded-lg m-2 overflow-auto'>
                        <div className='flex flex-row justify-around gap-2 border-2 text-center  bg-blue-500 text-white min-w-[1560px]'>
                            <div className='text-[18px] text-[600] text-centerw-32'>Years.</div>
                            {
                                month.map((item, key) => {
                                    return (
                                        <div className='text-[18px] text-[600] text-center w-32'>{item}</div>
                                    )
                                })
                            }
                            <div className='text-[18px] text-[600] text-centerw-32'>Total.</div>
                        </div>
                        {
                            Object.entries(normalMonthResult).map(([key, value]) => (
                                <div className=" bg-white flex flex-row justify-around text-center min-w-[1560px]" key={key}>
                                    <div className="p-2 text-black text-center w-32">{key}</div>
                                    <div className="p-2 text-black text-center w-32">{value[1].toFixed(2)}</div>
                                    <div className="p-2 text-black text-center w-32">{value[2].toFixed(2)}</div>
                                    <div className="p-2 text-black text-center w-32">{value[3].toFixed(2)}</div>
                                    <div className="p-2 text-black text-center w-32">{value[4].toFixed(2)}</div>
                                    <div className="p-2 text-black text-center w-32">{value[5].toFixed(2)}</div>
                                    <div className="p-2 text-black text-center w-32">{value[6].toFixed(2)}</div>
                                    <div className="p-2 text-black text-center w-32">{value[7].toFixed(2)}</div>
                                    <div className="p-2 text-black text-center w-32">{value[8].toFixed(2)}</div>
                                    <div className="p-2 text-black text-center w-32">{value[9].toFixed(2)}</div>
                                    <div className="p-2 text-black text-center w-32">{value[10].toFixed(2)}</div>
                                    <div className="p-2 text-black text-center w-32">{value[11].toFixed(2)}</div>
                                    <div className="p-2 text-black text-center w-32">{value[12].toFixed(2)}</div>
                                    <div className="p-2 text-black text-center w-32">{value[13].toFixed(2)}</div>
                                </div>
                            ))
                        }
                    </div>
                }

                {showResult == 1 && <div className='flex flex-col bg-white m-2 rounded-lg overflow-auto'>
                    <div className='grid grid-cols-11 border-b-2 border-black bg-blue-500 text-white min-w-[1440px] text-center p-1'>
                        <div className='font-[600] text-[16px] flex flex-row text-center justify-start'>S.No&nbsp;Entry<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(0, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(0, 1); }} /></div></div>
                        <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>Exit&nbsp;<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(2, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(2, 1); }} /></div></div>
                        <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>WeekDay<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(4, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(4, 1); }} /></div></div>
                        <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>Standing_Position<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(5, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(4, 1); }} /></div></div>
                        <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>Entry&nbsp;Price<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(6, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(4, 1); }} /></div></div>
                        <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>Exit&nbsp; Price<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(7, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(5, 1); }} /></div></div>
                        <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>Profit<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(9, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(6, 1); }} /></div></div>
                        <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>Monthly&nbsp;Expiry<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(10, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(7, 1); }} /></div></div>
                        <div className='font-[600] text-[16px]'>Reason</div>
                        <div className='font-[600] text-[16px]'>Low & High</div>
                        <div className='font-[600] text-[16px]'>Target Price</div>
                    </div>
                    {
                        normalData.length > 0 && normalData.map((item, key) => {
                            return (
                                <div>
                                    <div className='grid grid-cols-11 border-b-2 min-w-[1440px] p-2 text-center' key={key} style={{ backgroundColor: item[10] == 'rc' ? '#F8F8F8' : 'white' }}>
                                        <div className='text-[16px]'>{key + 1} {item[0]}&nbsp;&nbsp;{item[1]}</div>
                                        <div className='text-[16px]'>{item[2]}&nbsp;&nbsp;{item[3]}</div>
                                        <div className='text-[16px]'>{item[4]}</div>
                                        <div className='text-[16px]'>{item[5]}</div>
                                        <div className='text-[16px]'><span className='text-blue-600'>$</span>{item[6]}</div>
                                        <div className='text-[16px]'><span className='text-blue-600'>$</span>{item[7]}</div>
                                        <div className='text-[16px]' style={{ color: item[9] > 0 ? "green" : "red" }}><span className='text-blue-600'>$</span>{item[9]}</div>
                                        <div className='text-[16px]'>{item[10]}</div>
                                        <div className='text-[16px]'>{item[13]}</div>
                                        <div className='text-[16px]'>{item[14]}--{item[15]}</div>
                                        <div className='text-[16px'><span className='text-blue-600'>$</span>{item[16]}</div>
                                    </div>
                                </div>
                            )
                        })
                    }

                </div>}
                {/* Circular Result */}
                {
                    showResult == 4 && concise && <div className='flex flex-col bg-white m-2 rounded-lg overflow-auto'>
                        <div className='grid grid-cols-8 border-b-2 border-black bg-blue-500 text-white min-w-[1440px] text-center p-2'>
                            <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>S.No</div>
                            <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>Start&nbsp;Datetime</div>
                            <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>End&nbsp;Datetime</div>
                            <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>Net Circular Pnl&nbsp;</div>
                            <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>First Trade</div>
                            <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>Last Trade</div>
                            <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>Maximum Loss</div>
                            <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>Max Standing Day</div>
                        </div>
                        {
                            circularResult.length > 0 && circularResult.map((list, key) => {
                                return (
                                    <div>
                                        <div className='m-2 grid grid-cols-8 gap-3 p-1 text-center border-b-2'>
                                            <div className=''>{key + 1}</div>
                                            <div className=''> {list[0][0]}&nbsp;&nbsp;{list[0][1]}</div>
                                            <div className=''> {list[list.length - 1][2]}&nbsp;&nbsp;{list[list.length - 1][3]}</div>
                                            <div className=''> {circularProfit[key]}</div>
                                            <div className=''> {circularProperty[key][2]}</div>
                                            <div className=''> {circularProperty[key][1]}</div>
                                            <div className=''> {((circularProperty[key][2] - circularProperty[key][1]) / 2 * ((circularProperty[key][2] - circularProperty[key][1]) / targetDiff + 1) * lotSize).toFixed(2)} <span className='text-blue-600'>({(circularProperty[key][2] - circularProperty[key][1]).toFixed(2)})</span></div>
                                            <div className=''> {circularProperty[key][0]}</div>
                                        </div>
                                    </div>
                                )
                            })
                        }

                    </div>
                }
                {
                    showResult == 4 && !concise && <div className='flex flex-col bg-white m-2 rounded-lg overflow-auto'>
                        <div className='grid grid-cols-11 border-b-2 border-black min-w-[1440px] text-center p-2'>
                            <div className='font-[600] text-[16px] flex flex-row text-center justify-start'>S.No&nbsp;Entry<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(0, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(0, 1); }} /></div></div>
                            <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>Exit&nbsp;<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(2, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(2, 1); }} /></div></div>
                            <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>WeekDay<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(4, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(4, 1); }} /></div></div>
                            <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>Standing_Position<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(5, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(4, 1); }} /></div></div>
                            <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>Entry&nbsp;Price<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(6, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(4, 1); }} /></div></div>
                            <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>Exit&nbsp; Price<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(7, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(5, 1); }} /></div></div>
                            <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>Profit<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(9, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(6, 1); }} /></div></div>
                            <div className='font-[600] text-[16px] flex flex-row text-center justify-center'>Monthly&nbsp;Expiry<div className='flex flex-col'><MdOutlineKeyboardArrowUp style={{ fontSize: "16px", cursor: 'pointer' }} onClick={() => { sorted(10, 0); }} /><MdOutlineKeyboardArrowDown style={{ fontSize: "16px", cursor: 'pointer', marginTop: "-6px" }} onClick={() => { sorted(7, 1); }} /></div></div>
                            <div className='font-[600] text-[16px]'>Reason</div>
                            <div className='font-[600] text-[16px]'>Low & High</div>
                            <div className='font-[600] text-[16px]'>Target Price</div>
                        </div>
                        {
                            circularResult.length > 0 && circularResult.map((list, key) => {
                                return (
                                    <div>
                                        {list.map((item, index) => {
                                            return (
                                                <div className='grid grid-cols-11 border-b-2 min-w-[1440px] p-2 text-center' key={index + key} style={{ backgroundColor: item[10] == 'rc' ? '#F8F8F8' : 'white' }}>
                                                    <div className='text-[16px]'>{index + 1} {item[0]}&nbsp;&nbsp;{item[1]}</div>
                                                    <div className='text-[16px]'>{item[2]}&nbsp;&nbsp;{item[3]}</div>
                                                    <div className='text-[16px]'>{item[4]}</div>
                                                    <div className='text-[16px]'>{item[5]}</div>
                                                    <div className='text-[16px]'><span className='text-blue-600'>$</span>{item[6]}</div>
                                                    <div className='text-[16px]'><span className='text-blue-600'>$</span>{item[7]}</div>
                                                    <div className='text-[16px]' style={{ color: item[9] > 0 ? "green" : "red" }}><span className='text-blue-600'>$</span>{item[9]}</div>
                                                    <div className='text-[16px]'>{item[10]}</div>
                                                    <div className='text-[16px]'>{item[13]}</div>
                                                    <div className='text-[16px]'>{item[14]}--{item[14]}</div>
                                                    <div className='text-[16px'><span className='text-blue-600'>$</span>{item[16]}</div>
                                                </div>
                                            )
                                        })}
                                        <div className='m-2 border-2 rounded-lg border-black flex flex-row flex-wrap gap-6 p-2'>
                                            <div className=''><span className='font-[600]'>Circular :</span>{key + 1}</div>
                                            <div className=''><span className='font-[600]'>Start Datetime:</span> {list[0][0]}&nbsp;&nbsp;{list[0][1]}</div>
                                            <div className=''><span className='font-[600]'>End Datetime:</span> {list[list.length - 1][2]}&nbsp;&nbsp;{list[list.length - 1][3]}</div>
                                            <div className=''><span className='font-[600]'>Net Circular Pnl:</span> {circularProfit[key]}</div>
                                            <div className=''><span className='font-[600]'>First Trade:</span> {circularProperty[key][2]}</div>
                                            <div className=''><span className='font-[600]'>Last Trade:</span> {circularProperty[key][1]}</div>
                                            <div className=''><span className='font-[600]'>Maximum Loss:</span>{((circularProperty[key][2] - circularProperty[key][1]) / 2 * ((circularProperty[key][2] - circularProperty[key][1]) / targetDiff + 1) * lotSize).toFixed(2)}({(circularProperty[key][2] - circularProperty[key][1]).toFixed(2)})</div>
                                            <div className=''><span className='font-[600]'>Max. Standing Day:</span> {circularProperty[key][0]}</div>
                                        </div>
                                    </div>
                                )
                            })
                        }

                    </div>
                }

                {/* Page Size-> No. Of Items */}
                <div className="flex flex-grow flex-wrap" >
                    <div className="flex flex-row m-10">
                        <div className="font-[600] text-[16px]">Page Size</div>
                        <select className="ml-3 outline-0 rounded-md" value={pageSize} onChange={(e) => { setPageSize(e.target.value); }}>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                            <option value={75}>75</option>
                            <option value={100}>100</option>
                        </select>
                    </div>
                    <div className="flex justify-end flex-grow mb-10 mt-10 mr-10">
                        <div className="flex flex-row gap-2">
                            <div className='rounded-md border-2 border-white h-[32px] w-[32px] bg-[#2D5BFF] text-white'><FaArrowLeft className='m-1' /></div>
                            {
                                NumberBox.map((item, key) => {
                                    return (
                                        <div className="rounded h-[32px] w-[32px] font-[Ubuntu]  text-[16px] font-[700] text-center text-[#2D5BFF] justify-center border-[1px] border-[#ECF0FF] cursor-pointer active:bg-[#ECF0FF]" key={key} style={{ backgroundColor: activeColor === key ? "white" : "#ECF0FF" }} onClick={() => { setCurrentIndex(key + 1); setActiveColor(key); }}>{key + 1}</div>
                                    )
                                })
                            }
                            <div className='rounded-md border-2 border-white h-[32px] w-[32px] bg-[#2D5BFF] text-white'><FaArrowRight className='m-1' /></div>
                        </div>
                    </div>
                </div>
            </div>
            }
            <Tooltip id="my-tooltip" style={{ width: '300px', borderRadius: '10px', backgroundColor: 'rgb(147 197 253)', color: 'black', boxShadow: '5px 5px 5px rgba(0, 0,  , 0.1)' }} />
        </div>
    );
};

export default Normal;
