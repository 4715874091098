export const bearish=[
  {
    "_id": {
      "$oid": "662ce3939f6403cd2438023d"
    },
    "name": "Short Call",
    "url":"ShortCall",
    "array": {
      "leg": 1,
      "atmprice":"spot",
      "segmentType": [
        "option",
      ],
      "lotarray1": [
        50
      ],
      "legarray": [
        "SC",
      ],
      "darray": [
        "0",
      ],
      "intraday": "Yes",
      "tradesymbol": "NIFTY",
      "gap": 50,
      "stoploss": 1,
      "maxprofit": -1,
      "starttime": "09:30",
      "endtime": "15:25",
      "startdate": "2022-01-03",
      "enddate": "2022-02-03",
      "startDay": 4,
      "endDay": 0,
      "atm": [
        "ATM+100",
      ],
      "squareoff": "one",
      "waitTrade": false,
      "waitTradeType": [
        "Point ++",
      ],
      "waitTradeValue": [
        0,
        
      ],
      "slToCost": false,
      "reEntry": false,
      "protectType": "Lock & Trail Profit",
      "profitIncrease": "1000",
      "lockMinimumProfit": "0",
      "profitReaches": "1000",
      "trailProfit": "300",
      "legsStoplossType": [
        "SL%",
      ],
      "legsStoploss": [
        "-1",
      ],
      "legsTargetProfit": [
        "-1",
      ],
      "legsTargetProfitType": [
        "TP%",
      ],
      "reEntryValue": [
        "-1",
      ],
      "reEntryChoice": [
        "Re-Entry",
      ],
      "legSymbol": [
        "NIFTY",
      ],
      "legExpiryType": [
        "Weekly",
      ],
      "atmChoice": [
        "point",
      ],
      "closestPremiumChoice": [
        "CP~",
      ],
      "closestPremiumValue": [
        100,
      ],
      "legsReEntry": [
        false,
      ],
      "protectProfit": false,
      "reEntryType": [
        "SL",
      ],
      "trailStoploss": [
        false,
      ],
      "trailStoplossLoss": [
        "",
      ],
      "trailStoplossProfit": [
        "",
      ],
      "trailStoplossType": [
        "point",
      ],
      "showRangeBreakout": false,
      "rangeStartTime": "09:20",
      "rangeEndTime": "09:30",
      "lotarray": [
        1,
      ],
      "optionBuySell": [
        "Sell",
      ],
      "optionCallPut": [
        "Call",
      ]
    }
  },
  {
    "_id": {
      "$oid": "662ce3939f6403cd2438023d"
    },
    "name": "Long Put",
    "url":"LongPut",
    "array": {
      "leg": 1,
      "atmprice":"spot",
      "segmentType": [
        "option",
      ],
      "lotarray1": [
        50
      ],
      "legarray": [
        "BP",
      ],
      "darray": [
        "0",
      ],
      "intraday": "Yes",
      "tradesymbol": "NIFTY",
      "gap": 50,
      "stoploss": 1,
      "maxprofit": -1,
      "starttime": "09:30",
      "endtime": "15:25",
      "startdate": "2022-01-03",
      "enddate": "2022-02-03",
      "startDay": 4,
      "endDay": 0,
      "atm": [
        "ATM+100",
      ],
      "squareoff": "one",
      "waitTrade": false,
      "waitTradeType": [
        "Point ++",
      ],
      "waitTradeValue": [
        0,
        
      ],
      "slToCost": false,
      "reEntry": false,
      "protectType": "Lock & Trail Profit",
      "profitIncrease": "1000",
      "lockMinimumProfit": "0",
      "profitReaches": "1000",
      "trailProfit": "300",
      "legsStoplossType": [
        "SL%",
      ],
      "legsStoploss": [
        "-1",
      ],
      "legsTargetProfit": [
        "-1",
      ],
      "legsTargetProfitType": [
        "TP%",
      ],
      "reEntryValue": [
        "-1",
      ],
      "reEntryChoice": [
        "Re-Entry",
      ],
      "legSymbol": [
        "NIFTY",
      ],
      "legExpiryType": [
        "Weekly",
      ],
      "atmChoice": [
        "point",
      ],
      "closestPremiumChoice": [
        "CP~",
      ],
      "closestPremiumValue": [
        100,
      ],
      "legsReEntry": [
        false,
      ],
      "protectProfit": false,
      "reEntryType": [
        "SL",
      ],
      "trailStoploss": [
        false,
      ],
      "trailStoplossLoss": [
        "",
      ],
      "trailStoplossProfit": [
        "",
      ],
      "trailStoplossType": [
        "point",
      ],
      "showRangeBreakout": false,
      "rangeStartTime": "09:20",
      "rangeEndTime": "09:30",
      "lotarray": [
        1,
      ],
      "optionBuySell": [
        "Buy",
      ],
      "optionCallPut": [
        "Put",
      ]
    }
  },
  {
    "_id": {
      "$oid": "662ce3939f6403cd2438023d"
    },
    "name": "Bear Call Spread",
    "url":"BearCallSpread",
    "array": {
      "leg": 2,
      "atmprice":"spot",
      "segmentType": [
        "option",
        "option"
      ],
      "lotarray1": [
        50,
        50
      ],
      "legarray": [
        "BC",
        "SC"
      ],
      "darray": [
        "0",
        "0"
      ],
      "intraday": "Yes",
      "tradesymbol": "NIFTY",
      "gap": 50,
      "stoploss": 1,
      "maxprofit": -1,
      "starttime": "09:30",
      "endtime": "15:25",
      "startdate": "2022-01-03",
      "enddate": "2022-02-03",
      "startDay": 4,
      "endDay": 0,
      "atm": [
        "ATM+200",
        "ATM-300"
      ],
      "squareoff": "one",
      "waitTrade": false,
      "waitTradeType": [
        "Point ++",
        "Point ++"
      ],
      "waitTradeValue": [
        0,
        0
      ],
      "slToCost": false,
      "reEntry": false,
      "protectType": "Lock & Trail Profit",
      "profitIncrease": "1000",
      "lockMinimumProfit": "0",
      "profitReaches": "1000",
      "trailProfit": "300",
      "legsStoplossType": [
        "SL%",
        "SL%"
      ],
      "legsStoploss": [
        "-1",
        "-1"
      ],
      "legsTargetProfit": [
        "-1",
        "-1"
      ],
      "legsTargetProfitType": [
        "TP%",
        "TP%"
      ],
      "reEntryValue": [
        "-1",
        "-1"
      ],
      "reEntryChoice": [
        "Re-Entry",
        "Re-Entry"
      ],
      "legSymbol": [
        "NIFTY",
        "NIFTY"
      ],
      "legExpiryType": [
        "Weekly",
        "Weekly"
      ],
      "atmChoice": [
        "point",
        "point"
      ],
      "closestPremiumChoice": [
        "CP~",
        "CP~"
      ],
      "closestPremiumValue": [
        100,
        100
      ],
      "legsReEntry": [
        false,
        false
      ],
      "protectProfit": false,
      "reEntryType": [
        "SL",
        "SL"
      ],
      "trailStoploss": [
        false,
        false
      ],
      "trailStoplossLoss": [
        "",
        ""
      ],
      "trailStoplossProfit": [
        "",
        ""
      ],
      "trailStoplossType": [
        "point",
        "point"
      ],
      "showRangeBreakout": false,
      "rangeStartTime": "09:20",
      "rangeEndTime": "09:30",
      "lotarray": [
        1,
        1
      ],
      "optionBuySell": [
        "Buy",
        "Sell"
      ],
      "optionCallPut": [
        "Call",
        "Call"
      ]
    }
  },
  {
    "_id": {
      "$oid": "662ce3939f6403cd2438023d"
    },
    "name": "Bear Put Spread",
    "url":"BearPutSpread",
    "array": {
      "leg": 2,
      "atmprice":"spot",
      "segmentType": [
        "option",
        "option"
      ],
      "lotarray1": [
        50,
        50
      ],
      "legarray": [
        "SP",
        "BP"
      ],
      "darray": [
        "0",
        "0"
      ],
      "intraday": "Yes",
      "tradesymbol": "NIFTY",
      "gap": 50,
      "stoploss": 1,
      "maxprofit": -1,
      "starttime": "09:30",
      "endtime": "15:25",
      "startdate": "2022-01-03",
      "enddate": "2022-02-03",
      "startDay": 4,
      "endDay": 0,
      "atm": [
        "ATM+200",
        "ATM-300"
      ],
      "squareoff": "one",
      "waitTrade": false,
      "waitTradeType": [
        "Point ++",
        "Point ++"
      ],
      "waitTradeValue": [
        0,
        0
      ],
      "slToCost": false,
      "reEntry": false,
      "protectType": "Lock & Trail Profit",
      "profitIncrease": "1000",
      "lockMinimumProfit": "0",
      "profitReaches": "1000",
      "trailProfit": "300",
      "legsStoplossType": [
        "SL%",
        "SL%"
      ],
      "legsStoploss": [
        "-1",
        "-1"
      ],
      "legsTargetProfit": [
        "-1",
        "-1"
      ],
      "legsTargetProfitType": [
        "TP%",
        "TP%"
      ],
      "reEntryValue": [
        "-1",
        "-1"
      ],
      "reEntryChoice": [
        "Re-Entry",
        "Re-Entry"
      ],
      "legSymbol": [
        "NIFTY",
        "NIFTY"
      ],
      "legExpiryType": [
        "Weekly",
        "Weekly"
      ],
      "atmChoice": [
        "point",
        "point"
      ],
      "closestPremiumChoice": [
        "CP~",
        "CP~"
      ],
      "closestPremiumValue": [
        100,
        100
      ],
      "legsReEntry": [
        false,
        false
      ],
      "protectProfit": false,
      "reEntryType": [
        "SL",
        "SL"
      ],
      "trailStoploss": [
        false,
        false
      ],
      "trailStoplossLoss": [
        "",
        ""
      ],
      "trailStoplossProfit": [
        "",
        ""
      ],
      "trailStoplossType": [
        "point",
        "point"
      ],
      "showRangeBreakout": false,
      "rangeStartTime": "09:20",
      "rangeEndTime": "09:30",
      "lotarray": [
        1,
        1
      ],
      "optionBuySell": [
        "Sell",
        "Buy"
      ],
      "optionCallPut": [
        "Put",
        "Put"
      ]
    }
  },
  {
    "_id": {
      "$oid": "662ce3939f6403cd2438023d"
    },
    "name": "Short Synthetic Future",
    "url":"ShortSyntheticFuture",
    "array": {
      "leg": 2,
      "atmprice":"spot",
      "segmentType": [
        "option",
        "option"
      ],
      "lotarray1": [
        50,
        50
      ],
      "legarray": [
        "BC",
        "SC"
      ],
      "darray": [
        "0",
        "0"
      ],
      "intraday": "Yes",
      "tradesymbol": "NIFTY",
      "gap": 50,
      "stoploss": 1,
      "maxprofit": -1,
      "starttime": "09:30",
      "endtime": "15:25",
      "startdate": "2022-01-03",
      "enddate": "2022-02-03",
      "startDay": 4,
      "endDay": 0,
      "atm": [
        "ATM+100",
        "ATM+100"
      ],
      "squareoff": "one",
      "waitTrade": false,
      "waitTradeType": [
        "Point ++",
        "Point ++"
      ],
      "waitTradeValue": [
        0,
        0
      ],
      "slToCost": false,
      "reEntry": false,
      "protectType": "Lock & Trail Profit",
      "profitIncrease": "1000",
      "lockMinimumProfit": "0",
      "profitReaches": "1000",
      "trailProfit": "300",
      "legsStoplossType": [
        "SL%",
        "SL%"
      ],
      "legsStoploss": [
        "-1",
        "-1"
      ],
      "legsTargetProfit": [
        "-1",
        "-1"
      ],
      "legsTargetProfitType": [
        "TP%",
        "TP%"
      ],
      "reEntryValue": [
        "-1",
        "-1"
      ],
      "reEntryChoice": [
        "Re-Entry",
        "Re-Entry"
      ],
      "legSymbol": [
        "NIFTY",
        "NIFTY"
      ],
      "legExpiryType": [
        "Weekly",
        "Weekly"
      ],
      "atmChoice": [
        "point",
        "point"
      ],
      "closestPremiumChoice": [
        "CP~",
        "CP~"
      ],
      "closestPremiumValue": [
        100,
        100
      ],
      "legsReEntry": [
        false,
        false
      ],
      "protectProfit": false,
      "reEntryType": [
        "SL",
        "SL"
      ],
      "trailStoploss": [
        false,
        false
      ],
      "trailStoplossLoss": [
        "",
        ""
      ],
      "trailStoplossProfit": [
        "",
        ""
      ],
      "trailStoplossType": [
        "point",
        "point"
      ],
      "showRangeBreakout": false,
      "rangeStartTime": "09:20",
      "rangeEndTime": "09:30",
      "lotarray": [
        1,
        1
      ],
      "optionBuySell": [
        "Buy",
        "Sell"
      ],
      "optionCallPut": [
        "Call",
        "Call"
      ]
    }
  },
  {
    "_id": {
      "$oid": "662ce3939f6403cd2438023d"
    },
    "name": "Risk Reversal",
    "url":"RiskReversal",
    "array": {
      "leg": 2,
      "atmprice":"spot",
      "segmentType": [
        "option",
        "option"
      ],
      "lotarray1": [
        50,
        50
      ],
      "legarray": [
        "BC",
        "SC"
      ],
      "darray": [
        "0",
        "0"
      ],
      "intraday": "Yes",
      "tradesymbol": "NIFTY",
      "gap": 50,
      "stoploss": 1,
      "maxprofit": -1,
      "starttime": "09:30",
      "endtime": "15:25",
      "startdate": "2022-01-03",
      "enddate": "2022-02-03",
      "startDay": 4,
      "endDay": 0,
      "atm": [
        "ATM+400",
        "ATM-400"
      ],
      "squareoff": "one",
      "waitTrade": false,
      "waitTradeType": [
        "Point ++",
        "Point ++"
      ],
      "waitTradeValue": [
        0,
        0
      ],
      "slToCost": false,
      "reEntry": false,
      "protectType": "Lock & Trail Profit",
      "profitIncrease": "1000",
      "lockMinimumProfit": "0",
      "profitReaches": "1000",
      "trailProfit": "300",
      "legsStoplossType": [
        "SL%",
        "SL%"
      ],
      "legsStoploss": [
        "-1",
        "-1"
      ],
      "legsTargetProfit": [
        "-1",
        "-1"
      ],
      "legsTargetProfitType": [
        "TP%",
        "TP%"
      ],
      "reEntryValue": [
        "-1",
        "-1"
      ],
      "reEntryChoice": [
        "Re-Entry",
        "Re-Entry"
      ],
      "legSymbol": [
        "NIFTY",
        "NIFTY"
      ],
      "legExpiryType": [
        "Weekly",
        "Weekly"
      ],
      "atmChoice": [
        "point",
        "point"
      ],
      "closestPremiumChoice": [
        "CP~",
        "CP~"
      ],
      "closestPremiumValue": [
        100,
        100
      ],
      "legsReEntry": [
        false,
        false
      ],
      "protectProfit": false,
      "reEntryType": [
        "SL",
        "SL"
      ],
      "trailStoploss": [
        false,
        false
      ],
      "trailStoplossLoss": [
        "",
        ""
      ],
      "trailStoplossProfit": [
        "",
        ""
      ],
      "trailStoplossType": [
        "point",
        "point"
      ],
      "showRangeBreakout": false,
      "rangeStartTime": "09:20",
      "rangeEndTime": "09:30",
      "lotarray": [
        1,
        1
      ],
      "optionBuySell": [
        "Buy",
        "Sell"
      ],
      "optionCallPut": [
        "Call",
        "Call"
      ]
    }
  },
  {
    "_id": {
      "$oid": "662ce4de694ea15d1ed36adb"
    },
    "name": "Bearish Butterfly",
    "url":"BearishButterfly",
    "array": {
      "leg": 4,
      "atmprice":"spot",
      "segmentType": [
        "option",
        "option",
        "option",
        "option"
      ],
      "lotarray1": [
        50,
        50,
        50,
        50
      ],
      "legarray": [
        "BP",
        "SC",
        "SP",
        "BC"
      ],
      "darray": [
        "-200",
        "0",
        "0",
        "+200"
      ],
      "intraday": "Yes",
      "tradesymbol": "NIFTY",
      "gap": 50,
      "stoploss": 1,
      "maxprofit": -1,
      "starttime": "09:30",
      "endtime": "15:25",
      "startdate": "2022-01-03",
      "enddate": "2022-02-03",
      "startDay": 4,
      "endDay": 0,
      "atm": [
        "ATM-200",
        "ATM",
        "ATM",
        "ATM+200"
      ],
      "squareoff": "one",
      "waitTrade": false,
      "waitTradeType": [
        "Point ++",
        "Point ++",
        "Point ++",
        "Point ++"
      ],
      "waitTradeValue": [
        0,
        0,
        0,
        0
      ],
      "slToCost": false,
      "reEntry": false,
      "protectType": "Lock & Trail Profit",
      "profitIncrease": "1000",
      "lockMinimumProfit": "0",
      "profitReaches": "1000",
      "trailProfit": "300",
      "legsStoplossType": [
        "SL%",
        "SL%",
        "SL%",
        "SL%"
      ],
      "legsStoploss": [
        "-1",
        "-1",
        "-1",
        "-1"
      ],
      "legsTargetProfit": [
        "-1",
        "-1",
        "-1",
        "-1"
      ],
      "legsTargetProfitType": [
        "TP%",
        "TP%",
        "TP%",
        "TP%"
      ],
      "reEntryValue": [
        "-1",
        "-1",
        "-1",
        "-1"
      ],
      "reEntryChoice": [
        "Re-Entry",
        "Re-Entry",
        "Re-Entry",
        "Re-Entry"
      ],
      "legSymbol": [
        "NIFTY",
        "NIFTY",
        "NIFTY",
        "NIFTY"
      ],
      "legExpiryType": [
        "Weekly",
        "Weekly",
        "Weekly",
        "Weekly"
      ],
      "atmChoice": [
        "point",
        "point",
        "point",
        "point"
      ],
      "closestPremiumChoice": [
        "CP~",
        "CP~",
        "CP~",
        "CP~"
      ],
      "closestPremiumValue": [
        100,
        100,
        100,
        100
      ],
      "legsReEntry": [
        false,
        false,
        false,
        false
      ],
      "protectProfit": false,
      "reEntryType": [
        "SL",
        "SL",
        "SL",
        "SL"
      ],
      "trailStoploss": [
        false,
        false,
        false,
        false
      ],
      "trailStoplossLoss": [
        "",
        "",
        "",
        ""
      ],
      "trailStoplossProfit": [
        "",
        "",
        "",
        ""
      ],
      "trailStoplossType": [
        "point",
        "point",
        "point",
        "point"
      ],
      "showRangeBreakout": false,
      "rangeStartTime": "09:20",
      "rangeEndTime": "09:30",
      "lotarray": [
        1,
        1,
        1,
        1
      ],
      "optionBuySell": [
        "Buy",
        "Sell",
        "Sell",
        "Buy"
      ],
      "optionCallPut": [
        "Put",
        "Call",
        "Put",
        "Call"
      ]
    }
  },
  {
    "_id": {
      "$oid": "662ce4de694ea15d1ed36adb"
    },
    "name": "Bearish Condor",
    "url":"BearishCondor",
    "array": {
      "leg": 4,
      "atmprice":"spot",
      "segmentType": [
        "option",
        "option",
        "option",
        "option"
      ],
      "lotarray1": [
        50,
        50,
        50,
        50
      ],
      "legarray": [
        "BP",
        "SC",
        "SP",
        "BC"
      ],
      "darray": [
        "-200",
        "0",
        "0",
        "+200"
      ],
      "intraday": "Yes",
      "tradesymbol": "NIFTY",
      "gap": 50,
      "stoploss": 1,
      "maxprofit": -1,
      "starttime": "09:30",
      "endtime": "15:25",
      "startdate": "2022-01-03",
      "enddate": "2022-02-03",
      "startDay": 4,
      "endDay": 0,
      "atm": [
        "ATM-200",
        "ATM",
        "ATM",
        "ATM+200"
      ],
      "squareoff": "one",
      "waitTrade": false,
      "waitTradeType": [
        "Point ++",
        "Point ++",
        "Point ++",
        "Point ++"
      ],
      "waitTradeValue": [
        0,
        0,
        0,
        0
      ],
      "slToCost": false,
      "reEntry": false,
      "protectType": "Lock & Trail Profit",
      "profitIncrease": "1000",
      "lockMinimumProfit": "0",
      "profitReaches": "1000",
      "trailProfit": "300",
      "legsStoplossType": [
        "SL%",
        "SL%",
        "SL%",
        "SL%"
      ],
      "legsStoploss": [
        "-1",
        "-1",
        "-1",
        "-1"
      ],
      "legsTargetProfit": [
        "-1",
        "-1",
        "-1",
        "-1"
      ],
      "legsTargetProfitType": [
        "TP%",
        "TP%",
        "TP%",
        "TP%"
      ],
      "reEntryValue": [
        "-1",
        "-1",
        "-1",
        "-1"
      ],
      "reEntryChoice": [
        "Re-Entry",
        "Re-Entry",
        "Re-Entry",
        "Re-Entry"
      ],
      "legSymbol": [
        "NIFTY",
        "NIFTY",
        "NIFTY",
        "NIFTY"
      ],
      "legExpiryType": [
        "Weekly",
        "Weekly",
        "Weekly",
        "Weekly"
      ],
      "atmChoice": [
        "point",
        "point",
        "point",
        "point"
      ],
      "closestPremiumChoice": [
        "CP~",
        "CP~",
        "CP~",
        "CP~"
      ],
      "closestPremiumValue": [
        100,
        100,
        100,
        100
      ],
      "legsReEntry": [
        false,
        false,
        false,
        false
      ],
      "protectProfit": false,
      "reEntryType": [
        "SL",
        "SL",
        "SL",
        "SL"
      ],
      "trailStoploss": [
        false,
        false,
        false,
        false
      ],
      "trailStoplossLoss": [
        "",
        "",
        "",
        ""
      ],
      "trailStoplossProfit": [
        "",
        "",
        "",
        ""
      ],
      "trailStoplossType": [
        "point",
        "point",
        "point",
        "point"
      ],
      "showRangeBreakout": false,
      "rangeStartTime": "09:20",
      "rangeEndTime": "09:30",
      "lotarray": [
        1,
        1,
        1,
        1
      ],
      "optionBuySell": [
        "Buy",
        "Sell",
        "Sell",
        "Buy"
      ],
      "optionCallPut": [
        "Put",
        "Call",
        "Put",
        "Call"
      ]
    }
  },
  ]