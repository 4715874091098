

// we are not using but for Updation,We will use this method

import { create } from 'zustand';
import {devtools, persist} from 'zustand/middleware'

const backtestResultStore = (set) => ({
csv:[],
inputData:{},
appearNA:false,
tradeOnlyFirstEntry:false,
vixStart:0,
vixExit:99,
resultStartDate:"2022-01-03",
resultEndDate:"2022-02-03",
dayOption:[1,1,1,1,1],
variant:["contained", "contained", "contained", "contained", "contained", "contained"],
tempFilter:[],
showRoi:false,
stats:{},
resultFilter:[],

updateCsv :(newCSV)=>{
    set({
        csv:newCSV
    })
},
updateInputData : (newData)=>{
    set({
        inputData:newData
    })
},
updateAppearNA: () => {
    set((state) => ({
        appearNA: !state.appearNA  // Properly toggles the appearNA state
    }));
},
updateTradeOnlyFirstEntry: () => {
    set((state) => ({
        tradeOnlyFirstEntry: !state.tradeOnlyFirstEntry  // Properly toggles the tradeOnlyFirstEntry state
    }));
},
updateVixStart:(newVixvalue)=>{
    set({
        vixStart:newVixvalue
    })
},
updateVixExit:(newVixvalue)=>{
    set({
        vixExit:newVixvalue
    })
},
updateResultStartDate:(newDate)=>{
    set({
        resultStartDate:newDate
    })
},
updateResultEndDate:(newDate)=>{
    set({
        resultEndDate:newDate
    })
},
updateDayOption:(newData)=>{
    set({
        dayOption:newData
    })
},
updateDayVariant:(newVariant)=>{
    set({
        variant:newVariant
    })
},
// Action to update variant and dayoption
updateVariantAndDayoption: (index) => set((state) => {
// Update variant
const newVariant = [...state.variant];
newVariant[index] = state.variant[index] === 'contained' ? 'outlined' : 'contained';

// Update dayoption
const newDayoption = [...state.dayOption];
newDayoption[index] = 1 - state.dayOption[index];

return { variant: newVariant, dayOption: newDayoption };
}),
updateTempFilter: (newFilter)=>{
    set({
        tempFilter:newFilter
    })
},
updateStats:(newStats)=>{
    set({
        stats:newStats
    })
},

})

// Now,We will make a above code as a store and perform action in another component to perform it.
const useBacktestResultStore = create(
    devtools(
        persist(backtestResultStore, {
            name: "backtestResult",
        })
    )
)


export default useBacktestResultStore;