import { formatDate,NoOfDaysBetweenTwoDate,daysFromToday } from "../../../Result/Utility";
import { totalMonthProfit,totalWeekProfit } from "./Util";

export const combinedStatsResult = (combinedProfit, combinedResult, margin,startdate,enddate,premiumPrice) => {
    let n=0;
    let dayoption = new Array(6).fill(1);
    // setDate(Array(parseInt(combinedProfit.length)).fill(""))
    let total_profit = 0, winningtrades = 0, losingtrades = 0, maxprofit = 0, maxloss = 0, barChartDateArray = [], previous = 0, cnt = 0, premiumAmount = 0;
    n = combinedProfit.length;
    let cumulative = new Array(combinedProfit.length);
    let profitArray = new Array(combinedProfit.length)
    let week1 = new Array(5).fill(0)   //For Storing profit on the basis of daywise
    let month1 = {}   //For Storing profit on the basis of monthwise
    let net_profit = new Array(combinedProfit.length)  //For Storing profit
    // expectancy
    let profitQuantity = 0, totalProfit = 0, totalLoss = 0, lossQuantity = 0;
    let maxStreak = 0, lossStreak = 0;
    let winStreakDict = [0, 0, 0, 0];
    let lossStreakDict = [0, 0, 0];
    let prev = 1, count = 0;
    // drawdown
    let maxProfit = 0; // Initialize maxPrice with the first element of the array
    let MaximumDrawdown = 0;
    let monthlyDrawdown = {};
    let result = new Array(combinedResult.length)
    // investmentDay
    let investmentDay = 0;
    // group by expiry
    const expirySet = new Set();
    let weekResult = {};
    // mdd day
    let mddCount = 0, recoveryStartDate = "", maxLoss = 9999999, ans = { "count": 0, "startDate": "", "endDate": "", "recovery": 0, "continue": false, "status": 'Running', "recoveryDate": "" };
    let dayOpen = { "pdhProfit": 0, "pdhProfitCnt": 0, "pdhLoss": 0, "pdhLossCnt": 0, "pdlProfit": 0, "pdlLoss": 0, "pdlProfitCnt": 0, "pdlLossCnt": 0 }
    let gap = { "upProfit": 0, "upProfitCnt": 0, "upLoss": 0, "upLossCnt": 0, "downProfit": 0, "downProfitCnt": 0, "downLoss": 0, "downLossCnt": 0 }
    let vix = new Array(3).fill().map(() => ({ profit: 0, cnt: 0 }));
    let drawDownDate = combinedResult.length>0 && combinedResult[0][0] ? combinedResult[0][0] : '';

    //------start loop ---
    for (var i = 0; i < combinedProfit.length; i++) {
        // Formula to Calculate Sum of Profit at a particular Day
        let flag = 0; // It indicate supremacy of strategy profit or stoploss
        net_profit[i] = combinedProfit[i];
        total_profit += combinedProfit[i]

        //  Method to Sum of Profit DayWise
        if (dayoption[0] === 1 && combinedResult[i][1] === 'Mon') {
            week1[0] += parseFloat(net_profit[i]);
            if (weekResult.hasOwnProperty(combinedResult[i][0][0] + combinedResult[i][0][1] + combinedResult[i][0][2] + combinedResult[i][0][3])) {
                weekResult[combinedResult[i][0][0] + combinedResult[i][0][1] + combinedResult[i][0][2] + combinedResult[i][0][3]][0] += parseFloat(net_profit[i]);
            } else {
                weekResult[combinedResult[i][0][0] + combinedResult[i][0][1] + combinedResult[i][0][2] + combinedResult[i][0][3]] = new Array(5).fill(0);
                weekResult[combinedResult[i][0][0] + combinedResult[i][0][1] + combinedResult[i][0][2] + combinedResult[i][0][3]][0] += parseFloat(net_profit[i]);
            }
        }
        else if (dayoption[1] === 1 && combinedResult[i][1] === 'Tue') {
            week1[1] += parseFloat(net_profit[i]);
            if (weekResult.hasOwnProperty(combinedResult[i][0][0] + combinedResult[i][0][1] + combinedResult[i][0][2] + combinedResult[i][0][3])) {
                weekResult[combinedResult[i][0][0] + combinedResult[i][0][1] + combinedResult[i][0][2] + combinedResult[i][0][3]][1] += parseFloat(net_profit[i]);
            } else {
                weekResult[combinedResult[i][0][0] + combinedResult[i][0][1] + combinedResult[i][0][2] + combinedResult[i][0][3]] = new Array(5).fill(0);
                weekResult[combinedResult[i][0][0] + combinedResult[i][0][1] + combinedResult[i][0][2] + combinedResult[i][0][3]][1] += parseFloat(net_profit[i]);
            }

        }
        else if (dayoption[2] === 1 && combinedResult[i][1] === 'Wed') {
            week1[2] += parseFloat(net_profit[i]);
            if (weekResult.hasOwnProperty(combinedResult[i][0][0] + combinedResult[i][0][1] + combinedResult[i][0][2] + combinedResult[i][0][3])) {
                weekResult[combinedResult[i][0][0] + combinedResult[i][0][1] + combinedResult[i][0][2] + combinedResult[i][0][3]][2] += parseFloat(net_profit[i]);
            } else {
                weekResult[combinedResult[i][0][0] + combinedResult[i][0][1] + combinedResult[i][0][2] + combinedResult[i][0][3]] = new Array(5).fill(0);
                weekResult[combinedResult[i][0][0] + combinedResult[i][0][1] + combinedResult[i][0][2] + combinedResult[i][0][3]][2] += (net_profit[i]);
            }

        }
        else if (dayoption[3] === 1 && combinedResult[i][1] === 'Thu') {
            week1[3] += parseFloat(net_profit[i]);
            if (weekResult.hasOwnProperty(combinedResult[i][0][0] + combinedResult[i][0][1] + combinedResult[i][0][2] + combinedResult[i][0][3])) {
                weekResult[combinedResult[i][0][0] + combinedResult[i][0][1] + combinedResult[i][0][2] + combinedResult[i][0][3]][3] += parseFloat(net_profit[i]);
            } else {
                weekResult[combinedResult[i][0][0] + combinedResult[i][0][1] + combinedResult[i][0][2] + combinedResult[i][0][3]] = new Array(5).fill(0);
                weekResult[combinedResult[i][0][0] + combinedResult[i][0][1] + combinedResult[i][0][2] + combinedResult[i][0][3]][3] += parseFloat(net_profit[i]);
            }

        }
        else if (dayoption[4] === 1 && combinedResult[i][1] === 'Fri') {
            week1[4] += parseFloat(net_profit[i]);
            if (weekResult.hasOwnProperty(combinedResult[i][0][0] + combinedResult[i][0][1] + combinedResult[i][0][2] + combinedResult[i][0][3])) {
                weekResult[combinedResult[i][0][0] + combinedResult[i][0][1] + combinedResult[i][0][2] + combinedResult[i][0][3]][4] += parseFloat(net_profit[i]);
            } else {
                weekResult[combinedResult[i][0][0] + combinedResult[i][0][1] + combinedResult[i][0][2] + combinedResult[i][0][3]] = new Array(5).fill(0);
                weekResult[combinedResult[i][0][0] + combinedResult[i][0][1] + combinedResult[i][0][2] + combinedResult[i][0][3]][4] += parseFloat(net_profit[i]);
            }
        }
        else
            continue;

        // Method to find the MontlyWise Profit
        if (month1.hasOwnProperty(combinedResult[i][0][0] + combinedResult[i][0][1] + combinedResult[i][0][2] + combinedResult[i][0][3])) {
            month1[combinedResult[i][0][0] + combinedResult[i][0][1] + combinedResult[i][0][2] + combinedResult[i][0][3]][parseInt(combinedResult[i][0][5] + combinedResult[i][0][6])] += combinedProfit[i]
        } else {
            month1[combinedResult[i][0][0] + combinedResult[i][0][1] + combinedResult[i][0][2] + combinedResult[i][0][3]] = new Array(13).fill(0);
            month1[combinedResult[i][0][0] + combinedResult[i][0][1] + combinedResult[i][0][2] + combinedResult[i][0][3]][parseInt(combinedResult[i][0][5] + combinedResult[i][0][6])] += combinedProfit[i];
        }

        // Method to find the Cumulative Data
        cumulative[cnt] = previous + net_profit[i];
        previous = cumulative[cnt];

        // Method to find the Maximum Drawdown
        // Maximum Drawdown is the biggest loss observed in an investment from peak to trough
        // Formula of Drawdown:: MIN((B2-MAX($B$2-B2))/MAX($B$2-B2)
        if (cumulative[cnt] > maxProfit) {
            // console.log(maxProfit,maxLoss,cumulative[cnt],drawDownDate,recoveryStartDate,csv[i][0],'tradeOnllllll')
            let dayCount = NoOfDaysBetweenTwoDate(drawDownDate, combinedResult[i][0])
            if (ans.count < dayCount) {
                ans.count = parseFloat(dayCount).toFixed(0);
                ans.startDate = formatDate(drawDownDate);
                ans.endDate = formatDate(combinedResult[i][0]);
                ans.recovery = NoOfDaysBetweenTwoDate(recoveryStartDate, combinedResult[i][0],);
                ans.continue = false;
                ans.status = '';
                ans.recoveryDate = recoveryStartDate;
            }

            maxProfit = cumulative[cnt]; // Update maxPrice if a new peak is reached
            maxLoss = maxProfit;
            drawDownDate = combinedResult[i][0];
            recoveryStartDate = '';
        }

        if (cumulative[cnt] < maxLoss) {
            recoveryStartDate = combinedResult[i][0];
            maxLoss = cumulative[cnt];
        }

        const currentDrawdown = (cumulative[cnt] - maxProfit);

        if (currentDrawdown < MaximumDrawdown) {
            MaximumDrawdown = currentDrawdown; // Update drawdown if a new minimum drawdown is reached
        }
        result[cnt] = MaximumDrawdown;

        // Method to find the MontlyWise Profit
        if (monthlyDrawdown.hasOwnProperty(combinedResult[i][0][0] + combinedResult[i][0][1] + combinedResult[i][0][2] + combinedResult[i][0][3])) {
            monthlyDrawdown[combinedResult[i][0][0] + combinedResult[i][0][1] + combinedResult[i][0][2] + combinedResult[i][0][3]] = MaximumDrawdown
        } else {
            monthlyDrawdown[combinedResult[i][0][0] + combinedResult[i][0][1] + combinedResult[i][0][2] + combinedResult[i][0][3]] = new Array(13).fill(0);
            monthlyDrawdown[combinedResult[i][0][0] + combinedResult[i][0][1] + combinedResult[i][0][2] + combinedResult[i][0][3]] = MaximumDrawdown;
        }

        // Method to find all date
        barChartDateArray[cnt] = combinedResult[i][0]

        // Method to find the Total Premium Amount
        premiumAmount += net_profit[i] * premiumPrice;

        // Method to find the Expectancy
        // Formula to Calculate Expectancy
        // Expectancy=(WinRate × AverageWin)−(LossRate × AverageLoss)
        if (net_profit[i] >= 0) {
            profitQuantity += 1;
            totalProfit += net_profit[i];
            // Calculate streak
            if (prev > 0) {
                count += 1
                prev = 1;
            } else {
                lossStreak = count > lossStreak ? count : lossStreak;
                count = 1;
                prev = 1;
            }
        } else {
            lossQuantity += 1;
            totalLoss += net_profit[i];
            if (prev < 0) {
                count += 1;
                prev = -1;
            } else {
                maxStreak = count > maxStreak ? count : maxStreak;
                count = 1;
                prev = -1;
            }
        }

        // Method to find the winning trades and lossing trades
        profitArray[cnt] = net_profit[i]
        if (net_profit[i] > 0) {
            winningtrades += 1;
        }
        else {
            losingtrades += 1;
        }
        if (net_profit[i] > maxprofit) {
            maxprofit = net_profit[i];
        }
        if (net_profit[i] < maxloss) {
            maxloss = net_profit[i];
        }

        // Count Number of InvestmentDay
        investmentDay += 1
        cnt++;
    }
    //------- End Loop -----
    if (prev < 0) {
        if (count <= 3) { lossStreakDict[count - 1] += 1; }
    } else {
        if (count <= 4) { winStreakDict[count - 1] += 1; }
    }
    // daywise profitabiliry
    weekResult['Total'] = totalWeekProfit(weekResult);
    // monthwise profitability
    month1 = totalMonthProfit(month1, monthlyDrawdown, MaximumDrawdown,margin);
    // Calculate Expectancy
    let Expectancy1 = 0;
    totalLoss = -1 * totalLoss;
    if (totalLoss == 0) {
        Expectancy1 = 'Infinity';
    }
    else if (totalProfit == 0) {
        Expectancy1 = 0;
    }
    else {
        if (cnt == 0) {
            Expectancy1 = 0;
        } else {
            Expectancy1 = ((profitQuantity * totalProfit) / (totalLoss * cnt)) - lossQuantity / cnt;
        }
    }
    // check again at every point
    let current_cnt=daysFromToday(drawDownDate);
    if(ans.count==0 || ans.count<=current_cnt){
        ans.count=daysFromToday(drawDownDate);
        ans.startDate=formatDate(drawDownDate);
        ans.endDate='Running';
        ans.recovery=recoveryStartDate==''?0:daysFromToday(recoveryStartDate);
        ans.continue=true;
        ans.status='Running'
    }

    const st_date = new Date(startdate);
    const end_date = new Date(enddate);
    const investmentmonth = (end_date.getFullYear() - st_date.getFullYear()) * 12 + (end_date.getMonth() - st_date.getMonth());
    return { "stats": { "margin":margin, "profit":parseFloat(total_profit).toFixed(2), "maximumDrawdown":parseFloat(MaximumDrawdown).toFixed(2), "maxProfit":parseFloat(maxprofit).toFixed(2), "maxLoss":parseFloat(maxloss).toFixed(2), "winTrade":winningtrades, "lossTrade":losingtrades, "avgProfit":profitQuantity != 0 ? (totalProfit / profitQuantity).toFixed(2) : 0, "avgLoss":lossQuantity != 0 ? (totalLoss / lossQuantity).toFixed(2) : 0,"avgProfit": (total_profit / ((investmentmonth + 1) * 100)).toFixed(2),"investmentDay":investmentDay,"maxStreak": maxStreak,"lossStreak": lossStreak,"gap":gap,"vix":vix,"dayOpen":dayOpen,"mdd":ans}, 'week': weekResult, 'month': month1, 'profit': net_profit, 'drawdown': result, 'cumulative': cumulative };
}