import React, { useState, useEffect } from "react";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";
import { toast } from 'react-toastify'
import { Dialog } from '@mui/material';
import { Audio, ColorRing } from 'react-loader-spinner';
import { useNavigate } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";

// import component
import BucketResult from "./Result/BucketResult";
import useBucketStore from "../../store/BucketStore";
import Backtest from "./../Backtest/Features/BucketBacktest";
import Setting from "./Setting";
import { extractErrorMessage } from './../../utility/utils'
import DateRange from "./DateRange";
import Sidebar from "./Sidebar";
import { handleNumber } from "./Util";
import { GET_STRATEGIES, BACKTEST, UPDATE_BASKET, PRE_DEFINED_STRATEGY } from "../../constant/constant";
import useBucketResultStore from "../../store/bucketResultStore";

// icon and other stuff
import { FaCheck, FaEdit } from "react-icons/fa";
import { MdContentCopy, MdDeleteForever, MdClose, MdAdd } from "react-icons/md";


const Bucket = (props) => {
  const navigate = useNavigate();
  // select all 
  const [selectAllDay, setSelectAllDay] = useState(true);
  const [selectAllDayList, setSelectAllDayList] = useState([true, true, true, true, true])
  const [selectAllStrategy, setSelectAllStrategy] = useState(true);

  const [visible, setVisible] = useState(false);
  const [visibleImportStrategy, setVisibleImportStrategy] = useState(false);
  const [result, setResult] = useState([]);
  const [bucketResultVisible, setBucketResultVisible] = useState(false);
  const [updateStrategy, setUpdateStrategy] = useState([]);
  const [update, setUpdate] = useState(false);
  const [copyStrategy, setCopyStrategy] = useState(false);
  const [wait, setWait] = useState(false);
  const [waitSelected, setWaitSelected] = useState(false);
  const [executedStrategy, setExecutedStrategy] = useState([]);
  const [visibleDeleteStrategy, setVisibleDeleteStrategy] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState();

  // use of Zustand to Store Management
  const { savedstrategies, updateSavedStrategies } = useBucketStore();
  const { activeBasketName, } = useBucketStore();
  const { activeBasket, deleteStrategiesOnActiveBasket, } = useBucketStore();
  const [inputData, setInputData] = useState([]);
  const [updateIndex, setUpdateIndex] = useState('');
  const { strategyMultiplier, updateStatsInclude, updateStatsMultiplier, removeStrategyChecked, preDefinedStrategy, updatePreDefinedStrategy } = useBucketStore();
  // import Basket list and choose strategy from it.
  const [chooseAllStrategy, setChooseAllStrategy] = useState(false)
  // check to run strategy
  const [checkToRunStrategy, setCheckToRunStrategy] = useState(false);
  const [alertMessage, SetAlertMessage] = useState('Do You Really Want to Execute All Selected Strategy?')
  // handle day option
  const { dayOption, updateDayOption, removeDayOption } = useBucketStore();
  // change setting
  const { settingDetail } = useBucketStore();
  // Slippage
  const { slippage, updateSlippage, removeSlippage, removeStrategyMultiplier, updateStrategyResultInclude, strategyResultInclude, strategyChecked, updateStrategyChecked, updateStrategyCheckedAll } = useBucketStore();
  // Confirmation of completely run one strategy single single
  const [confirmRunStrategy, setConfirmRunStrategy] = useState(true);
  // set the lot size of banknify and nifty.Also set nifty and banknifty.
  // Filtration 
  const [tempFilter, setTempFilter] = useState([])
  // bucket startdate 
  const { bucketStartDate, bucketEndDate, updateBucketStartDate, updateBucketEndDate } = useBucketStore();
  const { updateResultFilterBucket } = useBucketResultStore();
  // progress
  const [currentProgress, setCurrentProgress] = useState(0);

  // all the function of this component
  // a)Execute Particular Strategy() --> Only For Execute one Strategy
  // b)Execute Strategy() -->For Execute all Strategy
  // c)delete Basket()
  // d)RenameBasketName()
  // e)OnGettingStrategies()
  // f)addBasketOnActiveBasket()
  // g)fetchBasket()

  // get token 
  const getToken = () => {
    let token = secureLocalStorage.getItem('backtest_token');
    if (token && token.startsWith('backtest_')) {
      return token.replace('backtest_', '');
    } else {
      navigate('/backtest/login');
      return null;
    }
  };

  // handle api error
  const handleApiError = (error) => {
    if (error?.response?.status === 401) {
      navigate('/backtest/login');
    } else {
      toast.error(extractErrorMessage(error));
    }
    console.error(error, 'Error during fetching strategies');
  };

  //  This function will execute particular strategy
  const ExecuteParticularStrategy = async (item, index) => {
    setConfirmRunStrategy(false);
    const data = item.array;
    let lotsize = []
    data.tradesymbol === "NIFTY" ?
      data.lotarray.map((item, key) => { lotsize.push(item * settingDetail.niftylotsize); return item * settingDetail.niftylotsize; }) :
      data.lotarray.map((item, key) => { lotsize.push(item * settingDetail.bankniftylotsize); return item * settingDetail.bankniftylotsize; });
    const temp = {
      'leg': data.leg, 'segmentType': data.segmentType, 'lotarray1': lotsize, 'legarray': data.legarray, 'darray': data.darray, 'intraday': data.intraday, 'tradesymbol': data.tradesymbol, 'gap': data.gap, 'stoploss': data.stoploss,
      'maxprofit': data.maxprofit, 'starttime': data.starttime, 'endtime': data.endtime, 'startdate': bucketStartDate, 'enddate': bucketEndDate,
      'timeGroups': data.timeGroups, 'startTimeArray': data.startTimeArray, 'endTimeArray': data.endTimeArray, 'startDay': data.startDay, 'endDay': data.endDay, 'atm': data.atm, 'atmprice': data.atmprice?data.atmprice:"spot", 'squareoff': data.squareoff, 'waitTrade': data.waitTrade,
      'waitTradeType': data.waitTradeType, 'waitTradeValue': data.waitTradeValue, 'slToCost': data.slToCost, 'reEntry': data.reEntry, 'protectType': data.protectType, 'profitIncrease': data.profitIncrease, 'lockMinimumProfit': data.lockMinimumProfit,
      'profitReaches': data.profitReaches, 'trailProfit': data.trailProfit, 'legsStoplossType': data.legsStoplossType, 'legsStoploss': data.legsStoploss, 'legsTargetProfit': data.legsTargetProfit, 'legsTargetProfitType': data.legsTargetProfitType,
      'reEntryValue': data.reEntryValue, 'reEntryChoice': data.reEntryChoice, 'legSymbol': data.legSymbol, 'legExpiryType': data.legExpiryType, 'atmChoice': data.atmChoice, 'closestPremiumChoice': data.closestPremiumChoice, 'closestPremiumValue': data.closestPremiumValue,
      'legsReEntry': data.legsReEntry, 'protectProfit': data.protectProfit, 'reEntryType': data.reEntryType, "trailStoploss": data.trailStoploss, "trailStoplossLoss": data.trailStoplossLoss, "trailStoplossProfit": data.trailStoplossProfit, "trailStoplossType": data.trailStoplossType,
      "showRangeBreakout": data.showRangeBreakout, "rangeStartTime": data.rangeStartTime, "rangeEndTime": data.rangeEndTime
    }
    let token = getToken();
    var config = { method: "post", maxBodyLength: Infinity, url: BACKTEST, headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}`, }, data: temp, };
    try {
      const res = await axios(config);
      const response = res.data;
      if (response) {
        const res = { 'legarray': data.legarray, 'result': response.b, 'reEntryResult': response.c, 'margin': response.margin, 'lotarray': data.lotarray1, 'atm': data.atm, 'leg': data.leg }
        const tempRes = [...result]; tempRes[index] = res; setResult(tempRes); setBucketResultVisible(true);
        const tempInput = [...inputData];
        tempInput[index] = temp;
        setInputData(tempInput);
        const input1 = [...executedStrategy]; input1[index] = 2; setExecutedStrategy(input1);
        updateStrategyResultInclude([...strategyResultInclude, true]);
        // console.log(result, tempRes, tempInput, 'execute particular strategy')
      }
      setConfirmRunStrategy(true);
    } catch (error) {
      handleApiError(error);
      const input1 = [...executedStrategy]; input1[index] = 0; setExecutedStrategy(input1);
      setConfirmRunStrategy(true);
    }
  }

  // This function will execute choice strategy and whole strategy
  const ExecuteStrategy = async (choice) => {
    const tempResult = Array(activeBasket.strategy.length).fill({});
    const input_data = Array(activeBasket.strategy.length).fill({});
    updateStatsInclude(Array(activeBasket.strategy.length).fill(true))
    updateStatsMultiplier(Array(activeBasket.strategy.length).fill(1))
    setBucketResultVisible(false);
    setExecutedStrategy((prev) => Array(activeBasket.strategy.length).fill(1));
    updateStrategyResultInclude(Array(activeBasket.strategy.length).fill(true));
    updateResultFilterBucket(Array(activeBasket.strategy.length).fill([]))
    let totalStrategy = activeBasket.strategy.length;
    let current = 0;
    if (!choice) {
      updateStrategyCheckedAll(Array(activeBasket.strategy.length).fill(true))
    }
    choice ? setWaitSelected(true) : setWait(true);
    for (let i = 0; i < activeBasket.strategy.length; i++) {
      const data = activeBasket.strategy[i].array;
      if (strategyChecked[i]) {
        // set a lot with respect to quantity
        let lotsize = []
        data.tradesymbol === "NIFTY" ?
          data.lotarray.map((item, key) => { lotsize.push(item * settingDetail.niftylotsize); return item * settingDetail.niftylotsize; }) :
          data.lotarray.map((item, key) => { lotsize.push(item * settingDetail.bankniftylotsize); return item * settingDetail.bankniftylotsize; });
        const temp = {
          'leg': data.leg, 'segmentType': data.segmentType, 'lotarray': data.lotarray, 'lotarray1': lotsize, 'legarray': data.legarray, 'darray': data.darray, 'intraday': data.intraday, 'tradesymbol': data.tradesymbol, 'gap': data.gap, 'stoploss': data.stoploss,
          'maxprofit': data.maxprofit, 'starttime': data.starttime, 'endtime': data.endtime, 'startdate': bucketStartDate, 'enddate': bucketEndDate,
          'timeGroups': data.timeGroups, 'startTimeArray': data.startTimeArray, 'endTimeArray': data.endTimeArray, 'startDay': data.startDay, 'endDay': data.endDay, 'atm': data.atm, 'atmprice': data.atmprice?data.atmprice:"spot", 'squareoff': data.squareoff, 'waitTrade': data.waitTrade,
          'waitTradeType': data.waitTradeType, 'waitTradeValue': data.waitTradeValue, 'slToCost': data.slToCost, 'reEntry': data.reEntry, 'protectType': data.protectType, 'profitIncrease': data.profitIncrease, 'lockMinimumProfit': data.lockMinimumProfit,
          'profitReaches': data.profitReaches, 'trailProfit': data.trailProfit, 'legsStoplossType': data.legsStoplossType, 'legsStoploss': data.legsStoploss, 'legsTargetProfit': data.legsTargetProfit, 'legsTargetProfitType': data.legsTargetProfitType,
          'reEntryValue': data.reEntryValue, 'reEntryChoice': data.reEntryChoice, 'legSymbol': data.legSymbol, 'legExpiryType': data.legExpiryType, 'atmChoice': data.atmChoice, 'closestPremiumChoice': data.closestPremiumChoice, 'closestPremiumValue': data.closestPremiumValue,
          'legsReEntry': data.legsReEntry, 'protectProfit': data.protectProfit, 'reEntryType': data.reEntryType, "trailStoploss": data.trailStoploss, "trailStoplossLoss": data.trailStoplossLoss, "trailStoplossProfit": data.trailStoplossProfit, "trailStoplossType": data.trailStoplossType,
          "showRangeBreakout": data.showRangeBreakout, "rangeStartTime": data.rangeStartTime, "rangeEndTime": data.rangeEndTime
        }

        // Get the token from secureLocalStorage
        let token = getToken();

        var config = { method: "post", maxBodyLength: Infinity, url: BACKTEST, headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}`, }, data: temp, };
        try {
          const res = await axios(config); const response = res.data;
          if (response) {
            const res = { 'legarray': data.legarray, 'result': response.b, 'reEntryResult': response.c, 'margin': response.margin, 'lotarray': lotsize, 'atm': data.atm, 'leg': data.leg }
            tempResult[i] = res;
            input_data[i] = temp;
            setExecutedStrategy(prevState => {
              const input = [...prevState];
              input[i] = 2;
              return input;
            });
            current += 1;
            setCurrentProgress((current * 100) / totalStrategy);
          }
        } catch (error) {
          if (error?.response?.status === 401) {
            const input = [...executedStrategy]; input[i] = 0; setExecutedStrategy(input);
            navigate('/backtest/login');
          } else {
            const input = [...executedStrategy]; input[i] = 0; setExecutedStrategy(input);
            toast.error(extractErrorMessage(error))
          }
          console.log(error, 'error at execute strategy')
        }
      }
    }
    setResult(tempResult);
    setInputData(input_data);
    setWait(false);
    setWaitSelected(false);
    setBucketResultVisible(true);
    setCurrentProgress(0);
  }

  const onGettingStrategies = () => {
    // Get the token from secureLocalStorage
    let token = getToken();
    let data = JSON.stringify({ id: secureLocalStorage.getItem("backtest_id") });
    let config = { method: "post", maxBodyLength: Infinity, url: GET_STRATEGIES, headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` }, data: data, };

    axios
      .request(config)
      .then((response) => {
        const response1 = response?.data?.data?.array
        const temp = response1 ? response1 : [];
        updateSavedStrategies([...temp, ...preDefinedStrategy]);
      })
      .catch((error) => {
        handleApiError(error);
      });
  };

  const deleteStrategy = (key, confirm) => {
    const id = secureLocalStorage.getItem('backtest_id');
    const data = activeBasket;
    const temp = [...data.strategy];
    temp.splice(key, 1);
    data.strategy = temp;

    // Get the token from secureLocalStorage
    let token = getToken();

    let config = { method: "post", url: `${UPDATE_BASKET}/${id}/${activeBasketName}`, headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` }, data: temp };
    if (confirm) {
      axios
        .request(config)
        .then((response) => {
          toast.success('Delete Strategy Successfully');
          deleteStrategiesOnActiveBasket(data?.strategy);
          setExecutedStrategy(prevResult => { const updatedResult = [...prevResult]; updatedResult.splice(key, 1); return updatedResult; });
          removeDayOption(key);
          removeStrategyChecked(key);
          removeStrategyMultiplier(key);
          const temp1 = [...strategyMultiplier];
          temp1.splice(key, 1);
          updateStatsInclude(temp1);
          removeSlippage(key);
          setResult(prevResult => { const updatedResult = [...prevResult]; updatedResult.splice(key, 1); return updatedResult; });
          // console.log(executedStrategy, dayOption, strategyMultiplier, include, result);
          setVisibleDeleteStrategy(false);
        })
        .catch((error) => {
          handleApiError(error);
        });
    }
  }


  // find all saved strategies from an array
  const onGettingPreDefinedStrategy = () => {
    let data = JSON.stringify({ id: secureLocalStorage.getItem("backtest_id") });
    // Get the token from secureLocalStorage
    let token = getToken();
    let config = { method: "get", maxBodyLength: Infinity, url: PRE_DEFINED_STRATEGY, headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}`, }, data: data, };
    axios
      .request(config)
      .then((response) => {
        response?.data?.data && updatePreDefinedStrategy(response?.data?.data);
      })
      .catch((error) => {
        handleApiError(error);
      });
  };

  // handle day
  const updateDay = (index) => {
    const data = [...selectAllDayList];
    data[index] = !data[index];
    //----------------------
    const inputdata = [...dayOption];
    for (let i = 0; i < activeBasket.strategy.length > 0; i++) {
      inputdata[i][index] = data[index] ? 1 : 0;
    }
    updateDayOption(inputdata);

    if (data[0] && data[1] && data[2] && data[3] && data[4]) {
      setSelectAllDay(true);
    } else {
      setSelectAllDay(false);
    }
    setSelectAllDayList(data);
  }

  const checkAllStrategyDay = (index,dayOption) => {
    let cnt = 0;
    let flag = false;

    for (let i = 0; i < activeBasket?.strategy?.length; i++) {
      if (dayOption[i][index] == 1) {
        cnt++;
      }
    }
    if (cnt == activeBasket.strategy.length) {
      setSelectAllDayList((prevResult) => { const inputdata = [...prevResult]; inputdata[index] = true; return inputdata; })
      flag=true;
    }
    else if (selectAllDayList[index]) {
      setSelectAllDayList((prevResult) => { const inputdata = [...prevResult]; inputdata[index] = false; return inputdata; })
    }
    return flag;
  }

  const checkAllDay = (flag,index) => {
    let dayCount = 0;
    for (let i = 0; i < 5; i++) {
      if (index == i && flag) {
        dayCount++;
      } else if (selectAllDayList[i] && index !== i) {
        dayCount++;
      }
    }
    if (dayCount == 5) {
      setSelectAllDay(true);
    } else if (selectAllDay) {
      setSelectAllDay(false);
    }
  }

  const updateStrategyDay = (key, index) => {
    const current_status = dayOption[key][index];

    // Create a deep copy of the `dayOption` array
    const currentDayOption = [...dayOption];

    // Create a shallow copy of the array at the specific `key` index
    const strategyDayOption = [...currentDayOption[key]];

    // Modify the specific `index` within the copied `strategyDayOption`
    strategyDayOption[index] = 1 - current_status;

    // Assign the modified `strategyDayOption` back to `currentDayOption`
    currentDayOption[key] = strategyDayOption;

    // Update the state with the modified `currentDayOption`
    updateDayOption(currentDayOption);

    //  CHECK SELECT ALL DAY LIST 
    let flag = checkAllStrategyDay(index,currentDayOption);
    // check for all day checkbox
    checkAllDay(flag,index);

  }
  // method to handle when we click on select all day checkbox
  const handleSelectAllDay = () => {
    let selectedAll = Array(activeBasket.strategy.length).fill([1, 1, 1, 1, 1])
    let notSelectedAll = Array(activeBasket.strategy.length).fill([0, 0, 0, 0, 0])
    selectAllDay ? updateDayOption(notSelectedAll) : updateDayOption(selectedAll);
    selectAllDay ? setSelectAllDayList([false, false, false, false, false]) : setSelectAllDayList([true, true, true, true, true]);
    setSelectAllDay(!selectAllDay);
  }
  const handleSelectedAllStrategy = () => {
    selectAllStrategy ? updateStrategyCheckedAll(Array(activeBasket.strategy.length).fill(false)) : updateStrategyCheckedAll(Array(activeBasket.strategy.length).fill(true));;
    setSelectAllStrategy(!selectAllStrategy);
  }


  useEffect(() => {
    activeBasket != '' && setExecutedStrategy(Array(activeBasket.strategy.length).fill(0));
    activeBasket != '' && updateStrategyCheckedAll(Array(activeBasket.strategy.length).fill(true));
    activeBasket != '' && updateDayOption(Array(activeBasket.strategy.length).fill([1, 1, 1, 1, 1]))
    activeBasket != '' && updateStatsMultiplier(Array(activeBasket.strategy.length).fill(1));
    activeBasket != '' && updateSlippage(Array(activeBasket.strategy.length).fill(0));
    onGettingPreDefinedStrategy();
    updateStrategyResultInclude([]);
    updateResultFilterBucket([]);
  }, [])


  useEffect(() => {
    onGettingStrategies();
  }, [visibleImportStrategy == true])

  useEffect(() => {
    if (bucketResultVisible) {
      let cnt = 0;
      for (let i = 0; i < executedStrategy.length; i++) {
        if (executedStrategy[i] == 2)
          cnt++
      }
      // console.log(executedStrategy.length,'executedstrategy')
      if (cnt == 0)
        setBucketResultVisible(false);
    }
  }, [executedStrategy])


  return (
    <div >
      <div className="flex flex-col sm:flex-row">
        <div className="flex flex-col m-2 sm:w-[320px] gap-4 p-4 bg-white rounded-lg shadow-lg">
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                className="w-5 h-5"
                checked={selectAllStrategy}
                onChange={handleSelectedAllStrategy}
              />
              <div className="text-sm font-medium">Select All</div>
            </div>
            <Setting />
          </div>

          <div className="flex justify-between items-center">
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                className="w-5 h-5"
                checked={selectAllDay}
                onChange={handleSelectAllDay}
              />
              <div className="text-sm font-medium">Select Days</div>
            </div>
            <div className="flex gap-2">
              {["Mon", "Tue", "Wed", "Thu", "Fri"].map((day, index) => (
                <button
                  key={index}
                  className={`text-sm h-8 w-8 rounded-full flex items-center justify-center font-medium 
                      ${selectAllDayList[index] ? "bg-blue-600 text-white" : "bg-gray-200 text-gray-600"} 
                       transition-colors duration-200`}
                  onClick={() => updateDay(index)}
                >
                  {day}
                </button>
              ))}
            </div>
          </div>

          <div className="flex flex-col gap-2">
            <div className="flex items-center">
              <div className="w-[90px] text-sm font-medium">From Date:</div>
              <input
                type="date"
                className="flex-grow p-1 border-2 rounded-md text-md focus:ring-2 focus:ring-blue-600 border-black"
                value={bucketStartDate}
                onChange={(e) => updateBucketStartDate(e.target.value)}
              />
            </div>

            <div className="flex items-center">
              <div className="w-[90px] text-sm font-medium">End Date:</div>
              <input
                type="date"
                className="flex-grow p-1 border-2 rounded-md text-md focus:ring-2 focus:ring-blue-600 border-black"
                value={bucketEndDate}
                onChange={(e) => updateBucketEndDate(e.target.value)}
              />
            </div>
          </div>

          <Sidebar setBucketResultVisible={setBucketResultVisible} setExecutedStrategy={setExecutedStrategy} setVisibleBacktest={setVisible} result={result} setResult={setResult} executedStrategy={executedStrategy} />

          <div className="flex justify-center mt-6 relative">
            <div className="absolute -top-3 bg-white text-blue-700 font-semibold px-2 py-1 rounded-full border-2 border-blue-700">Execute</div>
          </div>

          {!waitSelected && (
            <button
              className="mt-4 p-2 bg-blue-600 hover:bg-blue-700 text-white rounded-md shadow-md flex items-center justify-center transition-colors duration-300"
              onClick={() => { setCheckToRunStrategy(true); SetAlertMessage('Do You Really Want to Execute All Selected Strategy?.'); setChooseAllStrategy(true); }}
            >
              Run Selected Strategy
            </button>
          )}

          {waitSelected && (
            <div className="mt-4 p-2 bg-blue-600 text-white rounded-md shadow-md flex items-center justify-center">
              <Audio height="40" width="120" color='lightblue' ariaLabel='three-dots-loading' />
            </div>
          )}

          {!wait && (
            <button
              className="mt-2 p-2 bg-blue-600 hover:bg-blue-700 text-white rounded-md shadow-md flex items-center justify-center transition-colors duration-300"
              onClick={() => { setCheckToRunStrategy(true); SetAlertMessage('Do You Really Want to Execute All Strategy?.'); setChooseAllStrategy(false); }}
            >
              Run All Strategy
            </button>
          )}

          {wait && (
            <div className="mt-4 p-2 bg-blue-600 text-white rounded-md shadow-md flex items-center justify-center">
              <Audio height="30" width="120" color='lightblue' ariaLabel='three-dots-loading' />
            </div>
          )}
        </div>

        <div className="relative flex flex-grow min-h-[300px] flex-wrap flex-col rounded bg-white m-2">
          <div className="flex justify-end m-1"><button className="flex flex-row rounded bg-blue-400 hover:bg-blue-500 text-center justify-center text-white p-1" onClick={() => setVisible(true)}><MdAdd className="text-2xl"/> Add New Strategy</button></div>
          <div className=""><LinearProgress variant="determinate" sx={{ backgroundColor: "white" }} value={currentProgress} /></div>
          {/* <div className="text-center justify-center bg-gradient-to-r from-blue-400 via-purple-500 to-pink-500 text-white text-2xl font-extrabold py-2 rounded-lg shadow-md">STRATEGY</div> */}
          {/* When basktet contain no strategy */}
          {
            activeBasket?.strategy?.length==0 && <p className="text-2xl flex flex-row justify-center text-center underline">There is no strategy at selected basket.Minimum 1 strategy required at basket</p>
          }
          {/* show all strategy */}
          <div className="flex flex-row flex-wrap overflow-auto gap-4 p-6">
            {activeBasket?.strategy?.length > 0 && activeBasket.strategy.map((item, key) => {
              return (
                <div className="rounded-xl p-2 flex flex-col flex-wrap gap-2 bg-white border-blue-300 border-2" key={`activeBasket-${key}`}>
                  <div className="grid grid-cols-2">
                    <div className="bg-white text-[16px] text-[#718EBF]">Strategy [#{key + 1}]</div>
                    <div className="mr-2 p-1 min-w-[160px] text-center rounded-md border-[#DFEAF2] border-[1px] text-sm font-semibold overflow-hidden">{item?.name}</div>
                  </div>
                  <div className="flex flex-row gap-1">
                    <div className="w-12 flex text-center justify-center border-[1px] border-[#DFEAF2] rounded">{(strategyChecked[key] == true || strategyChecked[key] == false) && <input type="checkbox" className="ml-2 mr-2 p-1 border-2" checked={strategyChecked[key]} onChange={() => { updateStrategyChecked(key, !strategyChecked[key]) }} />}</div>
                    <button className="p-1 text-blue-600 bg-[lightblue] flex justify-center font-semibold text-center w-16 text-sm rounded border-[#DFEAF2] border-[1px]"
                      onClick={() => { confirmRunStrategy && ExecuteParticularStrategy(item, key); const inputData = [...executedStrategy]; inputData[key] = 1; confirmRunStrategy && setExecutedStrategy(inputData); }}>{executedStrategy[key] == 1 ? <ColorRing
                        visible={true} height="24" width="30" ariaLabel="blocks-loading" wrapperStyle={{}} wrapperClass="blocks-wrapper" colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                      /> : executedStrategy[key] == 2 ? <FaCheck className="text-2xl text-center" /> : 'RUN'}</button>

                    {["Mon", "Tue", "Wed", "Thu", "Fri"].map((day, index) => (
                      <button key={index} className="ml-2 text-sm border-blue-500 shadow-lg h-8 w-8 rounded text-center justify-center font-medium focus:outline-none focus:border-0" style={{ backgroundColor: dayOption && dayOption[key] && dayOption[key][index] ? dayOption[key][index] == 1 ? "#89CFF0" : "lightblue" : '' }}
                        onClick={() => { updateStrategyDay(key, index); }}> {day} </button>))}

                  </div>
                  <div className="flex flex-row gap-1">
                    <div className="font-serif  mr-2 p-1 text-sm w-[30px] h-8  text-center rounded border-[#DFEAF2] border-[1px]">{item?.array?.tradesymbol == 'NIFTY' ? 'N' : 'BN'}</div>
                    <div className="font-serif mr-2 p-1 text-sm w-16 h-8 text-center rounded border-[#DFEAF2] border-[1px]">{item?.array?.intraday === 'Yes' ? 'Intraday' : 'Positional'}</div>
                    <div className="rounded flex flex-row text-center justify-center h-8 border-[#DFEAF2] border-[1px]">
                      <div className="text-l border-[#DFEAF2] border-r-[1px] text-center justify-center pl-1 pr-1 bg-[#DAECF2]">Slippage</div>
                      <input type="text" value={slippage[key] !== undefined ? slippage[key] : ''} className="w-8  border-l-[1px] border-[#DFEAF2] bg-inherit text-center justify-center hover:outline-none focus:outline-none" onChange={(e) => { const inputData = [...slippage]; inputData[key] = handleNumber(e.target.value, inputData[key]); updateSlippage(inputData); }} />
                    </div>
                    {/* Strategy Multiplier */}
                    <div className="rounded flex flex-row text-center justify-center h-8 border-[#DFEAF2] border-[1px]">
                      <div className="text-l border-[#DFEAF2] border-r-[1px] text-center justify-center pl-1 pr-1 bg-[#DAECF2]">Multiplier</div>
                      <input type="text" value={strategyMultiplier[key] && strategyMultiplier[key]} className="w-8  border-l-[1px] border-[#DFEAF2] bg-inherit text-center justify-center hover:outline-none focus:outline-none" onChange={(e) => { const inputData = [...strategyMultiplier]; inputData[key] = handleNumber(e.target.value, inputData[key]); updateStatsMultiplier(inputData); }}></input>
                    </div>
                  </div>
                  {/* Profit Week Day Wise */}
                  <div className="flex flex-row justify-end">
                    <div className="flex flex-row gap-2">
                      <div className="mt-1"><MdContentCopy className="text-2xl font-semibold cursor-pointer" onClick={() => { setVisible(true); setCopyStrategy(true); setUpdateStrategy(item); }} /></div>
                      {/* Method to delete a particular leg */}
                      <div className="mt-1"><MdDeleteForever className="text-2xl font-semibold cursor-pointer text-red-500" onClick={() => { setVisibleDeleteStrategy(true); setDeleteIndex(key); }} /></div>
                      {/* Method to update a particular leg */}
                      <div className="mt-1"><FaEdit className="text-2xl font-semibold cursor-pointer" onClick={() => { setUpdate(true); setVisible(true); setUpdateStrategy(item); setUpdateIndex(key); }} /></div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
          <div className="lg:absolute bottom-0 w-full"><DateRange /></div>
        </div>
      </div>

      <Dialog className="border-2 border-t-8 border-blue-500 rounded-xl w-full" scroll={"paper"} maxWidth="xl" onClose={(event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') { setVisible(false); }
      }} open={visible}>
        <div className="">
          <Backtest setVisible={setVisible} setUpdate={setUpdate} update={update} updateStrategy={updateStrategy} updateIndex={updateIndex} setUpdateStrategy={setUpdateStrategy} copyStrategy={copyStrategy} setCopyStrategy={setCopyStrategy} executedStrategy={executedStrategy} setExecutedStrategy={setExecutedStrategy}
            result={result} setResult={setResult} />
        </div>
      </Dialog>

      {/* saved strategy */}
      <Dialog className="border-2 border-t-8 border-blue-500 rounded-xl" onClose={(event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') { setVisibleImportStrategy(false); }
      }} open={visibleImportStrategy}>
        <div className="text-center text-[#333B69] font-serif p-4 w-80">List of Strategy</div>
        <div className="flex flex-col p-2">
          {
            savedstrategies.map((item, key) => {
              return (
                <div className="text-l text-black h-8 border-2 border-t-4 border-blue-500 m-2 rounded-xl text-center p-2" key={key}>{item.name}</div>
              )
            })
          }
          <div className="text-l text-white h-8 border-2 border-t-4 border-blue-500 m-2 bg-red-600 rounded-xl p-1 text-center cursor-pointer" onClick={() => { setVisibleImportStrategy(false); }}>Cancel</div>
        </div>
      </Dialog>

      {/* Check to Run Strategy or Not */}
      <Dialog className="border-2 border-t-8 border-blue-500 rounded-xl" onClose={(event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') { setCheckToRunStrategy(false); }
      }} open={checkToRunStrategy}>
        <div className="flex flex-row border-b-2 bg-blue-400 text-white">
          <div className="text-xl p-2 w-96">Execute Strategy</div>
          <div className="text-xl flex justify-end pt-2 pl-4 cursor-pointer"><MdClose className="text-2xl font-semibold" onClick={() => { setCheckToRunStrategy(false); }} /></div>
        </div>
        <div className="flex flex-col p-4">
          <div className="text-lg p-2">{alertMessage}</div>
        </div>
        <div className="flex justify-end gap-2 p-1 bg-gray-400">
          <button className="border-2 p-1 w-24 bg-blue-600 rounded-lg justify-center text-center text-white cursor-pointer" onClick={() => { setCheckToRunStrategy(false); ExecuteStrategy(chooseAllStrategy); }}>YES</button>
          <button className="border-2 p-1 w-24 bg-white rounded-lg justify-center text-center text-blue-600 border-blue-600 cursor-pointer" onClick={() => { setCheckToRunStrategy(false); }}>NO</button>
        </div>
      </Dialog>

      {/* Delete a strategy*/}
      <Dialog className="border-2 border-t-8 border-blue-500 rounded-xl" onClose={(event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') { setVisibleDeleteStrategy(false); }
      }} open={visibleDeleteStrategy}>
        <div className="flex flex-row border-b-2 bg-blue-400 text-white">
          <div className="text-xl p-2 w-96">Delete Strategy</div>
          <div className="text-xl flex justify-end font-semibold p-2 cursor-pointer"><MdClose onClick={() => { setVisibleDeleteStrategy(false); }} /></div>
        </div>
        <div className="flex flex-col text-center justify-center mt-2 p-4">
          <div className="font-serif text-l justify-start text-left p-4 text-xl">Are You Sure Want to Delete a Strategy?</div>
        </div>
        <div className="flex justify-end gap-2 p-1 bg-gray-400">
          <button className="border-2 p-1 w-24 bg-blue-500 rounded-lg justify-center text-center text-white cursor-pointer" onClick={() => { deleteStrategy(deleteIndex, true); }}>Delete</button>
          <button className="border-2 p-1 w-24 bg-white rounded-lg justify-center text-center text-blue-600 border-blue-600 cursor-pointer" onClick={() => { setVisibleDeleteStrategy(false); }}>Cancel</button>
        </div>
      </Dialog>

      {/* Bucket Result */}
      {bucketResultVisible && <BucketResult setTempFilter={setTempFilter} tempFilter={tempFilter} slippage={slippage} setSlippage={updateSlippage} result={result} inputData={inputData} activeBasket={activeBasket} executedStrategy={executedStrategy} />}
    </div>
  );
};

export default Bucket;
