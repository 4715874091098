import React, { useEffect, useState } from 'react'
import { UPLOAD_SPAN_DATA, DELETE_MARGIN_DATA, MARGIN_DATA } from './../../constant/constant'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import secureLocalStorage from 'react-secure-storage'
import { useNavigate } from 'react-router-dom';
import HelpIcon from '@mui/icons-material/Help';
import { Tooltip } from 'react-tooltip';

const Margin = () => {
    const navigate = useNavigate();
    // For upload json data of nifty and banknifty span data
    const [spanData, setSpanData] = useState("")
    const [date, setDate] = useState('');
    const [tradeSymbol, setTradeSymbol] = useState(-1);
    const [atm, setAtm] = useState('');
    const [marginData, setMarginData] = useState([]);
    const [weeklyExpiry, setWeeklyExpiry] = useState('');
    const [monthlyExpiry, setMonthlyExpiry] = useState('');

    const getMarginData = async () => {
        let token = secureLocalStorage.getItem('backtest_token');

        if (token && token.startsWith('backtest_')) {
            token = token.replace('backtest_', '');
        } else {
            navigate('/backtest/login');
        }
        let config = { method: "get", url: MARGIN_DATA, headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}`, }, };
        try {
            const response = await axios.request(config);
            // console.log(response.data.data, 'result')
            setMarginData(JSON.parse(response.data.data));
            toast.success('Margin Data Get Successfully.');
        } catch (error) {
            if (error?.response?.status === 401) {
                navigate('/backtest/login');
            } else if (error?.response?.data?.message) {
                toast.error(error.response.data.message)
            } else {
                toast.error('Network Issue');
            }
            console.log(error, 'error at get margin data')
        }
    }

    const uploadSpanData = async () => {
        let token = secureLocalStorage.getItem('backtest_token');

        if (token && token.startsWith('backtest_')) {
            token = token.replace('backtest_', '');
        } else {
            navigate('/backtest/login');
        }
        const data = { "Date": date, "Symbol": tradeSymbol, "Span": JSON.parse(spanData), "Atm": atm, "timeStamp": new Date().toLocaleString(), "expiry": weeklyExpiry, "monthlyExpiry": monthlyExpiry };
        let config = { method: "post", maxBodyLength: Infinity, url: UPLOAD_SPAN_DATA, headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` }, data: data };
        try {
            const response = await axios.request(config);
            getMarginData();
            toast.success('Span Data Uploaded Successfully.');
            setWeeklyExpiry('');
            setMonthlyExpiry('');
            setSpanData("");
            setTradeSymbol(-1);
            setAtm('');
            setDate('');
        } catch (error) {
            if (error?.response?.status === 401) {
                navigate('/backtest/login');
            } else if (error?.response?.data?.message) {
                toast.error(error.response.data.message)
            } else {
                toast.error('Network Issue');
            }
            console.log(error, 'error at upload span data')
        }
    }
    const deleteSpanData = async (id) => {
        const marginId = id;
        let token = secureLocalStorage.getItem('backtest_token');
        
        if (token && token.startsWith('backtest_')) {
            token = token.replace('backtest_', '');
        } else {
            navigate('/backtest/login');
        }
        let config = { method: "delete", maxBodyLength: Infinity, url: `${DELETE_MARGIN_DATA}/${marginId}`, headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` }, };
        try {
            const response = await axios.request(config);
            setMarginData(marginData.filter((item) => (item?._id?.$oid !== id)))
            toast.success('Margin Data Deleted Successfully.');
        } catch (error) {
            if (error?.response?.status === 401) {
                navigate('/backtest/login');
            } else if (error?.response?.data?.message) {
                toast.error(error.response.data.message)
            } else {
                toast.error('Network Issue');
            }
            console.log(error, 'error at delete span data')
        }
    }
    useEffect(() => {
        getMarginData();
    }, [])
    return (
        <div>
            <ToastContainer />
            <div className="font-semibold ml-5 mt-5 text-2xl ">Margin File</div>
            <div className="m-5 -mt-0 rounded-lg shadow-lg shadow-white bg-white p-2">
                <div className="flex flex-col gap-2 mt-2">
                    <div className="grid grid-cols-2 ">
                        <div className="text-left text-l font-serif flex flex-row">Trade Symbol<div data-tooltip-id="my-tooltip" data-tooltip-content="Enter symbol name of given data."><HelpIcon sx={{ color: "#0096FF", fontSize: "20px" }} /></div></div>
                        <select className="rounded-md p-2 flex-grow bg-[#ECF0FF] outline-0" value={tradeSymbol} onChange={(e) => { setTradeSymbol(e.target.value); }}>
                            <option value={-1}>SELECT SYMBOL</option>
                            <option value='NIFTY'>NIFTY</option>
                            <option value='BANKNIFTY'>BANKNIFTY</option>
                            <option value='NIFTY_FUTURE'>NIFTY_FUTURE</option>
                            <option value='BANKNIFTY_FUTURE'>BANKNIFTY_FUTURE</option>
                        </select>
                    </div>
                    <div className="grid grid-cols-2">
                        <div className="text-left text-l font-serif flex flex-row">Span File Date<div data-tooltip-id="my-tooltip" data-tooltip-content="Enter the DATE of given data like as 13-03-2024."><HelpIcon sx={{ color: "#0096FF", fontSize: "20px" }} /></div></div>
                        <input type="date" value={date} className="rounded-md p-2 flex-grow bg-[#ECF0FF] outline-0" onChange={(e) => { setDate(e.target.value); }}></input>
                    </div>
                    <div className="grid grid-cols-2">
                        <div className="text-left text-l font-serif flex flex-row">Weekly Expiry<div data-tooltip-id="my-tooltip" data-tooltip-content="Enter the DATE of given data like as 13-03-2024."><HelpIcon sx={{ color: "#0096FF", fontSize: "20px" }} /></div></div>
                        <input type="date" value={weeklyExpiry} className="rounded-md p-2 flex-grow bg-[#ECF0FF] outline-0" onChange={(e) => { setWeeklyExpiry(e.target.value); }}></input>
                    </div>
                    <div className="grid grid-cols-2">
                        <div className="text-left text-l font-serif flex flex-row">Monthly Expiry<div data-tooltip-id="my-tooltip" data-tooltip-content="Enter the DATE of given data like as 13-03-2024."><HelpIcon sx={{ color: "#0096FF", fontSize: "20px" }} /></div></div>
                        <input type="date" value={monthlyExpiry} className="rounded-md p-2 flex-grow bg-[#ECF0FF] outline-0" onChange={(e) => { setMonthlyExpiry(e.target.value); }}></input>
                    </div>
                    <div className="grid grid-cols-2">
                        <div className="text-left text-l font-serif flex flex-row">ATM<div data-tooltip-id="my-tooltip" data-tooltip-content="Enter ATM price of given data like as 22000."><HelpIcon sx={{ color: "#0096FF", fontSize: "20px" }} /></div></div>
                        <input type="number" placeholder='Please Enter ATM' value={atm} className="bg-[#ECF0FF] rounded-md p-2 flex-grow outline-0" onChange={(e) => { setAtm(e.target.value); }}></input>
                    </div>
                    <div className="text-left text-l font-serif flex flex-row">SPAN Data<div data-tooltip-id="my-tooltip" data-tooltip-content="{a)Firstly get data from nseIndia.com
         b)get NIFTY and BANKNIFTY data from xml c)convert into json d) format of data : { oopPf : { pfId:string,pfCode:'BANKNIFTY',series:[]}}}"><HelpIcon sx={{ color: "#0096FF", fontSize: "20px" }} /></div></div>
                    <textarea className="bg-[#ECF0FF] rounded-lg h-32 p-2 outline-0" placeholder="Please Insert Span Data Of Particular Symbol." value={spanData} onChange={(e) => { setSpanData(e.target.value); }} />
                    <div className="h-8 text-l bg-[#2D5BFF] justify-center text-white cursor-pointer text-center shadow-lg border-white border-2 rounded-lg font-semibold" onClick={() => {
                        uploadSpanData();
                    }}>Upload</div>
                </div>
            </div>
            <div className="m-4 border-2 p-2 grid grid-cols-1 lg:grid-cols-2">
                {
                    marginData.map((temp, index) => {
                        return (
                            <div className="flex flex-col gap-2 m-2 p-1 border-2 bg-white border-t-4 border-b-4 shadow-lg rounded-lg overflow-auto" key={index}>
                                <div className="grid grid-cols-2">
                                    <div className="text-left text-l font-serif font-semibold">Span File Date</div>
                                    <div className="border-[1px] border-black rounded-md p-1 flex-grow text-black font-medium">{temp?.Date}</div>
                                </div>
                                <div className="grid grid-cols-2">
                                    <div className="text-left text-l font-serif font-semibold">Weekly Expiry</div>
                                    <div className="border-[1px] border-black rounded-md p-1 flex-grow text-black font-medium">{temp?.expiry}</div>
                                </div>
                                <div className="grid grid-cols-2">
                                    <div className="text-left text-l font-serif font-semibold">Monthly Expiry</div>
                                    <div className="border-[1px] border-black rounded-md p-1 flex-grow text-black font-medium">{temp?.monthlyExpiry}</div>
                                </div>
                                <div className="grid grid-cols-2">
                                    <div className="text-left text-l font-serif font-semibold">Trade Symbol</div>
                                    <div className="border-[1px] border-black rounded-md p-1 flex-grow text-black font-medium">{temp?.Symbol}</div>
                                </div>
                                <div className="grid grid-cols-2">
                                    <div className="text-left text-l font-serif font-semibold">ATM</div>
                                    <div className="border-[1px] border-black rounded-md p-1 flex-grow text-black font-medium">{temp?.Atm}</div>
                                </div>
                                <div className='flex justify-end'><div className="h-8 bg-[#FF3030] flex justify-center w-36 text-white cursor-pointer text-center shadow-lg border-white rounded-lg font-[500] text-xl" onClick={() => { deleteSpanData(temp._id.$oid) }}>Delete</div></div>
                            </div>
                        )
                    })
                }
            </div>
            <Tooltip id="my-tooltip" style={{ width: '300px', borderRadius: '10px', backgroundColor: 'rgb(147 197 253)', color: 'black', boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.1)' }} />
        </div>
    )
}

export default Margin