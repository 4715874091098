import React, { useState } from 'react'

const Main = (props) => {
    const [showStats, setShowStats] = useState(false)
    return (
        <>
            <div className="grid grid-cols-1 lg:grid-cols-2 w-full mr-2 bg-white border-4 border-blue-500 rounded-lg">
                <div className='flex text-center justify-center text-xl font-semibold rounded-t-md lg:col-span-2  h-10 text-white bg-[#718EBF]'>Backtest Result</div>
                <div className='grid grid-cols-1 font-medium text-xl font-serif'>
                    <div className="grid grid-cols-2 bg-green-100 border-white p-1 border-2">
                        <h1 className="pl-2 text-[#232323] text-l text-l font-medium border-r-2 border-gray-100 " >Net Profit</h1>
                        <h1 className="text-right pr-4 justify-center text-[#718EBF] " style={{ color: parseFloat(props?.stats?.profit) >= 0 ? "green" : "red" }} >&#8377; {parseFloat(props?.stats?.profit).toFixed(0)}</h1>
                    </div>
                    <div className="grid grid-cols-2 border-white p-1 bg-green-100 border-2">
                        <h1 className="pl-2 text-[#232323]  text-l font-medium border-r-2 border-white" >Max Profit</h1>
                        <h1 className="text-right pr-4 justify-center text-green-600">&#8377; {parseFloat(props?.stats?.maxProfit).toFixed(0)}</h1>
                    </div>
                    <div className="grid grid-cols-2 border-white p-1 bg-red-100 border-2">
                        <h1 className="pl-2 text-[#232323]  text-l font-medium border-r-2 border-gray-100 " >Max Loss</h1>
                        <h1 className="text-right pr-4 justify-center text-red-600 ">&#8377; {props?.stats?.maxLoss == 999999999 ? 0 : parseFloat(props?.stats?.maxLoss).toFixed(0)}</h1>
                    </div>
                    <div className="grid grid-cols-2 border-white p-2 bg-green-100 border-2">
                        <h1 className="pl-2 text-[#232323]  text-l font-medium border-r-2 border-gray-100 " >Win%(days)</h1>
                        <h1 className="text-right pr-4 justify-center  text-green-600 " >{props?.stats?.investmentDay != 0 ? parseFloat((props?.stats?.winTrade * 100) / props?.stats?.investmentDay).toFixed(0) : 0}%({props?.stats?.winTrade})</h1>
                    </div>
                    <div className="grid grid-cols-2 border-white p-2 bg-red-100 border-2">
                        <h1 className="pl-2 text-[#232323]  text-l font-medium border-r-2 border-white" >Loss%(days)</h1>
                        <h1 className="text-right pr-4 justify-center text-green-600" >{props?.stats?.investmentDay != 0 ? parseFloat((props?.stats?.lossTrade * 100) / props?.stats?.investmentDay).toFixed(0) : 0}%({props?.stats?.lossTrade})</h1>
                    </div>
                    <div className="grid grid-cols-2 border-white p-2 bg-green-100 border-2">
                        <h1 className="pl-2 text-[#232323]  text-l font-medium border-r-2 border-gray-100" >Average Profit On Win Days</h1>
                        <h1 className="text-right pr-4 justify-center text-green-600 " >&#8377; {parseFloat(props?.stats?.avgProfit).toFixed(0)}</h1>
                    </div>
                    <div className="grid grid-cols-2 border-white p-2 bg-red-100 border-2">
                        <h1 className="pl-2 text-[#232323]  text-l font-medium border-r-2 border-white" >Average Loss On Loss Days</h1>
                        <h1 className="text-right pr-4 justify-center text-red-600">&#8377; {-1 * parseFloat(props?.stats?.avgLoss).toFixed(0)}</h1>
                    </div>
                    <div className="grid grid-cols-2 bg-white border-white p-1 border-2">
                        <h1 className="pl-2 text-[#232323]  text-l font-medium border-r-2 border-white" >Risk Reward Ratio</h1>
                        <h1 className="text-right pr-4 justify-center text-green-600">{props.avgLoss != 0 ? (parseFloat(props?.stats?.avgProfit) / parseFloat(props?.stats?.avgLoss)).toFixed(2) : 'Infinity'}</h1>
                    </div>

                    <div className="grid grid-cols-2 border-white p-2 bg-yellow-50 border-2">
                        <h1 className="pl-2 text-[#232323] text-l font-medium border-r-2 border-gray-100" > Maximum Win Streak</h1>
                        <h1 className="text-right pr-4 justify-center text-green-600" >{props?.stats?.maxStreak} Days</h1>
                    </div>
                    <div className="grid grid-cols-2 border-white p-2 bg-blue-100 border-2">
                        <h1 className="pl-2 text-[#232323] text-l font-medium border-r-2 border-white" >Maximum Loss Streak</h1>
                        <h1 className="text-right pr-4 justify-center text-green-600">{props?.stats?.lossStreak} Days</h1>
                    </div>
                    <div className="grid grid-cols-2 bg-white border-white p-1 border-2">
                        <h1 className="pl-2 text-[#232323] text-l font-medium border-r-2 border-white" >MDD Days(Recovery Days)</h1>
                        <h1 className="text-right pr-4 justify-center">
                            {props?.stats?.mdd?.count !== undefined ? parseFloat(props?.stats?.mdd?.count).toFixed(0) : 'N/A'}
                            {` (${props?.stats?.mdd?.recovery !== undefined ? parseFloat(props?.stats?.mdd?.recovery) : 'N/A'} Days`}
                            {props?.stats?.mdd?.continue ? ' - Running)' : ')'}
                            <br />
                            <span className="text-sm">
                                ({props?.stats?.mdd?.startDate ? props.stats.mdd.startDate : 'N/A'} - {props?.stats?.mdd?.endDate ? props.stats.mdd.endDate : 'N/A'})
                            </span>
                        </h1>
                    </div>
                </div>
                <div className='grid grid-cols-1 font-medium text-xl font-serif'>
                    <div className="grid grid-cols-2 border-white p-2 bg-green-100">
                        <h1 className="pl-2 text-[#232323] border-r-2 border-white" >Estimated Margin</h1>
                        <h1 className="text-right pr-4 justify-center text-[#718EBF]" >&#8377; {parseFloat(props?.stats?.margin).toFixed(0)}</h1>
                    </div>
                    <div className="grid grid-cols-2 bg-white border-white p-2">
                        <h1 className="pl-2 text-[#232323] text-l font-medium border-r-2" >Minimum Capital Required</h1>
                        <h1 className="text-right pr-4 justify-center">&#8377; {props?.stats?.maximumDrawdown > 0 ? props?.stats?.margin : (props?.stats?.margin + -1 * props?.stats?.maximumDrawdown).toFixed(0)}</h1>
                    </div>

                    <div className="grid grid-cols-2 bg-red-100 border-white p-2 border-2">
                        <h1 className="pl-2 text-[#232323]  text-l font-medium border-r-2 border-white" >Maximum Drawdown</h1>
                        <h1 className="text-right pr-4 justify-center text-[#718EBF] text-l font-medium" style={{ color: props?.stats?.maximumDrawdown > 0 ? "green" : "red" }} >{parseFloat(props?.stats?.maximumDrawdown).toFixed(0)}</h1>
                    </div>
                    <div className="grid grid-cols-2 bg-white border-white p-1 border-2">
                        <h1 className="pl-2 text-[#232323]  text-l font-medium border-r-2 border-white" >Risk Reward Ratio</h1>
                        <h1 className="text-right pr-4 justify-center text-green-600">{props?.stats?.avgLoss != 0 ? (parseFloat(props?.stats?.avgProfit) / parseFloat(props?.stats?.avgLoss)).toFixed(2) : 'Infinity'}</h1>
                    </div>
                    <div className="grid grid-cols-2  border-white p-1 bg-yellow-50 border-2">
                        <h1 className="pl-2 text-[#232323] text-l font-medium border-r-2 border-white" >Gap Up (*PDC)</h1>
                        <h1 className="text-right pr-4 justify-center"><span className='text-green-600'>Profit: &#8377;{parseFloat(props?.stats?.gap?.upProfit).toFixed(0)}{`(${props?.stats?.gap?.upProfitCnt})`}</span><br /> <span className='text-red-600'>Loss: &#8377;{parseFloat(props?.stats?.gap?.upLoss).toFixed(0)}{`(${props?.stats?.gap?.upLossCnt})`}</span> </h1>
                    </div>
                    <div className="grid grid-cols-2 border-white p-1 bg-blue-100 border-2">
                        <h1 className="pl-2 text-[#232323] text-l font-medium border-r-2" >Gap Down (*PDC)</h1>
                        <h1 className="text-right pr-4 justify-center"><span className='text-green-600'>Profit: &#8377;{parseFloat(props?.stats?.gap?.downProfit).toFixed(0)}{`(${props?.stats?.gap?.downProfitCnt})`}</span> <br /> <span className='text-red-600'>Loss: &#8377;{parseFloat(props?.stats?.gap?.downLoss).toFixed(0)}{`(${props?.stats?.gap?.downLossCnt})`}</span> </h1>
                    </div>
                    <div className="grid grid-cols-2 border-white p-1 bg-yellow-50 border-2">
                        <h1 className="pl-2 text-[#232323] text-l font-medium border-r-2 border-white" >Day Open  {'>'} PDH</h1>
                        <h1 className="text-right pr-4 justify-center">
                            <span className="text-green-600">
                                Profit: &#8377;
                                {props?.stats?.dayOpen?.pdhProfit !== undefined ? parseFloat(props.stats.dayOpen.pdhProfit).toFixed(0) : '0'}
                                {` (${props?.stats?.dayOpen?.pdhProfitCnt !== undefined ? props.stats.dayOpen.pdhProfitCnt : '0'})`}
                            </span>
                            <br />
                            <span className="text-red-600">
                                Loss: &#8377;
                                {props?.stats?.dayOpen?.pdhLoss !== undefined ? parseFloat(props.stats.dayOpen.pdhLoss).toFixed(0) : '0'}
                                {` (${props?.stats?.dayOpen?.pdhLossCnt !== undefined ? props.stats.dayOpen.pdhLossCnt : '0'})`}
                            </span>
                        </h1>
                    </div>
                    <div className="grid grid-cols-2 border-white p-1 bg-blue-100 border-2">
                        <h1 className="pl-2 text-[#232323] text-l font-medium border-r-2" >Day Open  {'<'} PDL</h1>
                        <h1 className="text-right pr-4 justify-center">
                            <span className="text-green-600">
                                Profit: &#8377;
                                {props?.stats?.dayOpen?.pdlProfit !== undefined ? parseFloat(props.stats.dayOpen.pdlProfit).toFixed(0) : '0'}
                                {` (${props?.stats?.dayOpen?.pdlProfitCnt !== undefined ? props.stats.dayOpen.pdlProfitCnt : '0'})`}
                            </span>
                            <br />
                            <span className="text-red-600">
                                Loss: &#8377;
                                {props?.stats?.dayOpen?.pdlLoss !== undefined ? parseFloat(props.stats.dayOpen.pdlLoss).toFixed(0) : '0'}
                                {` (${props?.stats?.dayOpen?.pdlLossCnt !== undefined ? props.stats.dayOpen.pdlLossCnt : '0'})`}
                            </span>
                        </h1>
                    </div>
                    <div className="grid grid-cols-2 bg-white border-white p-1 border-2">
                        <h1 className="pl-2 text-[#232323] text-l font-medium border-r-2 border-white" >Vix</h1>
                        <h1 className="text-right pr-4 justify-center">
                            From 1 to 10(inclusive) : <span style={{ color: props?.stats?.vix[0]?.profit > 0 ? 'green' : 'red' }}>&#8377;{parseFloat(props?.stats?.vix[0]?.profit).toFixed(0)}</span>{`(${props?.stats?.vix[0]?.cnt})`}<br />
                            From 11 to 15(inclusive) : <span style={{ color: props?.stats?.vix[1]?.profit > 0 ? 'green' : 'red' }}>&#8377;{parseFloat(props?.stats?.vix[1]?.profit).toFixed(0)}</span>{`(${props?.stats?.vix[1]?.cnt})`}<br />
                            From 16 to 20(inclusive): <span style={{ color: props?.stats?.vix[2]?.profit > 0 ? 'green' : 'red' }}>&#8377;{parseFloat(props?.stats?.vix[2]?.profit).toFixed(0)}</span>{`(${props?.stats?.vix[2]?.cnt})`}<br />
                            Above than 20: <span style={{ color: props?.stats?.vix[3]?.profit > 0 ? 'green' : 'red' }}>&#8377;{parseFloat(props?.stats?.vix[3]?.profit).toFixed(0)}</span>{`(${props?.stats?.vix[3]?.cnt})`}</h1>
                    </div>

                </div>
            </div>

            {/* <div className='flex text-center justify-center mb-5'><div className='bg-white flex text-center justify-center p-2 w-48 cursor-pointer' onClick={() => { setShowStats(!showStats); }}>{showStats ? 'SHOW MORE STATS' : 'SHOW LESS STATS'}{showStats ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}</div></div> */}
        </>
    )
}

export default Main