import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode"; // Assuming you have jwt-decode library installed
import secureLocalStorage from 'react-secure-storage'

const ProtectedRoute = (props) => {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    let token = secureLocalStorage.getItem('backtest_token');
    let active = secureLocalStorage.getItem('active');

    // Remove the 'backtest_' prefix if it exists
    if (token && token.startsWith('backtest_')) {
      token = token.replace('backtest_', '');
    } else {
      navigate('/backtest/login');
    }

    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000; // Convert milliseconds to seconds
        if (decodedToken.exp > currentTime) {
          // Token is valid
          active ? setShow(true) : navigate('/backtest/profile');
        } else {
          // Token has expired, redirect to login
          navigate('/backtest/login');
        }
      } catch (error) {
        // Error decoding token, redirect to login
        navigate('/backtest/login');
      }
    } else {
      // Token not found, redirect to login
      navigate('/backtest/login');
    }
  }, [navigate]);

  return show && <props.component />;
};

export default ProtectedRoute;