import React, { useState } from 'react'
import axios from 'axios';
import { Dialog } from '@mui/material';
import useBucketStore from '../../store/BucketStore';
import { UPDATE_BASKET } from '../../constant/constant';
import secureLocalStorage from 'react-secure-storage';
import { useNavigate } from 'react-router-dom';
import { extractErrorMessage } from '../../utility/utils';
import { toast } from 'react-toastify';
import { MdImportExport } from 'react-icons/md';
import { MdClose } from 'react-icons/md';

const ImportBasket = (props) => {
    const [visibleImportBasket, setVisibleImportBasket] = useState(false);
    const [chooseBasket, setChooseBasket] = useState("");
    const [chooseStrategy, setChooseStrategy] = useState([]);
    const [chooseAllStrategy, setChooseAllStrategy] = useState(false);
    const { activeBasket, updateActiveBasket, basketList, activeBasketName, updateStrategyCheckedAll,
        updateDayOption, updateStatsMultiplier, updateStatsInclude, updateSlippage, dayOption, strategyMultiplier, include, slippage } = useBucketStore();
    const navigate = useNavigate();

    const addBasketOnActiveBasket = () => {
        console.log(activeBasket, 'active Basket',chooseBasket,chooseStrategy)
        // validation
        if(chooseBasket=="" || !chooseBasket){
          toast.warn('Option Matrix Required!!');
          return;
        }else if(!chooseAllStrategy && chooseStrategy.length==0){
            toast.warn('Minimum Choose One Strategy!!')
            return;
        }
        if (chooseAllStrategy) {
            const d1 = activeBasket;
            const temp = [...d1.strategy, ...chooseBasket.strategy]
            const temp1 = activeBasket;
            temp1.strategy = temp;

            const id = secureLocalStorage.getItem('backtest_id');
            // Get the token from secureLocalStorage
            let token = secureLocalStorage.getItem('backtest_token');

            // Remove the 'backtest_' prefix if it exists
            if (token && token.startsWith('backtest_')) {
                token = token.replace('backtest_', '');
            } else {
                navigate('/backtest/login');
            }
            let config = { method: "post", url: `${UPDATE_BASKET}/${id}/${activeBasketName}`, headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` }, data: temp1.strategy };
            axios
                .request(config)
                .then((response) => {
                    toast.success('Basket Added Successfully!!!');
                    setChooseAllStrategy(false);
                    setVisibleImportBasket(false);
                    updateActiveBasket(temp1);
                    updateStrategyCheckedAll(Array(temp1.strategy.length).fill(true));

                    updateDayOption([...dayOption, [1, 1, 1, 1, 1]]);
                    updateStatsMultiplier([...strategyMultiplier, 1]);
                    updateStatsInclude([...include, true]);
                    updateSlippage([...slippage, 0]);

                })
                .catch((error) => {
                    if (error?.response?.status === 401) {
                        navigate('/backtest/login');
                    } else {
                        toast.error(extractErrorMessage(error));
                    }
                    console.log(error, 'error at add basket on active basket')
                });
        } else {
            const d1 = activeBasket;
            const temp = [...d1.strategy];
            console.log(chooseStrategy, 'chooseStrategy')
            for (let i = 0; i < chooseStrategy?.length; i++) {
                console.log('choose strategy ...');
                chooseStrategy[i] && temp.push(chooseBasket.strategy[i]);
            }
            const temp1 = activeBasket;
            temp1.strategy = temp;

            const id = secureLocalStorage.getItem('backtest_id');
            // Get the token from secureLocalStorage
            let token = secureLocalStorage.getItem('backtest_token');

            // Remove the 'backtest_' prefix if it exists
            if (token && token.startsWith('backtest_')) {
                token = token.replace('backtest_', '');
            } else {
                navigate('/backtest/login');
            }
            let config = { method: "post", url: `${UPDATE_BASKET}/${id}/${activeBasketName}`, headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` }, data: temp1.strategy };
            axios
                .request(config)
                .then((response) => {
                    toast.success('Basket Added Successfully!!!');
                    setChooseStrategy([]);
                    setVisibleImportBasket(false);
                    updateActiveBasket(temp1);
                    updateStrategyCheckedAll(Array(temp1.strategy.length).fill(true));
                })
                .catch((error) => {
                    if (error?.response?.status === 401) {
                        navigate('/backtest/login');
                    } else {
                        toast.error(extractErrorMessage(error));
                    }
                    console.log(error, 'error at bucket(add basket on active basket)')
                });
        }
    }
    return (
        <>
            <button
                className="p-2 bg-gradient-to-r from-blue-500 to-blue-700 text-white rounded-md shadow-md hover:shadow-lg transition-transform duration-300 flex items-center justify-center gap-2"
                onClick={() => setVisibleImportBasket(true)}
            >
                <MdImportExport className="text-2xl font-semibold" />
                Import Option Matrix
            </button>
            {/* Dialog Box for import Basket List */}
            <Dialog className="border-2 border-t-8 border-blue-500 rounded-xl p-2" maxWidth="lg" onClose={(event, reason) => {
                if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                    setVisibleImportBasket(false);
                }
            }} open={visibleImportBasket}>
                <div className="flex flex-row border-b-2 bg-blue-400 text-white">
                    <div className="text-xl p-2 w-96">List of Option Matrix</div>
                    <div className="text-xl flex justify-end font-semibold p-2 cursor-pointer"><MdClose onClick={() => { setVisibleImportBasket(false); }} /></div>
                </div>
                <div className="flex flex-col p-6">
                    <div className="text-black">Option Matrix List:</div>
                    <select
                        className="border-2 border-black rounded p-1"
                        value={chooseBasket ? chooseBasket.bucket_name : ''}  // Use an identifier instead of the whole object
                        onChange={(e) => {
                            const selectedBasket = basketList.find(item => item.bucket_name === e.target.value);
                            console.log(selectedBasket, 'choose basket');
                            setChooseBasket(selectedBasket);
                            setChooseStrategy(Array(selectedBasket?.strategy?.length || 0).fill(false));
                        }}
                    >
                        <option value="">Select Basket</option>
                        {basketList.map((item, key) => (
                            <option value={item.bucket_name} key={`basket-${key}`}>
                                {item.bucket_name}
                            </option>
                        ))}
                    </select>

                    <hr className="mt-2"></hr>
                    <div className="flex flex-col">

                        <div className="text-l font-serif">Select Strategy:</div>
                        {
                            chooseBasket && chooseBasket.strategy && chooseBasket.strategy.length > 0 &&
                            <div>
                                <div className="flex flex-row gap-2"><input type="checkbox" value={chooseAllStrategy} onChange={() => { setChooseAllStrategy(!chooseAllStrategy); }}></input><div className="text-l text-blue-400">All Strategy</div></div>
                            </div>
                        }
                        {
                            chooseBasket && chooseBasket.strategy && chooseBasket.strategy.length > 0 && chooseBasket.strategy.map((item, key) => {
                                return (
                                    <div className="flex flex-row gap-2"><input type="checkbox" defaultChecked={chooseStrategy[key] ? chooseStrategy[key] : false} onChange={() => {
                                        const inputData = [...chooseStrategy]; inputData[key] = !inputData[key]; setChooseStrategy(inputData);
                                    }}></input><div className="text-l text-blue-400">{item.name}</div></div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="flex justify-end gap-2 mt-2 bg-gray-400 p-2">
                    <div className="border-2 p-1 w-24 bg-blue-600 rounded-lg justify-center text-center text-white cursor-pointer" onClick={() => { addBasketOnActiveBasket(); }}>Import</div>
                    <div className="border-2 p-1 w-24 bg-white rounded-lg justify-center text-center text-blue-600 border-blue-600 cursor-pointer" onClick={() => { setVisibleImportBasket(false); }}>Cancel</div>
                </div>
            </Dialog>
        </>
    )
}

export default ImportBasket