// Method to filter on the basis of gapup/down and prevdayHL
export const resultFilterMethod=(resultFilter,csv)=>{
    for (let k = 0; k < resultFilter.length; k++) {
        let value = (csv['marketEntryPrice']?.Open - csv['marketClosedPricePreviousDay']?.Close)
        if (resultFilter[k].type == 'GapUpDown') {
            if (resultFilter[k].sign1 == ">=") {
                if (!(resultFilter[k].or && resultFilter[k].sign2 == ">=")) {
                    if (!(value >= resultFilter[k].value1 || value >= resultFilter[k].value2))
                        return false;
                } else if (resultFilter[k].or && resultFilter[k].sign2 == "<=") {
                    if (!(value >= resultFilter[k].value1 || value <= resultFilter[k].value2))
                        return false;
                } else if (!(value >= resultFilter[k].value1 && !resultFilter[k].or))
                    return false;
            } else {
                if (resultFilter[k].or && resultFilter[k].sign2 == ">=") {
                    if (!(value <= resultFilter[k].value1 || value >= resultFilter[k].value2))
                        return false;
                } else if (resultFilter[k].or && resultFilter[k].sign2 == ">=") {
                    if (!(value <= resultFilter[k].value1 || value >= resultFilter[k].value2))
                        return false;
                } else if (!(value <= resultFilter[k].value1 && !resultFilter[k].value2))
                    return false;
            }
        } else
            if (resultFilter[k]?.type == 'PrevDayHL') {
                if (!(resultFilter[k]?.sign1 == csv['marketOpen'] || (resultFilter[k].or && resultFilter[k]?.sign2 == csv['marketOpen']))) {
                    return false;
                }
            }
    }
    return true;
}